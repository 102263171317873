import initializeLoadContentSaga from './content/load-content'
import initializeSubmitFormSaga from './checkout-form/form-submission'
import initializeAbandonedCartSaga from './user/abandoned-cart'
import initializeExistingUserSaga from './user/existing-user'
import initializeCreateUserSaga from './user/create-user'
import initializeGetAccessToken from './user/get-token'
import initializeLogin from './user/login'
import initializeLogout from './user/logout'
import initializeLoadSavedStateSaga from './saved-state/saved-state'
import initializeCalculatePriceSaga from './checkout-form/calculate-price'
import initializeCreateBillingInfoSaga from './checkout-form/create-billing-info'
import initializeCreatePaymentSaga from './checkout-form/create-payment'
import initializeGetPaymentMethodSaga from './checkout-form/get-payment-methods'
import initializeSubmitPaymentSaga from './checkout-form/submit-payment'
import initializeUserLoggedInSaga from './user/user-logged-in'
import initializeExistingUserSubmitFormSaga from './checkout-form/existing-user-form-submission'
import initializeResetCheckoutFormAffectedStatesSaga from './checkout-form/reset-checkout-form-affected-states'
import initializeGTCTrackingSaga from './tracking/track-event'
import initializeCalculateVolumeDiscountSaga from './checkout-form/calculate-volume-discount'
import initializeGetSelectedTier from './checkout-form/get-selected-tier'
import initializeGetVolumeDiscountSaga from './checkout-form/get-volume-discount'
import initializeSetBillingFrequencySaga from './checkout-form/set-billing-frequency'
import initializeValidateCouponSaga from './coupon/validate-coupon'
import initializeGetCouponDetailsSaga from './coupon/get-coupon-details'
import initializeCalculateCouponPriceSaga from './coupon/calculate-coupon-price'
import initializeObserveValidateCouponSaga from './coupon/observe-validate-coupon'
import initializeAttempUrlCouponSaga from './coupon/attempt-url-coupon'
import initialCheckCMSCouponSaga from './coupon/check-cms-coupon'
import initializeCheckVolumeDiscountAgainstRepriceCouponSaga from './coupon/check-volume-discount-against-reprice-coupon'
import initializeAttemptCMSCouponSaga from './coupon/attempt-cms-coupon'
import initializeReservePhoneNumberSaga from './number-picker/reserve-phone-number'

export const gtcSagas = {
    gtccontentSaga: initializeLoadContentSaga,
    gtcsubmitFormSaga: initializeSubmitFormSaga,
    gtcabandonedCartSaga: initializeAbandonedCartSaga,
    gtcexistingUserSaga: initializeExistingUserSaga,
    gtcgetAccessToken: initializeGetAccessToken,
    gtcuserLoginSaga: initializeLogin,
    gtcuserLogoutSaga: initializeLogout,
    gtccreateUserSaga: initializeCreateUserSaga,
    gtccalculatePriceSaga: initializeCalculatePriceSaga,
    gtccreateBillingInfoSaga: initializeCreateBillingInfoSaga,
    gtccreatePaymentSaga: initializeCreatePaymentSaga,
    gtcgetPaymentSaga: initializeGetPaymentMethodSaga,
    gtcsubmitPaymentSaga: initializeSubmitPaymentSaga,
    gtcloadSavedStateSaga: initializeLoadSavedStateSaga,
    gtcuserLoggedInSaga: initializeUserLoggedInSaga,
    gtcexistingUserSubmitFormSaga: initializeExistingUserSubmitFormSaga,
    gtcresetCheckoutFormAffectedStates: initializeResetCheckoutFormAffectedStatesSaga,
    gtcTrackingSaga: initializeGTCTrackingSaga,
    gtccalculateVolumeDiscountSaga: initializeCalculateVolumeDiscountSaga,
    gtcgetVolumeDiscountSaga: initializeGetVolumeDiscountSaga,
    gtcgetSelectedTierSaga: initializeGetSelectedTier,
    gtcsetBillingFrequencySaga: initializeSetBillingFrequencySaga,
    gtcvalidateCouponSaga: initializeValidateCouponSaga,
    gtcgetCouponDetailsSaga: initializeGetCouponDetailsSaga,
    gtccalculateCouponPriceSaga: initializeCalculateCouponPriceSaga,
    gtcobserveValidateCouponSaga: initializeObserveValidateCouponSaga,
    gtcattemptUrlCouponSaga: initializeAttempUrlCouponSaga,
    gtcheckCMSCouponSaga: initialCheckCMSCouponSaga,
    gtccheckVolumeDiscountAgainstRepriceCouponSaga: initializeCheckVolumeDiscountAgainstRepriceCouponSaga,
    gtcattemptCMSCouponSaga: initializeAttemptCMSCouponSaga,
    gtcreservePhoneNumberSaga: initializeReservePhoneNumberSaga,
}
export default gtcSagas
