import React, { createContext, FunctionComponent } from 'react'
import { connectRedux, GoToComponentProps } from '@common/utils'
import {
    mapGTCTrackingActions,
    mapGTCTrackingState,
    GTCTrackingContextType,
    GTCTrackingActionsType,
} from '@gtc/connectedComponents/Tracking/connector'

type GTCTrackingProps = GoToComponentProps<Record<string, unknown>, GTCTrackingActionsType>

export const GTCTrackingContext = createContext<GTCTrackingContextType | null>(null)
export const GTCTrackingProvider: FunctionComponent<GTCTrackingProps> = ({ children, actions }) => {
    const trackingContextValue: GTCTrackingContextType = {
        track: actions.track,
    }
    return <GTCTrackingContext.Provider value={trackingContextValue}>{children}</GTCTrackingContext.Provider>
}

export const ConnectedGTCTrackingProvider = connectRedux(
    GTCTrackingProvider,
    mapGTCTrackingState,
    mapGTCTrackingActions
)
