import { checkoutActions, CheckoutActionTypes } from '@gtc/state/checkout/actions'
import { getCheckoutState } from '@gtc/state/selectors'
import { call, put, select, takeEvery } from 'redux-saga/effects'
import { deleteReservation, reservePhoneNumber } from './reserve-phone-number-helpers'

function* reservePhoneNumberSaga(action: ReturnType<typeof checkoutActions.reservePhoneNumber>) {
    const { phoneNumberReservationId } = yield select(getCheckoutState)
    const { phoneNumber, vanityPhrase } = action.payload

    yield put(checkoutActions.setReservingPhoneNumber(true))

    // release previous selection (if the user changed their mind and chooses a different number)
    if (phoneNumberReservationId) {
        yield call(deleteReservation, phoneNumberReservationId)
    }

    // reserve selected phone number
    yield call(reservePhoneNumber, phoneNumber, vanityPhrase)

    yield put(checkoutActions.setReservingPhoneNumber(false))
}

function* initializeReservePhoneNumberSaga() {
    yield takeEvery(CheckoutActionTypes.RESERVE_PHONE_NUMBER, reservePhoneNumberSaga)
}

export default initializeReservePhoneNumberSaga
