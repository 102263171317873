import { AppState } from 'app-store'
import { checkoutActions } from '@gtc/state/checkout/actions'
import { SelectedAddon } from '@gtc/state/add-on'
import { getFatalError } from '@common/state/selectors'
import { PhoneNumber } from '@gtc/services/numbersApi'
import { getCurrentPlan } from '@gtc/utils/get-current-plan'
import {
    getUserState,
    getLabelContent,
    getCheckoutState,
    getPlanData,
    getBuyFormState,
    getCouponState,
    getContentData,
} from '@gtc/state/selectors'

import { BuyFormData } from '@gtc/state/buy-form/state'
import { getActiveCouponServerData } from '@gtc/utils'
import { fatalErrorActions } from '@common/state/fatal-error/actions'
import { CustomizeState } from './state'

export const mapCustomizeState = (state: AppState): CustomizeState => {
    const {
        selectedPlan,
        changePlan,
        quantityLabelLong,
        quantityLabelPlural,
        quantityLabel,
        internalErrorMessage,
        continue: contentContinue,
        quantityPopupInfo,
        additionalUserLicences,
        howManyMore,
        existingUserLicencePlural,
        existingUserLicense,
        additional,
        changePlanMethodModalHeader,
        changePlanModalSubheader,
        modalCloseButtonText,
        edit,
        changeNumberModalHeader,
        changeNumberModalContent,
        changePlanUrl,
        couponDisclaimer,
        monthly,
        annual,
        user,
        year,
        month,
        billedMonthlyPrice,
        billedAnnualPrice,
        numberPickerMainNumber: mainNumber,
        numberPickerMainNumberSubText: mainNumberSubText,
    } = getLabelContent(state)
    const {
        organizers,
        selectedPlanSku,
        selectedAddons,
        billingFrequency,
        selectedTierIndex,
        isChoosingPhoneNumber,
        phoneNumber,
        phoneNumberVanityPhrase,
        reservingPhoneNumber,
        reservingPhoneNumberError,
    } = getCheckoutState(state)
    const { initialData } = getBuyFormState(state)
    const planData = getPlanData(state)
    const currSelectedPlan = getCurrentPlan(selectedPlanSku, planData)
    const { Locale, CurrencyCode } = getContentData(state)
    const coupon = getCouponState(state)
    const { isCouponValidAndApplied, urlCouponCode } = coupon
    const couponServerData = getActiveCouponServerData(coupon, currSelectedPlan)
    const {
        userType,
        existingAccountInformation,
        loggedIn: { isUserLoggedIn },
    } = getUserState(state)
    const { fatalErrorBlocker } = getFatalError(state)

    return {
        content: {
            selectedPlan,
            changePlan,
            quantityLabelLong,
            quantityLabelPlural,
            quantityLabel,
            internalErrorMessage,
            continue: contentContinue,
            quantityPopupInfo,
            additionalUserLicences,
            howManyMore,
            existingUserLicencePlural,
            existingUserLicense,
            additional,
            changePlanMethodModalHeader,
            changePlanModalSubheader,
            modalCloseButtonText,
            edit,
            changeNumberModalHeader,
            changeNumberModalContent,
            changePlanUrl,
            couponDisclaimer,
            monthly,
            annual,
            user,
            year,
            month,
            billedMonthlyPrice,
            billedAnnualPrice,
            mainNumber,
            mainNumberSubText,
        },
        organizers,
        planData,
        selectedPlan: currSelectedPlan,
        isUserLoggedIn,
        selectedAddons,
        userType,
        fatalErrorBlocker,
        existingAccountInformation,
        initialData,
        billingFrequency,
        isCouponValidAndApplied,
        urlCouponCode,
        couponServerData,
        locale: Locale,
        selectedTierIndex,
        CurrencyCode,
        coupon,
        isChoosingPhoneNumber,
        phoneNumber,
        phoneNumberVanityPhrase,
        reservingPhoneNumber,
        reservingPhoneNumberError,
    }
}

export const mapCustomizeActions = {
    setOrganizers: (organizer: number) => checkoutActions.setOrganizers(organizer),
    setSelectedAddons: (addons: SelectedAddon[]) => checkoutActions.setSelectedAddons(addons),
    calculatePrice: (values: BuyFormData, planSKU?: string) =>
        checkoutActions.calculatePrice(values, 'customize', planSKU),
    setSelectedPlan: (planSku: string) => checkoutActions.setSelectedPlan(planSku),
    setIsChoosingPhoneNumber: (isChoosingPhoneNumber: boolean) =>
        checkoutActions.setIsChoosingPhoneNumber(isChoosingPhoneNumber),
    reservePhoneNumber: (phoneNumber: PhoneNumber, vanityPhrase: string) =>
        checkoutActions.reservePhoneNumber(phoneNumber, vanityPhrase),
    setFatalError: (hasError: boolean) => fatalErrorActions.setFatalError(hasError),
    setReservingPhoneNumberError: (message: string) => checkoutActions.setReservingPhoneNumberError(message),
}

export type CustomizeActionsType = typeof mapCustomizeActions
