import { call, takeEvery, put } from 'redux-saga/effects'
import { userActions, UserActionTypes } from '@gtc/state/user/actions'
import { createUserAccount } from '@gtc/services'
import { UserAccountType } from '@gtc/state/user/state'
import { fatalErrorActions } from '@common/state/fatal-error/actions'
import { TRACKING_EVENTS } from '@common/constants'
import { gtcTrackingActions } from '@gtc/state/tracking/actions'
import { TRACKING_COMPONENTS } from '@gtc/constants'
import { GenericResponse } from '@common/state/generic-response/generic-response'

export function* createUserSaga(action: ReturnType<typeof userActions.createNewUser>) {
    const componentName = TRACKING_COMPONENTS.CREATE_USER_SAGA
    try {
        const { userValues } = action.payload
        const userCreationResponse: GenericResponse = yield call(createUserAccount, userValues)
        if (userCreationResponse.data) {
            const userData: UserAccountType = userCreationResponse.data
            return userData
        }
    } catch (e) {
        yield put(
            gtcTrackingActions.track(
                {
                    event: TRACKING_EVENTS.ERROR_PURCHASE,
                    eventData: { errorMessage: 'failed to create user account' },
                },
                componentName
            )
        )
        yield put(fatalErrorActions.setFatalError(true, { trackErrorMessage: 'failed to create user account' }))
    }
    return null
}

function* initializeCreateUserSaga() {
    yield takeEvery(UserActionTypes.CREATE_NEW_USER, createUserSaga)
}

export default initializeCreateUserSaga
