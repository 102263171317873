import get from 'lodash/get'
import { formatDate } from '@common/utils'
import { Addon, SelectedAddon } from '@gtc/state/add-on'
import { Plan } from '@gtc/state/plan'
import { PlanAddonPriceItem } from '@gtc/state/checkout/state'
import { PRODUCT_PRICE_ITEM_TYPE } from '@gtc/constants'
import isEmpty from 'lodash/isEmpty'
import { CouponProcessedDataPerSku } from '@gtc/state/coupon/state'

export const getAddonPrice = (addon: Addon, annual: boolean, currency: string) => {
    let price: number = get(addon, ['pricingInfo', currency, 'price'], 0)
    if (annual) {
        price *= 12
    }
    return price
}

export const calculateTotalPriceWithAddons = (
    calculatedPrice: number,
    selectedAddons: SelectedAddon[],
    selectedPlan: Plan,
    isAnnual: boolean,
    currency: string
) => {
    let total = calculatedPrice
    selectedAddons.forEach((addon: SelectedAddon) => {
        const currAddon = selectedPlan.addons.find((x) => x.sKU === addon.sKU)
        if (currAddon) {
            total += getAddonPrice(currAddon, isAnnual, currency) * addon.quantity
        }
    })
    return total
}

export const formattedEndDate = (isAnnual: boolean, locale: string) => {
    const date = new Date()
    if (isAnnual) {
        date.setFullYear(date.getFullYear() + 1)
    } else {
        date.setMonth(date.getMonth() + 1)
    }

    const formattedExpDate = formatDate(date, 'UTC', locale)

    return formattedExpDate
}

export const getFeesFromProductPrice = (productPrices: PlanAddonPriceItem[] | undefined): number => {
    let feeAmount = 0
    if (productPrices && !isEmpty(productPrices)) {
        const [feePriceItem] = productPrices.filter(
            (productPriceItem) => productPriceItem.type === PRODUCT_PRICE_ITEM_TYPE.fee
        )
        feeAmount = feePriceItem && !isEmpty(feePriceItem) ? feePriceItem.listPrice : 0
    }
    return feeAmount
}
// renewal billing amount is the  sum of ((unitprice-proratedamount) of plan * organizers + (unirprice-proratedamount) of addon * number of selected addons)
export const getRenewalAmountForAddonUser = (
    productPrices: PlanAddonPriceItem[] | undefined,
    organizers: number,
    selectedAddons: Addon[]
): number => {
    let renewalAmount = 0
    const selectedAddonQuantityMap = new Map(selectedAddons.map((addon) => [addon.sKU, addon.quantity]))
    if (productPrices && !isEmpty(productPrices)) {
        productPrices.forEach((productPriceItem) => {
            if (productPriceItem.type === PRODUCT_PRICE_ITEM_TYPE.product) {
                if (productPriceItem.itemId === 0) {
                    renewalAmount += (productPriceItem.unitPrice - productPriceItem.proratedAmount) * organizers
                } else {
                    const { productSKU } = productPriceItem
                    renewalAmount +=
                        (productPriceItem.unitPrice - productPriceItem.proratedAmount) *
                        (selectedAddonQuantityMap.get(productSKU) || 0)
                }
            }
        })
    }
    return renewalAmount
}

export const getCouponTotalSavings = (
    isCouponAppliedToPlan: boolean,
    couponProcessedDataPerSku: CouponProcessedDataPerSku | null,
    couponDoubleDipAmountForNewUser: number
): number => {
    if (couponProcessedDataPerSku && isCouponAppliedToPlan) {
        const totalCouponSavings = Object.keys(couponProcessedDataPerSku).reduce((total, productSku) => {
            const productCouponData = couponProcessedDataPerSku[productSku]
            if (productCouponData && !isEmpty(productCouponData)) {
                return total + productCouponData.discountTotalSavings
            }
            return total
        }, 0)
        return totalCouponSavings + couponDoubleDipAmountForNewUser
    }
    return couponDoubleDipAmountForNewUser
}
