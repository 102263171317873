import initializeTrackErrorSaga from './error/track-error'
import initializeFlexFormSaga from './flex/get-flex-token'
import initializeCreateTokenSaga from './flex/create-flex-token'
import initializeTrackingSaga from './tracking/track-event'
import initializeLoadLocationInfoSaga from './location/location-info'
import initializeValidateAddressSaga from './location/validate-address'
import initializeGetCountriesSaga from './location/get-countries'
import initializeCreateFlexMicroformSaga from './flex/create-flex-microform'
import initializeClearFlexMicroformSaga from './flex/clear-flex-microform-state'
import initializePostToMarketoSaga from './marketing/marketo'
import initializeCreateGetInternationalBlocker from './location/international-blocker'
import initializeUpdateAccountEmailVerificationSaga from './account/update-account-email-verification'
import initializeSetLanguageSaga from './content/set-language'

export const commonSagas = {
    countriesSaga: initializeGetCountriesSaga,
    errorSaga: initializeTrackErrorSaga,
    flexFormSaga: initializeFlexFormSaga,
    createTokenSaga: initializeCreateTokenSaga,
    trackingSaga: initializeTrackingSaga,
    locationSaga: initializeLoadLocationInfoSaga,
    validateAddressSaga: initializeValidateAddressSaga,
    createFlexMicroformSaga: initializeCreateFlexMicroformSaga,
    clearFlexMicroformSaga: initializeClearFlexMicroformSaga,
    postToMarketoSaga: initializePostToMarketoSaga,
    internationalBlockerSaga: initializeCreateGetInternationalBlocker,
    updateAccountEmailVerificationSaga: initializeUpdateAccountEmailVerificationSaga,
    setLanguage: initializeSetLanguageSaga,
}
export default commonSagas
