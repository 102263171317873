import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { ErrorBoundary } from '@common/components'
import { initializeApplicationInsights } from '@common/utils/application-insight'
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js'
import { AppRoutes } from './Routes'
import { appStore } from './app-store'

const reactPlugin = initializeApplicationInsights()

const App = () => (
    // we will have to change/move this code and make it part of products/g2c and have separate webpack build process
    <>
        <ErrorBoundary>
            <AppInsightsContext.Provider value={reactPlugin}>
                <Provider store={appStore}>
                    <AppRoutes />
                </Provider>
            </AppInsightsContext.Provider>
        </ErrorBoundary>
    </>
)

/**
 * Inject overall application store state and routes
 *
 * @todo update in the future to route different sites
 */
render(<App />, document.getElementById('app'))
