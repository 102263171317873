import { CURRENCY } from '@common/constants'
import { labelContentInitialData } from '@gtc/data'
import { ContentState } from './state'
import { ContentActionTypes } from './actions'
import type { ContentActions } from './actions'

export const initialContentState: ContentState = {
    contentData: {
        CurrencyCode: CURRENCY.USD,
        ContentLoaded: false,
        Locale: 'en-US',
        PlanGroupId: '',
        DefaultCountry: '',
        marketoProductName: '',
        marketoSalesBrief: '',
        marketoSalesforceCampaignId: '',
        submittoMarketo: true,
    },
    labelContent: labelContentInitialData,
    planData: [],
    contentLoaded: false,
}

export const contentReducer = (previousState: ContentState | undefined, action: ContentActions): ContentState => {
    const state = previousState || initialContentState
    switch (action.type) {
        case ContentActionTypes.GET_LABEL_CONTENT: {
            return state
        }
        case ContentActionTypes.SET_CONTENT: {
            const { content, plans, labels } = action.payload
            return {
                ...state,
                contentData: {
                    ...state.contentData,
                    ...content,
                },
                labelContent: { ...labels },
                planData: [...plans],
            }
        }
        case ContentActionTypes.SET_CONTENT_LOADED: {
            return {
                ...state,
                contentLoaded: action.payload.loaded,
            }
        }
        case ContentActionTypes.GET_COMPARE_MODAL_CONTENT: {
            return state
        }
        case ContentActionTypes.SET_COUPON_DISCLAIMER: {
            return {
                ...state,
                labelContent: {
                    ...state.labelContent,
                    couponDisclaimer: action.payload.couponDisclaimer,
                },
            }
        }
        default:
            return state
    }
}
