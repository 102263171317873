import React, { FunctionComponent } from 'react'
import classNames from 'classnames'

import { ALLOWED_NUMERIC_EXTRA_CHARACTERS } from '@common/constants/input'
// import components
import { ErrorMessage } from '../ErrorMessage'

// Import Local Styles
import styles from './license-selector.module.css'

export interface LicenseSelectorProps {
    theme: 'dark' | 'light'
    organizers: number
    setOrganizers: (organizers: number) => void
    error: string
    min: number
    max: number
    qaTag?: string
}

export const LicenseSelector: FunctionComponent<LicenseSelectorProps> = (props) => {
    const { organizers, setOrganizers, min, max, qaTag, theme = 'light' } = props
    const minusClassList = classNames({
        [styles.button]: true,
        [styles.disabled]: organizers === min,
        [styles.button__dark]: theme === 'dark',
    })
    const plusClassList = classNames({
        [styles.button]: true,
        [styles.disabled]: organizers === max,
        [styles.button__dark]: theme === 'dark',
    })
    let qatSubtract
    let qatAdd
    let qatInput
    if (qaTag) {
        qatSubtract = `${qaTag}-subtract-${organizers === min ? 'disable' : 'enable'}`
        qatAdd = `${qaTag}-add-${organizers === max ? 'disable' : 'enable'}`
        qatInput = `${qaTag}-input`
    }

    // make sure value is within allowed values before setting itqr
    const updateOrganizers = (value: string | number) => {
        let targetValue = typeof value === 'string' ? Number(value.trim()) : value

        if (targetValue < min) {
            targetValue = min
        } else if (targetValue > max) {
            targetValue = max
        }

        setOrganizers(targetValue)
    }

    // for users using the plus/minus buttons
    const onClickButton = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, direction: string) => {
        event.preventDefault()
        updateOrganizers(direction === 'plus' ? organizers + 1 : organizers - 1)
    }

    // final validation and input sync up when they blur out
    const updateNumberSelector = (event: React.FocusEvent<HTMLInputElement> | React.ChangeEvent) => {
        const targetElement = event.target as HTMLInputElement
        updateOrganizers(targetElement.value)
    }

    // restrict characters the user can enter in the input field
    const validateKey = (e: React.KeyboardEvent) => {
        const { key } = e
        if (!Number(key) && !ALLOWED_NUMERIC_EXTRA_CHARACTERS.find((keyVal) => keyVal === key)) {
            e.preventDefault()
        }
    }

    return (
        <>
            {organizers >= max && <ErrorMessage warning errorMessage={props.error} />}
            <div className={styles.selector}>
                <button
                    type="button"
                    name="subtract"
                    data-qat={qatSubtract}
                    className={minusClassList}
                    onClick={(event) => {
                        onClickButton(event, 'minus')
                    }}
                >
                    <span>&#8211;</span>
                </button>
                <div className={styles['input-wrapper']} aria-labelledby="license-number">
                    <input
                        id="license-number"
                        maxLength={max.toString().length}
                        name="LicenseSelector"
                        onBlur={(e) => updateNumberSelector(e)}
                        onKeyDown={(e) => validateKey(e)}
                        onChange={(e) => updateNumberSelector(e)}
                        type="number"
                        className={styles.input}
                        value={organizers}
                        min={min}
                        max={max}
                        data-qat={qatInput}
                        aria-label="License number"
                    />
                </div>
                <button
                    type="button"
                    name="add"
                    className={plusClassList}
                    data-qat={qatAdd}
                    onClick={(event) => {
                        onClickButton(event, 'plus')
                    }}
                >
                    <span>+</span>
                </button>
            </div>
        </>
    )
}
