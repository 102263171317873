import { ActionUnion, createAction } from '@common/utils'
import { TrackingState } from '@common/state/tracking/state'

export enum GTCTrackingActionTypes {
    TRACK = '[gtc-tracking] gtc-track-event',
}

export const gtcTrackingActions = {
    track: (trackingInfo: TrackingState, component: string) =>
        createAction(GTCTrackingActionTypes.TRACK, { trackingInfo }, { component }),
}

export type GTCTrackingActions = ActionUnion<typeof gtcTrackingActions>
