import { LmiProCheckoutState } from '@lmipro/state/checkout/state'
import { getCheckoutState, getPlanData } from '@lmipro/state/selectors'
import { LmiProPlan } from '@lmipro/state/plan'
import { select, call, SelectEffect, CallEffect } from 'redux-saga/effects'
import { getCurrentPlan, getPlanNameSet } from '@lmipro/utils/get-current-plan'
import { getCurrentAddons } from '@lmipro/utils/get-current-addons'
import { LmiProAddon } from '@lmipro/state/add-on'
import { UrlParams, updatePlanUrlParam } from '@common/utils'
import isEmpty from 'lodash/isEmpty'

export function* getSelectedPlan(
    planSku?: string
): Generator<SelectEffect, LmiProPlan, LmiProCheckoutState & LmiProPlan[]> {
    const { selectedPlanSku }: LmiProCheckoutState = yield select(getCheckoutState)
    const planSkuToRetrieve = planSku || selectedPlanSku
    const planData: LmiProPlan[] = yield select(getPlanData)
    const selectedPlan: LmiProPlan = getCurrentPlan(planSkuToRetrieve, planData)
    if (!selectedPlan) {
        return planData[0]
    }
    return selectedPlan
}

export function* getSelectedAddons(): Generator<
    SelectEffect | CallEffect<LmiProPlan> | CallEffect<LmiProAddon[]>,
    LmiProAddon[],
    LmiProCheckoutState & LmiProPlan & LmiProAddon[]
> {
    const { selectedAddons }: LmiProCheckoutState = yield select(getCheckoutState)
    const selectedPlan: LmiProPlan = yield call(getSelectedPlan)
    const myAddons: LmiProAddon[] = yield call(getCurrentAddons, selectedAddons, selectedPlan)
    return myAddons
}

export function* getUpdatedPlanUrlParams() {
    const selectedPlan: LmiProPlan = yield call(getSelectedPlan)
    const planData: LmiProPlan[] = yield select(getPlanData)
    const planNameSet: Set<string> = yield call(getPlanNameSet, planData)
    const planUrlParams: UrlParams = yield call(
        updatePlanUrlParam,
        isEmpty(selectedPlan) ? '' : selectedPlan.name,
        planNameSet
    )
    return planUrlParams
}
