export enum RESOLVE_TRACKING_COMPONENTS {
    CHECKOUT_PAGE = 'checkout-form',
    FORM_SUBMISSION_SAGA = 'form-submission-saga',
    BILLING_PAGE = 'billing',
    CUSTOMIZE_PAGE = 'customize',
    NEXT_STEPS_PAGE = 'next-steps',
    FATAL_ERROR_PAGE = 'fatal-error',
    CALCULATE_PRICE_SAGA = 'calculate-price-saga',
    CREATE_USER_SAGA = 'create-user-saga',
    CREATE_BILLING_INFO_SAGA = 'create-billing-info-saga',
    CREATE_PAYMENT_SAGA = 'create-payment-saga',
    GET_AVAILABLE_PAYMENT_METHODS_SAGA = 'get-available-payment-methods-saga',
    SUBMIT_PAYMENT_SAGA = 'submit-payment-saga',
    EXISTING_USER_FORM_SUBMISSION_SAGA = 'existing-user-form-submission-saga',
}
