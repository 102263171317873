import { call, put, takeLeading, select } from 'redux-saga/effects'
import { locationActions, LocationActionTypes } from '@common/state/location/actions'
import { fatalErrorActions } from '@common/state/fatal-error/actions'
import { Country, LocationState } from '@common/state/location/state'
import commonServices, { SiteParamType } from '@common/services'
import { getLocationState } from '@common/state/selectors'
import { COUNTRIES, CURRENCY, LANGUAGE, LOCALES, RESTRICTED_COUNTRIES } from '@common/constants'
import { GenericResponse } from '@common/state/generic-response/generic-response'

export function* getCountriesSaga(action: ReturnType<typeof locationActions.getCountries>) {
    try {
        yield put(locationActions.setFetchedCountries(false))
        const params: SiteParamType = {
            sc_site: action.payload.sc_site,
        }
        const response: GenericResponse = yield call(commonServices.getCountries, params)
        if (response && response.data) {
            let countriesData: Country[] = response.data.countries

            // Get USA country data
            const usaCountryData = countriesData.find((country) => country.abbreviation === COUNTRIES.US)

            // Need to show the USA always on the top, so need to separate it and push it after sortng the coutries
            // Also need to remove restricted countries
            countriesData = countriesData.filter(
                (country) =>
                    !Object.values(RESTRICTED_COUNTRIES).includes(country.abbreviation as RESTRICTED_COUNTRIES) &&
                    country.abbreviation !== COUNTRIES.US
            )

            // Sort countries based off names
            const sortedCountryData: Country[] = countriesData.sort((a, b) => (a.name > b.name && 1) || -1)
            if (usaCountryData) {
                sortedCountryData.unshift(usaCountryData)
            }

            yield put(locationActions.setCountries(sortedCountryData))
            yield put(locationActions.setFetchedCountries(true))
            const locationInfoData: LocationState = yield select(getLocationState)
            const isLocationEligible = countriesData.find(
                (eligibleCountry) => locationInfoData.locationInfo.country === eligibleCountry.abbreviation
            )
            if (!isLocationEligible) {
                yield put(
                    locationActions.setLocationInfo({
                        country: COUNTRIES.US,
                        currency: CURRENCY.USD,
                        locale: LOCALES.EN_US,
                        language: LANGUAGE.EN,
                    })
                )
            }
        }
    } catch (e) {
        yield put(fatalErrorActions.setFatalError(true, { trackErrorMessage: 'country response failure' }))
    }
}

function* initializeGetCountriesSaga() {
    yield takeLeading(LocationActionTypes.GET_COUNTRIES, getCountriesSaga)
}

export default initializeGetCountriesSaga
