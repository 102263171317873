// Import Dependencies
import React, { FunctionComponent, useEffect, useState } from 'react'
import { GoToComponentProps, connectRedux, getFlexMicroform, setFlexMicroform } from '@common/utils'
import { FlexConnectorState } from '@common/state/flex/state'
import { FlexFormActionsType, mapFlexFormState, mapFlexFormActions } from '@common/connectedComponents/Flex/connector'
import { FLEX_KEY } from '@common/constants/flex'
import { useFlexForm, useMetrixScript } from '@common/hooks'
import { FlexTokenDataType } from '@common/services'

type FlexFormProps = GoToComponentProps<
    FlexConnectorState,
    FlexFormActionsType,
    {
        shouldRefresh: boolean
    }
>

/** Make sure to extract out unnecessary dependencies out of the HOC.
 *
 */

const FlexForm: FunctionComponent<FlexFormProps> = ({
    state: { flexState, currency, country, isLocationInfoLoaded },
    actions,
    props,
}) => {
    const [gettingKey, setGettingKey] = useState(false)
    const [needToRefresh, setNeedToRefresh] = useState(props.shouldRefresh)
    // load the flex script
    const flexScriptLoaded = useFlexForm()

    // load the metrix script
    const sessionId = `${flexState.fingerprint}`
    const orgId = FLEX_KEY
    const { mid } = flexState.keyInfo.flexKey

    useMetrixScript(!!flexState.microformCreated, orgId, sessionId, mid)

    // update the internal state to props.shouldRefresh
    useEffect(() => {
        setNeedToRefresh(props.shouldRefresh)
    }, [props.shouldRefresh])

    // Refresh the flex component and microform
    useEffect(() => {
        if (needToRefresh && flexState.microformCreated) {
            setFlexMicroform(null)
            actions.setMicroformCreated(false)
            actions.setCardValid(false)
            actions.setCvvValid(false)
            actions.setUserInteractedWithFlexField(false)
            actions.setUserInteractedWithFlexFieldCvv(false)
            setNeedToRefresh(false)
        }
    }, [actions, flexState.microformCreated, needToRefresh])

    // get the flex key
    useEffect(() => {
        const getFlexKey = () => {
            const data: FlexTokenDataType = {
                currency,
                country,
                targetOrigin: window.location.origin,
                skipSca: false,
            }
            actions.getFlexToken(data, 1)
        }

        if (flexState.keyInfo.flexKey.keyId === '' && !gettingKey && currency && isLocationInfoLoaded) {
            getFlexKey()
            setGettingKey(true)
        }
    }, [actions, isLocationInfoLoaded, country, currency, flexState.keyInfo.flexKey.keyId, gettingKey])

    // create the microform
    useEffect(() => {
        if (flexScriptLoaded && !flexState.microformCreated && flexState.keyInfo.flexKey.keyId) {
            actions.createFlexMicroform(flexState.keyInfo)
        }
    }, [actions, flexScriptLoaded, flexState.keyInfo, flexState.microformCreated])

    // destroy the flex frame when the component unmounts
    useEffect(() => {
        return () => {
            const microformInstance = getFlexMicroform()
            if (flexState.microformCreated && microformInstance) {
                microformInstance.teardown()
                actions.clearMicroformInstance()
            }
        }
    }, [actions, flexState.microformCreated])

    return <></>
}

export const ConnectedFlexForm = connectRedux(FlexForm, mapFlexFormState, mapFlexFormActions)
