import { BillingPeriod, CURRENCY, TimePeriod } from '@common/constants'
import { Plan } from '@gtc/state/plan'
import { Price, Pricing, Tiers } from '@gtc/state/price'

const tierMap = new Map<string, number>()

export const getSelectedTierIndex = (
    currentOrganizers: number,
    billingFrequency: string,
    selectedPlan: Plan,
    currency: CURRENCY
) => {
    const { sKU, pricingInfo } = selectedPlan
    const identifier = `${sKU}-${currency}-${billingFrequency}-${currentOrganizers}`
    if (!tierMap.get(identifier)) {
        const currencyPricing = pricingInfo[currency as keyof Price] as Pricing
        const billingFrequencyKey = billingFrequency === TimePeriod.Year ? BillingPeriod.ANNUAL : BillingPeriod.MONTHLY
        const billingFrequencyTier = currencyPricing[billingFrequencyKey as keyof Pricing]

        const billingFrequencyTierKeys = Object.keys(billingFrequencyTier)

        billingFrequencyTierKeys.some((tierName, index) => {
            const tierData = billingFrequencyTier[tierName as keyof Tiers]
            if (currentOrganizers >= tierData.min && currentOrganizers <= tierData.max) {
                tierMap.set(identifier, index)
                return true
            }
            return false
        })
    }

    return tierMap.get(identifier) as number
}
