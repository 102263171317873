import React, { FunctionComponent } from 'react'
import classNames from 'classnames'

import styles from './radioInputField.module.css'

export interface RadioInputFieldProps {
    error?: string
    modifier?: string
}

export const RadioInputField: FunctionComponent<RadioInputFieldProps> = ({ error, modifier = '', children }) => {
    const wrapperClassList = classNames({
        [styles.wrapper]: true,
        [modifier]: !!modifier,
    })
    return (
        <div className={wrapperClassList}>
            <div className={styles.radios}>{children}</div>
            {error && <div className={styles['error-message']}>{error}</div>}
        </div>
    )
}
