import { ConnectContainerAppState } from '@gtc/store'

export const getLabelContent = (state: ConnectContainerAppState) => state.connect.content.labelContent
export const getCheckoutState = (state: ConnectContainerAppState) => state.connect.checkout
export const getPlanData = (state: ConnectContainerAppState) => state.connect.content.planData
export const getContentState = (state: ConnectContainerAppState) => state.connect.content
export const getContentData = (state: ConnectContainerAppState) => state.connect.content.contentData
export const getUserState = (state: ConnectContainerAppState) => state.connect.user
export const getBuyFormState = (state: ConnectContainerAppState) => state.connect.form
export const getCouponState = (state: ConnectContainerAppState) => state.connect.coupon
