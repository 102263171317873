import { takeLatest, put, call } from 'redux-saga/effects'

import { userActions, UserActionTypes } from '@gtc/state/user/actions'
import { fatalErrorActions } from '@common/state/fatal-error/actions'
import { authInit, saveToken, appInsightsTrackEvent } from '@common/utils'
import { clearUserSession } from '@common/services'
import { Token } from '@getgo/auth-client'

function* getAccessToken() {
    try {
        yield put(userActions.setVerifyingToken(true))
        const newAccessToken: Token | undefined = yield call(authInit)
        // if new token save it
        if (newAccessToken) {
            yield put(userActions.setAccessToken(newAccessToken))
            yield call(saveToken, newAccessToken)
        } else {
            try {
                yield call(clearUserSession)
            } catch (e: any) {
                appInsightsTrackEvent(e, { errormessage: e.message })
            }
        }

        yield put(userActions.setIsUserLoggedIn(!!newAccessToken))
        // to check if this action ran
        yield put(userActions.setVerifyShouldUserLogIn(true))
        yield put(userActions.setVerifyingToken(false))
    } catch (e: any) {
        yield put(fatalErrorActions.setFatalError(true, { trackErrorMessage: 'failed to get access token' }))
        yield put(userActions.setVerifyingToken(false))
        appInsightsTrackEvent(e, { errormessage: e.message })
    }
}

function* initializeGetAccessToken() {
    yield takeLatest(UserActionTypes.GET_ACCESS_TOKEN, getAccessToken)
}

export default initializeGetAccessToken
