import { call, takeEvery, put, select } from 'redux-saga/effects'
import { buyCheckoutActions, BuyCheckoutActionTypes } from '@buy/state/checkout/actions'
import commonServices from '@buy/services'
import { fatalErrorActions } from '@common/state/fatal-error/actions'
import { TRACKING_EVENTS } from '@common/constants'
import { buyTrackingActions } from '@buy/state/tracking/actions'
import { BUY_TRACKING_COMPONENTS } from '@buy/constants'
import { BuySubmitWithCardAddResponse } from '@buy/state/checkout/state'
import { FATAL_ERROR_TYPE } from '@common/state/fatal-error/state'
import { FlexState } from '@common/state/flex/state'
import { getFlexState } from '@common/state/selectors'

export function* createAndSubmitPaymentSaga(action: ReturnType<typeof buyCheckoutActions.createAndSubmitPayment>) {
    const componentName = BUY_TRACKING_COMPONENTS.CREATE_PAYMENT_SAGA
    const { keyInfo }: FlexState = yield select(getFlexState)
    try {
        const { submitValues } = action.payload
        const createAndSubmitPaymentResponse: BuySubmitWithCardAddResponse = yield call(
            commonServices.submitPaymentWithCardAdd,
            submitValues
        )
        if (createAndSubmitPaymentResponse.status === 200 || createAndSubmitPaymentResponse.status === 204) {
            return createAndSubmitPaymentResponse.data
        }
    } catch {
        yield put(
            buyTrackingActions.track(
                {
                    event: TRACKING_EVENTS.ERROR_PURCHASE,
                    eventData: { errorMessage: 'failed to save payment info' },
                },
                componentName
            )
        )
        yield put(
            fatalErrorActions.setFatalError(true, {
                fatalErrorType: keyInfo?.scaToken?.isScaEnabledForMid ? FATAL_ERROR_TYPE.CARDINAL_PAYMENT : undefined,
                trackErrorMessage: 'failed to save payment info',
            })
        )
    }
    return undefined
}

function* initializeCreateAndSubmitPaymentSaga() {
    yield takeEvery(BuyCheckoutActionTypes.CREATE_AND_SUBMIT_PAYMENT, createAndSubmitPaymentSaga)
}

export default initializeCreateAndSubmitPaymentSaga
