import React, { FunctionComponent, useState } from 'react'
import classNames from 'classnames'
import { Input, InputProps, PasswordStrengthIndicator } from '@common/components'

import Eye from '@common/images/icon-pw-show@2x.svg'
import EyeClosed from '@common/images/icon-pw-hide@2x.svg'

import styles from './password-validation.module.css'

export interface PasswordValidationProps extends InputProps {
    label: string
    touched: boolean
    genericPasswordLabel: string
    modifier?: string
    passwordStrength: string
    passwordStrengthLevel1: string
    passwordStrengthLevel2: string
    passwordStrengthLevel3: string
    passwordStrengthLevel4: string
    passwordStrengthTip1: string
    passwordStrengthTip2: string
    passwordStrengthTip3: string
    passwordStrengthTipDrawer: string
    isSubmittingRef: React.RefObject<boolean>
}

export const PasswordValidation: FunctionComponent<PasswordValidationProps> = ({
    touched,
    genericPasswordLabel,
    modifier = '',
    passwordStrength,
    passwordStrengthLevel1,
    passwordStrengthLevel2,
    passwordStrengthLevel3,
    passwordStrengthLevel4,
    passwordStrengthTipDrawer,
    passwordStrengthTip1,
    passwordStrengthTip2,
    passwordStrengthTip3,
    isSubmittingRef,
    ...props
}) => {
    const { id, label, error, value = '', disabled, valid } = props

    const [showPW, setShowPW] = useState(false)
    const [isPWFocused, setIsPWFocused] = useState(false)

    const setFocusState = (e: React.FocusEvent<HTMLInputElement>) => {
        setIsPWFocused(true)
        if (props.onFocus) props.onFocus(e)
    }

    const setBlurState = (e: React.FocusEvent<HTMLInputElement>) => {
        setIsPWFocused(false)
        if (props.onBlur) props.onBlur(e)
    }

    const classList = classNames({
        [styles.wrapper]: true,
        [modifier]: !!modifier,
    })

    const InputWrapperClassList = classNames({
        [styles['password-wrapper']]: true,
        [styles['password-wrapper--focused']]: isPWFocused,
        [styles['password-wrapper--error']]: error,
        [styles['password-wrapper--disabled']]: disabled,
        [styles['password-wrapper--valid']]: valid,
    })

    const labelClasslist = classNames({
        [styles.label]: true,
        [styles['label--focused']]: isPWFocused || valid || value,
    })

    return (
        <div className={classList}>
            <label className={labelClasslist} htmlFor={id}>
                {label}
            </label>
            <div className={InputWrapperClassList}>
                <Input
                    {...props}
                    onBlur={(e) => setBlurState(e)}
                    onFocus={(e) => setFocusState(e)}
                    type={showPW ? 'text' : 'password'}
                />
                <button onClick={() => setShowPW(!showPW)} className={styles.eye} type="button">
                    <img src={showPW ? EyeClosed : Eye} alt="eye" />
                </button>
            </div>

            <PasswordStrengthIndicator
                id="password_indicator"
                value={value}
                touched={touched}
                error={error}
                passwordStrength={passwordStrength}
                passwordStrengthLevel1={passwordStrengthLevel1}
                passwordStrengthLevel2={passwordStrengthLevel2}
                passwordStrengthLevel3={passwordStrengthLevel3}
                passwordStrengthLevel4={passwordStrengthLevel4}
                passwordStrengthTipDrawer={passwordStrengthTipDrawer}
                passwordStrengthTip1={passwordStrengthTip1}
                passwordStrengthTip2={passwordStrengthTip2}
                passwordStrengthTip3={passwordStrengthTip3}
                genericPasswordLabel={genericPasswordLabel}
                isSubmittingRef={isSubmittingRef}
                isFocused={isPWFocused}
            />
        </div>
    )
}
