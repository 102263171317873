import { couponActions, CouponActionTypes } from '@gtc/state/coupon/actions'
import { put, takeLatest } from 'redux-saga/effects'

function* attempUrlCouponSaga(action: ReturnType<typeof couponActions.setUrlCouponCode>) {
    const { couponCode } = action.payload
    if (couponCode) {
        // if url promotion available
        yield put(couponActions.getCouponDetails(couponCode as string))
    } else {
        yield put(couponActions.attemptForCMSCoupon())
    }
}

export default function* initializeAttempUrlCouponSaga() {
    yield takeLatest(CouponActionTypes.SET_URL_COUPON_CODE, attempUrlCouponSaga)
}
