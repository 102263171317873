import axios from 'axios'
import { stringifyParamsUsingArrayFormatNone } from '@common/utils/stringifyParamsUsingArrayFormatNone'
import { getGUID } from '@common/utils/getGUID'

export const numbersApiUserId = getGUID()

// search numbers
export interface PhoneNumber {
    e164Number: string
    city?: string
    region?: string
}
export interface NumbersRequest {
    areaCode?: string
    localVanity?: string
    tollFreeVanity?: string
    tollFreeWildcard?: string
    numberPattern?: string
    quantity?: number
}
export interface NumbersResponse {
    phoneNumberDetails: PhoneNumber[]
}
export function searchNumbers(params: NumbersRequest) {
    // See ecomm api: https://confluence.ops.expertcity.com/x/iD-ICg
    // and numbers api: https://developer-internal.goto.com/apis/numbers-external/v1#tag/Number-search/paths/~1numbers/get
    return axios.get('/ecommapi/connectproduct/numberssearch', { params })
}

// reserve phone numbers
export interface ReserveNumbersRequest {
    phoneNumbers: string[]
    userId: string
}
export interface ReserveNumbersResponse {
    [index: number]: {
        id: string
        createdByUserId: string
        creationTime: string
        expirationTime: string
        phoneNumber: string
        phoneNumberType: 'TOLL_FREE' | 'LOCAL'
        city: string
        state: string
        error: string
    }
}
export function reserveNumbers(data: ReserveNumbersRequest) {
    // See ecomm api: https://confluence.ops.expertcity.com/x/iD-ICg
    // and numbers api: https://developer-internal.goto.com/apis/numbers-external/v1#tag/Number-reservation/paths/~1reservations/post
    return axios.post('/ecommapi/connectproduct/reservenumbers', data)
}

// delete phone number reservation
export interface DeleteNumbersReservationRequest {
    reservationIds: string[]
    userId: string
}
export interface DeleteNumbersReservationResponse {
    [index: number]: {
        reservationId: string
        deleted: boolean
        error: string
    }
}
export function deleteNumbersReservation(params: DeleteNumbersReservationRequest) {
    // See ecomm api: https://confluence.ops.expertcity.com/x/iD-ICg
    // and numbers api: https://developer-internal.goto.com/apis/numbers-external/v1#tag/Number-reservation/paths/~1reservations/delete
    return axios.delete('/ecommapi/connectproduct/deletenumbersreservation', {
        params,
        // See stringifyParamsUsingArrayFormatNone.ts for the reason this is needed here
        paramsSerializer: stringifyParamsUsingArrayFormatNone,
    })
}

export interface NumbersOrderRequest {
    phoneNumbersToOrder: {
        reservationId: string
        phoneNumber: string
    }[]
    userId: string
    isTollfreeOutbound: boolean
}
export interface NumbersOrderResponse {
    id: string
    numbersOrdered: string[]
    orderDate: string
    orderedByUserId: string
    errorMessage: string
}
export function orderNumbers(data: NumbersOrderRequest) {
    // See ecomm api: https://confluence.ops.expertcity.com/x/iD-ICg
    // and numbers api: https://developer-internal.goto.com/apis/numbers-external/v1#tag/Number-order/paths/~1accounts~1{accountKey}~1numbers/post
    return axios.post('/ecommapi/connectproduct/numbersorder', data)
}
