import React, { FunctionComponent, ButtonHTMLAttributes } from 'react'
import classNames from 'classnames'
import { Theme } from '@common/constants/theme-types'

import styles from './common-button.module.css'

type Purpose = 'primary' | 'secondary' | 'tertiary' | 'quaternary'

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    modifier?: string
    purpose?: Purpose
    arrow?: boolean
    theme?: Theme
    size?: 'small' | 'medium' | 'large' | undefined
    qaTag?: string
    'aria-labelledby'?: string | undefined
}

export const CommonButton: FunctionComponent<ButtonProps> = ({
    modifier = '',
    purpose = 'primary',
    arrow,
    theme = 'default',
    size,
    children,
    disabled,
    qaTag = 'common-button',
    ...props
}) => {
    const classList = classNames({
        [styles.button]: true,
        [styles['button--arrow']]: arrow,
        [styles[`button--${theme}`]]: true,
        [styles[`button--${theme}-${purpose}`]]: true,
        [styles[`button--${size}`]]: size,
        [modifier]: modifier,
        [styles.disabled]: disabled,
    })

    return (
        <button {...props} className={classList} disabled={disabled} data-qat={qaTag}>
            {children}
        </button>
    )
}
