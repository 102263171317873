import { takeEvery, put } from 'redux-saga/effects'
import { resolveBuyFormActions, ResolveBuyFormActionTypes } from '@gtresolve/state/buy-form/actions'
import { locationActions } from '@common/state/location/actions'
import { locationInitialData } from '@common/data'
import { resolveCheckoutActions } from '@gtresolve/state/checkout/actions'
import { initialCheckoutState } from '@gtresolve/state/checkout/reducer'

export function* resetBuyFormAffectedStatesSaga() {
    yield put(resolveBuyFormActions.resetForm())
    yield put(locationActions.setValidAddress(false))
    yield put(locationActions.setCorrectedAddress(locationInitialData.correctedAddress))
    yield put(locationActions.setAddressErrors(locationInitialData.addressErrors))
    yield put(
        resolveCheckoutActions.setCalculatedPrice(
            [],
            initialCheckoutState.calculatedNetTotal,
            initialCheckoutState.calculatedTotal,
            initialCheckoutState.calculatedTax,
            initialCheckoutState.calculatedCredit,
            initialCheckoutState.anniversaryDate
        )
    )
}

function* initializeResetBuyFormAffectedStatesSaga() {
    yield takeEvery(ResolveBuyFormActionTypes.RESET_BUY_FORM_AFFECTED_STATES, resetBuyFormAffectedStatesSaga)
}

export default initializeResetBuyFormAffectedStatesSaga
