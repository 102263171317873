import throttle from 'lodash/throttle'
import { MiddlewareAPI, Dispatch, Action } from 'redux'

export const createThrottleMiddleware = (throttlefns: ((store: MiddlewareAPI) => void)[]) => {
    const throttleMiddleware = (store: MiddlewareAPI) => (next: Dispatch) => (action: Action) => {
        const resultState = next(action)
        const throttledfn = throttle(() => {
            throttlefns.forEach((throttlefn) => {
                throttlefn(store)
            })
        }, 1000)
        throttledfn()
        return resultState
    }
    return throttleMiddleware
}
