import { locationActions } from '@common/state/location/actions'
import { getLocationState } from '@common/state/selectors'
import { AppState } from 'app-store'
import { SC_SITE } from '@common/constants'
import { InternationalBlockerViewState } from './state'

export const mapInternationalBlockerState = (state: AppState): InternationalBlockerViewState => {
    const { isLocationInfoLoaded, isCountriesLoaded, isUserCountryAllowed } = getLocationState(state)

    return { isLocationInfoLoaded, isCountriesLoaded, isUserCountryAllowed }
}

export const mapInternationalBlockerActions = {
    getCountries: () => locationActions.getCountries(SC_SITE.CONNECT),
    getLocationInfo: () => locationActions.getLocationInfo(),
    getIsUserCountryAllowed: () => locationActions.getIsUserCountryAllowed(),
    setIsUserCountryAllowed: (isUserCountryAllowed: boolean) =>
        locationActions.setIsUserCountryAllowed(isUserCountryAllowed, 'modal'),
}

export type InternationalBlockerType = typeof mapInternationalBlockerActions
