import { buyCouponActions, BuyCouponActionTypes } from '@buy/state/coupon/actions'
import { BuyPlan } from '@buy/state/plan'
import isEmpty from 'lodash/isEmpty'
import { call, put, takeLatest } from 'redux-saga/effects'
import { getSelectedPlan } from '../content/content-helpers'

function* checkCMSCouponSaga(action: ReturnType<typeof buyCouponActions.checkCMSCoupon>) {
    // clear out previous coupon processed data
    yield put(buyCouponActions.resetCouponData())
    const { selectedPlanSku } = action.payload
    const selectedPlan: BuyPlan = yield call(getSelectedPlan, selectedPlanSku)
    const { activeCoupon } = selectedPlan ?? {} ?? {}
    if (activeCoupon && !isEmpty(activeCoupon)) {
        // apply the cms coupon only if cms coupon is available
        yield put(buyCouponActions.observeValidateCMSCoupon(activeCoupon))
    }
}

export default function* initialCheckCMSCouponSaga() {
    yield takeLatest(BuyCouponActionTypes.CHECK_CMS_COUPON, checkCMSCouponSaga)
}
