import { BillingPeriod, CURRENCY, TimePeriod } from '@common/constants'
import { LocationState } from '@common/state/location/state'
import { getLocationState } from '@common/state/selectors'
import { lmiproCheckoutActions, LmiProCheckoutActionTypes } from '@lmipro/state/checkout/actions'
import { LmiProCheckoutState, LmiProVolumeDiscountPerTier } from '@lmipro/state/checkout/state'
import { getCheckoutState, getUserState } from '@lmipro/state/selectors'
import { select } from 'redux-saga-test-plan/matchers'
import { call, put, take, takeLatest } from 'redux-saga/effects'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import { LmiProUserState } from '@lmipro/state/user/state'
import { LMIPRO_USER_TYPES } from '@lmipro/constants'
import { initialData } from '@lmipro/data/buy-form'
import { getTotalVolumeDiscount } from '@lmipro/utils'

function* getVolumeDiscountSaga(action: ReturnType<typeof lmiproCheckoutActions.getVolumeDiscount>) {
    const { userType }: LmiProUserState = yield select(getUserState)
    if (userType === LMIPRO_USER_TYPES.ADDON) {
        const buyFormInitialData = { ...initialData, CountryCode: '' }
        yield put(lmiproCheckoutActions.calculatePrice(buyFormInitialData, 'get volume discount saga'))
    } else {
        const { organizers, selectedTierIndex, billingFrequency } = action.payload
        const { locationInfo }: LocationState = yield select(getLocationState)
        const { currency = CURRENCY.USD } = locationInfo
        const { volumeDiscount, selectedPlanSku }: LmiProCheckoutState = yield select(getCheckoutState)
        const billingFrequencyKey = billingFrequency === TimePeriod.Year ? BillingPeriod.ANNUAL : BillingPeriod.MONTHLY
        const isAnnual = billingFrequencyKey === BillingPeriod.ANNUAL
        const volumeDiscountPerTier: LmiProVolumeDiscountPerTier = get(volumeDiscount, [
            selectedPlanSku,
            currency,
            billingFrequencyKey,
            selectedTierIndex,
        ])

        if (!isEmpty(volumeDiscountPerTier)) {
            const { discountPricePerUnit } = volumeDiscountPerTier
            const totalVolumeDiscount: number = yield call(
                getTotalVolumeDiscount,
                discountPricePerUnit,
                organizers,
                isAnnual
            )
            yield put(lmiproCheckoutActions.setCurrentVolumeDiscountPriceAndPercentage(totalVolumeDiscount))
        } else {
            // since the volume discount is not available, we will wait to listen for next volume
            // discount set action being dispatched to store and then get the data
            const setVolumeDiscountAction: ReturnType<typeof lmiproCheckoutActions.setVolumeDiscount> = yield take(
                LmiProCheckoutActionTypes.SET_VOLUME_DISCOUNT
            )
            const { volumeDiscountForSelectedPlanAndCurrency } = setVolumeDiscountAction.payload
            const volumeDiscountPerTierNow = get(volumeDiscountForSelectedPlanAndCurrency, [
                billingFrequencyKey,
                selectedTierIndex,
            ])
            const { discountPricePerUnit } = volumeDiscountPerTierNow
            const totalVolumeDiscount: number = yield call(
                getTotalVolumeDiscount,
                discountPricePerUnit,
                organizers,
                isAnnual
            )
            yield put(lmiproCheckoutActions.setCurrentVolumeDiscountPriceAndPercentage(totalVolumeDiscount))
        }
    }
}

function* initializeGetVolumeDiscountSaga() {
    yield takeLatest(LmiProCheckoutActionTypes.GET_VOLUME_DISCOUNT, getVolumeDiscountSaga)
}

export default initializeGetVolumeDiscountSaga
