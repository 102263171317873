import { call, put, takeEvery } from 'redux-saga/effects'
import { ResolveCheckoutActionTypes } from '@gtresolve/state/checkout/actions'
import { getAvailablePaymentMethods } from '@gtresolve/services'
import { ResolvePaymentDataReturnType } from '@gtresolve/state/checkout/state'
import { fatalErrorActions } from '@common/state/fatal-error/actions'
import { TRACKING_EVENTS } from '@common/constants'
import { resolveTrackingActions } from '@gtresolve/state/tracking/actions'
import { RESOLVE_TRACKING_COMPONENTS } from '@gtresolve/constants'
import { GenericResponse } from '@common/state/generic-response/generic-response'

export function* getAvailablePaymentMethodsSaga() {
    const componentName = RESOLVE_TRACKING_COMPONENTS.GET_AVAILABLE_PAYMENT_METHODS_SAGA
    try {
        const paymentMethodsCreation: GenericResponse = yield call(getAvailablePaymentMethods)
        if (paymentMethodsCreation.data) {
            const paymentData: ResolvePaymentDataReturnType[] = paymentMethodsCreation.data
            return paymentData
        }
    } catch (e) {
        yield put(
            resolveTrackingActions.track(
                {
                    event: TRACKING_EVENTS.ERROR_PURCHASE,
                    eventData: { errorMessage: 'failed to get available payment methods' },
                },
                componentName
            )
        )
        yield put(
            fatalErrorActions.setFatalError(true, { trackErrorMessage: 'failed to get available payment methods' })
        )
    }
    return null
}

function* initializeGetAvailablePaymentMethodsSaga() {
    yield takeEvery(ResolveCheckoutActionTypes.GET_AVAILABLE_PAYMENT_METHODS, getAvailablePaymentMethodsSaga)
}

export default initializeGetAvailablePaymentMethodsSaga
