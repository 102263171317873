import { ActionUnion, createAction } from '@common/utils'
import { SC_SITE } from '@common/constants'
import { Country, State, Address, LocationInfoState, LocationFormData, AddressError } from './state'

export enum LocationActionTypes {
    SET_COUNTRIES = '[location-actions] set-countries',
    SET_FETCHED_COUNTRIES = '[location-actions] set-fetched-countries',
    SET_STATES = '[location-actions] set-states',
    SET_COUNTRY_HAS_STATES = '[location-actions] set-country-has-states',
    GET_COUNTRIES = '[location-actions] get-countries',
    VALIDATE_ADDRESS = '[location-actions] validate-address',
    SET_VALID_ADDRESS = '[location-actions] set-valid-address',
    SET_IS_VALIDADING_ADDRESS = '[location-actions] set-is-validating-address',
    SET_CORRECTED_ADDRESS = '[location-actions] set-corrected-address',
    SET_ADDRESS_ERRORS = '[location-actions] set-address-errors',
    GET_LOCATION_INFO = '[location-info] get-location-info',
    SET_LOCATION_INFO = '[location-info] set-location-info',
    SET_IS_LOCATION_INFO_LOADED = '[location-info] set-is-location-info-loaded',
    SET_IS_USER_COUNTRY_ALLOWED = '[location-info] set-is-user-country-allowed',
    GET_IS_USER_COUNTRY_ALLOWED = '[location-info] get-is-user-country-allowed',
}

export const locationActions = {
    setCountries: (countries: Country[]) =>
        createAction(LocationActionTypes.SET_COUNTRIES, {
            countries,
        }),
    setFetchedCountries: (isCountriesLoaded: boolean) =>
        createAction(LocationActionTypes.SET_FETCHED_COUNTRIES, {
            isCountriesLoaded,
        }),
    setStates: (states: State[]) =>
        createAction(LocationActionTypes.SET_STATES, {
            states,
        }),
    setCountryHasStates: (countryHasStates: boolean) =>
        createAction(LocationActionTypes.SET_COUNTRY_HAS_STATES, {
            countryHasStates,
        }),
    // eslint-disable-next-line camelcase
    getCountries: (sc_site: SC_SITE) => createAction(LocationActionTypes.GET_COUNTRIES, { sc_site }),
    validateAddress: (values: LocationFormData) =>
        createAction(LocationActionTypes.VALIDATE_ADDRESS, {
            values,
        }),
    setValidAddress: (isValid: boolean) =>
        createAction(LocationActionTypes.SET_VALID_ADDRESS, {
            isValid,
        }),
    setIsValidatingAddress: (isValidatingAddress: boolean) =>
        createAction(LocationActionTypes.SET_IS_VALIDADING_ADDRESS, {
            isValidatingAddress,
        }),
    setCorrectedAddress: (address: Address) =>
        createAction(LocationActionTypes.SET_CORRECTED_ADDRESS, {
            address,
        }),
    setAddressErrors: (addressErrors: AddressError[]) =>
        createAction(LocationActionTypes.SET_ADDRESS_ERRORS, {
            addressErrors,
        }),
    getLocationInfo: (isExternal = false) =>
        createAction(LocationActionTypes.GET_LOCATION_INFO, {
            isExternal,
        }),
    setLocationInfo: (locationInfo: LocationInfoState) =>
        createAction(LocationActionTypes.SET_LOCATION_INFO, {
            locationInfo,
        }),
    setIsLocationInfoLoaded: (isLocationInfoLoaded: boolean) =>
        createAction(LocationActionTypes.SET_IS_LOCATION_INFO_LOADED, {
            isLocationInfoLoaded,
        }),
    setIsUserCountryAllowed: (isUserCountryAllowed: boolean, component?: string) =>
        createAction(LocationActionTypes.SET_IS_USER_COUNTRY_ALLOWED, { isUserCountryAllowed }, { component }),
    getIsUserCountryAllowed: () => createAction(LocationActionTypes.GET_IS_USER_COUNTRY_ALLOWED),
}

export type LocationActions = ActionUnion<typeof locationActions>
