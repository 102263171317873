import { ExistingBillingInfoType } from '@common/state/user/state'

export const processExistingBillingInfo = (data: ExistingBillingInfoType) => {
    const processedBillingData: ExistingBillingInfoType = {
        accountType: data.accountType || '',
        addressLine1: data.addressLine1 || '',
        addressLine2: data.addressLine2 || '',
        anniversaryDate: data.anniversaryDate || '',
        city: data.city || '',
        companyName: data.companyName || '',
        country: data.country || '',
        email: data.email || '',
        federalTaxType: data.federalTaxType || '',
        firstName: data.firstName || '',
        hasInAppSubscription: !!data.hasInAppSubscription,
        lastName: data.lastName || '',
        paymentAccountKey: data.paymentAccountKey || '',
        phoneNumber: data.phoneNumber || '',
        postalCode: data.postalCode || '',
        state: data.state || '',
        status: data.status || '',
        taxNumber: data.taxNumber || '',
    }
    return processedBillingData
}
