import React, { FunctionComponent } from 'react'

// Import Components
import errorIcon from '@common/images/icon-error.svg'
import { BoxWrapper } from '../BoxWrapper'

import styles from './error-view.module.css'

export const ErrorView: FunctionComponent = ({ children }) => {
    return (
        <section className={styles.wrapper}>
            <BoxWrapper modifier={styles.msgBlock}>
                <img className={styles.errorImg} src={errorIcon} alt="error icon" />
                <p className={styles.errorMsg}>{children}</p>
            </BoxWrapper>
        </section>
    )
}

export default ErrorView
