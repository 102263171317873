import { FatalErrorState } from './state'
import { FatalErrorActionTypes } from './actions'
import type { FatalErrorActions } from './actions'

export const initialFatalErrorState: FatalErrorState = {
    isFatalError: false,
    fatalErrorMessage: '',
    fatalErrorBlocker: false,
    fatalErrorType: undefined,
}

export const fatalErrorReducer = (
    previousState: FatalErrorState | undefined,
    action: FatalErrorActions
): FatalErrorState => {
    const state = previousState || initialFatalErrorState
    switch (action.type) {
        case FatalErrorActionTypes.SET_FATAL_ERROR: {
            return {
                isFatalError: action.payload.hasError,
                fatalErrorMessage: action.payload.message?.fatalErrorMessage,
                fatalErrorBlocker: !!action.payload.blocker,
                fatalErrorType: action.payload.message?.fatalErrorType,
            }
        }
        default:
            return state
    }
}
