import { call, takeEvery } from 'redux-saga/effects'

import { UserActionTypes } from '@gtc/state/user/actions'
import { logout, UrlParams } from '@common/utils'
import { getUpdatedPlanUrlParams } from '../content/content-helpers'

function* userLogout() {
    try {
        const { pathname, search }: UrlParams = yield call(getUpdatedPlanUrlParams)
        yield call(logout, pathname, search)
    } catch (e) {
        // no error
    }
}

function* initializeLogout() {
    yield takeEvery(UserActionTypes.USER_LOGOUT, userLogout)
}

export default initializeLogout
