import { constantsType } from '@common/state/constants-state'

export enum CURRENCY {
    USD = 'USD',
    EUR = 'EUR',
    GBP = 'GBP',
    AUD = 'AUD',
    CAD = 'CAD',
    MXN = 'MXN',
}

export enum LOCALES {
    EN_US = 'en-US',
}

export enum LANGUAGE {
    EN = 'en',
}

export const CURRENCY_SYMBOLS: constantsType = {
    USD: '$',
    EUR: '€',
    GBP: '£',
    AUD: 'AU$',
    CAD: 'C$',
    MXN: 'MX$',
}
