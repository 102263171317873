/* eslint-disable camelcase */
import { GooglePlace } from '@common/state/addressAutoFill/state'
import { Address } from '@common/state/location/state'
import { getInnerHTMLByClassName } from './getInnerHTMLByClassName'

export const getAddressFromGooglePlace = (place: GooglePlace): Address => {
    const addressComponents = place.address_components || []
    const addAddress = place.adr_address || ''
    const htmlDocument = new DOMParser().parseFromString(addAddress, 'text/html')

    // Refer to: https://developers.google.com/maps/documentation/javascript/places-autocomplete#get-place-information
    // And: https://developers.google.com/maps/documentation/javascript/place-data-fields?hl=en_US
    const address: Address = {
        addressLine1: getInnerHTMLByClassName(htmlDocument, 'street-address', 0),
        city: getInnerHTMLByClassName(htmlDocument, 'locality', 0),
        state: getInnerHTMLByClassName(htmlDocument, 'region', 0),
        postalCode: getInnerHTMLByClassName(htmlDocument, 'postal-code', 0),
        country: addressComponents.find((c) => c.types.indexOf('country') > -1)?.short_name || '',
        phoneNumber: '',
    }

    return address
}
