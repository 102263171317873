import { combineReducers } from 'redux'
import { contentReducer } from '@buy/state/content/reducer'
import { checkoutReducer } from '@buy/state/checkout/reducer'
import { buyFormReducer } from '@buy/state/buy-form/reducer'
import { userReducer } from '@buy/state/user/reducer'
import { couponReducer } from '@buy/state/coupon/reducer'

export const buyAppReducer = combineReducers({
    content: contentReducer,
    checkout: checkoutReducer,
    form: buyFormReducer,
    user: userReducer,
    coupon: couponReducer,
})

export type BuyAppState = ReturnType<typeof buyAppReducer>
export type BuyContainerAppState = {
    buy: BuyAppState
}
