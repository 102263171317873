import React, { useEffect, useRef, useState } from 'react'
import { ModalDrawer } from '@common/components/ModalDrawer'
import { NumbersRequest, PhoneNumber } from '@gtc/services/numbersApi'
import { scrollToTop } from '@common/utils/scroll'
import { Navigation } from './components/Navigation/Navigation'
import { SearchFilters } from './components/SearchFilters/SearchFilters'
import { NumberPickerContent } from '../NumberPicker.types'
import { FooterButtons } from './components/FooterButtons/FooterButtons'
import { SearchResults } from './components/SearchResults/SearchResults'
import { VanityPhrase } from './components/VanityPhrase/VanityPhrase'

const searchQuantity = 20
const displayQuantity = 10
const tollFreeWildcard = '8**'

export interface NumberPickerModalProps {
    loading: boolean
    numberList: PhoneNumber[]
    content: NumberPickerContent
    handleDismiss: () => void
    handleSearchNumbers: (params: NumbersRequest) => void
    handleChangeNumber: (number: PhoneNumber, vanityPhrase: string) => void
    handleResetError?: () => void
    defaultSelectedNumber?: PhoneNumber
    defaultAreaCode?: string
    errorMessage?: string
    reservingPhoneNumber?: boolean
    isFirstTimeChoosing?: boolean
}

export const NumberPickerModal: React.FC<NumberPickerModalProps> = (props) => {
    // props
    const {
        handleSearchNumbers,
        handleChangeNumber,
        handleResetError,
        numberList,
        loading,
        defaultSelectedNumber,
        defaultAreaCode = '',
        content,
        errorMessage = '',
        reservingPhoneNumber = false,
        isFirstTimeChoosing = true,
    } = props

    // state
    const [areaCode, setAreaCode] = useState(defaultAreaCode)
    const [vanityPhrase, setVanityPhrase] = useState('')
    const [isLocalNumber, setIsLocalNumber] = useState(true)
    const [isBasicNumber, setIsBasicNumber] = useState(true)
    const [clickedSearch, setClickedSearch] = useState(false)
    const [number, setNumber] = useState(defaultSelectedNumber)

    // refs
    const scrollingDivRef = useRef<HTMLDivElement>(null)

    // consts
    const isTollFreeNumber = !isLocalNumber
    const isVanityNumber = !isBasicNumber

    // side effects
    useEffect(() => {
        // Whenever the errorMessage gets set, tell the modal's
        // scrollable div to scroll to the top
        if (errorMessage) {
            scrollToTop(scrollingDivRef.current)
        }
    }, [errorMessage])

    const resetError = () => {
        if (handleResetError) {
            handleResetError()
        }
    }

    const handleDismiss = () => {
        props.handleDismiss()
    }

    const handleClickLocal = () => {
        resetError()
        setIsLocalNumber(true)
        setVanityPhrase('')
        setIsBasicNumber(true)
        setClickedSearch(false)
    }

    const handleClickTollFree = () => {
        resetError()
        setIsLocalNumber(false)
        setIsBasicNumber(true)
        setVanityPhrase('')
        setClickedSearch(true)
        handleSearchNumbers({
            tollFreeWildcard,
            quantity: searchQuantity,
        })
    }

    const handleClickBasic = () => {
        setIsBasicNumber(true)
        setVanityPhrase('')
        if (isTollFreeNumber) {
            setClickedSearch(true)
            handleSearchNumbers({
                tollFreeWildcard,
                quantity: searchQuantity,
            })
        }
    }

    const handleClickVanity = () => {
        setClickedSearch(false)
        setIsBasicNumber(false)
        setAreaCode('')
    }

    const handleChangeAreaCode = (value: string) => {
        setAreaCode(value)
    }

    const handleSearch = () => {
        setClickedSearch(true)
        handleSearchNumbers({
            areaCode: isLocalNumber ? areaCode : undefined,
            quantity: searchQuantity,
            localVanity: isLocalNumber ? vanityPhrase : undefined,
            tollFreeVanity: isTollFreeNumber ? vanityPhrase : undefined,
        })
    }

    const handleReset = () => {
        resetError()
        setVanityPhrase('')
        setClickedSearch(false)
    }

    const handleSelectNumber = (newNumber: PhoneNumber) => {
        resetError()
        setNumber(newNumber)
    }

    const handleChangeVanityPhrase = (value: string) => {
        setVanityPhrase(value)
    }

    const handleSave = () => {
        if (handleChangeNumber) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            handleChangeNumber(number!, vanityPhrase!)
        }
    }

    const headerText = isFirstTimeChoosing ? content.chooseNumber : content.editNumber
    const isResetVisible = clickedSearch && (isLocalNumber || !isBasicNumber)
    const isSearchDisabled =
        // The Numbers API requires 3 characters for area code. (Note: The input field mask only allows up to 3 characters)
        (isLocalNumber && isBasicNumber && areaCode.length < 3) ||
        // The Numbers API requires 3 to 7 characters for local vanity phrase. (Note: The input field mask only allows up to 7 characters)
        (isLocalNumber && isVanityNumber && vanityPhrase.length < 3) ||
        // The Numbers API requires 4 to 7 characters for toll-free vanity phrase. (Note: The input field mask only allows up to 7 characters)
        (isTollFreeNumber && isVanityNumber && vanityPhrase.length < 4)
    const isSaveDisabled = !!errorMessage || !number || reservingPhoneNumber

    // The search results have a 5 minute cache on the server. So to avoid showing numbers that have already
    // been reserved by users of GoToAdmin's NumberPicker, we query for [queryQuantity], reverse that list, and
    // only display [displayQuantity] of the reversed list to our users. See WAE1-
    const displayNumberList = [...numberList].reverse().slice(0, displayQuantity)

    return (
        <ModalDrawer
            id="numberpicker_modal"
            qaTag="numberpicker_modal"
            handleDismiss={props.handleDismiss}
            headerText={headerText}
            closeText={content.close}
            showLoadingSpinner={reservingPhoneNumber}
            allowEscToDismiss={!reservingPhoneNumber} // Don't allow ESC to dismiss while saving
            scrollingDivRef={scrollingDivRef}
            actionButtons={
                <FooterButtons
                    content={content}
                    clickedSearch={clickedSearch}
                    isResetVisible={isResetVisible}
                    isSaveDisabled={isSaveDisabled}
                    isSearchDisabled={isSearchDisabled}
                    handleDismiss={handleDismiss}
                    handleReset={handleReset}
                    handleSave={handleSave}
                    handleSearch={handleSearch}
                />
            }
        >
            <Navigation
                content={content}
                isLocalNumber={isLocalNumber}
                isBasicNumber={isBasicNumber}
                handleClickLocal={handleClickLocal}
                handleClickTollFree={handleClickTollFree}
                handleClickBasic={handleClickBasic}
                handleClickVanity={handleClickVanity}
            />
            {isLocalNumber && isBasicNumber && !clickedSearch && (
                <SearchFilters
                    content={content}
                    handleChangeAreaCode={handleChangeAreaCode}
                    defaultAreaCode={areaCode}
                />
            )}
            {clickedSearch && (
                <SearchResults
                    content={content}
                    errorMessage={errorMessage}
                    loading={loading}
                    numberList={displayNumberList}
                    handleSelectNumber={handleSelectNumber}
                    defaultSelectedNumber={number}
                    isLocalNumber={isLocalNumber}
                    vanityPhrase={vanityPhrase}
                />
            )}
            {!isBasicNumber && !clickedSearch && (
                <VanityPhrase
                    content={content}
                    vanityPhrase={vanityPhrase}
                    handleChangeVanityPhrase={handleChangeVanityPhrase}
                />
            )}
        </ModalDrawer>
    )
}
