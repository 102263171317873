/* eslint-disable react/require-default-props */
import React from 'react'
import styles from './badge.module.css'

interface IProps {
    id?: string
    qaTag?: string
    children: React.ReactNode
}

export const Badge: React.FC<IProps> = ({ id = '', qaTag = '', children }) => (
    <div id={id} className={styles.wrapper} data-qat={qaTag}>
        {children}
    </div>
)
