import { PRODUCT_PRICE_ITEM_TYPE } from '@buy/constants'
import { BuyAddon } from '@buy/state/add-on'
import { BuyProductPriceItem } from '@buy/state/calculate-price'

export const getTotalProratedAmount = (productPrices: BuyProductPriceItem[]): number => {
    let proratedAmount = 0
    if (productPrices) {
        productPrices.forEach((productPriceItem) => {
            if (productPriceItem.type === PRODUCT_PRICE_ITEM_TYPE.product && productPriceItem.proratedAmount) {
                proratedAmount += productPriceItem.proratedAmount * productPriceItem.quantity
            }
        })
    }
    return proratedAmount
}

export const getAddonProrateAmount = (productPrices?: BuyProductPriceItem[], addon?: BuyAddon) => {
    let proratedAmount = 0
    if (productPrices && addon) {
        productPrices.forEach((productPriceItem) => {
            if (productPriceItem.proratedAmount && productPriceItem.productSKU === addon.sKU) {
                proratedAmount = productPriceItem.proratedAmount * productPriceItem.quantity
            }
        })
    }
    return proratedAmount
}
