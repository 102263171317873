import { call, takeEvery, put, select } from 'redux-saga/effects'
import { resolveCheckoutActions, ResolveCheckoutActionTypes } from '@gtresolve/state/checkout/actions'
import commonServices from '@gtresolve/services'
import { fatalErrorActions } from '@common/state/fatal-error/actions'
import { TRACKING_EVENTS } from '@common/constants'
import { resolveTrackingActions } from '@gtresolve/state/tracking/actions'
import { RESOLVE_TRACKING_COMPONENTS } from '@gtresolve/constants'
import { ResolveSubmitWithCardAddResponse } from '@gtresolve/state/checkout/state'
import { FATAL_ERROR_TYPE } from '@common/state/fatal-error/state'
import { FlexState } from '@common/state/flex/state'
import { getFlexState } from '@common/state/selectors'

export function* createAndSubmitPaymentSaga(action: ReturnType<typeof resolveCheckoutActions.createAndSubmitPayment>) {
    const componentName = RESOLVE_TRACKING_COMPONENTS.CREATE_PAYMENT_SAGA
    const { keyInfo }: FlexState = yield select(getFlexState)
    try {
        const { submitValues } = action.payload
        const createAndSubmitPaymentResponse: ResolveSubmitWithCardAddResponse = yield call(
            commonServices.submitPaymentWithCardAdd,
            submitValues
        )
        if (createAndSubmitPaymentResponse.status === 200 || createAndSubmitPaymentResponse.status === 204) {
            return createAndSubmitPaymentResponse.data
        }
    } catch {
        yield put(
            resolveTrackingActions.track(
                {
                    event: TRACKING_EVENTS.ERROR_PURCHASE,
                    eventData: { errorMessage: 'failed to save payment info' },
                },
                componentName
            )
        )
        yield put(
            fatalErrorActions.setFatalError(true, {
                fatalErrorType: keyInfo?.scaToken?.isScaEnabledForMid ? FATAL_ERROR_TYPE.CARDINAL_PAYMENT : undefined,
                trackErrorMessage: 'failed to save payment info',
            })
        )
    }
    return undefined
}

function* initializeCreateAndSubmitPaymentSaga() {
    yield takeEvery(ResolveCheckoutActionTypes.CREATE_AND_SUBMIT_PAYMENT, createAndSubmitPaymentSaga)
}

export default initializeCreateAndSubmitPaymentSaga
