import { couponActions, CouponActionTypes } from '@gtc/state/coupon/actions'
import { Plan } from '@gtc/state/plan'
import isEmpty from 'lodash/isEmpty'
import { call, put, takeLatest } from 'redux-saga/effects'
import { getSelectedPlan } from '../content/content-helpers'

function* checkCMSCouponSaga(action: ReturnType<typeof couponActions.checkCMSCoupon>) {
    // clear out previous coupon processed data
    yield put(couponActions.resetCouponData())
    const { selectedPlanSku } = action.payload
    const selectedPlan: Plan = yield call(getSelectedPlan, selectedPlanSku)
    const { activeCoupon } = selectedPlan
    if (activeCoupon && !isEmpty(activeCoupon)) {
        // apply the cms coupon only if cms coupon is available
        yield put(couponActions.observeValidateCMSCoupon(activeCoupon))
    }
}

export default function* initialCheckCMSCouponSaga() {
    yield takeLatest(CouponActionTypes.CHECK_CMS_COUPON, checkCMSCouponSaga)
}
