import { Microform } from '@common/state/flex/state'

let microform: Microform | null = null

export const getFlexMicroform = () => {
    return microform
}

export const setFlexMicroform = (microformInstance: Microform | null) => {
    microform = microformInstance
}
