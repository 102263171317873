import React, { FunctionComponent, useState, useEffect, InputHTMLAttributes } from 'react'
import classNames from 'classnames'

// Import Local Styles
import styles from './input.module.css'

export interface GenericInputProps extends InputHTMLAttributes<HTMLInputElement> {
    error?: string
    warning?: boolean
    valid?: boolean
    inputRef?: React.RefObject<HTMLInputElement> | null
    checkValid?: (value: string) => boolean
}

export const GenericInput: FunctionComponent<GenericInputProps> = (props) => {
    const autoComplete = props.autoComplete ? props.autoComplete : 'on'
    const { warning, error, onChange, valid, inputRef, value, maxLength = 50, checkValid, ...rest } = props

    const [inputValue, setInputValue] = useState(value || '')

    const classList = classNames({
        [styles.input]: true,
        [styles.error]: error,
        [styles.valid]: valid,
        [styles.warning]: warning,
    })

    useEffect(() => {
        setInputValue(value || '')
    }, [value, props.type])

    const setValue = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(event.target.value)
        if (onChange) {
            onChange(event)
        }
    }

    return (
        <input
            {...{
                autoComplete,
                ...rest,
            }}
            className={classList}
            onChange={(e) => {
                if (checkValid) {
                    if (checkValid(e.target.value)) {
                        setValue(e)
                    }
                } else {
                    setValue(e)
                }
            }}
            ref={inputRef}
            value={inputValue}
            maxLength={maxLength}
        />
    )
}
