import { LmiProCheckCouponProductsValidityParameters, LmiProCouponSkuValidity } from '@lmipro/state/coupon/state'
import { CouponServerData } from '@common/state/coupon/state'
import { LmiProCalculateCouponPriceActionPayload } from '@lmipro/state/coupon-util'
import { lmiproCouponActions, LmiProCouponActionTypes } from '@lmipro/state/coupon/actions'
import {
    checkCouponGenericValidity,
    checkCouponProductsValidity,
    checkIfCouponAppliedToMinimumSelectedProducts,
} from '@lmipro/utils'
import { call, put, takeLatest } from 'redux-saga/effects'

function* validateCouponSaga(action: ReturnType<typeof lmiproCouponActions.validateCoupon>) {
    const { couponValidationData, isAttemptingUrlCoupon } = action.payload
    const { couponServerData, selectedPlan, selectedAddons, billingFrequency } = couponValidationData
    const selectedPlanSku = selectedPlan?.sKU
    const isCouponGenericValid: boolean = yield call(checkCouponGenericValidity, couponServerData as CouponServerData)
    if (isCouponGenericValid) {
        const checkProductValidityArguments: LmiProCheckCouponProductsValidityParameters = {
            couponServerData,
            selectedPlanSku,
            selectedAddons,
            billingFrequency,
        }
        const couponSkuValidity: LmiProCouponSkuValidity = yield call(
            checkCouponProductsValidity,
            checkProductValidityArguments
        )
        const isCouponAppliedToMinimumProductsInCart: boolean = yield call(
            checkIfCouponAppliedToMinimumSelectedProducts,
            couponSkuValidity
        )

        yield put(lmiproCouponActions.setCouponValidAndApplied(isCouponAppliedToMinimumProductsInCart))
        if (isCouponAppliedToMinimumProductsInCart) {
            const calculateCouponPriceActionPayload: LmiProCalculateCouponPriceActionPayload = {
                ...couponValidationData,
                couponSkuValidity,
            }
            yield put(lmiproCouponActions.calculateCouponPrice(calculateCouponPriceActionPayload))
            yield put(lmiproCouponActions.setUrlCouponActive(isAttemptingUrlCoupon))
        } else if (isAttemptingUrlCoupon) {
            // look out for cms coupon code if current try is url coupon
            yield put(lmiproCouponActions.checkCMSCoupon(selectedPlanSku))
        } else {
            // no coupon can be applied in this case
            yield put(lmiproCouponActions.resetCouponData())
        }
    } else if (isAttemptingUrlCoupon) {
        // look out for cms coupon code if current try is url coupon
        yield put(lmiproCouponActions.checkCMSCoupon(selectedPlanSku))
    } else {
        // no coupon can be applied in this case
        yield put(lmiproCouponActions.resetCouponData())
    }
}

export default function* initializeValidateCouponSaga() {
    yield takeLatest(LmiProCouponActionTypes.VALIDATE_COUPON, validateCouponSaga)
}
