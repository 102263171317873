import { ActionUnion, createAction } from '@common/utils'
import { MarketoUserInfo } from './state'

export enum MarketingActionsTypes {
    SET_DISPLAY_OPTOUT = '[marketing] set-display_optout',
    SET_IS_USER_OPTOUT = '[marketing] set-is-user-optout',
    POST_MARKETO = '[marketing] post-to-marketo',
}

export const marketingActions = {
    setDisplayOptOut: (displayOutOut: boolean) =>
        createAction(MarketingActionsTypes.SET_DISPLAY_OPTOUT, { displayOutOut }),
    setIsUserOptOut: (isUserOutOut: boolean) =>
        createAction(MarketingActionsTypes.SET_IS_USER_OPTOUT, { isUserOutOut }),
    postToMarketo: (marketoUserInfo: MarketoUserInfo) =>
        createAction(MarketingActionsTypes.POST_MARKETO, { marketoUserInfo }),
}

export type MarketingActions = ActionUnion<typeof marketingActions>
