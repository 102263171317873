import { AppState } from 'app-store'
import { getBuyFormState, getLabelContent } from '@gtc/state/selectors'
import { userActions } from '@gtc/state/user/actions'
import { BuyFormData } from '@gtc/state/buy-form/state'
import { AccountFormInfoState } from './state'

export const mapAccountFormInfoState = (state: AppState): AccountFormInfoState => {
    const {
        createAnAccount,
        signIn,
        firstName,
        lastName,
        email,
        password,
        passwordRequirements,
        errorInvalidPassword,
        errorRequiredPassword,
        passwordStrength,
        passwordStrengthLevel1,
        passwordStrengthLevel2,
        passwordStrengthLevel3,
        passwordStrengthLevel4,
        passwordStrengthTipDrawer,
        passwordStrengthTip1,
        passwordStrengthTip2,
        passwordStrengthTip3,
    } = getLabelContent(state)
    const { clearEmail, steps } = getBuyFormState(state)
    return {
        content: { createAnAccount, signIn, firstName, lastName, email, password, passwordRequirements },
        errorContent: {
            errorInvalidPassword,
            errorRequiredPassword,
            passwordStrength,
            passwordStrengthLevel1,
            passwordStrengthLevel2,
            passwordStrengthLevel3,
            passwordStrengthLevel4,
            passwordStrengthTipDrawer,
            passwordStrengthTip1,
            passwordStrengthTip2,
            passwordStrengthTip3,
        },
        clearEmail,
        steps,
    }
}

export const mapAccountFormInfoActions = {
    checkExistingUser: (email: string, form?: BuyFormData) => userActions.checkExistingUser(email, form),
    userLogIn: () => userActions.userLogin(),
}

export type AccountFormInfoActionsType = typeof mapAccountFormInfoActions
