import React, { FunctionComponent } from 'react'
import classNames from 'classnames'

// Import Local Styles
import styles from './checkbox.module.css'

export interface CheckboxProps extends React.HTMLAttributes<HTMLInputElement> {
    checked?: boolean
    modifier?: string
    centered?: boolean
    label?: string
    qaTag?: string
    onchange?: React.ChangeEventHandler<HTMLInputElement>
    disabled?: boolean
}

export const Checkbox: FunctionComponent<CheckboxProps> = (props) => {
    const { onchange, label, id, checked, modifier = '', centered, qaTag = '', disabled } = props

    const wrapperClassList = classNames({
        [styles.wrapper]: true,
        [modifier]: modifier,
    })

    const labelClassList = classNames({
        [styles.label]: true,
        [styles['label--checked']]: checked,
        [styles.centered]: centered,
    })

    return (
        <label htmlFor={id} className={wrapperClassList} aria-labelledby={id} aria-checked={checked}>
            <input
                className={styles.checkbox}
                type="checkbox"
                onChange={onchange}
                id={id}
                checked={checked}
                data-qat={qaTag}
                disabled={disabled}
            />
            <span className={labelClassList}>{label}</span>
        </label>
    )
}
