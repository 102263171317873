import { PRODUCT_PRICE_ITEM_TYPE } from '@lmipro/constants'
import { LmiProAddon } from '@lmipro/state/add-on'
import { LmiProProductPriceItem } from '@lmipro/state/calculate-price'

export const getTotalProratedAmount = (productPrices: LmiProProductPriceItem[]): number => {
    let proratedAmount = 0
    if (productPrices) {
        productPrices.forEach((productPriceItem) => {
            if (productPriceItem.type === PRODUCT_PRICE_ITEM_TYPE.product && productPriceItem.proratedAmount) {
                proratedAmount += productPriceItem.proratedAmount * productPriceItem.quantity
            }
        })
    }
    return proratedAmount
}

export const getAddonProrateAmount = (productPrices?: LmiProProductPriceItem[], addon?: LmiProAddon) => {
    let proratedAmount = 0
    if (productPrices && addon) {
        productPrices.forEach((productPriceItem) => {
            if (productPriceItem.proratedAmount && productPriceItem.productSKU === addon.sKU) {
                proratedAmount = productPriceItem.proratedAmount * productPriceItem.quantity
            }
        })
    }
    return proratedAmount
}
