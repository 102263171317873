import { ContentData } from '@gtc/state/content-data'
import { LabelContent } from '@gtc/state/label-content'
import { ActionUnion, createAction } from '@common/utils'

export enum ContentActionTypes {
    GET_LABEL_CONTENT = '[content-actions] get-label-content',
    SET_CONTENT = '[content-actions] set-content',
    SET_CONTENT_LOADED = '[content-actions] set-content-loaded',
    GET_COMPARE_MODAL_CONTENT = '[content-actions] get-compare-modal-content',
    SET_COMPARE_MODAL_CONTENT = '[content-actions] set-compare-modal-content',
    SET_COUPON_DISCLAIMER = '[content-actions] set-coupon-disclaimer',
}

export const contentActions = {
    getLabelContent: (upgrade?: boolean) => createAction(ContentActionTypes.GET_LABEL_CONTENT, { upgrade }),
    setContent: (content: ContentData, labels: LabelContent, plans: []) =>
        createAction(ContentActionTypes.SET_CONTENT, {
            content,
            labels,
            plans,
        }),
    getCompareModalContent: (PlanGroupdId: string) =>
        createAction(ContentActionTypes.GET_COMPARE_MODAL_CONTENT, PlanGroupdId),
    setCompareModalContent: (content: []) => createAction(ContentActionTypes.SET_COMPARE_MODAL_CONTENT, { content }),
    setContentLoaded: (loaded: boolean) => createAction(ContentActionTypes.SET_CONTENT_LOADED, { loaded }),
    setCouponDisclaimer: (couponDisclaimer: string) =>
        createAction(ContentActionTypes.SET_COUPON_DISCLAIMER, { couponDisclaimer }),
}

// where do the get functions go

export type ContentActions = ActionUnion<typeof contentActions>
