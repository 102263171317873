import { ResolveCheckCouponProductsValidityParameters, ResolveCouponSkuValidity } from '@gtresolve/state/coupon/state'
import { CouponServerData } from '@common/state/coupon/state'
import { ResolveCalculateCouponPriceActionPayload } from '@gtresolve/state/coupon-util'
import { resolveCouponActions, ResolveCouponActionTypes } from '@gtresolve/state/coupon/actions'
import {
    checkCouponGenericValidity,
    checkCouponProductsValidity,
    checkIfCouponAppliedToMinimumSelectedProducts,
} from '@gtresolve/utils'
import { call, put, takeLatest } from 'redux-saga/effects'

function* validateCouponSaga(action: ReturnType<typeof resolveCouponActions.validateCoupon>) {
    const { couponValidationData, isAttemptingUrlCoupon } = action.payload
    const { couponServerData, selectedPlan, selectedAddons, billingFrequency } = couponValidationData
    const selectedPlanSku = selectedPlan?.sKU
    const isCouponGenericValid: boolean = yield call(checkCouponGenericValidity, couponServerData as CouponServerData)
    if (isCouponGenericValid) {
        const checkProductValidityArguments: ResolveCheckCouponProductsValidityParameters = {
            couponServerData,
            selectedPlanSku,
            selectedAddons,
            billingFrequency,
        }
        const couponSkuValidity: ResolveCouponSkuValidity = yield call(
            checkCouponProductsValidity,
            checkProductValidityArguments
        )
        const isCouponAppliedToMinimumProductsInCart: boolean = yield call(
            checkIfCouponAppliedToMinimumSelectedProducts,
            couponSkuValidity
        )

        yield put(resolveCouponActions.setCouponValidAndApplied(isCouponAppliedToMinimumProductsInCart))
        if (isCouponAppliedToMinimumProductsInCart) {
            const calculateCouponPriceActionPayload: ResolveCalculateCouponPriceActionPayload = {
                ...couponValidationData,
                couponSkuValidity,
            }
            yield put(resolveCouponActions.calculateCouponPrice(calculateCouponPriceActionPayload))
            yield put(resolveCouponActions.setUrlCouponActive(isAttemptingUrlCoupon))
        } else if (isAttemptingUrlCoupon) {
            // look out for cms coupon code if current try is url coupon
            yield put(resolveCouponActions.checkCMSCoupon(selectedPlanSku))
        } else {
            // no coupon can be applied in this case
            yield put(resolveCouponActions.resetCouponData())
        }
    } else if (isAttemptingUrlCoupon) {
        // look out for cms coupon code if current try is url coupon
        yield put(resolveCouponActions.checkCMSCoupon(selectedPlanSku))
    } else {
        // no coupon can be applied in this case
        yield put(resolveCouponActions.resetCouponData())
    }
}

export default function* initializeValidateCouponSaga() {
    yield takeLatest(ResolveCouponActionTypes.VALIDATE_COUPON, validateCouponSaga)
}
