import React, { useState, FunctionComponent } from 'react'
import classNames from 'classnames'
import debounce from 'lodash/debounce'

import { useIsMountedRef } from '@common/hooks/useIsMountedRef'
import { Input, InputProps } from '../Input'

import styles from './inputField.module.css'

export interface InputFieldProps extends InputProps {
    label?: string
    hint?: string
    wrapperClass?: string
    focus?: boolean
    modifier?: string
}

export const InputField: FunctionComponent<InputFieldProps> = ({
    wrapperClass = '',
    modifier = '',
    focus,
    ...props
}) => {
    const { id, label, error, hint, warning, value, style } = props
    const [isFocus, setIsFocus] = useState(!!focus)
    const debounceTime = 250

    const isMountedRef = useIsMountedRef()

    const getDebouncedOnFocus = (e: React.FocusEvent<HTMLInputElement>) =>
        debounce(() => {
            if (isMountedRef.current) {
                if (props.onFocus) {
                    props.onFocus(e)
                }
            }
        }, debounceTime)

    const getDebouncedOnBlur = (e: React.FocusEvent<HTMLInputElement>) =>
        debounce(() => {
            if (isMountedRef.current) {
                if (props.onBlur) {
                    props.onBlur(e)
                }
            }
        }, debounceTime)

    const getDebouncedOnChange = (e: React.ChangeEvent<HTMLInputElement>) =>
        debounce(() => {
            if (isMountedRef.current) {
                if (props.onChange) {
                    props.onChange(e)
                }
            }
        }, debounceTime)

    const wrapperClassList = classNames({
        [styles.wrapper]: true,
        [wrapperClass]: !!wrapperClass,
        [modifier]: !!modifier,
        [styles['wrapper--compact']]: true, // If we need other sizes in the future, we can introduce a prop for it then
        [styles['wrapper--focused']]: value || isFocus,
    })

    return (
        <div className={wrapperClassList} style={style} aria-labelledby={id}>
            <label className={styles.label} htmlFor={id}>
                {label}
            </label>
            <Input
                {...props}
                onBlur={(e) => {
                    e.persist()
                    setIsFocus(false)
                    const debounceOnBlur = getDebouncedOnBlur(e)
                    debounceOnBlur()
                }}
                onFocus={(e) => {
                    e.persist()
                    setIsFocus(true)
                    const debounceOnFocus = getDebouncedOnFocus(e)
                    debounceOnFocus()
                }}
                onChange={(e) => {
                    e.persist()
                    const debounceOnChange = getDebouncedOnChange(e)
                    debounceOnChange()
                }}
            />
            {error && (
                <div className={styles['error-message']} data-qat="inputField-error-message">
                    {error}
                </div>
            )}
            {hint && isFocus && <div className={styles.hint}>{hint}</div>}
            {warning && <div className={styles['warning-message']}>{warning}</div>}
        </div>
    )
}
