import { ContentState } from './state'
import { ContentActionTypes } from './actions'
import type { ContentActions } from './actions'

export const initialContentState: ContentState = {
    language: '',
}

export const contentReducer = (previousState: ContentState | undefined, action: ContentActions): ContentState => {
    const state = previousState || initialContentState
    switch (action.type) {
        case ContentActionTypes.SET_LANGUAGE: {
            const { language } = action.payload
            return {
                ...state,
                language,
            }
        }
        default:
            return state
    }
}
