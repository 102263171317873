/* eslint-disable camelcase */
import { trackEvent, getFormName } from '@common/utils'
import {
    CURRENCY,
    PRODUCT_FAMILY_KEY,
    PRODUCT_FAMILY_NAME,
    TimePeriod,
    TRACKING_EVENTS,
    VIEW_PATH,
} from '@common/constants'
import { ResolveCartData, ResolvePurchaseData, ResolveCustomizeItemsData } from '@gtresolve/state/tracking/state'
import { PageViewContentData, SessionInfo, TrackECommerceData } from '@common/state/tracking/state'
import { BUY_TYPE, ADDONS, RESOLVE_USER_TYPES, RESOLVE_TRACKING_COMPONENTS } from '@gtresolve/constants'
import { ResolveAddon } from '@gtresolve/state/add-on'
import { ResolvePlan } from '@gtresolve/state/plan'
import { ResolveCouponProcessedDataPerSku } from '@gtresolve/state/coupon/state'
import get from 'lodash/get'
import cloneDeep from 'lodash/cloneDeep'

const contentType: Record<string, string> = {
    [VIEW_PATH.PATH_CUSTOMIZE]: 'plan',
    [VIEW_PATH.PATH_CHECKOUT]: 'buy-form',
}

const morphAddonData = (addonList: ResolveAddon[]) => {
    const addonMap: Record<string, ResolveAddon> = {}
    addonList.forEach((addon) => {
        addonMap[addon.sKU] = addon
    })
    return addonMap
}

export const getBuyType = (userType: string) => {
    if (userType === RESOLVE_USER_TYPES.TRIALER) {
        return BUY_TYPE.TRIAL_CONVERSION
    }
    if (userType === RESOLVE_USER_TYPES.ADDON) {
        return BUY_TYPE.ADDON
    }
    return BUY_TYPE.DIRECT_BUY
}

interface PreviousTrackData {
    step: number
    cartData: ResolveCartData | null
}
const previousTrackData: PreviousTrackData = {
    step: 0, // default value
    cartData: null,
}
const setPreviousStep = (step: number) => {
    previousTrackData.step = step
}
const setPreviousCart = (cartData: ResolveCartData) => {
    previousTrackData.cartData = cloneDeep(cartData)
}

interface ProcessCartData {
    plan?: ResolvePlan
    addonList?: ResolveAddon[]
    billingFrequency: TimePeriod
    organizers: number
    currency: CURRENCY
    tier: string
    currentVolumeDiscountPrice: number
    couponCode: string
    couponProcessedDataPerSku: ResolveCouponProcessedDataPerSku | null
    totalCouponSavings: number
}

const processCartData = (productData: ProcessCartData) => {
    const { plan, addonList, billingFrequency, organizers, currency, tier, currentVolumeDiscountPrice } = productData

    let planInfo: TrackECommerceData | null = null
    if (plan) {
        const planPrice =
            billingFrequency === TimePeriod.Year
                ? plan.pricingInfo.USD.annual.tier1.price * 12
                : plan.pricingInfo.USD.monthly.tier1.price
        const billingFrequencyLowerCase = billingFrequency.toLowerCase()
        planInfo = {
            currency,
            item_brand: PRODUCT_FAMILY_NAME.G2R,
            item_category: PRODUCT_FAMILY_NAME.G2R,
            item_category2: plan.name,
            item_category3: billingFrequency,
            item_category4: '1',
            item_category5: undefined,
            item_name: `${plan.name}_${billingFrequencyLowerCase}`,
            item_id: `${plan.sKU}_${billingFrequencyLowerCase}`,
            item_variant: 'Product',
            price: planPrice,
            quantity: organizers,
            volume_discount: currentVolumeDiscountPrice ? currentVolumeDiscountPrice / organizers : undefined,
            discount: get(productData, ['couponProcessedDataPerSku', plan.sKU, 'discountUnitSavings'], 0),
            product_discount_tier: tier,
            coupon: get(productData, ['couponProcessedDataPerSku', plan.sKU], undefined)
                ? productData.couponCode
                : undefined,
        }
    }

    let addonInfoList: TrackECommerceData[] | null = null
    if (addonList) {
        addonInfoList = addonList.map((addon) => {
            const addonPrice =
                billingFrequency === TimePeriod.Year
                    ? addon.pricingInfo.USD.annual.tier1.price * 12
                    : addon.pricingInfo.USD.monthly.tier1.price
            const billingFrequencyLowerCase = billingFrequency.toLowerCase()
            const addonInfo: TrackECommerceData = {
                currency,
                item_brand: PRODUCT_FAMILY_NAME.G2R,
                item_category: PRODUCT_FAMILY_NAME.G2R,
                item_category2: addon.name,
                item_category3: billingFrequency,
                item_category4: '1',
                item_category5: undefined,
                item_name: `${PRODUCT_FAMILY_NAME.G2R} ${addon.name}_${billingFrequencyLowerCase}`,
                item_id: `${addon.sKU}_${billingFrequencyLowerCase}`,
                item_variant:
                    addon.sKU === ADDONS.MOBILE_ADDON ||
                    addon.sKU === ADDONS.CAMERA_SHARE_ADDON ||
                    addon.sKU === ADDONS.ENDPOINT_PROTECTION_ADDON
                        ? 'Add-on'
                        : 'Product',
                price: addonPrice,
                quantity: addon.quantity,
                volume_discount: addon.discountPrice ? addon.discountPrice / addon.quantity : undefined,
                discount:
                    get(productData, ['couponProcessedDataPerSku', addon.sKU, 'discountUnitSavings'], 0) || undefined,
                product_discount_tier: addon.tier || undefined,
                coupon: get(productData, ['couponProcessedDataPerSku', addon.sKU], undefined)
                    ? productData.couponCode
                    : undefined,
            }
            return addonInfo
        })
    }

    const cartItems: TrackECommerceData[] = []
    if (planInfo) {
        cartItems.push(planInfo)
    }
    if (addonInfoList) {
        cartItems.push(...addonInfoList)
    }
    return cartItems
}

export const trackAddToCart = (productData: ProcessCartData) => {
    const { tier, currentVolumeDiscountPrice, totalCouponSavings } = productData
    const cartItems = processCartData(productData)
    const eventData = {
        ecommerce: {
            items: cartItems,
        },
        actionField: {
            coupon: productData.couponCode || undefined,
        },
        discount_tier: tier,
        discount_total: currentVolumeDiscountPrice + totalCouponSavings,
    }
    trackEvent({ event: TRACKING_EVENTS.ADD_TO_CART, eventData })
}

export const trackRemoveFromCart = (productData: ProcessCartData) => {
    const { tier, currentVolumeDiscountPrice, totalCouponSavings } = productData
    const cartItems = processCartData(productData)
    const eventData = {
        ecommerce: {
            items: cartItems,
        },
        actionField: {
            coupon: productData.couponCode || undefined,
        },
        discount_tier: tier,
        discount_total: currentVolumeDiscountPrice + totalCouponSavings,
    }
    trackEvent({ event: TRACKING_EVENTS.REMOVE_FROM_CART, eventData })
}

export const checkCartData = (cartData: ResolveCartData) => {
    const previousCartData = previousTrackData.cartData
    if (previousCartData) {
        const {
            plan,
            plan: { addons },
        } = cartData
        const currentCartPlan = plan
        const currentCartAddons = morphAddonData(cartData.addonList)
        const previousCartPlan = previousCartData.plan
        const previousCartAddons = morphAddonData(previousCartData.addonList)

        if (currentCartPlan.name !== previousCartPlan.name) {
            trackRemoveFromCart(previousCartData)
            trackAddToCart(cartData)
        } else {
            let removedOrganizers = 0
            let addedOrganizers = 0
            const removedAddonList: ResolveAddon[] = []
            const addedAddonList: ResolveAddon[] = []
            if (previousCartData.organizers > cartData.organizers) {
                removedOrganizers = previousCartData.organizers - cartData.organizers
            } else if (previousCartData.organizers < cartData.organizers) {
                addedOrganizers = cartData.organizers - previousCartData.organizers
            }
            addons.forEach((addon: ResolveAddon) => {
                const addonSku = addon.sKU
                const currentAddon = currentCartAddons[addonSku]
                const previousAddon = previousCartAddons[addonSku]
                if (currentAddon && previousAddon) {
                    if (previousAddon.quantity > currentAddon.quantity) {
                        const removedQuantity = previousAddon.quantity - currentAddon.quantity
                        const removedAddon = { ...currentAddon, quantity: removedQuantity }
                        removedAddonList.push(removedAddon)
                    } else if (previousAddon.quantity < currentAddon.quantity) {
                        const addedQuantity = currentAddon.quantity - previousAddon.quantity
                        const addedAddon = { ...currentAddon, quantity: addedQuantity }
                        addedAddonList.push(addedAddon)
                    }
                } else if (!previousAddon && currentAddon) {
                    addedAddonList.push({ ...currentAddon, quantity: currentAddon.quantity })
                } else if (previousAddon && !currentAddon) {
                    removedAddonList.push({ ...previousAddon, quantity: previousAddon.quantity })
                }
            })

            const removedCartData: ProcessCartData = { ...cartData }
            const addedCartData: ProcessCartData = { ...cartData }
            if (removedOrganizers === 0) {
                removedCartData.plan = undefined
            } else if (removedOrganizers > 0) {
                removedCartData.organizers = removedOrganizers
            }
            if (addedOrganizers === 0) {
                addedCartData.plan = undefined
            } else if (addedOrganizers > 0) {
                addedCartData.organizers = addedOrganizers
            }

            if (removedAddonList.length === 0) {
                removedCartData.addonList = undefined
            } else if (removedAddonList.length > 0) {
                removedCartData.addonList = removedAddonList
            }

            if (addedAddonList.length === 0) {
                addedCartData.addonList = undefined
            } else if (addedAddonList.length > 0) {
                addedCartData.addonList = addedAddonList
            }

            if (addedAddonList.length > 0 || addedOrganizers > 0) {
                trackAddToCart(addedCartData)
            }
            if (removedAddonList.length > 0 || removedOrganizers > 0) {
                trackRemoveFromCart(removedCartData)
            }
        }
    } else {
        // just for double check, because there will always be previous cartData
        // if you are in checkCartData function because of the order in which events are fired
        trackAddToCart(cartData)
    }
}

export const trackCustomizeViewItem = (customizeItemsData: ResolveCustomizeItemsData) => {
    const { planData, billingFrequency, currency, organizers } = customizeItemsData

    // See "view_item" column in spreadsheet attached to WAE1-174
    const items = planData.map((plan) => {
        const planPrice =
            billingFrequency === TimePeriod.Year
                ? plan.pricingInfo.USD.annual.tier1.price * 12
                : plan.pricingInfo.USD.monthly.tier1.price
        const billingFrequencyLowerCase = billingFrequency.toLowerCase()
        const planInfo: TrackECommerceData = {
            currency,
            item_brand: PRODUCT_FAMILY_NAME.G2R, // product_name
            item_category: PRODUCT_FAMILY_NAME.G2R, // product_name
            item_category2: plan.name, // product_id
            item_category3: billingFrequency, // product_period
            item_category4: '1', // product_duration
            item_name: `${plan.name}_${billingFrequencyLowerCase}`, // Product / add-on name with period
            item_id: `${plan.sKU}_${billingFrequencyLowerCase}`, // Product / add-on unique identifier, sku with period
            item_variant: 'Product', // Specify if it's a "Product" or an "Add-on"
            price: planPrice, // product_price
            quantity: organizers, // product_quantity
        }
        return planInfo
    })

    const eventData = {
        ecommerce: {
            items,
        },
    }
    trackEvent({ event: TRACKING_EVENTS.VIEW_ITEM, eventData })
}

export const trackViewContent = (userData: PageViewContentData, pageName: string) => {
    const formName = getFormName(pageName)
    const contentTypeName = `/${pageName.split('/')[2]}`
    const eventData = {
        platform_environment: `${PRODUCT_FAMILY_KEY.G2R}-${process.env.ENVIRONMENT}`,
        platform_product: PRODUCT_FAMILY_KEY.G2R,
        platform_type: 'marketing',
        platform_locale: userData.locale,
        platform_country: userData.country,
        platform_language: userData.language,
        section: 'web',
        sub_section: 'resolve',
        template: pageName,
        content_type: `${pageName}-${contentType[contentTypeName]}`,
        page_type: pageName,
        content_number: 1,
        login_status: userData.isUserLoggedIn,
        funnel_name: `${PRODUCT_FAMILY_KEY.G2R}:marketing:${pageName}`,
        form_name: formName,
    }
    trackEvent({ event: TRACKING_EVENTS.VIEW_CONTENT, eventData })
}

export const trackSessionInfo = (sessionInfo: SessionInfo) => {
    const eventData = {
        session_last: sessionInfo.sessionLast,
        session_number: sessionInfo.sessionNumber,
        user_type: sessionInfo.userType,
        is_logged_in: sessionInfo.isUserLoggedIn,
    }
    trackEvent({ event: TRACKING_EVENTS.SESSION_INFORMATION, eventData })
}

export const trackBeginCheckout = (productData: ResolveCartData, currentStep: number) => {
    const { tier, currentVolumeDiscountPrice, totalCouponSavings } = productData
    const cartItems = processCartData(productData)

    const eventData = {
        ecommerce: {
            items: cartItems,
        },
        actionField: {
            step: currentStep,
            coupon: productData.couponCode || undefined,
        },
        discount_tier: tier,
        discount_total: currentVolumeDiscountPrice + totalCouponSavings,
    }
    trackEvent({ event: TRACKING_EVENTS.BEGIN_CHECKOUT, eventData })
    // if user navigates directly to checkout page (step 2), check cart data
    if (previousTrackData.step === 0 && currentStep === 2) {
        checkCartData(productData)
    }
    // if user navigates from customize page (step 1) to checkout page (step 2), check cart data
    if (previousTrackData.step === 1 && currentStep === 2) {
        checkCartData(productData)
    }
    // if user starts off in customize page, do not set previouse cart data yet, as it remains undefined
    if (previousTrackData.step !== 0) {
        setPreviousCart(productData)
    }
    setPreviousStep(currentStep)

    if (currentStep === 2 && productData.isExistingUser) {
        trackBeginCheckout(productData, 3)
        trackBeginCheckout(productData, 4)
    }
}

export const trackPurchase = (productData: ResolveCartData, purchaseData: ResolvePurchaseData) => {
    const { tier, currentVolumeDiscountPrice, totalCouponSavings } = productData
    const cartItems = processCartData(productData)
    const buyType = getBuyType(purchaseData.userType)
    const eventData = {
        ecommerce: {
            items: cartItems.map((item) => ({
                ...item,
                buy_type: buyType,
            })),
        },
        actionField: {
            transaction_id: purchaseData.transactionID,
            coupon: productData.couponCode || undefined,
            tax: purchaseData.calculatedTax,
            revenue: purchaseData.calculatedNetTotal,
        },
        plan: productData.billingFrequency,
        buy_type: buyType,
        conversion_type: 'purchase',
        discount_tier: tier,
        discount_total: currentVolumeDiscountPrice + totalCouponSavings,
    }
    trackEvent({ event: TRACKING_EVENTS.PURCHASE, eventData })
}

export const trackPurchaseError = (errorMessage: string, plan: string, buyType: string) => {
    const eventData = {
        plan, // 'annual' or 'monthly'
        buy_type: buyType, // 'add-on' or 'direct buy'
        conversion_type: 'purchase', // static value, case-sensitive
        error_purchase_message: errorMessage,
    }
    trackEvent({ event: TRACKING_EVENTS.ERROR_PURCHASE, eventData })
}

export const trackCompleteBuy = (userType: string, userEmail: string, productData: ResolveCartData) => {
    const eventData = {
        platform_product: PRODUCT_FAMILY_KEY.G2R,
        buy_type: getBuyType(userType),
        plan: productData.billingFrequency,
        conversion_type: 'purchase',
        user_email: userEmail,
    }
    trackEvent({ event: TRACKING_EVENTS.COMPLETE_BUY_FLOW, eventData })
}

export const trackFormError = (formIds: string[], formErrors: string[], pageName: string) => {
    const eventData = {
        form_error_id: formIds.toString(),
        form_error_message: formErrors.toString(),
        form_name: getFormName(pageName),
    }
    trackEvent({ event: TRACKING_EVENTS.ERROR_FORM, eventData })
}

export const trackUserInformation = (userType: string, userEmail: string, productData: ResolveCartData) => {
    const eventData = {
        buy_type: getBuyType(userType),
        plan: productData.billingFrequency,
        user_email: userEmail,
    }
    trackEvent({ event: TRACKING_EVENTS.USER_INFORMATION, eventData })
}

export const trackEmailValidation = (formName: string, userEmail: string) => {
    const eventData = {
        form_name: formName,
        user_email: userEmail,
    }
    trackEvent({ event: TRACKING_EVENTS.EMAIL_VALIDATION, eventData })
}

export const trackLogin = (userEmail: string) => {
    const eventData = {
        platform_product: PRODUCT_FAMILY_KEY.G2R,
        user_email: userEmail,
    }
    trackEvent({ event: TRACKING_EVENTS.LOGIN, eventData })
}

export const trackAbandonForm = () => {
    const eventData = {
        form_name: RESOLVE_TRACKING_COMPONENTS.CHECKOUT_PAGE,
    }
    trackEvent({ event: TRACKING_EVENTS.ABANDON_FORM, eventData })
}
