import { AppState } from 'app-store'
import { buyFormActions } from '@gtc/state/buy-form/actions'
import { BuyFormData } from '@gtc/state/buy-form/state'
import { getBuyFormState, getLabelContent } from '@gtc/state/selectors'
import { CheckoutFormContainerState } from '../../state/checkout-form-container/state'

export const mapCheckoutFormContainerState = (state: AppState): CheckoutFormContainerState => {
    const {
        errorInvalidCharacter,
        errorInvalidCVV,
        errorInvalidEmail,
        errorInvalidExpirationDate,
        errorInvalidPassword,
        errorInvalidPhoneNumber,
        errorInvalidTaxNumber,
        errorRequiredAddress,
        errorRequiredCity,
        errorRequiredFirstName,
        errorRequiredLastName,
        errorRequiredPostalCode,
        errorRequiredState,
        errorInvalidCreditCardNumber,
        emailAddressAlreadyInUse,
        errorRequiredCVV,
        errorRequiredCountry,
        errorRequiredCreditCardNumber,
        errorRequiredEmail,
        errorRequiredExpDate,
        errorRequiredPassword,
        errorRequiredPhoneNumber,
        errorInvalidPostalCode,
        errorGenericMessage,
        errorInvalidAddress,
        passwordStrength,
        passwordStrengthLevel1,
        passwordStrengthLevel2,
        passwordStrengthLevel3,
        passwordStrengthLevel4,
        passwordStrengthTipDrawer,
        passwordStrengthTip1,
        passwordStrengthTip2,
        passwordStrengthTip3,
    } = getLabelContent(state)
    const { initialData } = getBuyFormState(state)

    return {
        errorContent: {
            errorInvalidCharacter,
            errorInvalidCVV,
            errorInvalidEmail,
            errorInvalidExpirationDate,
            errorInvalidPassword,
            errorInvalidPhoneNumber,
            errorInvalidTaxNumber,
            errorRequiredAddress,
            errorRequiredCity,
            errorRequiredFirstName,
            errorRequiredLastName,
            errorRequiredPostalCode,
            errorRequiredState,
            errorInvalidCreditCardNumber,
            emailAddressAlreadyInUse,
            errorRequiredCVV,
            errorRequiredCountry,
            errorRequiredCreditCardNumber,
            errorRequiredEmail,
            errorRequiredExpDate,
            errorRequiredPassword,
            errorRequiredPhoneNumber,
            errorInvalidPostalCode,
            errorGenericMessage,
            errorInvalidAddress,
            passwordStrength,
            passwordStrengthLevel1,
            passwordStrengthLevel2,
            passwordStrengthLevel3,
            passwordStrengthLevel4,
            passwordStrengthTipDrawer,
            passwordStrengthTip1,
            passwordStrengthTip2,
            passwordStrengthTip3,
        },
        initialData,
    }
}

export const mapCheckoutFormContainerActions = {
    updateInitialFormState: (data: BuyFormData) => buyFormActions.updateInitialFormState(data),
}
export type CheckoutFormContainerActionsType = typeof mapCheckoutFormContainerActions
