import { FlexState } from './state'
import { FlexActionTypes } from './actions'
import type { FlexActions } from './actions'

export const initialFlexState: FlexState = {
    keyInfo: {
        flexKey: {
            keyId: '',
            mid: '',
        },
        scaToken: {
            isScaEnabledForMid: false,
            referenceId: '',
            tokenString: '',
        },
    },
    isCardValid: false,
    isCvvValid: false,
    userInteractedWithFlexField: false,
    userInteractedWithFlexFieldCvv: false,
    error: '',
    token: undefined,
    mask: '',
    cardType: '',
    microformCreated: false,
    fingerprint: '',
    cardinalChallengeCompleteJwt: '',
    isCardinalSetupComplete: false,
}

export const flexReducer = (previousState: FlexState | undefined, action: FlexActions): FlexState => {
    const state = previousState || initialFlexState
    switch (action.type) {
        case FlexActionTypes.SET_KEY_INFO:
            return {
                ...state,
                keyInfo: action.payload.key,
            }
        case FlexActionTypes.SET_FINGERPRINT:
            return {
                ...state,
                fingerprint: action.payload.fingerprint,
            }
        case FlexActionTypes.SET_CARD_VALID:
            return {
                ...state,
                isCardValid: action.payload.valid,
            }
        case FlexActionTypes.SET_CVV_VALID:
            return {
                ...state,
                isCvvValid: action.payload.valid,
            }
        case FlexActionTypes.SET_USER_INTERACTED_WITH_FIELD:
            return {
                ...state,
                userInteractedWithFlexField: action.payload.interacted,
            }
        case FlexActionTypes.SET_USER_INTERACTED_WITH_FIELD_CVV:
            return {
                ...state,
                userInteractedWithFlexFieldCvv: action.payload.interacted,
            }
        case FlexActionTypes.SET_ERROR:
            return {
                ...state,
                error: action.payload.error,
            }
        case FlexActionTypes.SET_TOKEN:
            return { ...state, token: action.payload.token }

        case FlexActionTypes.SET_MICROFORM_CREATED:
            return { ...state, microformCreated: action.payload.isCreated }

        case FlexActionTypes.RESET_FLEX_STATE:
            return initialFlexState

        case FlexActionTypes.SET_CHALLENGE_COMPLETE_JWT:
            return { ...state, cardinalChallengeCompleteJwt: action.payload.jwt }

        case FlexActionTypes.SET_CARDINAL_SETUP_COMPLETE:
            return { ...state, isCardinalSetupComplete: true }

        default:
            return state
    }
}
