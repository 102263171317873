/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/ban-types */
import { FunctionComponent, PropsWithChildren } from 'react'
import { connect } from 'react-redux'
import { ActionsObject } from './action-types'

export interface GoToComponentProps<TState, TActions extends ActionsObject = {}, TProps = {}> {
    /**
     * The data managed by redux.
     *
     * Typically, we use the same interface describing a slice of the redux store
     * but you may use any interface that can be derived from the redux data
     */
    state: TState

    /**
     * The action creators object which will be passed directly to the
     * react-redux connect function (https://react-redux.js.org/api/connect#object-shorthand-form)
     */
    actions: TActions

    /**
     * The presentationl properties of the connected react component
     */
    props: TProps
}

export const connectRedux = <TAppState, TState, TActions extends ActionsObject, TProps>(
    component: React.FunctionComponent<GoToComponentProps<TState, TActions, TProps>>,
    selector: (state: TAppState) => TState,
    actions?: TActions
): FunctionComponent<TProps> =>
    connect(
        selector,
        actions,
        (stateProps, dispatchProps, ownProps: PropsWithChildren<TProps>) => {
            const { children, ...otherOwnProps } = ownProps
            return {
                children,
                props: otherOwnProps,
                actions: dispatchProps,
                state: stateProps,
            }
        }
        // @ts-ignore
    )(component)
