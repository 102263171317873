import { getCouponInfo } from '@common/services'
import { buyCouponActions, BuyCouponActionTypes } from '@buy/state/coupon/actions'
import { buyContentActions } from '@buy/state/content/actions'
import { CouponInfoServerResponse } from '@common/state/coupon/state'
import isEmpty from 'lodash/isEmpty'
import { call, put, takeLatest } from 'redux-saga/effects'

function* getCouponDetailsSaga(action: ReturnType<typeof buyCouponActions.getCouponDetails>) {
    const { couponCode } = action.payload
    try {
        const couponInfoServerResponse: CouponInfoServerResponse = yield call(getCouponInfo, couponCode)
        if (!isEmpty(couponInfoServerResponse.data)) {
            const {
                data: { couponCode: couponServerResponseData, couponDisclaimer },
            } = couponInfoServerResponse
            if (couponServerResponseData) {
                yield put(buyCouponActions.setCouponServerData(couponServerResponseData))
            }
            if (couponDisclaimer) {
                yield put(buyContentActions.setCouponDisclaimer(couponDisclaimer))
            }
        }
    } catch (e) {
        console.error('unable to get url coupon details')
        yield put(buyCouponActions.attemptForCMSCoupon())
    }
}

export default function* initializeGetCouponDetailsSaga() {
    yield takeLatest(BuyCouponActionTypes.GET_COUPON_DETAILS, getCouponDetailsSaga)
}
