import { call, takeEvery, put } from 'redux-saga/effects'
import gtcServices from '@gtc/services'
import { contentActions, ContentActionTypes } from '@gtc/state/content/actions'

import { fatalErrorActions } from '@common/state/fatal-error/actions'
import { locationActions } from '@common/state/location/actions'
import { GenericResponse } from '@common/state/generic-response/generic-response'

function* loadContentSaga() {
    try {
        const labelServerResponse: GenericResponse = yield call(gtcServices.getLabelData)
        if (labelServerResponse && labelServerResponse.data) {
            const { labels, plans, ...content } = labelServerResponse.data
            const { sEOPageTitle } = labels
            yield call(() => {
                if (sEOPageTitle) document.title = sEOPageTitle
            })
            locationActions.getLocationInfo()
            yield put(contentActions.setContent(content, labels, plans))
            yield put(contentActions.setContentLoaded(true))
        }
    } catch (e) {
        yield put(fatalErrorActions.setFatalError(true, { trackErrorMessage: 'label data failed to load' }))
    }
}

function* initializeLoadContentSaga() {
    yield takeEvery(ContentActionTypes.GET_LABEL_CONTENT, loadContentSaga)
}

export default initializeLoadContentSaga
