import { select, put, takeLeading, call } from 'redux-saga/effects'
import queryString from 'query-string'
import { loadSessionState, loadState } from '@buy/utils'
import { BuyCheckoutActionTypes, buyCheckoutActions } from '@buy/state/checkout/actions'
import { getCheckoutState, getPlanData } from '@buy/state/selectors'
import { getSelectedPlan } from '@buy/saga/content/content-helpers'
import { BuyPlan } from '@buy/state/plan'
import { TimePeriod, SC_SITE, FEATURE_FLAGS } from '@common/constants'
import { BuyCheckoutState, BuySavedSessionState, BuySavedStoreState } from '@buy/state/checkout/state'
import { getCurrentAddons } from '@buy/utils/get-current-addons'
import { BuyAddon } from '@buy/state/add-on'
import { buyCouponActions } from '@buy/state/coupon/actions'
import { contentActions } from '@common/state/content/actions'
import { ADDONS } from '@buy/constants'
import commonServices from '@common/services'

function* loadSavedStateSaga(action: ReturnType<typeof buyCheckoutActions.getSavedState>) {
    try {
        const savedState: BuySavedStoreState | undefined = yield call(loadState)
        const savedSessionState: BuySavedSessionState | undefined = yield call(loadSessionState)
        const checkout: BuyCheckoutState = yield select(getCheckoutState)
        const planData: BuyPlan[] = yield select(getPlanData)
        const selectedPlan: BuyPlan = yield call(getSelectedPlan)
        const { urlPlan } = action.payload
        const {
            billingFrequency: queryBillingFrequency,
            minUsers,
            couponCode: queryCouponCode,
        } = queryString.parse(window.location.search)
        // grab url param for billing frequecy or saved one
        const preselectedBillingFrequency = queryBillingFrequency || savedState?.checkout?.billingFrequency
        // if min users was passed via url or saved in the store
        const preselectedMinUsers = Number(minUsers) || savedState?.checkout?.organizers
        // TODO: load based on product
        const featureFlags: { data: { flags: string[] } } = yield call(commonServices.getFeatureFlags, SC_SITE.RESOLVE)
        const useNewBuySkus = featureFlags?.data?.flags?.includes(FEATURE_FLAGS.RESOLVE_USE_NEW_SKUS)

        // grab url param for couponCode first and if not available go for saved one
        let preSavedCouponCode = (queryCouponCode as string) || savedSessionState?.coupon?.urlCouponCode
        // try to apply coupon code and also save the coupon code in state
        preSavedCouponCode = preSavedCouponCode || ''
        yield put(buyCouponActions.setUrlCouponCode(preSavedCouponCode))

        yield put(contentActions.setLanguage(savedSessionState?.language || ''))

        // if the value of the preselected frequency is an acceptable value and is not the same
        // as what is already saved, update it
        if (
            (preselectedBillingFrequency === TimePeriod.Month || preselectedBillingFrequency === TimePeriod.Year) &&
            preselectedBillingFrequency !== checkout.billingFrequency
        ) {
            yield put(buyCheckoutActions.setBillingFrequency(preselectedBillingFrequency))
        }

        if (preselectedMinUsers) {
            yield put(buyCheckoutActions.setOrganizers(preselectedMinUsers))
        }
        // grab url param for plan or saved in store
        if (urlPlan) {
            const preselectedPlan = urlPlan.replace(/-/g, ' ')
            // check if plan name is different
            if (urlPlan && urlPlan?.toLowerCase() !== selectedPlan.shortName.toLowerCase()) {
                // make sure plan exists in the plans data api call
                const foundPlan = planData.find(
                    (pln: BuyPlan) => pln.shortName.toLocaleLowerCase() === preselectedPlan.toLowerCase()
                )
                if (foundPlan) {
                    yield put(buyCheckoutActions.setSelectedPlan(foundPlan.sKU))
                }
            }
        } else if (savedState?.checkout?.selectedPlanSku) {
            // make sure plan exists in the plans data api call
            const foundPlan = planData.find((pln: BuyPlan) => pln.sKU === savedState?.checkout?.selectedPlanSku)
            if (foundPlan) {
                yield put(buyCheckoutActions.setSelectedPlan(foundPlan.sKU))
            }
        } else if (!savedState?.checkout?.selectedPlanSku) {
            // if no plan is selected, default to useNewBuySkus ? v2 plan : first plan
            yield put(buyCheckoutActions.setSelectedPlan(planData?.[useNewBuySkus ? 1 : 0]?.sKU ?? 0))
        }

        if (savedState) {
            const { selectedAddons } = savedState.checkout

            const plan: BuyPlan = yield call(getSelectedPlan)
            // if a plan was selected
            if (plan.name) {
                // find matching addon in the plan from preselected addon
                const foundAddons: BuyAddon[] = yield call(getCurrentAddons, selectedAddons, plan)
                yield put(buyCheckoutActions.setSelectedAddons(foundAddons))
                // find and update Agent addon quantity
                const agentAddons = foundAddons.find((addon) => addon.sKU.includes(ADDONS.AGENT))
                if (agentAddons) {
                    yield put(buyCheckoutActions.setAgents(agentAddons.quantity + plan.userAgentsIncludedAmount))
                }
                // find and update Endpoint addon quantity
                const endPointAddons = foundAddons.find(
                    (addon) => addon.sKU.includes(ADDONS.ENDPOINT) && addon.sKU !== ADDONS.ENDPOINT_PROTECTION_ADDON
                )
                if (endPointAddons) {
                    yield put(
                        buyCheckoutActions.setEndpoints(
                            endPointAddons.quantity * ADDONS.ENDPOINTS_ADDONS_INTERVAL + plan.endpointsIncludedAmount
                        )
                    )
                }
                // find and update extra addon selection
                const extraAddons = foundAddons.filter(
                    (addon) =>
                        addon.sKU === ADDONS.MOBILE_ADDON ||
                        addon.sKU === ADDONS.CAMERA_SHARE_ADDON ||
                        addon.sKU === ADDONS.ENDPOINT_PROTECTION_ADDON
                )
                if (extraAddons) {
                    yield put(buyCheckoutActions.setExtraAddons(extraAddons))
                }
            }
        }
        yield put(buyCheckoutActions.setSavedStateLoaded(true, 'saved state saga'))
    } catch (e) {
        // fail silently
    }
}

function* initializeLoadSavedStateSaga() {
    yield takeLeading(BuyCheckoutActionTypes.GET_SAVED_STATE, loadSavedStateSaga)
}

export default initializeLoadSavedStateSaga
