import { combineReducers } from 'redux'
import { contentReducer } from '@gtresolve/state/content/reducer'
import { checkoutReducer } from '@gtresolve/state/checkout/reducer'
import { buyFormReducer } from '@gtresolve/state/buy-form/reducer'
import { userReducer } from '@gtresolve/state/user/reducer'
import { couponReducer } from '@gtresolve/state/coupon/reducer'

export const resolveAppReducer = combineReducers({
    content: contentReducer,
    checkout: checkoutReducer,
    form: buyFormReducer,
    user: userReducer,
    coupon: couponReducer,
})

export type ResolveAppState = ReturnType<typeof resolveAppReducer>
export type ResolveContainerAppState = {
    resolve: ResolveAppState
}
