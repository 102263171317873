import { ActionUnion, createAction } from '@common/utils'
import { TrackingState } from '@common/state/tracking/state'

export enum ResolveTrackingActionTypes {
    TRACK = '[resolve-tracking] resolve-track-event',
}

export const resolveTrackingActions = {
    track: (trackingInfo: TrackingState, component: string) =>
        createAction(ResolveTrackingActionTypes.TRACK, { trackingInfo }, { component }),
}

export type ResolveTrackingActions = ActionUnion<typeof resolveTrackingActions>
