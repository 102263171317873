/* eslint-disable camelcase */
import { useEffect, useState } from 'react'
import { useLoadScript } from '@common/hooks/useLoadScript'
import { lockHtmlElementAttribute } from '@common/utils/lockHtmlElementAttribute'
import { GooglePlace, GooglePlacesConstructorOptions } from '@common/state/addressAutoFill/state'

// Example Google Places form: // https://developers.google.com/maps/documentation/javascript/examples/places-autocomplete-addressform
// https://developers.google.com/maps/documentation/javascript/places-autocomplete
// https://developers.google.com/maps/documentation/javascript/reference/places-widget
const googlePlacesApiKey = process.env.GOOGLE_PLACES_API_KEY || ''
const GOOGLE_MAPS_URL = `https://maps.googleapis.com/maps/api/js`

interface IProps {
    language?: string
    streetFieldRef: any
    countries: string[]
    onChangePlace?: (googlePlace: GooglePlace) => void
    shouldLoad?: boolean
}

export const useAddressAutoFill = ({
    language,
    streetFieldRef,
    countries,
    onChangePlace,
    shouldLoad = true,
}: IProps) => {
    // See list of supported languages here: https://developers.google.com/maps/faq#languagesupport
    // "By default the API will attempt to load the most appropriate language
    // based on the users location or browser settings. Some APIs allow you
    // to explicitly set a language when you make a request." - https://developers.google.com/maps/faq#languagesupport
    const googlePlacesUrl = `${GOOGLE_MAPS_URL}?language=${language}&key=${googlePlacesApiKey}&libraries=places&callback=initGoogleMaps`
    const [alreadyLoaded, setAlreadyLoaded] = useState(false)
    const shouldLoadScript = shouldLoad && !alreadyLoaded

    useLoadScript(googlePlacesUrl, shouldLoadScript)

    useEffect(() => {
        if (!shouldLoadScript) return

        // Callback for Google Maps script. This method name is used in the googlePlacesUrl above
        window.initGoogleMaps = () => {
            // Initialize Google Maps auto-complete
            // https://developers.google.com/maps/documentation/places/web-service/autocomplete?hl=en_US#input
            const options: GooglePlacesConstructorOptions = {
                type: ['address'], // https://developers.google.com/maps/documentation/places/web-service/autocomplete?hl=en_US#types
                fields: ['adr_address', 'address_components'], // https://developers.google.com/maps/documentation/places/web-service/place-data-fields?hl=en_US
                componentRestrictions: {
                    country: countries,
                },
            }
            const input = streetFieldRef.current

            // Prevent Google Places from changing the autocomplete attribute
            // from "new-password" to "off" because "off" doesn't work in Chrome
            // See: https://developer.mozilla.org/en-US/docs/Web/Security/Securing_your_site/Turning_off_form_autocompletion#preventing_autofilling_with_autocompletenew-password
            lockHtmlElementAttribute(input, 'autocomplete', true)

            const autoComplete = new window.google.maps.places.Autocomplete(input, options)

            autoComplete.addListener('place_changed', () => {
                const place = autoComplete.getPlace()
                if (onChangePlace) {
                    onChangePlace(place)
                }
            })
        }
        setAlreadyLoaded(true)
    }, [countries, streetFieldRef, onChangePlace, shouldLoadScript])
}

export default {
    useAddressAutoFill,
}
