import { ActionUnion, createAction } from '@common/utils'
import { TrackingState } from '@common/state/tracking/state'

export enum LmiProTrackingActionTypes {
    TRACK = '[lmipro-tracking] lmipro-track-event',
}

export const lmiproTrackingActions = {
    track: (trackingInfo: TrackingState, component: string) =>
        createAction(LmiProTrackingActionTypes.TRACK, { trackingInfo }, { component }),
}

export type LmiProTrackingActions = ActionUnion<typeof lmiproTrackingActions>
