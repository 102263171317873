import { BillingPeriod, CURRENCY, TimePeriod } from '@common/constants'
import { LmiProPlan } from '@lmipro/state/plan'
import { LmiProPrice, LmiProPricing, LmiProTiers } from '@lmipro/state/price'

const tierMap = new Map<string, number>()

export const getSelectedTierIndex = (
    currentOrganizers: number,
    billingFrequency: string,
    selectedPlan: LmiProPlan,
    currency: CURRENCY
) => {
    const { sKU = '', pricingInfo } = selectedPlan ?? {}
    const identifier = `${sKU}-${currency}-${billingFrequency}-${currentOrganizers}`
    if (!tierMap.get(identifier)) {
        const currencyPricing = (pricingInfo?.[currency as keyof LmiProPrice] as LmiProPricing) ?? []
        const billingFrequencyKey = billingFrequency === TimePeriod.Year ? BillingPeriod.ANNUAL : BillingPeriod.MONTHLY
        const billingFrequencyTier = currencyPricing[billingFrequencyKey as keyof LmiProPricing] ?? []

        const billingFrequencyTierKeys = Object.keys(billingFrequencyTier)

        billingFrequencyTierKeys.some((tierName, index) => {
            const tierData = billingFrequencyTier[tierName as keyof LmiProTiers]
            if (currentOrganizers >= tierData.min && currentOrganizers <= tierData.max) {
                tierMap.set(identifier, index)
                return true
            }
            return false
        })
    }

    return tierMap.get(identifier) as number
}
