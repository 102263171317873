import { BaseAppState } from '@common/store'
import { ModalState } from '@common/state/modal/state'
import { getModalState } from '@common/state/selectors'
import { modalActions } from '@common/state/modal/actions'

export const mapModalState = (state: BaseAppState): ModalState => {
    const { isModalClosable, isModalOpen, errorMessage } = getModalState(state)
    return {
        isModalClosable,
        isModalOpen,
        errorMessage,
    }
}

export const mapModalActions = {
    setModalOpen: (isModalOpen: boolean) => modalActions.setModalOpen(isModalOpen),
}

export type ModalActionsType = typeof mapModalActions
