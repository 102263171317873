import { ActionUnion, createAction } from '@common/utils'
import { FatalErrorMessageType } from './state'

export enum FatalErrorActionTypes {
    SET_FATAL_ERROR = '[fatal-errors] set-fatal-error',
}

export const fatalErrorActions = {
    setFatalError: (hasError: boolean, message?: FatalErrorMessageType, blocker?: boolean) =>
        createAction(FatalErrorActionTypes.SET_FATAL_ERROR, {
            hasError,
            message,
            blocker,
        }),
}

export type FatalErrorActions = ActionUnion<typeof fatalErrorActions>
