import { BaseAppState } from '@common/store'

export const getFatalError = (state: BaseAppState) => state.common.fatalError
export const getModalState = (state: BaseAppState) => state.common.modal
export const getFlexState = (state: BaseAppState) => state.common.flex
export const getLocationState = (state: BaseAppState) => state.common.location
export const getMarketingState = (state: BaseAppState) => state.common.marketing
export const getAccountState = (state: BaseAppState) => state.common.account
export const getContentState = (state: BaseAppState) => state.common.content
export const getCheckoutState = (state: BaseAppState) => state.common.checkout
