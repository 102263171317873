import axios from 'axios'
import { BillingInfoDataType, PaymentDataType, SubmitDataType, CalculatePriceDataType } from '@gtc/state/checkout/state'
import { UserDataType, AbandonedCartPayload } from '@gtc/state/user/state'
import { SubmitPaymentResponseData } from '@gtc/state/submit-payment/state'
import { SC_SITE } from '@common/constants'

export interface PaymentMethodDataType {
    accessToken?: string
    billingAccountKey?: string
}

export interface CardHeadersType {
    AccessToken: string
    BillingAccountKey: string
    PaymentMethodKey: string
}

export interface LabelDataType {
    id: string
    plan: string
    promoCode: string
    accessToken: string
    timezone: string
}

export const apiQueryParams = {
    sc_site: SC_SITE.CONNECT,
}

/* APP DATA */

// get buy flow api data
export function getLabelData(): Promise<any> {
    return axios.post('/ecommapi/content/buy/pro', null, { params: apiQueryParams })
}

// get countries
export function getCountries() {
    return axios.get(`/ecommapi/content/countries?sc_site=${SC_SITE.CONNECT}`)
}

// get available payment  methods
export function getAvailablePaymentMethods() {
    return axios.post('/ecommapi/purchase/GetAvailablePaymentMethods', null, {
        withCredentials: true,
    })
}

// create user account
export function createUserAccount(data: UserDataType) {
    return axios.post('/ecommapi/identity/CreateAccount/', data, {
        withCredentials: true,
        params: apiQueryParams,
    })
}

// create billing information
export function createBillingInfo(data: BillingInfoDataType) {
    return axios.post('/ecommapi/purchase/SetBillingInfo', data, {
        withCredentials: true,
    })
}

// calculate payment
export function calculatePrice(data: CalculatePriceDataType) {
    return axios.post('/ecommapi/purchase/CalculatePrice', data)
}

// create payment info
export function savePayment(data: PaymentDataType) {
    return axios.post('/ecommapi/purchase/SavePaymentMethodV3', data, {
        withCredentials: true,
    })
}

// submit payment
export function submitPayment(data: SubmitDataType) {
    return axios.post<SubmitPaymentResponseData>('/ecommapi/purchase/ProcessPurchaseV3', data, {
        withCredentials: true,
    })
}

// cart abandonment
export function submitAbandonedCart(data: AbandonedCartPayload) {
    return axios.post('/ecommapi/form/abandonment', data, { params: apiQueryParams })
}

export default {
    getLabelData,
}
