import { takeEvery, put } from 'redux-saga/effects'
import { contentActions, ContentActionTypes } from '@common/state/content/actions'
import { saveSessionState } from '@common/utils'
import { fatalErrorActions } from '@common/state/fatal-error/actions'
import { STORAGE_CONST } from '@common/constants'

function* setLanguageSaga(action: ReturnType<typeof contentActions.setLanguage>) {
    try {
        const { language } = action.payload
        saveSessionState(STORAGE_CONST.SAVED_LANGUAGE, language)
    } catch (e) {
        yield put(fatalErrorActions.setFatalError(true, { trackErrorMessage: 'label data failed to load' }))
    }
}

function* initializeSetLanguageSaga() {
    yield takeEvery(ContentActionTypes.SET_LANGUAGE, setLanguageSaga)
}

export default initializeSetLanguageSaga
