import { BaseAppState } from '@common/store'
import { FlexTokenDataType } from '@common/services'
import { flexActions } from '@common/state/flex/actions'
import { FlexState, FlexConnectorState, KeyInfo } from '@common/state/flex/state'
import { fatalErrorActions } from '@common/state/fatal-error/actions'
import { getFlexState, getLocationState } from '@common/state/selectors'

export const mapFlexFormState = (state: BaseAppState): FlexConnectorState => {
    const flexState: FlexState = getFlexState(state)
    const { locationInfo, isLocationInfoLoaded } = getLocationState(state)
    const { currency, country } = locationInfo
    return {
        flexState,
        currency,
        country,
        isLocationInfoLoaded,
    }
}

export const mapFlexFormActions = {
    setFatalError: (hasError: boolean) => fatalErrorActions.setFatalError(hasError),
    getFlexToken: (data: FlexTokenDataType, iterator: number) => flexActions.getFlexToken(data, iterator),
    setMicroformCreated: (isCreated: boolean) => flexActions.setMicroformCreated(isCreated),
    setCardValid: (valid: boolean) => flexActions.setCardValid(valid),
    setCvvValid: (valid: boolean) => flexActions.setCvvValid(valid),
    setUserInteractedWithFlexField: (interacted: boolean) => flexActions.setUserInteractedWithFlexField(interacted),
    setUserInteractedWithFlexFieldCvv: (interacted: boolean) =>
        flexActions.setUserInteractedWithFlexFieldCvv(interacted),
    createFlexMicroform: (keyInfo: KeyInfo) => flexActions.createFlexMicroform(keyInfo),
    clearMicroformInstance: () => flexActions.clearFlexMicroformInstance(),
}

export type FlexFormActionsType = typeof mapFlexFormActions
