export enum FEATURE_FLAGS {
    RESOLVE_TRIAL_ALLOWVERIFICATIONBYPASS = 'Resolve-Trial-AllowVerificationBypass',
    RESOLVE_FREE_WITH_TRIAL = 'Resolve-Free-With-Trial',
    RESOLVE_USE_NEW_SKUS = 'Resolve-SKUs-V2',
    RESOLVE_USE_CUSTOM_AGENTS_DEVICES = 'Resolve-V2-AgentsAndDevices',
    RESOLVE_USE_REMOTESUPPORT_AGENTS = 'Resolve-V2-RemoteSupport-AgentQuantity',

    // TODO: WAE1-3569: Either remove or update these flag names to be LMI specific
    LMIPRO_TRIAL_ALLOWVERIFICATIONBYPASS = 'Resolve-Trial-AllowVerificationBypass',
    LMIPRO_FREE_WITH_TRIAL = 'Resolve-Free-With-Trial',
    LMIPRO_USE_NEW_SKUS = 'Resolve-SKUs-V2',
    LMIPRO_USE_CUSTOM_AGENTS_DEVICES = 'Resolve-V2-AgentsAndDevices',
    LMIPRO_USE_REMOTESUPPORT_AGENTS = 'Resolve-V2-RemoteSupport-AgentQuantity',

    BUY_TRIAL_ALLOWVERIFICATIONBYPASS = 'Resolve-Trial-AllowVerificationBypass',
    BUY_FREE_WITH_TRIAL = 'Resolve-Free-With-Trial',
    BUY_USE_NEW_SKUS = 'Resolve-SKUs-V2',
    BUY_USE_CUSTOM_AGENTS_DEVICES = 'Resolve-V2-AgentsAndDevices',
    BUY_USE_REMOTESUPPORT_AGENTS = 'Resolve-V2-RemoteSupport-AgentQuantity',
}
