import { ADDON_SKUS, ADDONS } from '@gtresolve/constants'
import { BILLING_TYPES, PRODUCT_TYPES, PRODUCT_SKU, TimePeriod, FEATURE_FLAGS } from '@common/constants'
import { BillingDetailedSubscriptionPlan } from '@common/state/billing/state'
import { ResolvePlan } from '@gtresolve/state/plan'
import { ResolveSelectedAddon, ResolveAddon } from '@gtresolve/state/add-on'

const createAgentAddon = (type: PRODUCT_SKU, quantity: number) => ({
    sKU:
        type === PRODUCT_SKU.G2RE_STANDARD_NAME || type === PRODUCT_SKU.GTR_STANDARD_NAMED_V2
            ? ADDON_SKUS.STANDARD_AGENT_ADDON
            : ADDON_SKUS.BASIC_AGENT_ADDON,

    quantity,
})

const createEndpointAddon = (type: PRODUCT_SKU, quantity: number) => ({
    sKU:
        type === PRODUCT_SKU.G2RE_STANDARD_NAME || type === PRODUCT_SKU.GTR_STANDARD_NAMED_V2
            ? ADDON_SKUS.STANDARD_ENDPOINT_ADDON
            : ADDON_SKUS.BASIC_ENDPOINT_ADDON,

    quantity: quantity / ADDONS.ENDPOINTS_ADDONS_INTERVAL,
})

const createMobileAddon = (quantity: number) => ({
    sKU: ADDON_SKUS.MOBILE_ADDON,
    quantity,
})

const createCameraAddon = (quantity: number) => ({
    sKU: ADDON_SKUS.CAMERA_SHARE_ADDON,
    quantity,
})

const createEndpointProtectionAddon = (quantity: number) => ({
    sKU: ADDON_SKUS.ENDPOINT_PROTECTION_ADDON,
    quantity,
})

const getReplacementBookingAddons = (
    type: PRODUCT_SKU,
    agents: number,
    includedAgents: number,
    endpoints: number,
    includedEndpoints: number,
    mobileAddon?: ResolveAddon,
    cameraAddon?: ResolveAddon,
    endpointProtectionAddon?: ResolveAddon,
    subscribedMobileAddon?: BillingDetailedSubscriptionPlan,
    subscribedCameraAddon?: BillingDetailedSubscriptionPlan,
    subscribedEndpointProtectionAddon?: BillingDetailedSubscriptionPlan
) => {
    const addons = []
    const agentQuantity = agents - includedAgents
    const endpointQuantity = endpoints - includedEndpoints

    if (agentQuantity > 0) {
        addons.push(createAgentAddon(type, agentQuantity))
    }

    if (endpointQuantity > 0) {
        addons.push(createEndpointAddon(type, endpointQuantity))
    }

    if (mobileAddon || subscribedMobileAddon) {
        addons.push(createMobileAddon(agents))
    }

    if (cameraAddon || subscribedCameraAddon) {
        addons.push(createCameraAddon(agents))
    }

    if (endpointProtectionAddon || subscribedEndpointProtectionAddon) {
        addons.push(createEndpointProtectionAddon(endpoints))
    }

    return addons
}

const getAddons = (
    type: PRODUCT_SKU,
    agents: number,
    subscribedAgents: number,
    includedAgents: number,
    endpoints: number,
    subscribedEndpoints: number,
    includedEndpoints: number,
    mobileAddon?: ResolveAddon,
    cameraAddon?: ResolveAddon,
    endpointProtectionAddon?: ResolveAddon,
    subscribedMobileAddon?: BillingDetailedSubscriptionPlan,
    subscribedCameraAddon?: BillingDetailedSubscriptionPlan,
    subscribedEndpointProtectionAddon?: BillingDetailedSubscriptionPlan
) => {
    const addons = []
    const agentQuantity = agents - subscribedAgents - includedAgents
    const endpiontQuantity = endpoints - subscribedEndpoints - includedEndpoints

    if (endpiontQuantity > 0) {
        addons.push(createEndpointAddon(type, endpiontQuantity))
    }

    if (agentQuantity > 0) {
        addons.push(createAgentAddon(type, agentQuantity))
    }

    if (mobileAddon) {
        if (subscribedMobileAddon) {
            if (agentQuantity > 0) {
                addons.push(createMobileAddon(agentQuantity))
            }
        } else {
            addons.push(createMobileAddon(agents))
        }
    }

    if (cameraAddon) {
        if (subscribedCameraAddon) {
            if (agentQuantity > 0) {
                addons.push(createCameraAddon(agentQuantity))
            }
        } else {
            addons.push(createCameraAddon(agents))
        }
    }

    if (endpointProtectionAddon) {
        if (subscribedEndpointProtectionAddon) {
            if (endpiontQuantity > 0) {
                addons.push(createEndpointProtectionAddon(endpiontQuantity))
            }
        } else {
            addons.push(createEndpointProtectionAddon(endpoints))
        }
    }

    return addons
}

const getExtraAddons = (
    selectedPlan: ResolvePlan,
    mobileAddon?: ResolveAddon,
    cameraAddon?: ResolveAddon,
    endpointProtectionAddon?: ResolveAddon,
    subscribedMobileAddon?: BillingDetailedSubscriptionPlan,
    subscribedCameraAddon?: BillingDetailedSubscriptionPlan,
    subscribedEndpointProtectionAddon?: BillingDetailedSubscriptionPlan
) => {
    const extraMobileAddon = selectedPlan.addons.find((a) => a.sKU === ADDONS.MOBILE_ADDON)
    const extraCameraAddon = selectedPlan.addons.find((a) => a.sKU === ADDONS.CAMERA_SHARE_ADDON)
    const extraEndpointProtectionAddon = selectedPlan.addons.find((a) => a.sKU === ADDONS.ENDPOINT_PROTECTION_ADDON)
    const extraAddons = []

    if (subscribedMobileAddon && extraMobileAddon) {
        extraAddons.push(extraMobileAddon)
    } else if (mobileAddon) {
        extraAddons.push(mobileAddon)
    }

    if (subscribedCameraAddon && extraCameraAddon) {
        extraAddons.push(extraCameraAddon)
    } else if (cameraAddon) {
        extraAddons.push(cameraAddon)
    }

    if (subscribedEndpointProtectionAddon && extraEndpointProtectionAddon) {
        extraAddons.push(extraEndpointProtectionAddon)
    } else if (endpointProtectionAddon) {
        extraAddons.push(endpointProtectionAddon)
    }

    return extraAddons
}

const subscribedStandardPlanCart = (
    agents: number,
    subscribedAgents: number,
    endpoints: number,
    subscribedEndpoints: number,
    billingFrequency: TimePeriod,
    selectedPlan: ResolvePlan,
    subscribedPlan: BillingDetailedSubscriptionPlan,
    mobileAddon?: ResolveAddon,
    cameraAddon?: ResolveAddon,
    endpointProtectionAddon?: ResolveAddon,
    subscribedMobileAddon?: BillingDetailedSubscriptionPlan,
    subscribedCameraAddon?: BillingDetailedSubscriptionPlan,
    subscribedEndpointProtectionAddon?: BillingDetailedSubscriptionPlan,
    useResolveNewSkus?: boolean
) => {
    const subscribedBillFrequency =
        subscribedPlan.paymentPlan === BILLING_TYPES.ANNUAL ? TimePeriod.Year : TimePeriod.Month

    const cart = {
        includedAgents: selectedPlan.userAgentsIncludedAmount + subscribedAgents,
        includedEndpoints: selectedPlan.endpointsIncludedAmount + subscribedEndpoints,
        addons: [] as ResolveSelectedAddon[],
        extraAddons: getExtraAddons(
            selectedPlan,
            mobileAddon,
            cameraAddon,
            endpointProtectionAddon,
            subscribedMobileAddon,
            subscribedCameraAddon,
            subscribedEndpointProtectionAddon
        ),
        billingFrequency,
        canUpgradePlan: false,
        canUpgradeMobileAddon: !subscribedMobileAddon,
        canUpgradeCameraAddon: !subscribedCameraAddon,
        canUpgradeEndpointProtectionAddon: !subscribedEndpointProtectionAddon,
        canUpgradeBillingFrequency: false,
        isReplacementBooking: false,
        isUpgrading: true,
    }

    if (!cart.canUpgradeBillingFrequency || subscribedBillFrequency === billingFrequency) {
        cart.addons = getAddons(
            useResolveNewSkus ? PRODUCT_SKU.GTR_STANDARD_NAMED_V2 : PRODUCT_SKU.G2RE_STANDARD_NAME,
            agents,
            subscribedAgents,
            selectedPlan.userAgentsIncludedAmount,
            endpoints,
            subscribedEndpoints,
            selectedPlan.endpointsIncludedAmount,
            mobileAddon,
            cameraAddon,
            endpointProtectionAddon,
            subscribedMobileAddon,
            subscribedCameraAddon,
            subscribedEndpointProtectionAddon
        )
    } else {
        cart.isReplacementBooking = true
        cart.addons = getReplacementBookingAddons(
            useResolveNewSkus ? PRODUCT_SKU.GTR_STANDARD_NAMED_V2 : PRODUCT_SKU.G2RE_STANDARD_NAME,
            agents,
            selectedPlan.userAgentsIncludedAmount,
            endpoints,
            selectedPlan.endpointsIncludedAmount,
            mobileAddon,
            cameraAddon,
            endpointProtectionAddon,
            subscribedMobileAddon,
            subscribedCameraAddon,
            subscribedEndpointProtectionAddon
        )
    }

    return cart
}

const subscribedBasicPlanCart = (
    agents: number,
    subscribedAgents: number,
    endpoints: number,
    subscribedEndpoints: number,
    billingFrequency: TimePeriod,
    selectedPlan: ResolvePlan,
    basicPlan: ResolvePlan,
    subscribedPlan: BillingDetailedSubscriptionPlan,
    mobileAddon?: ResolveAddon,
    cameraAddon?: ResolveAddon,
    endpointProtectionAddon?: ResolveAddon,
    subscribedMobileAddon?: BillingDetailedSubscriptionPlan,
    subscribedCameraAddon?: BillingDetailedSubscriptionPlan,
    subscribedEndpointProtectionAddon?: BillingDetailedSubscriptionPlan,
    useResolveNewSkus?: boolean
) => {
    const includedAgents = basicPlan.userAgentsIncludedAmount + subscribedAgents
    const includedEndpoints = basicPlan.endpointsIncludedAmount + subscribedEndpoints
    const subscribedBillFrequency =
        subscribedPlan.paymentPlan === BILLING_TYPES.ANNUAL ? TimePeriod.Year : TimePeriod.Month

    const cart = {
        includedAgents:
            includedAgents < selectedPlan.userAgentsIncludedAmount
                ? selectedPlan.userAgentsIncludedAmount
                : includedAgents,

        includedEndpoints:
            includedEndpoints < selectedPlan.endpointsIncludedAmount
                ? selectedPlan.endpointsIncludedAmount
                : includedEndpoints,

        addons: [] as ResolveSelectedAddon[],
        extraAddons: getExtraAddons(
            selectedPlan,
            mobileAddon,
            cameraAddon,
            endpointProtectionAddon,
            subscribedMobileAddon,
            subscribedCameraAddon,
            subscribedEndpointProtectionAddon
        ),
        billingFrequency,
        canUpgradePlan: true,
        canUpgradeMobileAddon: !subscribedMobileAddon,
        canUpgradeCameraAddon: !subscribedCameraAddon,
        canUpgradeEndpointProtectionAddon: !subscribedEndpointProtectionAddon,
        canUpgradeBillingFrequency: false,
        isReplacementBooking: false,
        isUpgrading: true,
    }

    if (
        (!cart.canUpgradeBillingFrequency || subscribedBillFrequency === billingFrequency) &&
        subscribedPlan.productSku === selectedPlan.sKU
    ) {
        cart.addons = getAddons(
            useResolveNewSkus ? PRODUCT_SKU.GTR_REMOTE_SUPPORT_NAMED_V2 : PRODUCT_SKU.G2RE_BASIC_NAMED,
            agents,
            subscribedAgents,
            selectedPlan.userAgentsIncludedAmount,
            endpoints,
            subscribedEndpoints,
            selectedPlan.endpointsIncludedAmount,
            mobileAddon,
            cameraAddon,
            endpointProtectionAddon,
            subscribedMobileAddon,
            subscribedCameraAddon,
            subscribedEndpointProtectionAddon
        )
    } else {
        cart.isReplacementBooking = true
        cart.addons = getReplacementBookingAddons(
            selectedPlan.sKU as PRODUCT_SKU,
            agents,
            selectedPlan.userAgentsIncludedAmount,
            endpoints,
            selectedPlan.endpointsIncludedAmount,
            mobileAddon,
            cameraAddon,
            endpointProtectionAddon,
            subscribedMobileAddon,
            subscribedCameraAddon,
            subscribedEndpointProtectionAddon
        )
    }

    return cart
}
const trialPlanCart = (
    agents: number,
    endpoints: number,
    billingFrequency: TimePeriod,
    selectedPlan: ResolvePlan,
    mobileAddon?: ResolveAddon,
    cameraAddon?: ResolveAddon,
    endpointProtectionAddon?: ResolveAddon
) => {
    const cart = {
        includedAgents: selectedPlan.userAgentsIncludedAmount,
        includedEndpoints: selectedPlan.endpointsIncludedAmount,
        addons: getReplacementBookingAddons(
            selectedPlan.sKU as PRODUCT_SKU,
            agents,
            selectedPlan.userAgentsIncludedAmount,
            endpoints,
            selectedPlan.endpointsIncludedAmount,
            mobileAddon,
            cameraAddon,
            endpointProtectionAddon
        ),
        extraAddons: getExtraAddons(selectedPlan, mobileAddon, cameraAddon, endpointProtectionAddon),
        billingFrequency,
        canUpgradePlan: true,
        canUpgradeMobileAddon: true,
        canUpgradeCameraAddon: true,
        canUpgradeEndpointProtectionAddon: true,
        canUpgradeBillingFrequency: true,
        isReplacementBooking: true,
        isUpgrading: true,
    }

    return cart
}

const newPlanCart = (
    agents: number,
    endpoints: number,
    billingFrequency: TimePeriod,
    selectedPlan: ResolvePlan,
    mobileAddon?: ResolveAddon,
    cameraAddon?: ResolveAddon,
    endpointProtectionAddon?: ResolveAddon,
    urlPlan?: string
) => {
    const cart = {
        includedAgents: selectedPlan.userAgentsIncludedAmount,
        includedEndpoints: selectedPlan.endpointsIncludedAmount,
        addons: getReplacementBookingAddons(
            selectedPlan.sKU as PRODUCT_SKU,
            agents,
            selectedPlan.userAgentsIncludedAmount,
            endpoints,
            selectedPlan.endpointsIncludedAmount,
            mobileAddon,
            cameraAddon,
            endpointProtectionAddon
        ),
        extraAddons: getExtraAddons(selectedPlan, mobileAddon, cameraAddon, endpointProtectionAddon),
        billingFrequency,
        canUpgradePlan: urlPlan?.toLowerCase() !== 'standard',
        canUpgradeMobileAddon: true,
        canUpgradeCameraAddon: true,
        canUpgradeEndpointProtectionAddon: true,
        canUpgradeBillingFrequency: true,
        isReplacementBooking: true,
        isUpgrading: false,
    }

    return cart
}

export const getCartInfo = (
    agents: number,
    endpoints: number,
    billingFrequency: TimePeriod,
    plans: ResolvePlan[],
    selectedPlan: ResolvePlan,
    selectedExtraAddons: ResolveAddon[],
    accountSubscriptions?: BillingDetailedSubscriptionPlan[],
    featureFlags?: string[],
    urlPlan?: string
) => {
    const useResolveNewSkus = featureFlags?.includes(FEATURE_FLAGS.RESOLVE_USE_NEW_SKUS)
    const mobileAddon = selectedExtraAddons.find((a) => a.sKU === ADDON_SKUS.MOBILE_ADDON)
    const cameraAddon = selectedExtraAddons.find((a) => a.sKU === ADDON_SKUS.CAMERA_SHARE_ADDON)
    const endpointProtectionAddon = selectedExtraAddons.find((a) => a.sKU === ADDON_SKUS.ENDPOINT_PROTECTION_ADDON)
    const subscribedInfo = {
        agents: 0,
        endpoints: 0,
        plan: undefined as BillingDetailedSubscriptionPlan | undefined,
        mobileAddon: undefined as BillingDetailedSubscriptionPlan | undefined,
        cameraAddon: undefined as BillingDetailedSubscriptionPlan | undefined,
        endpointProtectionAddon: undefined as BillingDetailedSubscriptionPlan | undefined,
        hasTrial: false,
    }

    if (!accountSubscriptions?.length) {
        return newPlanCart(
            agents,
            endpoints,
            billingFrequency,
            selectedPlan,
            mobileAddon,
            cameraAddon,
            endpointProtectionAddon,
            useResolveNewSkus ? urlPlan : ''
        )
    }

    accountSubscriptions.forEach((subscription) => {
        if (subscription.status === 'Active') {
            switch (subscription.productSku) {
                case PRODUCT_SKU.G2RE_STANDARD_NAME:
                case PRODUCT_SKU.GTR_STANDARD_NAMED_V2:
                    subscribedInfo.plan = subscription
                    break

                case PRODUCT_SKU.G2RE_BASIC_NAMED:
                case PRODUCT_SKU.GTR_REMOTE_SUPPORT_NAMED_V2:
                case PRODUCT_SKU.GTR_REMOTE_ACCESS:
                    subscribedInfo.plan = subscription
                    break

                case ADDON_SKUS.BASIC_AGENT_ADDON:
                case ADDON_SKUS.STANDARD_AGENT_ADDON:
                    subscribedInfo.agents += subscription.quantity
                    break

                case ADDON_SKUS.BASIC_ENDPOINT_ADDON:
                case ADDON_SKUS.STANDARD_ENDPOINT_ADDON:
                    subscribedInfo.endpoints += subscription.quantity * ADDONS.ENDPOINTS_ADDONS_INTERVAL
                    break

                case ADDON_SKUS.MOBILE_ADDON:
                    subscribedInfo.mobileAddon = subscription
                    break

                case ADDON_SKUS.CAMERA_SHARE_ADDON:
                    subscribedInfo.cameraAddon = subscription
                    break

                case ADDON_SKUS.ENDPOINT_PROTECTION_ADDON:
                    subscribedInfo.endpointProtectionAddon = subscription
                    break

                default:
                    break
            }

            if (subscription.planType === PRODUCT_TYPES.TRIAL) {
                subscribedInfo.hasTrial = true
            }
        }
    })

    if (subscribedInfo.plan) {
        if (
            [PRODUCT_SKU.GTR_STANDARD_NAMED_V2, PRODUCT_SKU.G2RE_STANDARD_NAME].includes(
                subscribedInfo.plan.productSku as PRODUCT_SKU
            )
        ) {
            return subscribedStandardPlanCart(
                agents,
                subscribedInfo.agents,
                endpoints,
                subscribedInfo.endpoints,
                billingFrequency,
                selectedPlan,
                subscribedInfo.plan,
                mobileAddon,
                cameraAddon,
                endpointProtectionAddon,
                subscribedInfo.mobileAddon,
                subscribedInfo.cameraAddon,
                subscribedInfo.endpointProtectionAddon,
                useResolveNewSkus
            )
        }
        if (
            [
                PRODUCT_SKU.GTR_REMOTE_SUPPORT_NAMED_V2,
                PRODUCT_SKU.GTR_REMOTE_ACCESS,
                PRODUCT_SKU.G2RE_BASIC_NAMED,
            ].includes(subscribedInfo.plan.productSku as PRODUCT_SKU)
        ) {
            const basicPlan = plans.find(
                (p) => p.sKU === (useResolveNewSkus ? subscribedInfo.plan?.productSku : PRODUCT_SKU.G2RE_BASIC_NAMED)
            )

            if (basicPlan) {
                return subscribedBasicPlanCart(
                    agents,
                    subscribedInfo.agents,
                    endpoints,
                    subscribedInfo.endpoints,
                    billingFrequency,
                    selectedPlan,
                    basicPlan,
                    subscribedInfo.plan,
                    mobileAddon,
                    cameraAddon,
                    endpointProtectionAddon,
                    subscribedInfo.mobileAddon,
                    subscribedInfo.cameraAddon,
                    subscribedInfo.endpointProtectionAddon,
                    useResolveNewSkus
                )
            }
        }
    } else if (subscribedInfo.hasTrial) {
        return trialPlanCart(
            agents,
            endpoints,
            billingFrequency,
            selectedPlan,
            mobileAddon,
            cameraAddon,
            endpointProtectionAddon
        )
    }

    return newPlanCart(
        agents,
        endpoints,
        billingFrequency,
        selectedPlan,
        mobileAddon,
        cameraAddon,
        endpointProtectionAddon
    )
}
