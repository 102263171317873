import React, { FunctionComponent } from 'react'
import { GoToComponentProps, connectRedux } from '@common/utils'
import { FormatCurrencyState } from '@gtc/connectedComponents/FormatCurrency/state'
import {
    FormatCurrencyActionsType,
    mapFormatCurrencyState,
    mapFormatCurrencyActions,
} from '@gtc/connectedComponents/FormatCurrency/connector'
import { FormatCurrency } from '@common/components'

type FormatCurrencyProps = GoToComponentProps<
    FormatCurrencyState,
    FormatCurrencyActionsType,
    {
        value: number
    }
>

export const FormatCurrencyGTC: FunctionComponent<FormatCurrencyProps> = ({ state, props }) => {
    return <FormatCurrency {...props} {...state} />
}

export const ConnectedFormatCurrency = connectRedux(FormatCurrencyGTC, mapFormatCurrencyState, mapFormatCurrencyActions)
