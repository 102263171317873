import { FlexTokenDataType } from '@common/services'
import { ActionUnion, createAction } from '@common/utils'
import { Token, KeyInfo } from './state'

export enum FlexActionTypes {
    SET_KEY_INFO = '[flexform-actions] set-key-info',
    SET_CARD_VALID = '[flexform-actions] set-card-valid',
    SET_CVV_VALID = '[flexform-actions] set-cvv-valid',
    GET_FLEX_TOKEN = '[flexform-actions] get-flex-token',
    SET_USER_INTERACTED_WITH_FIELD = '[flexform-actions] set-user-interacted-with-field',
    SET_USER_INTERACTED_WITH_FIELD_CVV = '[flexform-actions] set-user-interacted-with-field-cvv',
    SET_ERROR = '[flexform-actions] set-error',
    SET_TOKEN = '[flexform-actions] set-token',
    CREATE_TOKEN = '[flexform-actions] create-token',
    SET_FINGERPRINT = '[flexform-actions] set-fingerprint',
    CREATE_MICROFORM = '[flexform-actions] create-microform',
    SET_MICROFORM_CREATED = '[flexform-actions] set-microform-created',
    RESET_FLEX_STATE = '[flexform-actions] reset-flex-state',
    CLEAR_FLEX_MICROFORM_INSTANCE = '[flexform-actions] clear-flex-microform-instance',
    SET_CHALLENGE_COMPLETE_JWT = '[flexform-actions] set-challenge-complete-jwt',
    SET_CARDINAL_SETUP_COMPLETE = '[flexform-actions] set-cardinal-setup-complete',
}

export const flexActions = {
    setKeyInfo: (key: KeyInfo) => createAction(FlexActionTypes.SET_KEY_INFO, { key }),
    setFingerPrint: (fingerprint: string) => createAction(FlexActionTypes.SET_FINGERPRINT, { fingerprint }),
    setCardValid: (valid: boolean) => createAction(FlexActionTypes.SET_CARD_VALID, { valid }),
    setCvvValid: (valid: boolean) => createAction(FlexActionTypes.SET_CVV_VALID, { valid }),
    setUserInteractedWithFlexField: (interacted: boolean) =>
        createAction(FlexActionTypes.SET_USER_INTERACTED_WITH_FIELD, {
            interacted,
        }),
    setUserInteractedWithFlexFieldCvv: (interacted: boolean) =>
        createAction(FlexActionTypes.SET_USER_INTERACTED_WITH_FIELD_CVV, {
            interacted,
        }),
    setError: (error: string) => createAction(FlexActionTypes.SET_ERROR, { error }),
    getFlexToken: (data: FlexTokenDataType, iterator: number) =>
        createAction(FlexActionTypes.GET_FLEX_TOKEN, { data, iterator }),
    createToken: (date: string) => createAction(FlexActionTypes.CREATE_TOKEN, { date }),
    setToken: (token: Token) => createAction(FlexActionTypes.SET_TOKEN, { token }),
    createFlexMicroform: (keyInfo: KeyInfo) =>
        createAction(FlexActionTypes.CREATE_MICROFORM, {
            keyInfo,
        }),
    setMicroformCreated: (isCreated?: boolean) => createAction(FlexActionTypes.SET_MICROFORM_CREATED, { isCreated }),
    resetFlexState: () => createAction(FlexActionTypes.RESET_FLEX_STATE),
    clearFlexMicroformInstance: () => createAction(FlexActionTypes.CLEAR_FLEX_MICROFORM_INSTANCE),
    setChallengeCompleteJwt: (jwt: string) => createAction(FlexActionTypes.SET_CHALLENGE_COMPLETE_JWT, { jwt }),
    setCardinalSetupComplete: () => createAction(FlexActionTypes.SET_CARDINAL_SETUP_COMPLETE),
}

export type FlexActions = ActionUnion<typeof flexActions>
