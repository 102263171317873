import { SelectedAddon } from '@gtc/state/add-on'
import { Plan } from '@gtc/state/plan'
import { Items } from '@gtc/state/checkout/state'

export const getPaymentItems = (
    selectedPlan: Plan,
    organizers: number,
    billingFrequency: string,
    selectedAddons: SelectedAddon[],
    upgradeFlow?: boolean
) => {
    const items: Items[] = [
        {
            itemId: 0,
            productSku: selectedPlan.sKU,
            paymentPlan: billingFrequency === 'YEAR' ? 'UpfrontAnnual' : 'UpfrontMonthly',
            quantity: organizers,
            contractLengthInMonth: billingFrequency === 'YEAR' ? 12 : 1,
            subscriptionsToUpgrade: null, // @todo update for upgrade flow
        },
    ]

    // add addon products to the items object
    if (selectedAddons.length > 0) {
        selectedAddons.map((addon: SelectedAddon, index: number) => {
            return items.push({
                itemId: index + 1,
                productSku: addon.sKU,
                paymentPlan: billingFrequency === 'YEAR' ? 'UpfrontAnnual' : 'UpfrontMonthly',
                quantity: addon.quantity + 1,
                subscriptionsToUpgrade: null, // @todo update for upgrade flow
                contractLengthInMonth: billingFrequency === 'YEAR' ? 12 : 1,
            })
        })
    } else if (!upgradeFlow) {
        items.push({
            itemId: 1,
            productSku: 'G2CPreDID',
            paymentPlan: billingFrequency === 'YEAR' ? 'UpfrontAnnual' : 'UpfrontMonthly',
            quantity: 1,
            subscriptionsToUpgrade: null, // @todo update for upgrade flow
            contractLengthInMonth: billingFrequency === 'YEAR' ? 12 : 1,
        })
    }

    return items
}
