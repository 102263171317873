import { COUNTRIES, CURRENCY, LANGUAGE, LOCALES } from '@common/constants'
import { LocationState } from '@common/state/location/state'

export const locationInitialData: LocationState = {
    countries: [],
    states: [],
    validAddress: false,
    correctedAddress: {
        addressLine1: '',
        city: '',
        country: '',
        phoneNumber: '',
        postalCode: '',
        state: '',
    },
    addressErrors: [],
    isLocationInfoLoaded: false,
    locationInfo: {
        country: COUNTRIES.US,
        currency: CURRENCY.USD,
        locale: LOCALES.EN_US,
        language: LANGUAGE.EN,
    },
    isCountriesLoaded: false,
    isUserCountryAllowed: true,
    isValidatingAddress: false,
}
