import { call, put, select, debounce } from 'redux-saga/effects'
import { lmiproUserActions, LmiProUserActionTypes } from '@lmipro/state/user/actions'
import { submitAbandonedCart } from '@lmipro/services'
import { fatalErrorActions } from '@common/state/fatal-error/actions'
import { ADDONS } from '@lmipro/constants'
import { COOKIE_NAMES, TimePeriod } from '@common/constants'
import Cookies from 'js-cookie'
import { getCheckoutState, getUserState } from '@lmipro/state/selectors'
import { LmiProCheckoutState } from '@lmipro/state/checkout/state'
import { LmiProAbandonedCartPayload, LmiProUserState } from '@lmipro/state/user/state'
import { LocationState } from '@common/state/location/state'
import { getLocationState } from '@common/state/selectors'

export function* abandonedCartSaga(action: ReturnType<typeof lmiproUserActions.submitAbandonedCart>) {
    const { isTrial, email, success, form, program } = action.payload.abandonedCartData
    const { locationInfo }: LocationState = yield select(getLocationState)
    const data: LmiProAbandonedCartPayload = {
        isTrial,
        success,
        email,
        program,
        mkto_trk: Cookies.get(COOKIE_NAMES.MARKETO_TRK) || '',
        country: locationInfo?.country,
        language: locationInfo?.language,
    }

    if (isTrial && !success) {
        data.firstName = form?.firstName
        data.lastName = form?.lastName
        data.phone = form?.phone
        data.employees = form?.employees
    }

    // Include billing and plan information if cart is abandoned in buy form (1st abandoned cart call only)
    if (!isTrial && !success) {
        const { existingBillingInformation, existingUserInformation }: LmiProUserState = yield select(getUserState)
        const {
            selectedPlanSku,
            selectedAddons,
            billingFrequency,
            calculatedNetTotal,
            agents,
            endpoints,
        }: LmiProCheckoutState = yield select(getCheckoutState)

        data.plan = selectedPlanSku
        data.quantity = agents
        data.numOfEndpoints = endpoints
        data.addOnCameraShare =
            selectedAddons.findIndex((addon) => addon.sKU === ADDONS.CAMERA_SHARE_ADDON) > -1 ? 'YES' : 'NO'
        data.addonMobile = selectedAddons.findIndex((addon) => addon.sKU === ADDONS.MOBILE_ADDON) > -1 ? 'YES' : 'NO'
        data.addonEndpointProtection =
            selectedAddons.findIndex((addon) => addon.sKU === ADDONS.ENDPOINT_PROTECTION_ADDON) > -1 ? 'YES' : 'NO'
        data.billing = billingFrequency === TimePeriod.Year ? 'Annual' : 'Monthly'
        data.firstName = form?.firstName || existingUserInformation?.Name?.GivenName
        data.lastName = form?.lastName || existingUserInformation?.Name?.FamilyName
        data.phone = form?.phone || existingBillingInformation?.phoneNumber
        data.company = form?.company || existingBillingInformation?.companyName

        if (calculatedNetTotal && calculatedNetTotal > 0) {
            data.totalPrice = calculatedNetTotal
        }
    }

    try {
        // yield delay(1000)
        yield call(submitAbandonedCart, data)
    } catch (e) {
        yield put(fatalErrorActions.setFatalError(true, { trackErrorMessage: 'failed to post abandoned email api' }))
    }
}

function* initializeAbandonedCartSaga() {
    yield debounce(1000, LmiProUserActionTypes.SUBMIT_ABANDONED_CART, abandonedCartSaga)
}

export default initializeAbandonedCartSaga
