import { ActionUnion, createAction } from '@common/utils'
import { CouponServerData } from '@common/state/coupon/state'
import {
    ResolveCalculateCouponPriceActionPayload,
    ResolveCheckVolumeDiscountAgainstRepriceCouponActionPayload,
    ResolveValidateCouponActionPayload,
} from '../coupon-util'
import { ResolveCouponProcessedDataPerSku } from './state'

export enum ResolveCouponActionTypes {
    SET_APPLY_COUPON_ATTEMPT_MADE = '[resolve-coupon-actions] check-apply-coupon-attempt-made',
    GET_COUPON_DETAILS = '[resolve-coupon-actions] get-coupon-details',
    CALCULATE_COUPON_PRICE = '[resolve-coupon-actions] calculate-coupon-price',
    VALIDATE_COUPON = '[resolve-coupon-actions] validate-coupon',
    SET_COUPON_PROCESSED_DATA = '[resolve-coupon-actions] set-coupon-processed-data',
    SET_COUPON_SERVER_DATA = '[resolve-coupon-actions] set-coupon-server-data',
    CHECK_CMS_COUPON = '[resolve-coupon-actions] check-cms-coupon',
    RESET_COUPON_DATA = '[resolve-coupon-actions] reset-coupon-data',
    SET_COUPON_VALID_AND_APPLIED = '[resolve-coupon-actions] set-coupon-valid-and-applied',
    SET_COUPON_DOUBLE_DIP_AMOUNT_FOR_NEW_USER = '[resolve-coupon-actions] set-coupon-double-dip-amount-for-new-user',
    SET_URL_COUPON_ACTIVE = '[resolve-coupon-actions] set-url-coupon-active',
    OBSERVE_VALIDATE_CMS_COUPON = '[resolve-coupon-actions] observe-validate-cms-coupon',
    CHECK_VOLUME_DISCOUNT_AGAINST_REPRICE_COUPON = '[resolve-coupon-actions] check-volume-discount-against-reprice-coupon',
    ATTEMPT_FOR_CMS_COUPON = '[resolve-coupon-actions] attempt-for-cms-coupon',
    SET_URL_COUPON_CODE = '[resolve-coupon-actions] set-url-coupon-code',
}

export const resolveCouponActions = {
    getCouponDetails: (couponCode: string) => createAction(ResolveCouponActionTypes.GET_COUPON_DETAILS, { couponCode }),
    validateCoupon: (couponValidationData: ResolveValidateCouponActionPayload, isAttemptingUrlCoupon: boolean) =>
        createAction(ResolveCouponActionTypes.VALIDATE_COUPON, { couponValidationData, isAttemptingUrlCoupon }),
    calculateCouponPrice: (couponCalculationData: ResolveCalculateCouponPriceActionPayload) =>
        createAction(ResolveCouponActionTypes.CALCULATE_COUPON_PRICE, { couponCalculationData }),
    setCouponProcessedData: (couponProcessedDataPerSku: ResolveCouponProcessedDataPerSku | null) =>
        createAction(ResolveCouponActionTypes.SET_COUPON_PROCESSED_DATA, { couponProcessedDataPerSku }),
    setCouponServerData: (couponServerData: CouponServerData) =>
        createAction(ResolveCouponActionTypes.SET_COUPON_SERVER_DATA, { couponServerData }),
    checkCMSCoupon: (selectedPlanSku: string) =>
        createAction(ResolveCouponActionTypes.CHECK_CMS_COUPON, { selectedPlanSku }),
    resetCouponData: () => createAction(ResolveCouponActionTypes.RESET_COUPON_DATA),
    setCouponValidAndApplied: (isCouponValidAndApplied: boolean) =>
        createAction(ResolveCouponActionTypes.SET_COUPON_VALID_AND_APPLIED, { isCouponValidAndApplied }),
    setCouponDoubleDipAmountForNewUser: (couponDoubleDipAmountForNewUser: number) =>
        createAction(ResolveCouponActionTypes.SET_COUPON_DOUBLE_DIP_AMOUNT_FOR_NEW_USER, {
            couponDoubleDipAmountForNewUser,
        }),
    setUrlCouponActive: (isUrlCouponActive: boolean) =>
        createAction(ResolveCouponActionTypes.SET_URL_COUPON_ACTIVE, { isUrlCouponActive }),
    observeValidateCMSCoupon: (couponServerData: CouponServerData) =>
        createAction(ResolveCouponActionTypes.OBSERVE_VALIDATE_CMS_COUPON, { couponServerData }),
    checkVolumeDiscountAgainstRepriceCoupon: (
        checkVolumeDiscountData: ResolveCheckVolumeDiscountAgainstRepriceCouponActionPayload
    ) =>
        createAction(ResolveCouponActionTypes.CHECK_VOLUME_DISCOUNT_AGAINST_REPRICE_COUPON, {
            checkVolumeDiscountData,
        }),
    attemptForCMSCoupon: () => createAction(ResolveCouponActionTypes.ATTEMPT_FOR_CMS_COUPON),
    setUrlCouponCode: (couponCode: string) =>
        createAction(ResolveCouponActionTypes.SET_URL_COUPON_CODE, { couponCode }),
}

export type ResolveCouponActions = ActionUnion<typeof resolveCouponActions>
