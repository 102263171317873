import { takeEvery, call } from 'redux-saga/effects'
import { fatalErrorActions, FatalErrorActionTypes } from '@common/state/fatal-error/actions'
import { FatalErrorData } from '@common/state/tracking/state'
import { trackFatalError } from '@common/utils'

export function* trackErrorSaga(action: ReturnType<typeof fatalErrorActions.setFatalError>) {
    const { message } = action.payload
    if (message && message.trackErrorMessage) {
        const errorData: FatalErrorData = {
            message: message.trackErrorMessage,
            path: `${window.location.hostname}${window.location.pathname}`,
        }
        yield call(trackFatalError, errorData)
    }
}

function* initializeTrackErrorSaga() {
    yield takeEvery(FatalErrorActionTypes.SET_FATAL_ERROR, trackErrorSaga)
}

export default initializeTrackErrorSaga
