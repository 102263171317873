import React, { FunctionComponent } from 'react'
import classNames from 'classnames'

import reserving from '@common/images/gtm-process-spinner-accelerated-blue-3s.svg'
import reservingWhite from '@common/images/gtm-process-spinner-white-b-2s.svg'
import reservingdisabled from '@common/images/gtm-process-spinner-accelerated-disabled-3s.svg'
import styles from './loadingSpinner.module.css'

export interface LoadingSpinnerProps {
    modifier?: string
    wrapperModifier?: string
    text?: string
    disabled?: boolean
    inline?: boolean
    white?: boolean
}
export const LoadingSpinner: FunctionComponent<LoadingSpinnerProps> = (props) => {
    const { modifier = '', wrapperModifier = '', text, disabled, inline, white = false } = props

    const imgClassList = classNames({
        [styles.loading]: true,
        [styles.small]: inline,
        [modifier]: modifier,
    })

    const wrapperClassList = classNames({
        [styles.wrapper]: true,
        [wrapperModifier]: wrapperModifier,
    })

    if (disabled) {
        return (
            <img
                alt="Loading Request"
                className={imgClassList}
                data-qat="loading-spinner"
                src={reservingdisabled}
                title="Loading Request"
            />
        )
    }

    if (inline) {
        return (
            <img
                alt="Loading Request"
                className={imgClassList}
                data-qat="loading-spinner"
                src={white ? reservingWhite : reserving}
                title="Loading Request"
            />
        )
    }

    return (
        <div className={wrapperClassList}>
            <img
                alt="Loading Request"
                className={imgClassList}
                data-qat="loading-spinner"
                src={white ? reservingWhite : reserving}
                title="Loading Request"
            />
            {text && <span className={styles.copy}>{text}</span>}
        </div>
    )
}
