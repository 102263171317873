import { takeEvery, call } from 'redux-saga/effects'
import { gtcTrackingActions, GTCTrackingActionTypes } from '@gtc/state/tracking/actions'
import { trackCTAClick, trackEvent, trackGenericEvent, trackGenericFormEvent } from '@common/utils'
import { PhoneInfo, PRODUCT_FAMILY_KEY, TRACKING_EVENTS } from '@common/constants'
import {
    trackBeginCheckout,
    trackCompleteBuy,
    trackCustomizeViewItem,
    trackFormError,
    trackPurchase,
    trackPurchaseError,
    trackSessionInfo,
    trackViewContent,
    trackUserInformation,
    trackEmailValidation,
} from '@gtc/utils'
import { CartData, PurchaseData, CustomizeItemsData } from '@gtc/state/tracking/state'
import { CTAClickData, PageViewContentData, SessionInfo } from '@common/state/tracking/state'
import {
    getCartInfo,
    getCompleteBuyData,
    getCustomizeItemsData,
    getPageViewData,
    getPhoneInfo,
    getPurchaseData,
    getSessionInfo,
} from './track-helpers'

export function* gtcTrackingSaga(action: ReturnType<typeof gtcTrackingActions.track>) {
    const { trackingInfo } = action.payload
    const eventName = trackingInfo.event
    const trackData = trackingInfo.eventData || {}

    const errorMessage = (trackData.errorMessage as string) || ''
    const formErrors = (trackData.formErrors as string[]) || ['']
    const formIds = (trackData.formIds as string[]) || ['']
    const step = (trackData.step as number) || 0
    const ctaClickData: CTAClickData = {
        name: (trackData.name as string) || '',
        url: (trackData.url as string) || '',
    }
    const formName = (trackData.formName as string) || ''
    const pathName = (trackData.currentLocation as string) || ''
    const invoiceNumber = (trackData.invoiceNumber as string) || ''
    const userEmail: string = (trackData.userEmail as string) || ''

    switch (eventName) {
        case TRACKING_EVENTS.VIEW_ITEM: {
            const customizeItemsData: CustomizeItemsData = yield call(getCustomizeItemsData)
            trackCustomizeViewItem(customizeItemsData)
            break
        }
        case TRACKING_EVENTS.VIEW_CONTENT: {
            const viewContentData: PageViewContentData = yield call(getPageViewData)
            trackViewContent(viewContentData, pathName)
            break
        }
        case TRACKING_EVENTS.SESSION_INFORMATION: {
            const sessionData: SessionInfo = yield call(getSessionInfo)
            trackSessionInfo(sessionData)
            break
        }
        case TRACKING_EVENTS.USER_INFORMATION: {
            const cartData: CartData = yield call(getCartInfo)
            const completeBuyData: string = yield call(getCompleteBuyData)
            trackUserInformation(completeBuyData, userEmail, cartData)
            break
        }
        case TRACKING_EVENTS.BEGIN_CHECKOUT: {
            const cartData: CartData = yield call(getCartInfo)
            const phoneInfo: PhoneInfo = yield call(getPhoneInfo)
            trackBeginCheckout(cartData, phoneInfo, step)
            break
        }
        case TRACKING_EVENTS.START_BUY_FLOW: {
            trackGenericEvent(eventName, PRODUCT_FAMILY_KEY.G2C, pathName)
            break
        }
        case TRACKING_EVENTS.PURCHASE: {
            const cartData: CartData = yield call(getCartInfo)
            const purchaseData: PurchaseData = yield call(getPurchaseData, invoiceNumber)
            const phoneInfo: PhoneInfo = yield call(getPhoneInfo)
            trackPurchase(cartData, purchaseData, phoneInfo)
            break
        }
        case TRACKING_EVENTS.ERROR_PURCHASE: {
            trackPurchaseError(errorMessage)
            break
        }
        case TRACKING_EVENTS.EMAIL_VALIDATION: {
            trackEmailValidation(formName, userEmail)
            break
        }
        case TRACKING_EVENTS.COMPLETE_BUY_FLOW: {
            const cartData: CartData = yield call(getCartInfo)
            const completeBuyData: string = yield call(getCompleteBuyData)
            trackCompleteBuy(completeBuyData, userEmail, cartData)
            break
        }
        case TRACKING_EVENTS.START_CONTACT_SALES_FLOW: {
            trackGenericEvent(eventName, PRODUCT_FAMILY_KEY.G2C, pathName)
            break
        }
        case TRACKING_EVENTS.LOAD_FORM: {
            trackGenericFormEvent(eventName, pathName)
            break
        }
        case TRACKING_EVENTS.START_FORM: {
            trackGenericFormEvent(eventName, pathName)
            break
        }
        case TRACKING_EVENTS.ABANDON_FORM: {
            trackGenericFormEvent(eventName, pathName)
            break
        }
        case TRACKING_EVENTS.SUBMIT_FORM: {
            trackGenericFormEvent(eventName, pathName)
            break
        }
        case TRACKING_EVENTS.ERROR_FORM: {
            trackFormError(formIds, formErrors, pathName)
            break
        }
        case TRACKING_EVENTS.CLICK_CTA: {
            trackCTAClick(ctaClickData)
            break
        }
        default: {
            yield call(trackEvent, { event: eventName, eventData: trackData })
        }
    }
}

function* initializeGTCTrackingSaga() {
    yield takeEvery(GTCTrackingActionTypes.TRACK, gtcTrackingSaga)
}

export default initializeGTCTrackingSaga
