export enum LMIPRO_USER_TYPES {
    NEW = 'NEW',
    TRIALER = 'TRIALER',
    ADDON = 'ADDON',
    CROSSPRODUCT = 'CROSSPRODUCT',
    BANNED = 'BANNED',
    EXPIRED = 'EXPIRED',
    IDENTITY_ONLY = 'IDENTITY_ONLY',
}

export enum LMIPRO_USER_TYPES_RESPONSE {
    USER_IDENTITY_NOT_FOUND = 'ValidateUserIdentityNotFound',
    USER_BOSS_NOT_FOUND = 'ValidateUserBossNotFound',
}

export enum LMIPRO_PARTIAL_NON_VALIDATION_USER_TYPES {
    TRIALER = 'TRIALER',
    CROSSPRODUCT = 'CROSSPRODUCT',
    EXPIRED = 'EXPIRED',
}
