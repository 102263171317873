import { takeEvery, put, call, select } from 'redux-saga/effects'
import { flexActions, FlexActionTypes } from '@common/state/flex/actions'
import { Token, Microform, Options, FlexState } from '@common/state/flex/state'
import { getFlexState } from '@common/state/selectors'
import { getFlexMicroform } from '@common/utils'
import { fatalErrorActions } from '@common/state/fatal-error/actions'

function tokenGenerator(microform: Microform, options: Options) {
    return new Promise((resolve, reject) => {
        microform.createToken(options, (err: unknown, token: Token) => {
            if (err) {
                reject(new Error('cannot create flex token'))
            }
            resolve(token)
        })
    })
}

export function* createTokenSaga(action: ReturnType<typeof flexActions.createToken>) {
    const flex: FlexState = yield select(getFlexState)
    const { date } = action.payload
    try {
        if (date && flex.microformCreated) {
            const flexMicroformInstance: Microform = yield call(getFlexMicroform)
            const options = {
                expirationMonth: date.substr(0, 2),
                expirationYear: `20${date.substr(3, 5)}`,
            }
            const tokenBase64: string = yield call(tokenGenerator, flexMicroformInstance, options)
            if (tokenBase64) {
                const tokenString = atob(tokenBase64.split('.')[1]) // decode base64 string
                const token: Token = JSON.parse(tokenString)
                if (token) {
                    yield put(flexActions.setToken(token))
                    return tokenBase64
                }
            }
        }
    } catch (e) {
        yield put(fatalErrorActions.setFatalError(true, { trackErrorMessage: 'failed to create flex token' }))
    }
    return null
}

function* initializeCreateTokenSaga() {
    yield takeEvery(FlexActionTypes.CREATE_TOKEN, createTokenSaga)
}

export default initializeCreateTokenSaga
