import { LocationState } from '@common/state/location/state'
import { getLocationState } from '@common/state/selectors'
import { CheckoutState } from '@gtc/state/checkout/state'
import { getSelectedAddons, getSelectedPlan } from '@gtc/saga/content/content-helpers'
import { getCheckoutState, getContentData, getCouponState, getPlanData, getUserState } from '@gtc/state/selectors'
import { UserState } from '@gtc/state/user/state'
import isEmpty from 'lodash/isEmpty'
import { select, call } from 'redux-saga/effects'
import { Plan } from '@gtc/state/plan'
import { Addon } from '@gtc/state/add-on'
import { USER_TYPES } from '@gtc/constants'
import { getActiveCouponServerData, getCouponTotalSavings, getSelectedTierIndex } from '@gtc/utils'
import { CouponState } from '@gtc/state/coupon/state'
import { CustomizeItemsData } from '@gtc/state/tracking/state'
import { ContentData } from '@gtc/state/content-data'
import { PhoneInfo, PHONE_NUMBER_STATUS, PHONE_NUMBER_TYPE } from '@common/constants'

export function* getCustomizeItemsData() {
    const planData: Plan[] = yield select(getPlanData)
    const { CurrencyCode }: ContentData = yield select(getContentData)
    const { billingFrequency, organizers }: CheckoutState = yield select(getCheckoutState)
    const customizeItemsData: CustomizeItemsData = {
        planData,
        billingFrequency,
        currency: CurrencyCode,
        organizers,
    }
    return customizeItemsData
}

export function* getPageViewData() {
    const {
        loggedIn: { isUserLoggedIn },
    }: UserState = yield select(getUserState)
    const locationState: LocationState = yield select(getLocationState)
    const { locale, country, language } = locationState.locationInfo
    return { locale, country, isUserLoggedIn, language }
}

export function* getSessionInfo() {
    const {
        userType,
        loggedIn: { isUserLoggedIn },
    }: UserState = yield select(getUserState)
    return { isUserLoggedIn, userType, sessionLast: '', sessionNumber: '' }
}

export function* getPhoneInfo() {
    const { isChoosingPhoneNumber, phoneNumber }: CheckoutState = yield select(getCheckoutState)

    const isLocalNumber = !!phoneNumber && !!phoneNumber.city
    const phoneInfo: PhoneInfo = {
        phoneNumberStatus: isChoosingPhoneNumber ? PHONE_NUMBER_STATUS.ENTERED : PHONE_NUMBER_STATUS.NOT_ENTERED,
        phoneNumberType:
            // eslint-disable-next-line no-nested-ternary
            phoneNumber ? (isLocalNumber ? PHONE_NUMBER_TYPE.LOCAL : PHONE_NUMBER_TYPE.TOLL_FREE) : undefined,
    }
    return phoneInfo
}

export function* getCartInfo() {
    const { billingFrequency, organizers, currentVolumeDiscountPrice, selectedTierIndex }: CheckoutState = yield select(
        getCheckoutState
    )
    const locationState: LocationState = yield select(getLocationState)
    const { existingBillingInformation, userType, existingAccountInformation }: UserState = yield select(getUserState)
    const { currency } = locationState.locationInfo
    const selectedPlan: Plan = yield call(getSelectedPlan)
    const selectedAddons: Addon[] = yield call(getSelectedAddons)

    let tierIndex = selectedTierIndex
    if (userType === USER_TYPES.ADDON && existingAccountInformation && !isEmpty(existingAccountInformation)) {
        const existingOrganizers = existingAccountInformation.quantity
        const totalOrganizers = organizers + existingOrganizers
        tierIndex = yield call(getSelectedTierIndex, totalOrganizers, billingFrequency, selectedPlan, currency)
    }

    const coupon: CouponState = yield select(getCouponState)
    const { couponProcessedDataPerSku, isCouponValidAndApplied, couponDoubleDipAmountForNewUser } = coupon
    const couponServerData = getActiveCouponServerData(coupon, selectedPlan)
    const totalCouponSavings = getCouponTotalSavings(
        isCouponValidAndApplied,
        couponProcessedDataPerSku,
        couponDoubleDipAmountForNewUser
    )

    return {
        plan: selectedPlan,
        addonList: selectedAddons,
        billingFrequency,
        organizers,
        currency,
        isExistingUser: !isEmpty(existingBillingInformation),
        tier: `Tier${tierIndex + 1}`,
        currentVolumeDiscountPrice,
        couponCode: couponServerData?.couponCode || '',
        couponProcessedDataPerSku,
        totalCouponSavings,
    }
}

export function* getPurchaseData(transactionID: string) {
    const { calculatedNetTotal, calculatedTax, promoCode }: CheckoutState = yield select(getCheckoutState)
    const { userType }: UserState = yield select(getUserState)
    return { calculatedNetTotal, calculatedTax, promoCode, userType, transactionID }
}

export function* getCompleteBuyData() {
    const { userType }: UserState = yield select(getUserState)
    return userType
}
