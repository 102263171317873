export enum ADDONS {
    ADDITIONAL_NUMBERS = 'G2CPreDID',
    MOBILE_ADDON = 'GTRMobileAddNamed',
    CAMERA_SHARE_ADDON = 'GTRCameraShareAddNamed',
    ENDPOINT_PROTECTION_ADDON = 'GTREndpointProtection',
    MAX_AGENTS_ADDONS = 51,
    MAX_DEVICE_ADDONS = 1500,
    MAX_ENDPOINTS_ADDONS = 1025,
    ENDPOINTS_ADDONS_INTERVAL = 25,
    DEVICE_ADDONS_INTERVAL = 25,
    AGENT = 'Agent',
    ENDPOINT = 'Endpoint',
    ADDON_PRICE_REPLACE = 'ADDON_PRICE_REPLACE',
}

export enum ADDON_SKUS {
    MOBILE_ADDON = 'GTRMobileAddNamed',
    CAMERA_SHARE_ADDON = 'GTRCameraShareAddNamed',
    ENDPOINT_PROTECTION_ADDON = 'GTREndpointProtection',
    AGENT_ADDON = 'GTRBasicAddAgentNamed',
    ENDPOINT_ADDON = 'GTRBasicAddEndpointNamed',
    BASIC_AGENT_ADDON = 'GTRBasicAddAgentNamed',
    BASIC_ENDPOINT_ADDON = 'GTRBasicAddEndpointNamed',
    STANDARD_AGENT_ADDON = 'GTRStandardAddAgentNamed',
    STANDARD_ENDPOINT_ADDON = 'GTRStandardAddEndpointNamed',
}
