import React, { FunctionComponent } from 'react'
import { formatCurrency } from '@common/utils/price-utils'

export interface FormatDecimalCurrencyProps {
    Locale: string
    CurrencyCode: string
    price: number
}
export const FormatDecimalCurrency: FunctionComponent<FormatDecimalCurrencyProps> = ({
    price,
    Locale,
    CurrencyCode,
}) => {
    const value = formatCurrency(price, Locale, CurrencyCode, true)
    const punc = value.toString().includes('.') ? '.' : ','
    const [wholeNumber, decimal = '00'] = value.split(punc)
    return (
        <>
            {wholeNumber}
            <sup>
                {punc}
                {decimal}
            </sup>
        </>
    )
}
