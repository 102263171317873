import { call, takeLeading, put } from 'redux-saga/effects'
import commonservices, { getExternalLocationInfo } from '@common/services'
import { locationActions, LocationActionTypes } from '@common/state/location/actions'
import { LOCALES, CURRENCY } from '@common/constants'
import { GenericResponse } from '@common/state/generic-response/generic-response'

export function* loadLocationInfo(action: ReturnType<typeof locationActions.getLocationInfo>) {
    try {
        const { isExternal } = action.payload
        const locationInfoResponse: GenericResponse = isExternal
            ? yield call(getExternalLocationInfo)
            : yield call(commonservices.getLocationInfo)
        if (locationInfoResponse && locationInfoResponse.data) {
            const { country, currencyRestricitons, language } = locationInfoResponse.data
            const eligibleCurrency = Object.values(CURRENCY).includes(currencyRestricitons[0])
            yield put(
                locationActions.setLocationInfo({
                    country,
                    currency: eligibleCurrency ? currencyRestricitons[0] : currencyRestricitons[1],
                    locale: `${language}-${country}` as LOCALES,
                    language,
                })
            )
            yield put(locationActions.setIsLocationInfoLoaded(true))
        }
    } catch (e: any) {
        console.error(e.message)
    }
}

function* initializeLoadLocationInfoSaga() {
    yield takeLeading(LocationActionTypes.GET_LOCATION_INFO, loadLocationInfo)
}

export default initializeLoadLocationInfoSaga
