import { useState } from 'react'
import { NumbersRequest, NumbersResponse, PhoneNumber, searchNumbers } from '@gtc/services/numbersApi'

interface IProps {
    content: {
        internalErrorMessage: string
    }
}

export const usePhoneNumberSearch = (props: IProps) => {
    // props
    const { internalErrorMessage } = props.content

    // state
    const [phoneNumberList, setPhoneNumberList] = useState([] as PhoneNumber[])
    const [phoneNumberListLoading, setPhoneNumberListLoading] = useState(false)
    const [phoneNumberListError, setPhoneNumberListError] = useState('')

    const handlePhoneNumberSearch = async (params: NumbersRequest) => {
        setPhoneNumberListLoading(true)
        setPhoneNumberListError('')
        try {
            const response = await searchNumbers(params)
            const { phoneNumberDetails } = response.data as NumbersResponse
            if (!phoneNumberDetails) {
                throw new Error('Unknown error')
            }
            setPhoneNumberList(phoneNumberDetails)
        } catch (ex) {
            setPhoneNumberList([])
            setPhoneNumberListError(internalErrorMessage)
        } finally {
            setPhoneNumberListLoading(false)
        }
    }

    return {
        phoneNumberList,
        phoneNumberListLoading,
        phoneNumberListError,
        setPhoneNumberListError,
        handlePhoneNumberSearch,
    }
}
