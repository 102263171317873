/* eslint-disable react/require-default-props */
import React, { useState, ChangeEvent } from 'react'
import { InputField } from '@common/components/InputField'
import { NumberPickerContent } from '@gtc/components/NumberPicker/NumberPicker.types'
import styles from './SearchFilters.module.css'

interface IProps {
    content: NumberPickerContent
    defaultAreaCode?: string
    handleChangeAreaCode: (areaCode: string) => void
}

export const SearchFilters = ({ content, handleChangeAreaCode, defaultAreaCode = '' }: IProps) => {
    const [areaCode, setAreaCode] = useState(defaultAreaCode)

    const handleAreaCodeChange = (event: ChangeEvent<HTMLInputElement>) => {
        const areaCodeTrimmed = event.target.value.trim()
        setAreaCode(areaCodeTrimmed)
        handleChangeAreaCode(areaCodeTrimmed)
    }

    return (
        <div className={styles.wrapper} data-qat="numberpicker_modal_searchfilters">
            {/*
                The original story called for dropdowns for Country, Region and Area Code here,
                but for the first release it was decided to only go with an Area Code input instead
            */}
            <h3 className={styles.areaCodeHeader}>{content.areaCodeHeader}</h3>
            <div className={styles.areaCodeSubHeader}>{content.areaCodeSubHeader}</div>
            <InputField
                data-qat="numberpicker_modal_searchfilters_areacode"
                label={content.areaCode}
                ismasked={true}
                mask="999"
                maskChar=""
                modifier={styles.areaCode}
                value={areaCode}
                onChange={handleAreaCodeChange}
            />
        </div>
    )
}
