import get from 'lodash/get'

import { AppState } from 'app-store'
import { BillingPeriod, TimePeriod } from '@common/constants'
import {
    getUserState,
    getLabelContent,
    getCheckoutState,
    getContentData,
    getPlanData,
    getCouponState,
} from '@gtc/state/selectors'
import { getCurrentPlan } from '@gtc/utils/get-current-plan'
import { getCurrentAddons } from '@gtc/utils/get-current-addons'
import { Plan } from '@gtc/state/plan'
import { checkoutActions } from '@gtc/state/checkout/actions'
import { getActiveCouponServerData } from '@gtc/utils'
import { OrderSummaryState } from './state'

export const mapOrderSummaryState = (state: AppState): OrderSummaryState => {
    const {
        monthly,
        annual,
        save,
        orderSummary,
        user,
        year,
        month,
        quantityLabelPlural,
        quantityLabel,
        subtotal,
        estimatedTax,
        tBD,
        recurringMonthlyCharge,
        autoRenewalNote,
        recurringAnnualCharge,
        quantityPopupInfo,
        estimatedTaxPopupInfo,
        newUserLicense,
        total,
        proratedAdjustment,
        today,
        newRecurringAnnualTotal,
        newRecurringMonthlyTotal,
        previousAnnualTotal,
        previousMonthlyTotal,
        billingCycleCredit,
        calculating,
        newUserLicensePlural,
        fees,
        feesPopupText,
        percentageOffAnnualDiscount,
        percentageOffVolumeDiscount,
        totalSavings,
        volumeDiscountPopupText,
        percentageOffPromoDiscount,
        couponAdjustmentSavingsPopupText,
        couponAdjustmentSavings,
        trustArcSupport,
    } = getLabelContent(state)

    const { Locale, CurrencyCode } = getContentData(state)

    const {
        organizers,
        selectedAddons,
        billingFrequency,
        selectedPlanSku,
        calculatedTotal,
        calculatedTax,
        productPrices,
        anniversaryDate,
        calculatedNetTotal,
        totalLoading,
        currentVolumeDiscountPrice,
        selectedTierIndex,
        calculatedProratedAmount,
        isChoosingPhoneNumber,
        phoneNumber,
        phoneNumberVanityPhrase,
    } = getCheckoutState(state)
    const planData: Plan[] = getPlanData(state)
    const selectedPlan = getCurrentPlan(selectedPlanSku, planData)
    const coupon = getCouponState(state)
    const { couponProcessedDataPerSku, isCouponValidAndApplied, couponDoubleDipAmountForNewUser } = coupon
    const couponServerData = getActiveCouponServerData(coupon, selectedPlan)
    const couponDiscountType = couponServerData ? couponServerData.couponDiscountType : null

    const currSelectedAddons = getCurrentAddons(selectedAddons, selectedPlan)
    const currentTier = `tier${selectedTierIndex + 1}`
    const billingFrequencyKey = billingFrequency === TimePeriod.Year ? BillingPeriod.ANNUAL : BillingPeriod.MONTHLY
    const baseTierAnnualPrice: number = get(selectedPlan, ['pricingInfo', CurrencyCode, 'annual', 'tier1', 'price'], 0)
    const baseTierMonthlyPrice: number = get(
        selectedPlan,
        ['pricingInfo', CurrencyCode, 'monthly', 'tier1', 'price'],
        0
    )
    const currentTierPrice: number = get(
        selectedPlan,
        ['pricingInfo', CurrencyCode, billingFrequencyKey, currentTier, 'price'],
        0
    )
    // TODO: property to be added for monthly annual contract
    const baseTierMonthlyAnnualContractPrice: number = get(
        selectedPlan,
        ['pricingInfo', CurrencyCode, '', 'tier1', 'price'],
        0
    )

    const {
        userType,
        existingBillingInformation,
        existingAccountInformation,
        loggedIn: { isUserLoggedIn },
    } = getUserState(state)

    return {
        content: {
            monthly,
            annual,
            save,
            orderSummary,
            user,
            year,
            month,
            quantityLabelPlural,
            quantityLabel,
            subtotal,
            estimatedTax,
            tBD,
            recurringMonthlyCharge,
            autoRenewalNote,
            recurringAnnualCharge,
            quantityPopupInfo,
            estimatedTaxPopupInfo,
            newUserLicense,
            total,
            proratedAdjustment,
            today,
            newRecurringAnnualTotal,
            newRecurringMonthlyTotal,
            previousAnnualTotal,
            previousMonthlyTotal,
            billingCycleCredit,
            calculating,
            newUserLicensePlural,
            fees,
            feesPopupText,
            percentageOffAnnualDiscount,
            percentageOffVolumeDiscount,
            totalSavings,
            volumeDiscountPopupText,
            percentageOffPromoDiscount,
            couponAdjustmentSavingsPopupText,
            couponAdjustmentSavings,
            trustArcSupport,
        },
        Locale,
        CurrencyCode,
        organizers,
        selectedAddons: currSelectedAddons,
        billingFrequency,
        selectedPlan,
        calculatedTax,
        calculatedTotal,
        isUserLoggedIn,
        productPrices,
        anniversaryDate,
        calculatedNetTotal,
        calculatedProratedAmount,
        userType,
        userEmail: existingBillingInformation?.email,
        existingAccountInformation,
        totalLoading,
        currentVolumeDiscountPrice,
        baseTierAnnualPrice,
        baseTierMonthlyPrice,
        currentTierPrice,
        couponProcessedDataPerSku,
        isCouponValidAndApplied,
        couponDiscountType,
        couponDoubleDipAmountForNewUser,
        isChoosingPhoneNumber,
        phoneNumber,
        phoneNumberVanityPhrase,
        baseTierMonthlyAnnualContractPrice,
    }
}

export const mapOrderSummaryActions = {
    setBillingFrequency: (frequency: TimePeriod) => checkoutActions.setBillingFrequency(frequency),
}

export type OrderSummaryActionsType = typeof mapOrderSummaryActions
