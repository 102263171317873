import React, { FunctionComponent } from 'react'
import classNames from 'classnames'

import { ConnectedFormatCurrency } from '@gtc/connectedComponents'
import styles from './toggle-button.module.css'

export interface ToggleButtonProps {
    content: Record<string, string | number>
    onChangeFn: (event: React.ChangeEvent<HTMLInputElement>) => void
    isOn: boolean
    modifier?: string | undefined
    id: string
    disabled?: boolean
    isLarge?: boolean
    widget?: boolean
    theme?: 'lmipro' | 'default'
}

export const ToggleButton: FunctionComponent<ToggleButtonProps> = ({
    content,
    onChangeFn,
    modifier = '',
    isOn = true,
    id,
    disabled,
    isLarge,
    widget,
    theme = 'default',
}) => {
    const handleChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        if (onChangeFn && !disabled) onChangeFn(ev)
    }

    const labelClasses = classNames({
        [styles.wrapper]: true,
        [styles[`isOn--${theme}`]]: isOn,
        [styles['wrapper--large']]: isLarge,
        [modifier]: modifier,
        [styles.disabled]: disabled,
        [styles.widget]: widget,
    })

    return (
        <label htmlFor={id} className={labelClasses} data-qat={isOn ? 'toggle-button-isOn' : ''}>
            <span className={styles.offLabel} data-qat="monthly-label-toggle-element">
                {content.label1}
            </span>
            <input
                className={styles.input}
                type="checkbox"
                onChange={handleChange}
                name={id}
                id={id}
                checked={isOn}
                data-qat={id}
            />
            <span className={styles.check} data-qat="toggle-button-check" />
            <span className={styles.onLabel} data-qat="monthly-label-toggle-element">
                {content.label2}
            </span>
            <span className={styles.callout} data-qat="toggle-button-callout">
                {content.callout}{' '}
                {content.discountAmount ? <ConnectedFormatCurrency value={content.discountAmount as number} /> : ''}
            </span>
        </label>
    )
}
