import { AccountState } from './state'
import { AccountActionTypes } from './actions'
import type { AccountActions } from './actions'

export const initialAccountState: AccountState = {
    accountSubscriptions: [],
}

export const accountReducer = (previousState: AccountState | undefined, action: AccountActions): AccountState => {
    const state = previousState || initialAccountState
    switch (action.type) {
        case AccountActionTypes.SET_EXISTING_ACCOUNT_SUBSCRIPTIONS: {
            const { subscriptions } = action.payload
            return {
                ...state,
                accountSubscriptions: subscriptions,
            }
        }
        default:
            return state
        // TODO: Migrate gtc/userReducer and gtResolve/userReducer into accountReducer
    }
}
