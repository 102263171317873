import { ActionUnion, createAction } from '@common/utils'

export enum ModalActionTypes {
    SET_MODAL_OPEN = '[modal-actions] set-modal-open',
    SET_ERROR_MODAL = '[modal-actions] set-error-modal',
}

export const modalActions = {
    setModalOpen: (isModalOpen?: boolean) =>
        createAction(ModalActionTypes.SET_MODAL_OPEN, {
            isModalOpen,
        }),
    setErrorModal: (errorMessage: string) =>
        createAction(ModalActionTypes.SET_ERROR_MODAL, {
            errorMessage,
        }),
}

export type ModalActions = ActionUnion<typeof modalActions>
