import { checkoutActions, CheckoutActionTypes } from '@gtc/state/checkout/actions'
import { CheckoutState } from '@gtc/state/checkout/state'
import { getCheckoutState } from '@gtc/state/selectors'
import { select } from 'redux-saga-test-plan/matchers'
import { put, takeLatest } from 'redux-saga/effects'

function* setBillingFrequencySaga(action: ReturnType<typeof checkoutActions.setBillingFrequency>) {
    const { frequency: billingFrequency } = action.payload
    const { organizers, selectedTierIndex }: CheckoutState = yield select(getCheckoutState)
    yield put(checkoutActions.getVolumeDiscount(organizers, selectedTierIndex, billingFrequency))
}

function* initializeSetBillingFrequencySaga() {
    yield takeLatest(CheckoutActionTypes.SET_BILLING_FREQUENCY, setBillingFrequencySaga)
}

export default initializeSetBillingFrequencySaga
