import { PRODUCT_FAMILY_KEY, TRACKING_EVENTS, VIEW_PATH } from '@common/constants'
import { CTAClickData, FatalErrorData, TrackingState } from '@common/state/tracking/state'

export const trackEvent = (trackData: TrackingState) => {
    if (!window.dataLayer) {
        window.dataLayer = []
    }
    const { event, ...trackInfo } = trackData
    window.dataLayer.push({ event, ...trackInfo?.eventData })
}

export const getFormName = (pathName: string) => {
    if (pathName) {
        if (pathName.includes(VIEW_PATH.PATH_CHECKOUT) || pathName.includes(VIEW_PATH.PATH_CUSTOMIZE)) {
            return 'buy-form'
        }
    }
    return undefined
}

export const trackGenericEvent = (eventName: TRACKING_EVENTS, productKey: PRODUCT_FAMILY_KEY, pathName: string) => {
    const eventData = {
        page_type: pathName,
        platform_product: productKey,
    }
    trackEvent({ event: eventName, eventData })
}

export const trackGenericFormEvent = (eventName: TRACKING_EVENTS, pathName: string) => {
    const eventData = {
        page_type: pathName,
        form_name: getFormName(pathName),
    }
    trackEvent({ event: eventName, eventData })
}

export const trackCTAClick = (ctaClickData: CTAClickData) => {
    const eventData = {
        cta_name: ctaClickData.name,
        cta_url: ctaClickData.url,
    }
    trackEvent({ event: TRACKING_EVENTS.CLICK_CTA, eventData })
}

export const trackFatalError = (errorData: FatalErrorData) => {
    const eventData = {
        error_message: errorData.message,
        path: errorData.path,
    }
    trackEvent({ event: TRACKING_EVENTS.FATAL_ERROR, eventData })
}

export const trackAccountError = (message: string) => {
    const eventData = {
        error_account: {
            account_error_message: message,
        },
    }
    trackEvent({ event: TRACKING_EVENTS.ERROR_ACCOUNT, eventData })
}
