import { buyCouponActions, BuyCouponActionTypes } from '@buy/state/coupon/actions'
import { put, takeLatest } from 'redux-saga/effects'
import { call, select } from 'redux-saga-test-plan/matchers'
import { getCheckoutState, getCouponState } from '@buy/state/selectors'
import { BuyCheckoutState } from '@buy/state/checkout/state'
import isEmpty from 'lodash/isEmpty'
import { buyCheckoutActions, BuyCheckoutActionTypes } from '@buy/state/checkout/actions'
import { COUPON_DISCOUNT_TYPE } from '@common/constants'
import { getActiveCouponServerData } from '@buy/utils'
import { BuyCouponState } from '@buy/state/coupon/state'
import { CouponServerData } from '@common/state/coupon/state'
import { BuyPlan } from '@buy/state/plan'
import get from 'lodash/get'
import { getSelectedPlan } from '../content/content-helpers'

type CheckVolumeDiscountAction =
    | ReturnType<typeof buyCouponActions.checkVolumeDiscountAgainstRepriceCoupon>
    | ReturnType<typeof buyCheckoutActions.setCurrentVolumeDiscountPriceAndPercentage>

function* checkVolumeDiscountAgainstRepriceCouponSaga(action: CheckVolumeDiscountAction) {
    const coupon: BuyCouponState = yield select(getCouponState)
    const { selectedPlanSku }: BuyCheckoutState = yield select(getCheckoutState)
    let { couponProcessedDataPerSku } = coupon
    let volumeDiscountPrice = 0
    let selectedPlan: BuyPlan = yield call(getSelectedPlan, selectedPlanSku)
    let couponServerData: CouponServerData | null = yield call(getActiveCouponServerData, coupon, selectedPlan)

    if (action.type === BuyCheckoutActionTypes.SET_CURRENT_VOLUME_DISCOUNT_PRICE_AND_PERCENTAGE) {
        volumeDiscountPrice = action.payload.currentVolumeDiscountPrice
    } else if (action.type === BuyCouponActionTypes.CHECK_VOLUME_DISCOUNT_AGAINST_REPRICE_COUPON) {
        const { checkVolumeDiscountData } = action.payload
        couponServerData = checkVolumeDiscountData.couponServerData
        couponProcessedDataPerSku = checkVolumeDiscountData.couponProcessedDataPerSku
        selectedPlan = checkVolumeDiscountData.selectedPlan
        const { currentVolumeDiscountPrice }: BuyCheckoutState = yield select(getCheckoutState)
        volumeDiscountPrice = currentVolumeDiscountPrice
    }

    if (
        couponServerData &&
        !isEmpty(couponServerData) &&
        couponServerData.couponDiscountType === COUPON_DISCOUNT_TYPE.REPRICE
    ) {
        const couponDiscountTotalSavings: number = get(
            couponProcessedDataPerSku,
            [selectedPlan?.sKU, 'discountTotalSavings'],
            0
        )
        if (volumeDiscountPrice < couponDiscountTotalSavings) {
            yield put(buyCheckoutActions.adjustCurrentVolumeDiscountForRepriceCoupon())
        }
    }
}

export default function* initializeCheckVolumeDiscountAgainstRepriceCouponSaga() {
    yield takeLatest(
        [
            BuyCouponActionTypes.CHECK_VOLUME_DISCOUNT_AGAINST_REPRICE_COUPON,
            BuyCheckoutActionTypes.SET_CURRENT_VOLUME_DISCOUNT_PRICE_AND_PERCENTAGE,
        ],
        checkVolumeDiscountAgainstRepriceCouponSaga
    )
}
