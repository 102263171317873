import { CURRENCY } from '@common/constants'
import { BillingFrequencyVolumeDiscountRecords, VolumeDiscountPerTier } from '@gtc/state/checkout/state'
import { Plan } from '@gtc/state/plan'
import { Price, Pricing, Tiers } from '@gtc/state/price'

export const getDiscount = (basePrice: number, newPrice: number): number => {
    return basePrice - newPrice
}

export const getDiscountPercentage = (basePrice: number, newPrice: number): number => {
    return Math.floor(((basePrice - newPrice) / basePrice) * 100)
}

const processPricingTier = (pricingTierKeys: string[], pricingTiers: Tiers): VolumeDiscountPerTier[] => {
    let basePrice = 0
    return pricingTierKeys.reduce((pricingTierAccumulator, pricingTier, pricingTierIndex) => {
        const pricingTierAccumulatorCopy = [...pricingTierAccumulator]
        const pricingTierData = pricingTiers[pricingTier as keyof Tiers]
        const tierPrice = pricingTierData.price
        if (pricingTierIndex === 0) {
            basePrice = tierPrice
        }

        const volumeDiscountPerTier: VolumeDiscountPerTier = {
            discountPricePerUnit: getDiscount(basePrice, tierPrice),
        }
        pricingTierAccumulatorCopy.push(volumeDiscountPerTier)
        return pricingTierAccumulatorCopy
    }, [] as VolumeDiscountPerTier[])
}

const processCurrencyPricing = (
    pricingKeys: string[],
    currencyPricing: Pricing
): BillingFrequencyVolumeDiscountRecords => {
    return pricingKeys.reduce((billingFrequencyAccumulator, billingFrequency) => {
        const billingFrequencyAccumulatorCopy = { ...billingFrequencyAccumulator }
        const pricingTiers = currencyPricing[billingFrequency as keyof Pricing]
        const pricingTierKeys = Object.keys(pricingTiers)
        billingFrequencyAccumulatorCopy[billingFrequency] = processPricingTier(pricingTierKeys, pricingTiers)
        return billingFrequencyAccumulatorCopy
    }, {} as BillingFrequencyVolumeDiscountRecords)
}

export const calculateVolumeDiscount = (
    selectedPlan: Plan,
    selectedCurrency: CURRENCY
): BillingFrequencyVolumeDiscountRecords => {
    const { pricingInfo } = selectedPlan
    const currencyPricing = pricingInfo[selectedCurrency as keyof Price] as Pricing
    const pricingKeys = Object.keys(currencyPricing)
    return processCurrencyPricing(pricingKeys, currencyPricing)
}

export const getTotalVolumeDiscount = (discountPerUser: number, organizers: number, annualize: boolean) => {
    const volumeDiscountTimesOrganizer = discountPerUser * organizers

    const totalVolumeDiscount = annualize ? volumeDiscountTimesOrganizer * 12 : volumeDiscountTimesOrganizer

    return totalVolumeDiscount
}
