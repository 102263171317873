import { addGTMIframe } from '@common/utils'
import { useEffect } from 'react'

export const useGTM = (gtmProductCode: string) => {
    useEffect(() => {
        const script = document.createElement('script')
        script.innerHTML = `
                (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-${gtmProductCode}');
            `
        document.head.prepend(script)
        const gtmIframe = addGTMIframe(gtmProductCode)
        return () => {
            document.head.removeChild(script)
            document.body.removeChild(gtmIframe)
        }
    }, [gtmProductCode])
}
