import { buyCouponActions, BuyCouponActionTypes } from '@buy/state/coupon/actions'
import { put, takeLatest } from 'redux-saga/effects'

function* attempUrlCouponSaga(action: ReturnType<typeof buyCouponActions.setUrlCouponCode>) {
    const { couponCode } = action.payload
    if (couponCode) {
        // if url promotion available
        yield put(buyCouponActions.getCouponDetails(couponCode as string))
    } else {
        yield put(buyCouponActions.attemptForCMSCoupon())
    }
}

export default function* initializeAttempUrlCouponSaga() {
    yield takeLatest(BuyCouponActionTypes.SET_URL_COUPON_CODE, attempUrlCouponSaga)
}
