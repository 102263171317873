import { useEffect, useRef, useState } from 'react'

export const useLoadScript = (source: string, shouldLoadScript = true, language?: string) => {
    const [scriptLoad, setScriptLoad] = useState(false)
    const isMountedRef = useRef(false)

    const qualifiedChatBotScriptContent = `(function(w,q){w['QualifiedObject']=q;w[q]=w[q]||function(){(w[q].q=w[q].q||[]).push(arguments)};})(window,'qualified')`

    const onScriptLoad = () => {
        if (isMountedRef.current) {
            setScriptLoad(true)
        }
    }
    const onScriptError = () => {
        if (isMountedRef.current) {
            setScriptLoad(false)
            isMountedRef.current = false
        }
    }

    useEffect(() => {
        if (shouldLoadScript && !isMountedRef.current) {
            const script = document.createElement('script')
            script.type = 'text/javascript'
            script.async = true
            script.src = `${source}${/trustarc\.com/i.test(source) ? `&language=${language || 'en'}` : ''}`

            script.addEventListener('load', onScriptLoad)
            script.addEventListener('error', onScriptError)

            if (/qualified\.com/i.test(source)) {
                const scriptTagQualifiedFunction = document.createElement('script')
                scriptTagQualifiedFunction.textContent = qualifiedChatBotScriptContent
                document.head.appendChild(scriptTagQualifiedFunction)
                document.head.appendChild(script)
            } else {
                document.body.appendChild(script)
            }

            isMountedRef.current = true
            return () => {
                if (isMountedRef.current) {
                    isMountedRef.current = false
                    script.removeEventListener('load', onScriptLoad)
                    script.removeEventListener('error', onScriptError)
                    document.body.removeChild(script)
                }
            }
        }
        setScriptLoad(true)

        return undefined
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [source, shouldLoadScript])
    return scriptLoad
}
