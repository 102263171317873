/* eslint-disable react/require-default-props */
import React from 'react'
import { InputField } from '@common/components/InputField'
import { NumberPickerContent } from '@gtc/components/NumberPicker'
import styles from './VanityPhrase.module.css'

interface IProps {
    vanityPhrase?: string
    content: NumberPickerContent
    handleChangeVanityPhrase: (vanityPhrase: string) => void
}

export const VanityPhrase = (props: IProps) => {
    const { content, vanityPhrase, handleChangeVanityPhrase } = props

    return (
        <div className={styles.wrapper}>
            <h3>{content.vanityPhrase}</h3>
            <InputField
                data-qat="numberpicker_modal_vanity_phrase"
                ismasked={true}
                mask="*******"
                maskChar=""
                label={content.vanityPhrasePlaceholder}
                onChange={(e) => handleChangeVanityPhrase(e.target.value.toUpperCase())}
                value={vanityPhrase}
                modifier={styles.phraseInputField}
            />
            <ul>
                <li>{content.vanityPhraseDescriptionLine1}</li>
                <li>{content.vanityPhraseDescriptionLine2}</li>
                <li>{content.vanityPhraseDescriptionLine3}</li>
            </ul>
        </div>
    )
}
