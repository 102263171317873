import { BuyCheckoutState } from '@buy/state/checkout/state'
import { getCheckoutState, getPlanData } from '@buy/state/selectors'
import { BuyPlan } from '@buy/state/plan'
import { select, call, SelectEffect, CallEffect } from 'redux-saga/effects'
import { getCurrentPlan, getPlanNameSet } from '@buy/utils/get-current-plan'
import { getCurrentAddons } from '@buy/utils/get-current-addons'
import { BuyAddon } from '@buy/state/add-on'
import { UrlParams, updatePlanUrlParam } from '@common/utils'
import isEmpty from 'lodash/isEmpty'

export function* getSelectedPlan(planSku?: string): Generator<SelectEffect, BuyPlan, BuyCheckoutState & BuyPlan[]> {
    const { selectedPlanSku }: BuyCheckoutState = yield select(getCheckoutState)
    const planSkuToRetrieve = planSku || selectedPlanSku
    const planData: BuyPlan[] = yield select(getPlanData)
    const selectedPlan: BuyPlan = getCurrentPlan(planSkuToRetrieve, planData)
    if (!selectedPlan) {
        return planData[0]
    }
    return selectedPlan
}

export function* getSelectedAddons(): Generator<
    SelectEffect | CallEffect<BuyPlan> | CallEffect<BuyAddon[]>,
    BuyAddon[],
    BuyCheckoutState & BuyPlan & BuyAddon[]
> {
    const { selectedAddons }: BuyCheckoutState = yield select(getCheckoutState)
    const selectedPlan: BuyPlan = yield call(getSelectedPlan)
    const myAddons: BuyAddon[] = yield call(getCurrentAddons, selectedAddons, selectedPlan)
    return myAddons
}

export function* getUpdatedPlanUrlParams() {
    const selectedPlan: BuyPlan = yield call(getSelectedPlan)
    const planData: BuyPlan[] = yield select(getPlanData)
    const planNameSet: Set<string> = yield call(getPlanNameSet, planData)
    const planUrlParams: UrlParams = yield call(
        updatePlanUrlParam,
        isEmpty(selectedPlan) ? '' : selectedPlan.name,
        planNameSet
    )
    return planUrlParams
}
