import { getDomain } from '@common/utils/get-domain'
import { LANGUAGE_CODES } from './languages'

// Get language tag from the url if there's one
const urlSplitForLang = window.location.pathname.slice(1, 3)
const hasLang = Object.values(LANGUAGE_CODES).includes(urlSplitForLang)
const urlLang = hasLang ? `/${urlSplitForLang}` : ''

export enum VIEW_PATH {
    PATH_SUCCESS = '/success',
    PATH_ERROR = '/fatal-error',
    PATH_CUSTOMIZE = '/customize',
    PATH_CHECKOUT = '/checkout',
    PATH_TRIAL = '/trial',
    PATH_FREE = '/free',
    PATH_BASE = '/',
}

export enum GTC_VIEW_PATH {
    PATH_NEXT_STEPS = '/next-steps',
}

export const PRODUCT_PATH = {
    CONNECT: '/connect',
    RESOLVE: `${urlLang}/resolve`,
    LOGMEINPRO: `${urlLang}/logmeinpro`,
    BUY: `${urlLang}/buy`,
}

export enum GTRESOLVE_VIEW_PATH {
    PATH_FREE_TIER = '/free',
    PATH_STANDARD_TIER = '/trial',
}

export const GTC_VIEW_PATHS = {
    CONNECT_CUSTOMIZE: `${PRODUCT_PATH.CONNECT}${VIEW_PATH.PATH_CUSTOMIZE}`,
    CONNECT_CHECKOUT: `${PRODUCT_PATH.CONNECT}${VIEW_PATH.PATH_CHECKOUT}`,
    CONNECT_TRIAL: `${PRODUCT_PATH.CONNECT}${VIEW_PATH.PATH_TRIAL}`,
    CONNECT_ERROR: `${PRODUCT_PATH.CONNECT}${VIEW_PATH.PATH_ERROR}`,
    CONNECT_NEXT_STEPS: `${PRODUCT_PATH.CONNECT}${GTC_VIEW_PATH.PATH_NEXT_STEPS}`,
}

export const GTRESOLVE_VIEW_PATHS = {
    RESOLVE_CUSTOMIZE: `${PRODUCT_PATH.RESOLVE}${VIEW_PATH.PATH_CUSTOMIZE}`,
    RESOLVE_CHECKOUT: `${PRODUCT_PATH.RESOLVE}${VIEW_PATH.PATH_CHECKOUT}`,
    RESOLVE_FREE_TIER: `${PRODUCT_PATH.RESOLVE}${GTRESOLVE_VIEW_PATH.PATH_FREE_TIER}`,
    RESOLVE_STANDARD_TIER: `${PRODUCT_PATH.RESOLVE}${GTRESOLVE_VIEW_PATH.PATH_STANDARD_TIER}`,
    RESOLVE_ERROR: `${PRODUCT_PATH.RESOLVE}${VIEW_PATH.PATH_ERROR}`,
}
// TODO: Changes needs to made based on unified config
export const BUY_VIEW_PATHS = {
    BUY_CUSTOMIZE: `${PRODUCT_PATH.BUY}${VIEW_PATH.PATH_CUSTOMIZE}`,
    BUY_CHECKOUT: `${PRODUCT_PATH.BUY}${VIEW_PATH.PATH_CHECKOUT}`,
    BUY_FREE_TIER: `${PRODUCT_PATH.BUY}${GTRESOLVE_VIEW_PATH.PATH_FREE_TIER}`,
    BUY_STANDARD_TIER: `${PRODUCT_PATH.BUY}${GTRESOLVE_VIEW_PATH.PATH_STANDARD_TIER}`,
    BUY_ERROR: `${PRODUCT_PATH.BUY}${VIEW_PATH.PATH_ERROR}`,
}

export enum BUY_VIEW_PATH {
    PATH_FREE_TIER = '/free',
    PATH_STANDARD_TIER = '/trial',
}

export const LMIPRO_VIEW_PATHS = {
    LMIPRO_CUSTOMIZE: `${PRODUCT_PATH.LOGMEINPRO}${VIEW_PATH.PATH_CUSTOMIZE}`,
    LMIPRO_CHECKOUT: `${PRODUCT_PATH.LOGMEINPRO}${VIEW_PATH.PATH_CHECKOUT}`,
}

export const LMIPRO_REDIRECT_PATHS = {
    LMIPRO_REDIRECT_PRO: `/pro`,
    LMIPRO_REDIRECT_CENTRAL: `/central`,
}

export const DOMAIN_NAME = getDomain()

export const APP_PATHS = { ...GTC_VIEW_PATHS, ...GTRESOLVE_VIEW_PATHS, ...LMIPRO_VIEW_PATHS }
