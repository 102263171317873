export enum STORAGE_CONST {
    SAVED_ROUTE = 'gtc-saved-route',
    SAVED_QUERY = 'gtc-saved-query',
    GTC_STORE = 'gtc-store',
    GTC_COUPON_STORE = 'gtc-coupon-store',
    GTR_STORE = 'gtr-store',
    GTR_COUPON_STORE = 'gtr-coupon-store',
    LMIPRO_STORE = 'lmipro-store',
    LMIPRO_COUPON_STORE = 'lmipro-coupon-store',
    SAVED_LANGUAGE = 'saved-language',
}
