// Google Places automatically changes the autocomplete attribute of the input field from "new-password" to "off" which
// doesn't work to prevent Chrome's address auto-fill dropdown from showing up
// This function monitors an element for changes to a given attribute and sets it back to the original value.
// See: https://stackoverflow.com/a/41425087
export const lockHtmlElementAttribute = (
    input: any,
    attributeName: string,
    unlockAfterFirstMutationAttempt: boolean
) => {
    const originalAttributeValue = input[attributeName]

    const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
            if (mutation.type === 'attributes' && mutation.attributeName === attributeName) {
                observer.disconnect()
                input.setAttribute(attributeName, originalAttributeValue)
                if (!unlockAfterFirstMutationAttempt) {
                    observer.observe(input, { attributes: true })
                }
            }
        })
    })

    observer.observe(input, { attributes: true })
}
