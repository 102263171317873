/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
// Import Dependencies
import React, { FunctionComponent, useEffect, useState } from 'react'
import { ModalState } from '@common/state/modal/state'
import Close from '@common/images/icon-close.svg'
// Import Stylesheet
import { GoToComponentProps, connectRedux, trapFocus } from '@common/utils'
import { ModalActionsType, mapModalState, mapModalActions } from '@common/connectedComponents/Modal/connector'
import { useScrollToTop } from '@common/hooks'
import styles from './modal.module.css'

type ModalProps = GoToComponentProps<
    ModalState,
    ModalActionsType,
    {
        isOpen?: boolean
        closeable: boolean
        onClose?: () => void
        children?: React.ReactNode
    }
>

const Modal: FunctionComponent<ModalProps> = ({ props, children }) => {
    const { isOpen, closeable, onClose } = props
    const [isModalOpen, setModalIsOpen] = useState(isOpen)
    const [isModalClosable, setModalCloseable] = useState(false)

    useScrollToTop()

    useEffect(() => {
        setModalIsOpen(isOpen)
        setModalCloseable(closeable)

        // Set focus trap upon modal opens
        if (isOpen) {
            setTimeout(() => trapFocus('#connectedModal', true), 0)
        }
    }, [isOpen, closeable])

    const onModalClose = () => {
        if (onClose && isModalClosable) onClose()
    }

    // if modal is set to be open
    if (isModalOpen) {
        return (
            <div
                className={styles.blocker}
                onClick={() => onModalClose()}
                onKeyDown={(e) => {
                    if (e.key === 'Escape' || e.key === 'Esc') {
                        onModalClose()
                    }
                }}
                role="button"
                tabIndex={0}
                id="connectedModal"
            >
                <div className={styles.modal} role="dialog" onClick={(e) => e.stopPropagation()}>
                    {children}
                    {isModalClosable && (
                        <button className={styles.close} onClick={() => onModalClose()}>
                            <img src={Close} alt="close" />
                        </button>
                    )}
                </div>
            </div>
        )
    }
    return null
}

export const ConnectedModal = connectRedux(Modal, mapModalState, mapModalActions)
