import { buyFormStateInitialData } from '@buy/data'
import { BuyFormState } from './state'
import { BuyFormActionTypes } from './actions'
import type { BuyFormActions } from './actions'

export const buyFormInitialState: BuyFormState = buyFormStateInitialData

export const buyFormReducer = (previousState: BuyFormState | undefined, action: BuyFormActions): BuyFormState => {
    const state = previousState || buyFormInitialState
    switch (action.type) {
        // need to add actions from the form components here
        case BuyFormActionTypes.RESET_BUY_FORM: {
            return buyFormInitialState
        }
        case BuyFormActionTypes.UPDATE_INITIAL_FORM_STATE: {
            const { data } = action.payload
            return {
                ...state,
                initialData: { ...data },
            }
        }
        case BuyFormActionTypes.SET_CLEAR_EMAIL:
            return {
                ...state,
                clearEmail: action.payload.clear,
            }
        case BuyFormActionTypes.UPDATE_STEPS:
            return {
                ...state,
                steps: {
                    ...state.steps,
                    [action.payload.stepName]: {
                        completed: action.payload.stepResult,
                        error: !action.payload.stepResult,
                    },
                },
            }
        case BuyFormActionTypes.SET_STATES_OPTIONAL:
            return {
                ...state,
                statesOptional: action.payload.optional,
            }

        default:
            return state
    }
}
