import {
    BillingPeriod,
    CURRENCY,
    TimePeriod,
    COUPON_DISCOUNT_TYPE,
    COUPON_PAYMENT_PLAN,
    COUPON_TYPE,
} from '@common/constants'

import { CouponItem, CouponServerData } from '@common/state/coupon/state'

import {
    ResolveCheckCouponProductsValidityParameters,
    ResolveCouponProcessedDataPerSku,
    ResolveCouponSkuValidity,
    ResolveCouponState,
} from '@gtresolve/state/coupon/state'

import {
    ResolveCouponCalculation,
    GetResolveProductItemsParameters,
    ResolveProcessCouponDataParameters,
    ResolveProductDetails,
} from '@gtresolve/state/coupon-util'

import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import { ADDONS, PRODUCT_PRICE_ITEM_TYPE } from '@gtresolve/constants'
import { ResolvePlan } from '@gtresolve/state/plan'
import { ResolveProductPriceItem } from '@gtresolve/state/calculate-price'

export const getActiveCouponServerData = (coupon: ResolveCouponState, selectedPlan: ResolvePlan) => {
    const { couponServerData, isUrlCouponActive, isCMSCouponActive } = coupon
    if (isUrlCouponActive) {
        return couponServerData
    }
    if (isCMSCouponActive && !isEmpty(selectedPlan?.activeCoupon)) {
        return selectedPlan.activeCoupon
    }
    return null
}

export const getAvailableCouponServerData = (
    coupon: ResolveCouponState,
    selectedPlan: ResolvePlan
): [CouponServerData | null, boolean] => {
    const { couponServerData, isUrlCouponCodeAvailable } = coupon
    if (!isEmpty(couponServerData) && isUrlCouponCodeAvailable) {
        return [couponServerData, true]
    }
    if (!isEmpty(selectedPlan?.activeCoupon)) {
        return [selectedPlan.activeCoupon, false]
    }
    return [null, false]
}

export const isCouponDateValid = (start: string, end: string): boolean => {
    const startDate = new Date(start)
    const endDate = new Date(end)
    const currentDate = new Date()
    const currentDatems = currentDate.getTime()
    return currentDatems >= startDate.getTime() && currentDatems <= endDate.getTime()
}

interface IsProductSkuAndPaymentPlanValidParameters {
    selectedProductSku: string
    couponValidationItemsMap: Map<string, CouponValidationItem>
    isAnnual: boolean
}
interface CouponValidationItem {
    paymentPlan: Set<COUPON_PAYMENT_PLAN>
}

const isProductSkuAndPaymentPlanValid = ({
    selectedProductSku,
    couponValidationItemsMap,
    isAnnual,
}: IsProductSkuAndPaymentPlanValidParameters): boolean => {
    const toCheckPaymentPlan = isAnnual ? COUPON_PAYMENT_PLAN.UPFRONTANNUAL : COUPON_PAYMENT_PLAN.UPFRONTMONTHLY
    if (couponValidationItemsMap.has(selectedProductSku)) {
        const { paymentPlan } = couponValidationItemsMap.get(selectedProductSku) as CouponValidationItem
        return paymentPlan.has(toCheckPaymentPlan)
    }

    return false
}

export const checkCouponGenericValidity = (couponServerData: CouponServerData): boolean => {
    const { isCouponValid, validFrom, validTo } = couponServerData
    return isCouponValid && isCouponDateValid(validFrom, validTo)
}

export const processFixTypeCouponValidity = (couponSkuValidity: ResolveCouponSkuValidity): ResolveCouponSkuValidity => {
    const fixCouponSkuValidity = { ...couponSkuValidity }
    const isAnyCouponSkuInvalid = Object.keys(couponSkuValidity).some(
        (couponSku) => couponSkuValidity[couponSku] === false
    )
    if (isAnyCouponSkuInvalid) {
        Object.keys(fixCouponSkuValidity).forEach((couponSku) => {
            fixCouponSkuValidity[couponSku] = false
        })
    }
    return fixCouponSkuValidity
}

// temporary code for default coupon Sku validity
export const falsyCouponSkuValidity = (couponSkuValidity: ResolveCouponSkuValidity): ResolveCouponSkuValidity => {
    return Object.keys(couponSkuValidity).reduce((skuAcc, currentSku) => {
        return { ...skuAcc, [currentSku]: false }
    }, {} as ResolveCouponSkuValidity)
}

export const getCouponValidationItemsMap = (couponItems: CouponItem[]): Map<string, CouponValidationItem> => {
    const couponValidationItemsMap = new Map<string, CouponValidationItem>()
    couponItems.forEach((couponItem) => {
        const { productSku, paymentPlan } = couponItem
        if (!couponValidationItemsMap.has(productSku)) {
            const couponValidationItem: CouponValidationItem = { paymentPlan: new Set() }
            couponValidationItemsMap.set(productSku, couponValidationItem)
        }
        const couponValidationItem = couponValidationItemsMap.get(productSku) as CouponValidationItem
        couponValidationItem.paymentPlan.add(paymentPlan)
        couponValidationItemsMap.set(productSku, couponValidationItem)
    })
    return couponValidationItemsMap
}

export const checkCouponProductsValidity = ({
    couponServerData,
    selectedPlanSku,
    selectedAddons,
    billingFrequency,
}: ResolveCheckCouponProductsValidityParameters): ResolveCouponSkuValidity => {
    const { couponType, couponItems } = couponServerData
    const couponValidationItemsMap = getCouponValidationItemsMap(couponItems)
    const isAnnual = billingFrequency === TimePeriod.Year
    const addonsValidity = selectedAddons.reduce((acc, selectedAddon) => {
        return {
            ...acc,
            [selectedAddon.sKU]: isProductSkuAndPaymentPlanValid({
                selectedProductSku: selectedAddon.sKU,
                couponValidationItemsMap,
                isAnnual,
            }),
        }
    }, {})
    const couponSkuValidity: ResolveCouponSkuValidity = {
        ...addonsValidity,
        [selectedPlanSku]: isProductSkuAndPaymentPlanValid({
            selectedProductSku: selectedPlanSku,
            couponValidationItemsMap,
            isAnnual,
        }),
    }
    // need to add the fixed coupon processing here later
    return couponType === COUPON_TYPE.FLEXIBLE ? couponSkuValidity : falsyCouponSkuValidity(couponSkuValidity)
}

export const checkIfCouponAppliedToMinimumSelectedProducts = (couponSkuValidity: ResolveCouponSkuValidity): boolean => {
    return Object.values(couponSkuValidity).some((productValidity) => productValidity === true)
}

export const calculateCouponDiscount = (
    couponDiscountType: COUPON_DISCOUNT_TYPE,
    productUnitPriceForCurrentTierWithBillingFrequency: number,
    couponDiscountAmount: number,
    productUnitPriceForBaseTierWithBillingFrequency: number
): ResolveCouponCalculation | null => {
    switch (couponDiscountType) {
        case COUPON_DISCOUNT_TYPE.AMOUNTOFF: {
            const discountUnitPrice = productUnitPriceForCurrentTierWithBillingFrequency - couponDiscountAmount
            const discountPercentage = Math.floor(
                (couponDiscountAmount / productUnitPriceForCurrentTierWithBillingFrequency) * 100
            )
            return {
                discountUnitPrice,
                discountPercentage,
                couponAmountOff: couponDiscountAmount,
            }
        }

        case COUPON_DISCOUNT_TYPE.PERCENTAGEOFF: {
            const couponAmountOff = (productUnitPriceForCurrentTierWithBillingFrequency * couponDiscountAmount) / 100
            const discountUnitPrice = productUnitPriceForCurrentTierWithBillingFrequency - couponAmountOff
            return {
                discountPercentage: couponDiscountAmount,
                discountUnitPrice,
                couponAmountOff,
            }
        }

        case COUPON_DISCOUNT_TYPE.REPRICE: {
            const discountUnitPrice =
                couponDiscountAmount < productUnitPriceForCurrentTierWithBillingFrequency ? couponDiscountAmount : 0
            if (discountUnitPrice === 0) {
                return null
            }
            const couponAmountOff = productUnitPriceForBaseTierWithBillingFrequency - couponDiscountAmount
            const discountPercentage = Math.floor(
                (couponAmountOff / productUnitPriceForBaseTierWithBillingFrequency) * 100
            )
            return {
                discountUnitPrice,
                couponAmountOff,
                discountPercentage,
            }
        }
        default:
            return null
    }
}

export const getProductItemsMap = ({
    billingFrequency,
    organizers,
    selectedPlan,
    selectedCurrency = CURRENCY.USD,
    selectedAddons,
    selectedTierIndex,
}: GetResolveProductItemsParameters): Map<string, ResolveProductDetails> => {
    const billingFrequencyKey = billingFrequency === TimePeriod.Year ? BillingPeriod.ANNUAL : BillingPeriod.MONTHLY
    const productItemsMap: Map<string, ResolveProductDetails> = new Map()
    const isAnnual = billingFrequency === TimePeriod.Year
    const selectedPlanUnitPriceForCurrentTier = get(
        selectedPlan,
        ['pricingInfo', selectedCurrency, billingFrequencyKey, `tier${selectedTierIndex + 1}`, 'price'],
        0
    )

    const selectedPlanUnitPriceForBaseTier = get(
        selectedPlan,
        ['pricingInfo', selectedCurrency, billingFrequencyKey, `tier1`, 'price'],
        0
    )

    const annualMultiplier = isAnnual ? 12 : 1
    const selectedPlanProductDetails: ResolveProductDetails = {
        quantity: organizers,
        unitPriceForCurrentTierWithBillingFrequency: selectedPlanUnitPriceForCurrentTier * annualMultiplier,
        unitPriceForBaseTierWithBillingFrequency: selectedPlanUnitPriceForBaseTier * annualMultiplier,
    }
    productItemsMap.set(selectedPlan.sKU, selectedPlanProductDetails)

    selectedAddons.forEach((addon) => {
        const { sKU, quantity } = addon
        const planAddons = selectedPlan.addons
        const filteredPlanAddons = planAddons.filter((planAddon) => planAddon.sKU === sKU)
        const planAddon = filteredPlanAddons[0]
        const planAddonPrice = get(planAddon, ['pricingInfo', selectedCurrency, 'price'], 0)
        const completePlanAddonPrice = planAddonPrice * annualMultiplier
        productItemsMap.set(sKU, {
            quantity,
            unitPriceForCurrentTierWithBillingFrequency: completePlanAddonPrice,
            unitPriceForBaseTierWithBillingFrequency: completePlanAddonPrice,
        })
    })
    return productItemsMap
}

export const getCouponItemPriceMap = (
    couponServerData: CouponServerData,
    selectedCurrency: CURRENCY = CURRENCY.USD,
    isAnnual: boolean
): Map<string, number> => {
    const { couponItems } = couponServerData
    const couponItemsMap = new Map(
        couponItems
            .filter((couponItem) =>
                isAnnual
                    ? couponItem.paymentPlan === COUPON_PAYMENT_PLAN.UPFRONTANNUAL
                    : couponItem.paymentPlan === COUPON_PAYMENT_PLAN.UPFRONTMONTHLY
            )
            .map((couponItem) => {
                const { productSku, priceChanges } = couponItem
                const selectedCurrencyPriceChangeList = priceChanges.filter(
                    (price) => price.currency === selectedCurrency
                )
                const selectedCurrencyPriceChange = selectedCurrencyPriceChangeList[0]
                return [productSku, selectedCurrencyPriceChange.value]
            })
    )
    return couponItemsMap
}

export const processCouponData = ({
    couponDiscountType,
    productItemsMap,
    couponItemsMap,
    couponSkuValidity,
}: ResolveProcessCouponDataParameters): ResolveCouponProcessedDataPerSku | null => {
    const productItemsEntries = [...productItemsMap.entries()]
    const couponDataPerSku: ResolveCouponProcessedDataPerSku = {}

    productItemsEntries.forEach(([productSku, productDetail]) => {
        if (couponSkuValidity[productSku] && couponItemsMap.has(productSku)) {
            const { quantity, unitPriceForBaseTierWithBillingFrequency, unitPriceForCurrentTierWithBillingFrequency } =
                productDetail
            const couponDiscountAmount = couponItemsMap.get(productSku) as number
            const calculatedCouponDiscount = calculateCouponDiscount(
                couponDiscountType,
                unitPriceForCurrentTierWithBillingFrequency,
                couponDiscountAmount,
                unitPriceForBaseTierWithBillingFrequency
            )
            if (calculatedCouponDiscount === null || calculatedCouponDiscount.discountUnitPrice <= 0) {
                couponDataPerSku[productSku] = null
            } else {
                const { discountUnitPrice, couponAmountOff, discountPercentage } = calculatedCouponDiscount
                couponDataPerSku[productSku] = {
                    discountPercentage,
                    discountUnitPrice,
                    discountUnitSavings: couponAmountOff,
                    discountTotalPrice: discountUnitPrice * quantity,
                    discountTotalSavings: couponAmountOff * quantity,
                }
            }
        } else {
            couponDataPerSku[productSku] = null
        }
    })
    return isEmpty(couponDataPerSku) ? null : couponDataPerSku
}

export const getCouponDoubleDipAmountForNewUser = (productPrices: ResolveProductPriceItem[]): number => {
    const couponDoubleDipAmount = productPrices.reduce((total, currentProductPrice) => {
        const { productSKU, type } = currentProductPrice
        if (type === PRODUCT_PRICE_ITEM_TYPE.product && productSKU === ADDONS.ADDITIONAL_NUMBERS) {
            return total + get(currentProductPrice, ['couponDiscount', 'amount'], 0)
        }
        return total
    }, 0)
    return couponDoubleDipAmount
}

export const calculateCouponPrice = (type: string, originalPrice: number, value: number, isAnnual: boolean) => {
    if (type === COUPON_DISCOUNT_TYPE.PERCENTAGEOFF) {
        return originalPrice * ((100 - value) / 100)
    }
    if (type === COUPON_DISCOUNT_TYPE.AMOUNTOFF) {
        const localValue = isAnnual ? value / 12 : value
        const amountOff = originalPrice - localValue
        if (amountOff > 0) {
            return amountOff
        }
        return originalPrice
    }
    return isAnnual ? value / 12 : value
}

export interface ResolveCouponPrices {
    couponAnnualPrice: number
    couponMonthlyPrice: number
}

export const getCouponPrices = (
    plan: ResolvePlan,
    bossMonthlyPrice: number,
    bossAnnualPrice: number,
    couponState: ResolveCouponState
): ResolveCouponPrices => {
    let couponMonthlyPrice = bossMonthlyPrice
    let couponAnnualPrice = bossAnnualPrice
    const couponData = getActiveCouponServerData(couponState, plan)
    const activeCoupon = couponData || plan.activeCoupon
    if (activeCoupon && activeCoupon.isCouponValid && activeCoupon.couponType !== COUPON_TYPE.FIX) {
        const thisPlansCoupons: CouponItem[] = activeCoupon.couponItems.filter(
            (planCoupon: CouponItem) => planCoupon.productSku === plan.sKU
        )
        thisPlansCoupons.forEach((thisCoupon) => {
            if (thisCoupon && thisCoupon.paymentPlan === COUPON_PAYMENT_PLAN.UPFRONTMONTHLY) {
                couponMonthlyPrice = calculateCouponPrice(
                    activeCoupon.couponDiscountType,
                    bossMonthlyPrice,
                    thisCoupon.priceChanges[0].value,
                    false
                )
            }
            if (thisCoupon && thisCoupon.paymentPlan === COUPON_PAYMENT_PLAN.UPFRONTANNUAL) {
                couponAnnualPrice = calculateCouponPrice(
                    activeCoupon.couponDiscountType,
                    bossAnnualPrice,
                    thisCoupon.priceChanges[0].value,
                    true
                )
            }
        })
    }
    return { couponAnnualPrice, couponMonthlyPrice }
}
