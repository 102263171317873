import { TRACKING_EVENTS } from '@common/constants'
import { usePageLoad } from '@common/hooks'
import { TRACKING_COMPONENTS } from '@gtc/constants'
import { useGTCTracking } from './useGTCTracking'

export interface NextStepsPageTrackingParams {
    isLabelsLoaded: boolean
}

export const useNextStepsPageTracking = (params: NextStepsPageTrackingParams) => {
    const { track } = useGTCTracking()
    const componentName = TRACKING_COMPONENTS.NEXT_STEPS_PAGE
    usePageLoad(() => {
        track({ event: TRACKING_EVENTS.VIEW_CONTENT }, componentName)
    }, params.isLabelsLoaded)
}
