import React, { useState, FunctionComponent } from 'react'
import classNames from 'classnames'
import { ConvertStringToReact } from '@common/components'
import { DIRECTIONS } from '@common/constants'

import info from '@common/images/icon-info-tooltip.svg'
// Import Local Styles
import styles from './tooltip.module.css'

export interface ToolTipProps {
    content: string
    direction?: DIRECTIONS
    additionalClass?: string
    contentClass?: string
}

export const Tooltip: FunctionComponent<ToolTipProps> = ({
    children,
    content,
    direction = '',
    additionalClass = '',
    contentClass = '',
}) => {
    const [isVisible, setIsVisible] = useState(false)

    const classList = classNames({
        [styles.wrapper]: true,
        [styles['wrapper--visible']]: isVisible,
        [styles['wrapper--right']]: direction === DIRECTIONS.RIGHT,
        [styles['wrapper--left']]: direction === DIRECTIONS.LEFT,
        [contentClass]: !!contentClass,
    })

    const containerClassList = classNames({
        [styles.trigger]: true,
        [additionalClass]: !!additionalClass,
    })

    return (
        <div
            className={containerClassList}
            onMouseEnter={() => setIsVisible(true)}
            onMouseLeave={() => setIsVisible(false)}
        >
            <img className={styles.icon} src={info} alt="info" />
            {children}
            <div className={classList}>
                <div className={styles.content} id="tooltip">
                    <ConvertStringToReact htmlString={content} />
                </div>
            </div>
        </div>
    )
}
