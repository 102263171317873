import { CheckoutState } from './state'
import { CheckoutActionTypes } from './actions'
import type { CheckoutActions } from './actions'

export const initialCheckoutState: CheckoutState = {
    inlineErrors: {},
}

export const checkoutReducer = (previousState: CheckoutState | undefined, action: CheckoutActions): CheckoutState => {
    const state = previousState || initialCheckoutState
    switch (action.type) {
        case CheckoutActionTypes.SET_CHECKOUT_INLINE_ERROR: {
            const { error } = action.payload
            return {
                ...state,
                inlineErrors: {
                    ...state.inlineErrors,
                    [error]: error,
                },
            }
        }
        case CheckoutActionTypes.REMOVE_CHECKOUT_INLINE_ERROR: {
            const { error } = action.payload
            /* eslint-disable @typescript-eslint/no-unused-vars */
            const { [error]: value, ...rest } = state.inlineErrors
            return {
                ...state,
                inlineErrors: {
                    ...rest,
                },
            }
        }
        default:
            return state
    }
}
