import { call, takeEvery, put } from 'redux-saga/effects'
import gtreServices from '@gtresolve/services'
import { contentActions, ResolveContentActionTypes } from '@gtresolve/state/content/actions'

import { fatalErrorActions } from '@common/state/fatal-error/actions'
import { locationActions } from '@common/state/location/actions'
import { GenericResponse } from '@common/state/generic-response/generic-response'
import commonServices from '@common/services'
import { SC_SITE, PRODUCT_SKU, FEATURE_FLAGS } from '@common/constants'

function* loadContentSaga() {
    try {
        const labelServerResponse: GenericResponse = yield call(gtreServices.getResolveLabelData)
        if (labelServerResponse && labelServerResponse.data) {
            const featureFlags: { data: { flags: string[] } } = yield call(
                commonServices.getFeatureFlags,
                SC_SITE.RESOLVE
            )
            const useNewResolveSkus = featureFlags?.data?.flags?.includes(FEATURE_FLAGS.RESOLVE_USE_NEW_SKUS)
            const { labels, plans, ...content } = labelServerResponse.data
            const { sEOPageTitle } = labels
            yield call(() => {
                if (sEOPageTitle) document.title = sEOPageTitle
            })
            locationActions.getLocationInfo()
            yield put(
                contentActions.setContent(
                    content,
                    { ...labels },
                    plans.filter((plan: { sKU: PRODUCT_SKU }) =>
                        useNewResolveSkus
                            ? [
                                  PRODUCT_SKU.GTR_REMOTE_SUPPORT_NAMED_V2,
                                  PRODUCT_SKU.GTR_STANDARD_NAMED_V2,
                                  PRODUCT_SKU.GTR_REMOTE_ACCESS,
                              ].includes(plan.sKU)
                            : [PRODUCT_SKU.G2RE_BASIC_NAMED, PRODUCT_SKU.G2RE_STANDARD_NAME].includes(plan.sKU)
                    ),
                    plans
                )
            )
            yield put(contentActions.setContentLoaded(true))
        }
    } catch (e) {
        yield put(fatalErrorActions.setFatalError(true, { trackErrorMessage: 'label data failed to load' }))
    }
}

function* initializeGTResolveLoadContentSaga() {
    yield takeEvery(ResolveContentActionTypes.GET_LABEL_CONTENT, loadContentSaga)
}

export default initializeGTResolveLoadContentSaga
