import { CURRENCY, TimePeriod } from '@common/constants'
import { LocationState } from '@common/state/location/state'
import { getLocationState } from '@common/state/selectors'
import { resolveCheckoutActions, ResolveCheckoutActionTypes } from '@gtresolve/state/checkout/actions'
import { ResolveCheckoutState } from '@gtresolve/state/checkout/state'
import { ResolvePlan } from '@gtresolve/state/plan'
import { getCheckoutState } from '@gtresolve/state/selectors'
import { getSelectedTierIndex } from '@gtresolve/utils'
import { call, put, select, takeLatest } from 'redux-saga/effects'
import { getSelectedPlan } from '../content/content-helpers'

function* getSelectedTierSaga(action: ReturnType<typeof resolveCheckoutActions.setOrganizers>) {
    const { organizers } = action.payload
    const { billingFrequency }: ResolveCheckoutState = yield select(getCheckoutState)
    const selectedPlan: ResolvePlan = yield call(getSelectedPlan)
    const { locationInfo }: LocationState = yield select(getLocationState)
    const { currency = CURRENCY.USD } = locationInfo
    const selectedTierIndex: number = yield call(
        getSelectedTierIndex,
        organizers,
        billingFrequency,
        selectedPlan,
        currency
    )
    yield put(resolveCheckoutActions.setSelectedTierIndex(selectedTierIndex, organizers))
    yield put(resolveCheckoutActions.getVolumeDiscount(organizers, selectedTierIndex, billingFrequency as TimePeriod))
}

function* initializeGetSelectedTier() {
    yield takeLatest(ResolveCheckoutActionTypes.SET_ORGANIZERS, getSelectedTierSaga)
}

export default initializeGetSelectedTier
