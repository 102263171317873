import { call, takeEvery, put, select, take } from 'redux-saga/effects'
import { resolveBuyFormActions, ResolveBuyFormActionTypes } from '@gtresolve/state/buy-form/actions'
import { fatalErrorActions } from '@common/state/fatal-error/actions'
import { resolveCheckoutActions } from '@gtresolve/state/checkout/actions'
import {
    ResolveSubmitDataType,
    ResolveCheckoutState,
    ResolvePaymentDataReturnType,
} from '@gtresolve/state/checkout/state'
import { getPaymentItems } from '@gtresolve/utils/get-item-values'
import {
    getCheckoutState,
    getUserState,
    getLabelContent,
    getContentData,
    getCouponState,
    getPlanData,
} from '@gtresolve/state/selectors'
import { getLocationState, getAccountState, getFlexState } from '@common/state/selectors'
import { getSelectedAddons, getSelectedPlan } from '@gtresolve/saga/content/content-helpers'
import { MarketoUserInfo } from '@common/state/marketing/state'
import { marketingActions } from '@common/state/marketing/actions'
import { ResolveContentData } from '@gtresolve/state/content-data'
import { CHECKOUTFORM_STEPS, RESOLVE_TRACKING_COMPONENTS } from '@gtresolve/constants'
import { LocationState } from '@common/state/location/state'
import { SC_SITE, TRACKING_EVENTS } from '@common/constants'
import { resolveTrackingActions } from '@gtresolve/state/tracking/actions'
import { ResolveAbandonedCartDataType, ResolveUserState } from '@gtresolve/state/user/state'
import { ExistingUserInfoType, ExistingBillingInfoType } from '@common/state/user/state'
import { LabelContent } from '@gtresolve/state/label-content'
import { ResolvePlan } from '@gtresolve/state/plan'
import { ResolveAddon } from '@gtresolve/state/add-on'
import { ResolveCouponState } from '@gtresolve/state/coupon/state'
import { CouponServerData } from '@common/state/coupon/state'
import isEmpty from 'lodash/isEmpty'
import { resolveUserActions } from '@gtresolve/state/user/actions'
import { getActiveCouponServerData, clearState, getSubscriptionInfo } from '@gtresolve/utils'
import { flexFormSaga } from '@common/sagas/flex/get-flex-token'
import { FlexState } from '@common/state/flex/state'
import { flexActions, FlexActionTypes } from '@common/state/flex/actions'
import { FlexTokenDataType } from '@common/services'
import { FATAL_ERROR_TYPE } from '@common/state/fatal-error/state'
import { REFERRER, REFERRER_QUERY_PARAM } from '@gtresolve/constants/referrers'
import { ResolveSubmitPaymentResponseData } from '@gtresolve/state/submit-payment/state'
import { abandonedCartSaga } from '@gtresolve/saga/user/abandoned-cart'
import { submitPaymentSaga } from './submit-payment'
import { getAvailablePaymentMethodsSaga } from './get-payment-methods'
import { createBillingInfoSaga } from './create-billing-info'

declare const Cardinal: any

function* submitFormSaga(action: ReturnType<typeof resolveBuyFormActions.existingUserSubmitForm>) {
    const componentName = RESOLVE_TRACKING_COMPONENTS.EXISTING_USER_FORM_SUBMISSION_SAGA
    const steps: Record<CHECKOUTFORM_STEPS, boolean> = {
        [CHECKOUTFORM_STEPS.CREATE_FLEX_TOKEN]: true,
        [CHECKOUTFORM_STEPS.CARDINAL_TRIGGER_BIN_PROCESS]: true,
        [CHECKOUTFORM_STEPS.CREATE_USER_ACCOUNT]: true,
        [CHECKOUTFORM_STEPS.SAVE_BILLING_INFO]: true,
        [CHECKOUTFORM_STEPS.SAVE_PAYMENT_INFO]: true,
        [CHECKOUTFORM_STEPS.CARDINAL_SEND_CHALLENGE]: false,
        [CHECKOUTFORM_STEPS.GET_AVAILABLE_PAYMENT_METHODS]: false,
        [CHECKOUTFORM_STEPS.SUBMIT_PAYMENT]: false,
    }
    // update the redux store to ensure consistency
    yield put(
        resolveBuyFormActions.updateSteps(
            CHECKOUTFORM_STEPS.CREATE_FLEX_TOKEN,
            steps[CHECKOUTFORM_STEPS.CREATE_FLEX_TOKEN]
        )
    )
    yield put(
        resolveBuyFormActions.updateSteps(
            CHECKOUTFORM_STEPS.CREATE_USER_ACCOUNT,
            steps[CHECKOUTFORM_STEPS.CREATE_USER_ACCOUNT]
        )
    )
    yield put(
        resolveBuyFormActions.updateSteps(
            CHECKOUTFORM_STEPS.SAVE_BILLING_INFO,
            steps[CHECKOUTFORM_STEPS.SAVE_BILLING_INFO]
        )
    )
    yield put(
        resolveBuyFormActions.updateSteps(
            CHECKOUTFORM_STEPS.SAVE_PAYMENT_INFO,
            steps[CHECKOUTFORM_STEPS.SAVE_PAYMENT_INFO]
        )
    )

    const checkout: ResolveCheckoutState = yield select(getCheckoutState)
    const { addonUserSuccessUrl, gotoAdminUrl }: LabelContent = yield select(getLabelContent)
    const { locationInfo }: LocationState = yield select(getLocationState)

    const userState: ResolveUserState = yield select(getUserState)
    const {
        addressLine1 = '',
        city = '',
        postalCode = '',
        state: stateCode = '',
        phoneNumber = '',
    } = userState.existingBillingInformation as ExistingBillingInfoType

    const {
        UserName,
        Name: { FamilyName, GivenName },
    } = userState.existingUserInformation as ExistingUserInfoType

    const { submittoMarketo, marketoProductName, marketoSalesBrief, marketoSalesforceCampaignId }: ResolveContentData =
        yield select(getContentData)

    const planData: ResolvePlan[] = yield select(getPlanData)
    const selectedPlan: ResolvePlan = yield call(getSelectedPlan)
    const selectedAddons: ResolveAddon[] = yield call(getSelectedAddons)
    const coupon: ResolveCouponState = yield select(getCouponState)
    const { isCouponValidAndApplied } = coupon
    const couponServerData: CouponServerData | null = yield call(getActiveCouponServerData, coupon, selectedPlan)
    const isCouponValid = isCouponValidAndApplied && couponServerData && !isEmpty(couponServerData)
    const { CompanyName } = action.payload.values

    try {
        const { accountSubscriptions } = yield select(getAccountState)
        const subscriptionInfo = getSubscriptionInfo(
            checkout.billingFrequency,
            selectedPlan,
            planData,
            accountSubscriptions
        )

        // #region if CompanyName is provided, update billing info
        if (CompanyName && userState.existingBillingInformation) {
            const { country, firstName, lastName, email, taxNumber, federalTaxType } =
                userState.existingBillingInformation
            const billingData = {
                addressLine1,
                city,
                state: stateCode,
                country,
                postalCode,
                firstName,
                lastName,
                email,
                phoneNumber,
                taxNumber: taxNumber || '',
                federalTaxType: federalTaxType || '',
                companyName: CompanyName,
            }

            const createBillingInfoAction = resolveCheckoutActions.createBillingInfo(billingData)
            yield call(createBillingInfoSaga, createBillingInfoAction)
        }
        // #endregion

        const items = getPaymentItems(
            selectedPlan,
            checkout.organizers,
            checkout.billingFrequency,
            selectedAddons,
            subscriptionInfo
        )

        // #region attempt to retrieve SCA token to determine Cardinal challenge is required or not
        const flexTokenData: FlexTokenDataType = {
            currency: locationInfo.currency,
            country: locationInfo.country,
            targetOrigin: window.location.origin,
            skipSca: false,
        }
        yield call(flexFormSaga, flexActions.getFlexToken(flexTokenData, 0))
        const { keyInfo }: FlexState = yield select(getFlexState)
        const isScaEnabledForMid = keyInfo?.scaToken?.isScaEnabledForMid
        if (isScaEnabledForMid) {
            // wait for Cardinal script is completely loaded before proceeding to next line of code
            yield take(FlexActionTypes.SET_CARDINAL_SETUP_COMPLETE)
        }
        // #endregion

        // #region get available payment menthods
        let paymentMethodKey = ''
        const getAvailablePaymentData: ResolvePaymentDataReturnType[] = yield call(getAvailablePaymentMethodsSaga)
        if (getAvailablePaymentData && getAvailablePaymentData[0]) {
            steps[CHECKOUTFORM_STEPS.GET_AVAILABLE_PAYMENT_METHODS] = true
            paymentMethodKey = getAvailablePaymentData[0].paymentMethodKey
        }
        yield put(
            resolveBuyFormActions.updateSteps(
                CHECKOUTFORM_STEPS.GET_AVAILABLE_PAYMENT_METHODS,
                steps[CHECKOUTFORM_STEPS.GET_AVAILABLE_PAYMENT_METHODS]
            )
        )
        // #endregion
        // #region process purchase - 1st purchase call
        let paymentKey = ''
        let isChallengeRequired = false
        if (steps[CHECKOUTFORM_STEPS.GET_AVAILABLE_PAYMENT_METHODS]) {
            const submitValues: ResolveSubmitDataType = {
                currency: locationInfo.currency,
                paymentMethodKey,
                products: items,
                grossAmount: checkout.calculatedTotal,
                couponCode: isCouponValid ? couponServerData?.couponCode : '',
                anniversaryDate: checkout.anniversaryDate,
                referenceId: keyInfo?.scaToken?.referenceId,
                cardinalJwt: keyInfo?.scaToken?.tokenString,
                isScaCompleted: false,
                isWithCardAddAfterSca: false,
            }
            const submitPaymentAction = resolveCheckoutActions.submitPayment(submitValues)
            const submitData: ResolveSubmitPaymentResponseData = yield call(submitPaymentSaga, submitPaymentAction)

            isChallengeRequired = submitData?.scaResult?.isScaRequired || false
            paymentKey = submitData?.scaResult?.paymentKey

            const payerAuthEnrollment = submitData?.scaResult?.payerAuthEnrollment
            if (isChallengeRequired && payerAuthEnrollment) {
                // if Cardinal challenge is required, prompt challenge modal
                const { acsUrl, paReq, authenticationTransactionId } = payerAuthEnrollment
                Cardinal.continue(
                    'cca',
                    {
                        AcsUrl: acsUrl,
                        Payload: paReq,
                    },
                    {
                        OrderDetails: {
                            TransactionId: authenticationTransactionId,
                        },
                    }
                )
                yield put(
                    resolveTrackingActions.track(
                        {
                            event: TRACKING_EVENTS.CARDINAL_SECURITY_MODAL,
                            eventData: {
                                securityModalAction: TRACKING_EVENTS.CARDINAL_INITIATED,
                            },
                        },
                        componentName
                    )
                )

                steps[CHECKOUTFORM_STEPS.CARDINAL_SEND_CHALLENGE] = true
                yield put(
                    resolveBuyFormActions.updateSteps(
                        CHECKOUTFORM_STEPS.CARDINAL_SEND_CHALLENGE,
                        steps[CHECKOUTFORM_STEPS.CARDINAL_SEND_CHALLENGE]
                    )
                )
            } else {
                // otherwise if challenge is NOT required, payment process is complete. Proceed to post-submit_payment steps
                if (submitData) {
                    steps[CHECKOUTFORM_STEPS.SUBMIT_PAYMENT] = true
                    yield put(
                        resolveTrackingActions.track(
                            { event: TRACKING_EVENTS.PURCHASE, eventData: { invoiceNumber: submitData.invoiceNumber } },
                            componentName
                        )
                    )
                }
                yield put(
                    resolveBuyFormActions.updateSteps(
                        CHECKOUTFORM_STEPS.SUBMIT_PAYMENT,
                        steps[CHECKOUTFORM_STEPS.SUBMIT_PAYMENT]
                    )
                )
            }
        }
        // #endregion

        // #region process purchase - 2nd purchase call
        if (steps[CHECKOUTFORM_STEPS.CARDINAL_SEND_CHALLENGE]) {
            // wait for cardinal challenge to be defeated
            yield take(FlexActionTypes.SET_CHALLENGE_COMPLETE_JWT)
            const { cardinalChallengeCompleteJwt }: FlexState = yield select(getFlexState)

            const submitValues: ResolveSubmitDataType = {
                currency: locationInfo.currency,
                products: items,
                grossAmount: checkout.calculatedTotal,
                couponCode: isCouponValid ? couponServerData?.couponCode : '',
                anniversaryDate: checkout.anniversaryDate,
                referenceId: keyInfo?.scaToken?.referenceId,
                isScaCompleted: true,
                isWithCardAddAfterSca: false,
                cardinalJwt: cardinalChallengeCompleteJwt,
                paymentKey,
                paymentMethodKey,
            }

            const submitPaymentAction = resolveCheckoutActions.submitPayment(submitValues)
            const submitData: ResolveSubmitPaymentResponseData = yield call(submitPaymentSaga, submitPaymentAction)
            if (submitData) {
                steps[CHECKOUTFORM_STEPS.SUBMIT_PAYMENT] = true
                yield put(
                    resolveTrackingActions.track(
                        { event: TRACKING_EVENTS.PURCHASE, eventData: { invoiceNumber: submitData.invoiceNumber } },
                        componentName
                    )
                )
            }

            yield put(
                resolveBuyFormActions.updateSteps(
                    CHECKOUTFORM_STEPS.SUBMIT_PAYMENT,
                    steps[CHECKOUTFORM_STEPS.SUBMIT_PAYMENT]
                )
            )
        }
        // #endregion

        // #region Set purchase complete
        if (steps[CHECKOUTFORM_STEPS.SUBMIT_PAYMENT]) {
            const abandonedCartData: ResolveAbandonedCartDataType = {
                email: UserName,
                success: true,
            }

            // Use "call" instead of "put" to 1) avoid the debounce and 2) wait for the promise to finish before proceeding
            const abandonCartAction = resolveUserActions.submitAbandonedCart(abandonedCartData)
            yield call(abandonedCartSaga, abandonCartAction)

            yield put(resolveTrackingActions.track({ event: TRACKING_EVENTS.COMPLETE_BUY_FLOW }, componentName))
            if (submittoMarketo) {
                const { planKey } = selectedPlan ?? {}
                const marketoData: MarketoUserInfo = {
                    firstName: GivenName,
                    lastName: FamilyName,
                    email: UserName,
                    planKey,
                    address: addressLine1,
                    city,
                    state: stateCode,
                    zipcode: postalCode,
                    phone: phoneNumber,
                    salesBrief: marketoSalesBrief,
                    campaignID: marketoSalesforceCampaignId,
                    product: marketoProductName,
                    company: CompanyName,
                    sc_site: SC_SITE.RESOLVE,
                }
                yield put(marketingActions.postToMarketo(marketoData))
            }
            yield put(
                resolveTrackingActions.track(
                    {
                        event: TRACKING_EVENTS.EMAIL_VALIDATION,
                        eventData: {
                            userEmail: UserName,
                            formName: RESOLVE_TRACKING_COMPONENTS.CHECKOUT_PAGE,
                        },
                    },
                    componentName
                )
            )
            // delete saved state
            yield call(clearState)
            yield put(resolveCheckoutActions.setAddonComplete(true))

            const referrer = new URLSearchParams(window.location.search).get(REFERRER_QUERY_PARAM)
            if (referrer === REFERRER.gotoAdmin) {
                window.location.href = gotoAdminUrl
            } else {
                window.location.href = addonUserSuccessUrl
            }
            return
        }
        // #endregion

        // Trigger form submission error if any of the steps are missed
        yield put(
            fatalErrorActions.setFatalError(true, {
                trackErrorMessage: 'existing user form submission failed',
                fatalErrorType: isScaEnabledForMid ? FATAL_ERROR_TYPE.CARDINAL_PAYMENT : undefined,
            })
        )
    } catch (e) {
        yield put(fatalErrorActions.setFatalError(true, { trackErrorMessage: 'existing user form submission failed' }))
    }
}

function* initializeSubmitFormSaga() {
    yield takeEvery(ResolveBuyFormActionTypes.EXISTING_USER_SUBMIT_BUY_FORM, submitFormSaga)
}

export default initializeSubmitFormSaga
