import { call, takeEvery, put } from 'redux-saga/effects'
import { buyUserActions, BuyUserActionTypes } from '@buy/state/user/actions'
import buyServices from '@buy/services'
import { BuyUserAccountType } from '@buy/state/user/state'
import { fatalErrorActions } from '@common/state/fatal-error/actions'
import { TRACKING_EVENTS } from '@common/constants'
import { buyTrackingActions } from '@buy/state/tracking/actions'
import { BUY_TRACKING_COMPONENTS } from '@buy/constants'
import { GenericResponse } from '@common/state/generic-response/generic-response'

export function* createUserSaga(action: ReturnType<typeof buyUserActions.createNewUser>) {
    const componentName = BUY_TRACKING_COMPONENTS.CREATE_USER_SAGA
    try {
        const { userValues } = action.payload
        const userCreationResponse: GenericResponse = yield call(buyServices.createUserAccount, userValues)
        if (userCreationResponse.data) {
            const userData: BuyUserAccountType = userCreationResponse.data
            return userData
        }
    } catch (e) {
        yield put(
            buyTrackingActions.track(
                {
                    event: TRACKING_EVENTS.ERROR_PURCHASE,
                    eventData: { errorMessage: 'failed to create user account' },
                },
                componentName
            )
        )
        yield put(fatalErrorActions.setFatalError(true, { trackErrorMessage: 'failed to create user account' }))
    }
    return null
}

function* initializeCreateUserSaga() {
    yield takeEvery(BuyUserActionTypes.CREATE_NEW_USER, createUserSaga)
}

export default initializeCreateUserSaga
