import { MarketingActions, MarketingActionsTypes } from './actions'
import { MarketingState } from './state'

export const initialMarketingState: MarketingState = {
    displayOptOut: false,
    isUserOptOut: false,
}

export const marketingReducer = (
    previousState: MarketingState | undefined,
    action: MarketingActions
): MarketingState => {
    const state = previousState || initialMarketingState
    switch (action.type) {
        case MarketingActionsTypes.SET_DISPLAY_OPTOUT: {
            return { ...state, displayOptOut: action.payload.displayOutOut }
        }
        case MarketingActionsTypes.SET_IS_USER_OPTOUT: {
            return { ...state, isUserOptOut: action.payload.isUserOutOut }
        }
        default:
            return state
    }
}
