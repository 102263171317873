const mapLetterToPhoneDialerNumber: Record<string, number> = {
    A: 2,
    B: 2,
    C: 2,
    D: 3,
    E: 3,
    F: 3,
    G: 4,
    H: 4,
    I: 4,
    J: 5,
    K: 5,
    L: 5,
    M: 6,
    N: 6,
    O: 6,
    P: 7,
    Q: 7,
    R: 7,
    S: 7,
    T: 8,
    U: 8,
    V: 8,
    W: 9,
    X: 9,
    Y: 9,
    Z: 9,
}

export const lettersToPhoneDialerNumbers = (characters: string) => {
    let phoneDialerNumbers = ''

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < characters.length; i++) {
        const character = characters[i].toUpperCase()
        const isNumber = /[0-9]/.test(character)
        const theDigit = !isNumber ? mapLetterToPhoneDialerNumber[character] : characters[i]
        phoneDialerNumbers += theDigit
    }

    return phoneDialerNumbers
}

export const indexOfVanityPhrase = (haystack: string, needle: string) => {
    const needleAsNumbers = lettersToPhoneDialerNumbers(needle)
    return haystack.indexOf(needleAsNumbers)
}
