import { call, takeLatest, select, put, delay } from 'redux-saga/effects'
import { resolveCheckoutActions, ResolveCheckoutActionTypes } from '@gtresolve/state/checkout/actions'
import { ResolveCalculatePriceDataType, ResolveCheckoutState } from '@gtresolve/state/checkout/state'
import gtreServices from '@gtresolve/services'
import { getBillingValues } from '@gtresolve/utils/get-billing-values'
import { getPaymentItems } from '@gtresolve/utils/get-item-values'
import { getSelectedPlan, getSelectedAddons } from '@gtresolve/saga/content/content-helpers'
import { getCheckoutState, getCouponState, getUserState, getPlanData } from '@gtresolve/state/selectors'
import { getLocationState, getAccountState } from '@common/state/selectors'
import { modalActions } from '@common/state/modal/actions'
import { fatalErrorActions } from '@common/state/fatal-error/actions'
import { BUY_TYPE, RESOLVE_TRACKING_COMPONENTS, RESOLVE_USER_TYPES } from '@gtresolve/constants'
import { resolveTrackingActions } from '@gtresolve/state/tracking/actions'
import { TimePeriod, TRACKING_EVENTS } from '@common/constants'
import { LocationState } from '@common/state/location/state'
import { ResolveUserState } from '@gtresolve/state/user/state'
import { ResolveAddon } from '@gtresolve/state/add-on'
import { ResolvePlan } from '@gtresolve/state/plan'
import { ResolveCalculatePriceAPIData, ResolveCalculatePriceAPIReturnType } from '@gtresolve/state/calculate-price'
import isEmpty from 'lodash/isEmpty'
import {
    getActiveCouponServerData,
    getCouponDoubleDipAmountForNewUser,
    getTotalProratedAmount,
    getTotalVolumeDiscount,
    getSubscriptionInfo,
} from '@gtresolve/utils'
import { ResolveCouponState } from '@gtresolve/state/coupon/state'
import { CouponServerData } from '@common/state/coupon/state'
import { resolveCouponActions } from '@gtresolve/state/coupon/actions'

export function* calculatePriceSaga(action: ReturnType<typeof resolveCheckoutActions.calculatePrice>) {
    const componentName = RESOLVE_TRACKING_COMPONENTS.CALCULATE_PRICE_SAGA
    try {
        yield put(resolveCheckoutActions.setTotalLoading(true))
        yield delay(1000)
        const checkout: ResolveCheckoutState = yield select(getCheckoutState)
        const { userType, existingBillingInformation }: ResolveUserState = yield select(getUserState)
        const { values, planSKU } = action.payload
        const selectedPlan: ResolvePlan = yield call(getSelectedPlan, planSKU)
        const selectedAddons: ResolveAddon[] = yield call(getSelectedAddons)
        const coupon: ResolveCouponState = yield select(getCouponState)
        const { isCouponValidAndApplied, couponProcessedDataPerSku } = coupon
        const couponServerData: CouponServerData | null = yield call(getActiveCouponServerData, coupon, selectedPlan)
        const isCouponValid = isCouponValidAndApplied && couponServerData && !isEmpty(couponServerData)

        if (userType === RESOLVE_USER_TYPES.ADDON) {
            values.Street1 = existingBillingInformation?.addressLine1 || ''
            values.City = existingBillingInformation?.city || ''
            values.CountryCode = existingBillingInformation?.country || ''
            values.Email = existingBillingInformation?.email || ''
            values.FirstName = existingBillingInformation?.firstName || ''
            values.LastName = existingBillingInformation?.lastName || ''
            values.ZipCode = existingBillingInformation?.postalCode || ''
            values.StateCode = existingBillingInformation?.state || ''
            values.TaxNumber = existingBillingInformation?.taxNumber || ''
            values.TaxType = existingBillingInformation?.federalTaxType || ''
        }

        const { locationInfo }: LocationState = yield select(getLocationState)
        const { accountSubscriptions } = yield select(getAccountState)
        const planData: ResolvePlan[] = yield select(getPlanData)

        const subscriptionInfo = getSubscriptionInfo(
            checkout.billingFrequency,
            selectedPlan,
            planData,
            accountSubscriptions
        )

        if (!subscriptionInfo.isReplacementBooking && !selectedAddons.length) {
            yield put(resolveCheckoutActions.setTotalLoading(false))
            yield put(resolveCheckoutActions.setCalculatedPrice([], 0, 0, 0, 0, ''))
            yield put(resolveCheckoutActions.setProratedAmount(0))
            return
        }

        const calculatePriceValues: ResolveCalculatePriceDataType = {
            address: getBillingValues(values),
            currency: locationInfo.currency,
            couponCode: isCouponValid ? couponServerData?.couponCode : '',
            cart: getPaymentItems(selectedPlan, 1, checkout.billingFrequency, selectedAddons, subscriptionInfo),
        }

        const calculatePriceCreation: ResolveCalculatePriceAPIReturnType = yield call(
            gtreServices.calculatePrice,
            calculatePriceValues
        )
        if (calculatePriceCreation && calculatePriceCreation.data) {
            const {
                errorMessages,
                productPrices,
                netAmount,
                grossAmount,
                taxAmount,
                anniversaryDate,
                creditback,
            }: ResolveCalculatePriceAPIData = calculatePriceCreation.data
            if (isEmpty(errorMessages)) {
                yield put(
                    resolveCheckoutActions.setCalculatedPrice(
                        productPrices,
                        netAmount,
                        grossAmount,
                        taxAmount,
                        creditback,
                        anniversaryDate === null ? '' : (anniversaryDate as string)
                    )
                )
                // since the selected plan has an item id of 0 is the product prices array, we need to get the first item from productPrice
                // check getPaymentItems function above for more info
                const [planProductPrice] = productPrices.filter((productPrice) => productPrice.itemId === 0)
                const { volumeDiscount } = planProductPrice
                if (volumeDiscount) {
                    const { amount } = volumeDiscount
                    const calculatedVolumeDiscount: number = yield call(getTotalVolumeDiscount, amount, 1, false)

                    yield put(
                        resolveCheckoutActions.setCurrentVolumeDiscountPriceAndPercentage(calculatedVolumeDiscount)
                    )
                }

                // if add-on user, calculate the prorated amount
                if (userType === RESOLVE_USER_TYPES.ADDON) {
                    const calculatedProratedAmount = getTotalProratedAmount(productPrices)
                    yield put(resolveCheckoutActions.setProratedAmount(calculatedProratedAmount))
                }

                // coupon discount for DID
                if (isCouponValid && couponProcessedDataPerSku && userType !== RESOLVE_USER_TYPES.ADDON) {
                    const couponDoubleDipAmountForNewUser: number = yield call(
                        getCouponDoubleDipAmountForNewUser,
                        productPrices
                    )

                    yield put(resolveCouponActions.setCouponDoubleDipAmountForNewUser(couponDoubleDipAmountForNewUser))
                }
            } else {
                yield put(
                    resolveTrackingActions.track(
                        {
                            event: TRACKING_EVENTS.ERROR_PURCHASE,
                            eventData: {
                                errorMessage: 'Sorry we are having trouble applying your credit',
                                buyType:
                                    subscriptionInfo.isTrial || !subscriptionInfo.plan
                                        ? BUY_TYPE.DIRECT_BUY
                                        : BUY_TYPE.ADDON,
                                plan: checkout.billingFrequency === TimePeriod.Month ? 'monthly' : 'annual',
                            },
                        },
                        componentName
                    )
                )
                yield put(modalActions.setErrorModal('Unable to get price data'))
            }
        }
        yield put(resolveCheckoutActions.setTotalLoading(false))
    } catch {
        yield put(
            resolveTrackingActions.track(
                { event: TRACKING_EVENTS.ERROR_PURCHASE, eventData: { errorMessage: 'error in calculating price' } },
                componentName
            )
        )
        yield put(fatalErrorActions.setFatalError(true, { trackErrorMessage: 'error in calculating price' }))
        yield put(resolveCheckoutActions.setTotalLoading(false))
    }
}

function* initializeCalculatePriceSaga() {
    yield takeLatest(ResolveCheckoutActionTypes.CALCULATE_PRICE, calculatePriceSaga)
}

export default initializeCalculatePriceSaga
