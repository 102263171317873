import { CheckoutState } from '@gtc/state/checkout/state'
import { getCheckoutState, getPlanData } from '@gtc/state/selectors'
import { Plan } from '@gtc/state/plan'
import { select, call, SelectEffect, CallEffect } from 'redux-saga/effects'
import { getCurrentPlan, getPlanNameSet } from '@gtc/utils/get-current-plan'
import { getCurrentAddons } from '@gtc/utils/get-current-addons'
import { Addon } from '@gtc/state/add-on'
import { UrlParams, updatePlanUrlParam } from '@common/utils'
import isEmpty from 'lodash/isEmpty'

export function* getSelectedPlan(planSku?: string): Generator<SelectEffect, Plan, CheckoutState & Plan[]> {
    const { selectedPlanSku }: CheckoutState = yield select(getCheckoutState)
    const planSkuToRetrieve = planSku || selectedPlanSku
    const planData: Plan[] = yield select(getPlanData)
    const selectedPlan: Plan = getCurrentPlan(planSkuToRetrieve, planData)
    if (!selectedPlan) {
        return planData[0]
    }
    return selectedPlan
}

export function* getSelectedAddons(): Generator<
    SelectEffect | CallEffect<Plan> | CallEffect<Addon[]>,
    Addon[],
    CheckoutState & Plan & Addon[]
> {
    const { selectedAddons }: CheckoutState = yield select(getCheckoutState)
    const selectedPlan: Plan = yield call(getSelectedPlan)
    const myAddons: Addon[] = yield call(getCurrentAddons, selectedAddons, selectedPlan)
    return myAddons
}

export function* getUpdatedPlanUrlParams() {
    const selectedPlan: Plan = yield call(getSelectedPlan)
    const planData: Plan[] = yield select(getPlanData)
    const planNameSet: Set<string> = yield call(getPlanNameSet, planData)
    const planUrlParams: UrlParams = yield call(
        updatePlanUrlParam,
        isEmpty(selectedPlan) ? '' : selectedPlan.name,
        planNameSet
    )
    return planUrlParams
}
