import { LmiProBuyFormData } from '@lmipro/state/buy-form/state'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'

let previousFormValues: LmiProBuyFormData

export const isValidationAddressEligible = (formValues: LmiProBuyFormData): boolean => {
    if (
        isEmpty(formValues.FirstName.trim()) ||
        isEmpty(formValues.LastName.trim()) ||
        isEmpty(formValues.Email.trim()) ||
        isEmpty(formValues.BillingFirstName.trim()) ||
        isEmpty(formValues.BillingLastName.trim())
    ) {
        return false
    }

    const hasFormValuesChanged = !isEqual(previousFormValues, formValues)
    previousFormValues = formValues
    return hasFormValuesChanged
}

export const isAddressComplete = (
    formValues: LmiProBuyFormData,
    isStatesOptional?: boolean,
    isTaxRequired?: boolean
): boolean => {
    if (
        isEmpty(formValues.CountryCode.trim()) ||
        isEmpty(formValues.City.trim()) ||
        isEmpty(formValues.Street1.trim()) ||
        (!isStatesOptional && isEmpty(formValues.StateCode.trim())) ||
        (isTaxRequired && !formValues.TaxNumber) ||
        isEmpty(formValues.ZipCode.trim())
    ) {
        return false
    }
    return true
}
