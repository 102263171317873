import { ActionUnion, createAction } from '@common/utils'
import { CURRENCY, TimePeriod } from '@common/constants'
import { LmiProSelectedAddon, LmiProAddon } from '@lmipro/state/add-on'
import { LmiProBuyFormData } from '@lmipro/state/buy-form/state'
import { LmiProProductPriceItem } from '@lmipro/state/calculate-price'
import {
    LmiProBillingInfoDataType,
    LmiProPaymentDataType,
    LmiProSubmitDataType,
    LmiProBillingFrequencyVolumeDiscountRecords,
    LmiProSubmitWithCardAddDataType,
} from './state'

export enum LmiProCheckoutActionTypes {
    SET_LOADING = '[lmipro-checkout-actions] set-loading',
    SET_PLAN_ID = '[lmipro-checkout-actions] set-plan-id',
    SET_BILLING_FREQUENCY = '[lmipro-checkout-actions] set-billing-frequency',
    CLEAR_ADDONS = '[lmipro-checkout-actions] clear-addons',
    SET_SELECTED_ADDONS = '[lmipro-checkout-actions] set-selected-addons',
    SET_ORGANIZERS = '[lmipro-checkout-actions] set-organizers',
    SET_SELECTED_PLAN = '[lmipro-checkout-actions] set-selected-plan',
    SET_EXISTING_USER_MODAL_OPEN = '[lmipro-checkout-actions] set-existing-user-modal',
    CREATE_BILLING_INFO = '[lmipro-checkout-actions] create-billing-info',
    CREATE_PAYMENT = '[lmipro-checkout-actions] create-payment',
    SUBMIT_PAYMENT = '[lmipro-checkout-actions] submit-payment',
    CREATE_AND_SUBMIT_PAYMENT = '[lmipro-checkout-actions] create-and-submit-payment',
    GET_AVAILABLE_PAYMENT_METHODS = '[lmipro-checkout-actions] get-available-payment-methods',
    CALCULATE_PRICE = '[lmipro-checkout-actions] calculate-price',
    SET_CALCULATED_PRICE = '[lmipro-checkout-actions] set-calculated-price',
    GET_SAVED_STATE = '[lmipro-checkout-actions] get-saved-state',
    SET_SAVED_STATE_LOADED = '[lmipro-checkout-actions] set-saved-state-loaded',
    RESET_CHECKOUT_STATE = '[lmipro-checkout-actions] reset-state',
    SET_EXISTING_ORGANIZERS = '[lmipro-checkout-actions] set-existing-organizers',
    SET_PURCHASE_COMPLETE = '[lmipro-checkout-actions] set-purchase-complete',
    SET_LOADING_TOTAL = '[lmipro-checkout-actions] set-loading-total',
    SET_ADDON_COMPLETE = '[lmipro-checkout-actions] set-addon-complete',
    SET_SELECTED_TIER_INDEX = '[lmipro-checkout-actions] set-selected-tier-index',
    CALCULATE_VOLUME_DISCOUNT = '[lmipro-checkout-actions] calculate-volume-discount',
    SET_VOLUME_DISCOUNT = '[lmipro-checkout-actions] set-volume-discount',
    SET_VOLUME_DISCOUNT_LOADING = '[lmipro-checkout-actions] set-volume-discount-loading',
    GET_VOLUME_DISCOUNT = '[lmipro-checkout-actions] get-volume-discount',
    SET_CURRENT_VOLUME_DISCOUNT_PRICE_AND_PERCENTAGE = '[lmipro-checkout-actions] set-current-volume-discount-price-and-percentage',
    ADJUST_CURRENT_VOLUME_DISCOUNT_FOR_REPRICE_COUPON = '[lmipro-checkout-actions] adjust-current-volume-discount-for-reprice-coupon',
    SET_PRORATED_AMOUNT = '[lmipro-checkout-actions] set-prorated-amount',
    SET_EXTRA_ADDON = '[lmipro-checkout-actions] set-extra-addon',
    SET_ENDPOINTS = '[lmipro-checkout-actions] set-endpoints',
    SET_AGENTS = '[lmipro-checkout-actions] set-agents',
    SET_TAX_TYPE = '[lmipro-checkout-actions] set-tax-type',
    SET_AUTO_LOGIN = '[lmipro-checkout-form-actions] set-auto-login',
}

export const lmiproCheckoutActions = {
    setLoading: (loading: boolean) => createAction(LmiProCheckoutActionTypes.SET_LOADING, { loading }),
    setPurchaseComplete: (complete: boolean) =>
        createAction(LmiProCheckoutActionTypes.SET_PURCHASE_COMPLETE, { complete }),
    setBillingFrequency: (frequency: TimePeriod) =>
        createAction(LmiProCheckoutActionTypes.SET_BILLING_FREQUENCY, { frequency }),
    clearAddons: () => createAction(LmiProCheckoutActionTypes.CLEAR_ADDONS),
    setSelectedPlan: (planSku: string) => createAction(LmiProCheckoutActionTypes.SET_SELECTED_PLAN, { planSku }),
    setSelectedAddons: (addons: LmiProSelectedAddon[]) =>
        createAction(LmiProCheckoutActionTypes.SET_SELECTED_ADDONS, { addons }),
    setOrganizers: (organizers: number) => createAction(LmiProCheckoutActionTypes.SET_ORGANIZERS, { organizers }),
    createBillingInfo: (billingValues: LmiProBillingInfoDataType) =>
        createAction(LmiProCheckoutActionTypes.CREATE_BILLING_INFO, {
            billingValues,
        }),
    createPayment: (paymentValues: LmiProPaymentDataType) =>
        createAction(LmiProCheckoutActionTypes.CREATE_PAYMENT, {
            paymentValues,
        }),
    submitPayment: (submitValues: LmiProSubmitDataType | LmiProSubmitWithCardAddDataType) =>
        createAction(LmiProCheckoutActionTypes.SUBMIT_PAYMENT, {
            submitValues,
        }),
    createAndSubmitPayment: (submitValues: LmiProSubmitWithCardAddDataType) =>
        createAction(LmiProCheckoutActionTypes.CREATE_AND_SUBMIT_PAYMENT, {
            submitValues,
        }),
    getPaymentMethods: () => createAction(LmiProCheckoutActionTypes.GET_AVAILABLE_PAYMENT_METHODS),
    calculatePrice: (values: LmiProBuyFormData, component?: string, planSKU?: string) =>
        createAction(
            LmiProCheckoutActionTypes.CALCULATE_PRICE,
            {
                values,
                planSKU,
            },
            { component }
        ),
    setCalculatedPrice: (
        productPrices: LmiProProductPriceItem[],
        net: number,
        total: number,
        tax: number,
        creditback: number,
        anniversaryDate: string
    ) =>
        createAction(LmiProCheckoutActionTypes.SET_CALCULATED_PRICE, {
            productPrices,
            net,
            total,
            tax,
            creditback,
            anniversaryDate,
        }),
    getSavedState: (urlPlan: string) =>
        createAction(LmiProCheckoutActionTypes.GET_SAVED_STATE, {
            urlPlan,
        }),
    setSavedStateLoaded: (savedStateLoaded: boolean, component: string) =>
        createAction(
            LmiProCheckoutActionTypes.SET_SAVED_STATE_LOADED,
            {
                savedStateLoaded,
            },
            { component }
        ),
    setTotalLoading: (loading: boolean) =>
        createAction(LmiProCheckoutActionTypes.SET_LOADING_TOTAL, {
            loading,
        }),
    setAddonComplete: (addonComplete: boolean) =>
        createAction(LmiProCheckoutActionTypes.SET_ADDON_COMPLETE, {
            addonComplete,
        }),
    resetCheckoutState: () => createAction(LmiProCheckoutActionTypes.RESET_CHECKOUT_STATE),
    setSelectedTierIndex: (selectedTierIndex: number, organizers: number) =>
        createAction(LmiProCheckoutActionTypes.SET_SELECTED_TIER_INDEX, { selectedTierIndex, organizers }),
    calculateVolumeDiscount: () => createAction(LmiProCheckoutActionTypes.CALCULATE_VOLUME_DISCOUNT),
    setVolumeDiscount: (
        selectedPlanSku: string,
        currency: CURRENCY,
        volumeDiscountForSelectedPlanAndCurrency: LmiProBillingFrequencyVolumeDiscountRecords
    ) =>
        createAction(LmiProCheckoutActionTypes.SET_VOLUME_DISCOUNT, {
            selectedPlanSku,
            currency,
            volumeDiscountForSelectedPlanAndCurrency,
        }),
    setVolumeDiscountLoading: (volumeDiscountLoading: boolean) =>
        createAction(LmiProCheckoutActionTypes.SET_VOLUME_DISCOUNT_LOADING, { volumeDiscountLoading }),
    getVolumeDiscount: (organizers: number, selectedTierIndex: number, billingFrequency: TimePeriod) =>
        createAction(LmiProCheckoutActionTypes.GET_VOLUME_DISCOUNT, {
            organizers,
            selectedTierIndex,
            billingFrequency,
        }),
    setCurrentVolumeDiscountPriceAndPercentage: (currentVolumeDiscountPrice: number) =>
        createAction(LmiProCheckoutActionTypes.SET_CURRENT_VOLUME_DISCOUNT_PRICE_AND_PERCENTAGE, {
            currentVolumeDiscountPrice,
        }),
    setProratedAmount: (proratedAmount: number) =>
        createAction(LmiProCheckoutActionTypes.SET_PRORATED_AMOUNT, { proratedAmount }),
    adjustCurrentVolumeDiscountForRepriceCoupon: () =>
        createAction(LmiProCheckoutActionTypes.ADJUST_CURRENT_VOLUME_DISCOUNT_FOR_REPRICE_COUPON),
    setExtraAddons: (addons: LmiProAddon[]) => createAction(LmiProCheckoutActionTypes.SET_EXTRA_ADDON, { addons }),
    setEndpoints: (endpoints: number) => createAction(LmiProCheckoutActionTypes.SET_ENDPOINTS, { endpoints }),
    setAgents: (agents: number) => createAction(LmiProCheckoutActionTypes.SET_AGENTS, { agents }),
    setTaxType: (type: string) => createAction(LmiProCheckoutActionTypes.SET_TAX_TYPE, { type }),
    setAutoLogin: (autoLogin?: string) =>
        createAction(LmiProCheckoutActionTypes.SET_AUTO_LOGIN, {
            autoLogin,
        }),
}

export type LmiProCheckoutActions = ActionUnion<typeof lmiproCheckoutActions>
