/* eslint-disable import/export */
import { ActionCreatorsMapObject } from 'redux'

export interface Action<T extends string> {
    type: T
}

export interface ActionWithPayload<T extends string, P> extends Action<T> {
    payload: P
}
export interface ActionWithPayloadMeta<T extends string, P, M> extends ActionWithPayload<T, P> {
    meta: M
}

export function createAction<T extends string>(type: T): Action<T>
export function createAction<T extends string, P>(type: T, payload: P): ActionWithPayload<T, P>
export function createAction<T extends string, P, M>(type: T, payload: P, meta: M): ActionWithPayloadMeta<T, P, M>

/* export function createAction<T extends string, P>(type: T, payload?: P) {
  return payload === undefined ? { type } : { type, payload }
} */

export function createAction<T extends string, P, M>(type: T, payload?: P, meta?: M) {
    if (payload === undefined && meta === undefined) {
        return { type }
    }
    if (payload === undefined) {
        return { type, meta }
    }
    if (meta === undefined) {
        return { type, payload }
    }
    return { type, payload, meta }
}

export type ActionsObject = ActionCreatorsMapObject<Action<string>>

export type ActionUnion<A extends ActionsObject> = ReturnType<A[keyof A]>
