import { CURRENCY_SYMBOLS, CURRENCY } from '@common/constants'

export const percentage = (price1: number, price2: number) => {
    return Math.floor(((price1 - price2) / price1) * 100)
}

export const formatCurrency = (value: number, locale: string, currency: string, decimals?: boolean) => {
    const localValue = Number(value)
    const options = {
        style: 'currency',
        currency,
        minimumFractionDigits: decimals ? 2 : 0,
        maximumFractionDigits: decimals ? 2 : 0,
    }

    const countryCode = locale.substring(3)

    // EUR format should be X XXX XXX,XX €
    if (currency === CURRENCY.EUR) {
        // Need to get price format with country code with `en` to get Euro price format with Euro symbol at front
        let formatted = new Intl.NumberFormat(`en-${countryCode}`, options).format(localValue)

        // For few Euro currency countries, we get different formats. But we need to show the same format for all Euro currency countries
        // We remove all the `.` and `,` in the format and join with spaces. Following condidition flows adds the `,` appropriately
        formatted = formatted.split(/[.,]/).join(' ')

        // If we don't € at the end in the format, we need to make sure to add it
        if (formatted.slice(-1) !== CURRENCY_SYMBOLS[currency]) {
            // This case is to remove € from the starting position
            formatted =
                formatted.substring(1, formatted.length - 3) && decimals
                    ? `${formatted.substring(0, formatted.length - 3)},${formatted.substring(
                          formatted.length - 2,
                          formatted.length
                      )}`
                    : formatted
            return `${formatted.substring(1, formatted.length)} ${CURRENCY_SYMBOLS[currency]}`
        }

        formatted =
            formatted.substring(0, formatted.length - 5) && decimals
                ? `${formatted.substring(0, formatted.length - 5)},${formatted.substring(
                      formatted.length - 4,
                      formatted.length
                  )}`
                : formatted
        return formatted
    }

    const formatted = new Intl.NumberFormat(`en-${countryCode}`, {
        ...options,
        currencyDisplay: 'narrowSymbol',
    }).format(localValue)

    // AUD format should be AU$X,XXX,XXX.XX
    // CAD format should be C$XXX XXX.XX
    if (currency === CURRENCY.AUD || currency === CURRENCY.CAD || currency === CURRENCY.MXN) {
        return `${CURRENCY_SYMBOLS[currency]}${formatted.split('$')[1]}`
    }

    return formatted
}
