import Auth, { Token } from '@getgo/auth-client'
import { authOptions, STORAGE_CONST } from '@common/constants'
import { getSessionState } from '@common/utils'
import { saveRouteAndQuery } from './save-route-query'

let auth: Auth | undefined
let initPromise: Promise<any> | undefined

export const authClass = () => {
    if (!auth) {
        auth = new Auth(authOptions)
    }
    return auth
}

export const logout = (pathname: string, search: string) => {
    const storedLanguage = getSessionState(STORAGE_CONST.SAVED_LANGUAGE)
    const pathnameSplit = pathname.split('/')

    const pathToStore = pathnameSplit.includes(storedLanguage)
        ? `/${pathnameSplit.splice(pathnameSplit.indexOf(storedLanguage) + 1).join('/')}`
        : pathname

    // delete saved token
    localStorage.removeItem('state')
    localStorage.removeItem('code_verifier')
    localStorage.removeItem('userToken')
    // save user route before leaving to log out
    saveRouteAndQuery(pathToStore, search)
    authClass().logout()
}

export const saveToken = (token: Token) => {
    const accessTokenString = JSON.stringify(token)
    localStorage.setItem('userToken', accessTokenString)
}

export const login = (pathname: string, search: string, sso?: boolean) => {
    if (!auth) {
        throw new Error('Attempting login before auth init')
    }
    if (sso) {
        return auth.loginWithSSO()
    }

    const storedLanguage = getSessionState(STORAGE_CONST.SAVED_LANGUAGE)
    const pathnameSplit = pathname.split('/')

    const pathToStore = pathnameSplit.includes(storedLanguage)
        ? `/${pathnameSplit.splice(pathnameSplit.indexOf(storedLanguage) + 1).join('/')}`
        : pathname

    // save user route before leaving to log out
    saveRouteAndQuery(pathToStore, search)

    // If 'state' parmeter is not found in the URL (first pass through init()), then set the initial 'state' and 'code_verifier' values in auth.options, and also store them in local storage
    const { options } = auth as any
    options.state = auth.randomString(32)
    options.code_verifier = auth.randomString(64)
    options.minLoa = 2 // may specify optional Minimum Level of Assurance (default: 2, 3 - triggers MFA challenge)
    localStorage.setItem('state', options.state)
    localStorage.setItem('code_verifier', options.code_verifier)

    // no valid token, or 'state' value in URL, start the login process.
    return auth.loginWithPKCE()
}

export const authInit = () => {
    if (initPromise) {
        return initPromise
    }
    // check for preexisting tokens
    const savedToken = localStorage.getItem('userToken')
    const parsedSavedToken = savedToken ? JSON.parse(savedToken) : ''
    const urlSearchParams = new URLSearchParams(window.location.search)
    const urlParams = (Object as any).fromEntries(urlSearchParams.entries())

    // this will check if saved token is valid or grab one from the hash if it exists
    // init() wrapped in promise because could return Promise || undefined. Bad design.
    initPromise = new Promise((resolve, reject) => {
        const token = authClass().init(parsedSavedToken)
        if (token instanceof Promise) {
            token.then(resolve, reject)
        } else {
            resolve(token)
        }
    }).then((token) => {
        // if no token exists, check for a 'state' parameter in the url hash, which is returned from the Authorization server with a 'code' parameter upon successful authorization in the redirect response .
        const stateFromLocation = urlParams.state
        const customizeUrl = window.location.pathname.replace(/(.*customize\/?).*/i, '$1')
        const urlPlan = window.location.pathname.replace(customizeUrl, '')

        // /standard and /remote-support for new users
        // redirect logged in users to customize
        if (token && urlPlan.length) {
            window.location.href = customizeUrl
        }

        // if there is a token we're logged in
        // OR
        // if no token and no state we're not trying to log in
        if (token || (!token && !stateFromLocation)) {
            return Promise.resolve(token)
        }
        // #########
        // verify params following a PKCE login redirect
        // #########

        // If 'state' is found, compare it to the state from session storage.
        // throw an error if the 'state' parameter does not match the state in session storage
        if (localStorage.getItem('state') !== stateFromLocation) {
            console.log('Probable session hijacking attack!')
            throw new Error('Probable session hijacking attack!')
        }
        // get the 'code' parameter from the URL - throw an error if it is not found
        const authorizationCode = urlParams.code
        if (!authorizationCode) {
            console.log('Authorization failed')
            throw new Error('Authorization failed')
        }
        const initialCodeVerifier = localStorage.getItem('code_verifier')
        const config = {
            state: stateFromLocation,
            code: authorizationCode,
            code_verifier: initialCodeVerifier,
        } as any
        // make the call for a token to the Authorization server and store the returned token in session storage to be used for subsequent API requests.
        return authClass()
            .requestPKCEToken(config)
            .then(
                (newToken) => {
                    authClass().storeTokenObject(newToken)
                    saveToken(newToken)
                    return Promise.resolve(newToken)
                },
                () => {
                    console.log('Authorization failed.')
                    throw new Error('Authorization failed.')
                }
            )
            .finally(() => {
                // remove any residual params from URL without refreshing page
                urlSearchParams.delete('code')
                urlSearchParams.delete('state')

                const searchParam = urlSearchParams.toString() ? `?${urlSearchParams.toString()}` : ''

                window.history.replaceState({}, document.title, window.location.href.replace(/\?.*$/, searchParam))
            })
    })

    return initPromise
}

export default {
    authInit,
    login,
}
