import React, { FunctionComponent, useState, useEffect, useCallback } from 'react'
import { Form, FormikProps } from 'formik'
import { CommonButton, ConvertStringToReact, LoadingSpinner } from '@common/components'
import { useNavigate } from 'react-router-dom'
import isEmpty from 'lodash/isEmpty'
import { getSessionState, saveSessionState, connectRedux, GoToComponentProps, scrollToTop } from '@common/utils'
import {
    mapCheckoutFormState,
    mapCheckoutFormActions,
    CheckoutFormActionsType,
} from '@gtc/connectedComponents/CheckoutForm/connector'
import { isAddressComplete, isValidationAddressEligible } from '@gtc/utils'
import {
    ConnectedAccountFormInfo,
    AccountFormInfoComponentProps,
    ConnectedBillingFormInfo,
    BillingFormInfoComponentProps,
    ConnectedPaymentFormInfo,
    PaymentFormInfoComponentProps,
    ConnectedCheckoutFormSubmission,
    CheckoutFormSubmissionComponentProps,
} from '@gtc/connectedComponents'
import { CheckoutFormState } from '@gtc/connectedComponents/CheckoutForm/state'
import { ConnectedModal } from '@common/connectedComponents/Modal'
import {
    CHECKOUTFORM_FIELDS,
    CHECKOUTFORM_VALIDATION_EXCLUDE_FIELD_TYPES,
    PARTIAL_NON_VALIDATION_USER_TYPES,
    USER_TYPES,
} from '@gtc/constants'
import { TRIAL_ERROR_IDS } from '@gtc/constants/error-strings'
import { useCheckoutFormPageTracking } from '@gtc/hooks'
import { ErrorContent } from '@gtc/state/checkout-form-container/state'
import { BuyFormData } from '@gtc/state/buy-form/state'
import { NumberPickerContent } from '@gtc/components/NumberPicker'
import { GTC_VIEW_PATHS } from '@common/constants'
import checkoutStyles from './checkout-form.module.css'

type CheckoutFormProps = GoToComponentProps<
    CheckoutFormState,
    CheckoutFormActionsType,
    {
        isTabletOrMobileView?: boolean
        isMobileView?: boolean
        initialData: BuyFormData
        errorContent: ErrorContent
        formikRef: React.RefObject<FormikProps<BuyFormData>>
        setHasAddressValidationErrors: React.Dispatch<React.SetStateAction<boolean>>
        setGeneralFieldErrors: React.Dispatch<React.SetStateAction<boolean>>
        setHasCardValidationErrors: React.Dispatch<React.SetStateAction<boolean>>
        hasAddressValidationErrors: boolean
        generalFieldErrors: boolean
        hasCardValidationErrors: boolean
        contentNumberPicker: NumberPickerContent
        isSubmittingRef: React.RefObject<boolean>
    }
>

const CheckoutForm: FunctionComponent<CheckoutFormProps> = ({ state, actions, props }) => {
    const {
        userInteractedWithFlexField,
        isUserOptOut: initialIsOptOut,
        didFailToOrderPhoneNumber,
        inlineErrors,
    } = state
    const {
        formikRef,
        setHasAddressValidationErrors,
        setGeneralFieldErrors,
        setHasCardValidationErrors,
        hasCardValidationErrors,
        generalFieldErrors,
        hasAddressValidationErrors,
        isSubmittingRef,
    } = props

    const { setCheckoutFormInlineError, removeCheckoutFormInlineError } = actions

    const navigate = useNavigate()
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [useSameForBilling, setUseSameForBilling] = useState(true)
    const [isOptOut, setIsOptOut] = useState(initialIsOptOut)
    const [isFormReset, setIsFormReset] = useState(false)
    const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false)

    const hasExistingPaymentInformation = !isEmpty(state.existingPaymentInformation)
    const isAddonFlow =
        state.isUserLoggedIn &&
        (state.userType === USER_TYPES.ADDON ||
            (state.userType === USER_TYPES.EXPIRED && hasExistingPaymentInformation))
    const isCrossProductFlow =
        (state.isUserLoggedIn && state.userType === USER_TYPES.CROSSPRODUCT && hasExistingPaymentInformation) ||
        (state.isUserLoggedIn && state.userType === USER_TYPES.TRIALER && hasExistingPaymentInformation)
    const isTrialUser =
        state.userType === USER_TYPES.IDENTITY_ONLY ||
        (state.userType === USER_TYPES.TRIALER && !hasExistingPaymentInformation) ||
        (state.userType === USER_TYPES.CROSSPRODUCT && !hasExistingPaymentInformation)
    const isExpiredTrialUser = state.userType === USER_TYPES.EXPIRED

    const resetFieldTouched = useCallback(() => {
        if (formikRef.current) {
            Object.keys(formikRef.current.values).forEach((fieldKey) => {
                formikRef.current?.setFieldTouched(fieldKey, false)
            })
        }
    }, [formikRef])

    const canValidateAddress = (values: BuyFormData) => {
        // for later: need to send a new FormData object with billing first name and billing last name instead of FN & LN
        if (isValidationAddressEligible(values) && isAddressComplete(values)) {
            actions.validateAddress(values)
            actions.calculatePrice(values)
        }
    }

    const resetFatalError = () => {
        if (isFormReset && state.isFatalError) {
            actions.setFatalError(false)
            setIsFormReset(false)
        }
    }

    // tracking
    const { trackFormError, trackBillingInfoStep, trackPaymentStep, trackSubmitForm } = useCheckoutFormPageTracking({
        planName: state.selectedPlan.name,
        formikRef,
    })

    const submitForm = (formValues: BuyFormData) => {
        try {
            const qualtricsDataGtc = getSessionState('qualtrics_data_gtc')
            saveSessionState('qualtrics_data_gtc', {
                ...(!qualtricsDataGtc ? {} : qualtricsDataGtc),
                userEmail: formValues.Email,
            })
        } catch (err) {
            console.error(err)
        }
        setIsSubmitting(true)
        actions.setIsUserOptOut(isOptOut)
        if (!userInteractedWithFlexField) {
            actions.setUserInteractedWithFlexField(true)
        }
        actions.submitForm(formValues)
        trackSubmitForm()
    }

    const onFormErrors = (formIds: string[], formErrors: string[]) => {
        scrollToTop()
        setGeneralFieldErrors(true)
        trackFormError(formIds, formErrors)
    }

    const preSubmit = () => {
        if (hasAddressValidationErrors || hasCardValidationErrors) {
            scrollToTop()
            if (hasCardValidationErrors) {
                trackFormError([TRIAL_ERROR_IDS.CARD_ERROR], ['invalid credit card'])
            }
            if (hasAddressValidationErrors) {
                trackFormError([TRIAL_ERROR_IDS.ADRESS_ERROR], ['invalid billing address'])
            }
        } else if (isAddonFlow || isCrossProductFlow) {
            setIsSubmitting(true)
            trackSubmitForm()
            actions.existingUserSubmitForm(isAddonFlow)
            // set globally if the user is optout of marketing material
            actions.setIsUserOptOut(isOptOut)
        } else if (formikRef.current) {
            formikRef.current.validateForm().then((errors) => {
                const errorList = Object.keys(errors)
                if (!state.isCardValid) {
                    actions.setUserInteractedWithFlexField(true)
                }
                if (!state.isCvvValid) {
                    actions.setUserInteractedWithFlexFieldCvv(true)
                }
                if (errors.Password && formikRef?.current) {
                    formikRef.current.touched.Password = true
                }
                if (errorList.length > 0) {
                    if (isTrialUser || isExpiredTrialUser) {
                        const trialUserValidErrors = Object.entries(errors).filter(
                            (errorField) => errorField[0] !== CHECKOUTFORM_FIELDS.PASSWORD
                        )
                        if (Object.keys(trialUserValidErrors).length > 0) {
                            const trialUserValidErrorValues = trialUserValidErrors.map(
                                (fieldErrorTuple) => fieldErrorTuple[1]
                            )
                            const trialUserValidErrorKeys = trialUserValidErrors.map(
                                (fieldErrorTuple) => fieldErrorTuple[0]
                            )
                            onFormErrors(trialUserValidErrorKeys, trialUserValidErrorValues as string[])
                        } else if (formikRef.current) submitForm(formikRef.current.values)
                    } else {
                        onFormErrors(Object.keys(errors), Object.values(errors) as string[])
                    }
                } else if (formikRef.current) {
                    submitForm(formikRef.current.values)
                }
            })
        }
    }

    useEffect(() => {
        if (!Object.keys(inlineErrors).length) {
            setGeneralFieldErrors(false)
        }
    }, [inlineErrors, setGeneralFieldErrors])

    useEffect(() => {
        if (state.userType === USER_TYPES.ADDON && formikRef.current) {
            actions.calculatePrice(formikRef.current.values)
        }
    }, [state.userType, actions, formikRef])

    // show form errors by scrolling to top
    useEffect(() => {
        const errorsInForm = generalFieldErrors || hasAddressValidationErrors
        if (errorsInForm) {
            scrollToTop()
            setIsSubmitting(false)
        }
    }, [hasAddressValidationErrors, generalFieldErrors])

    // reset the form and reset the states affected by checkout form
    useEffect(() => {
        if (state.isFatalError && formikRef.current) {
            scrollToTop()
            formikRef.current.setSubmitting(false)
            setIsSubmitting(false)
            resetFieldTouched()
            formikRef.current.setFieldValue(CHECKOUTFORM_FIELDS.EXPIRATION_DATE, '')
            setHasAddressValidationErrors(false)
            setHasCardValidationErrors(false)
            setGeneralFieldErrors(false)
            setIsFormReset(true)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [actions, formikRef, isFormReset, resetFieldTouched, state.isFatalError])

    // validate the non empty entries after populating
    useEffect(() => {
        if (state.userType !== USER_TYPES.ADDON) {
            Object.entries(props.initialData).forEach(([formField, formValue]) => {
                // ignore empty fields and the country field
                if (formValue !== '' && formField !== CHECKOUTFORM_VALIDATION_EXCLUDE_FIELD_TYPES.COUNTRYCODE) {
                    // if the user is a trialer, crossproduct, expired, banned
                    // then dont validate the identity and address fields
                    if (
                        !(
                            Object.values(PARTIAL_NON_VALIDATION_USER_TYPES).includes(
                                state.userType as PARTIAL_NON_VALIDATION_USER_TYPES
                            ) &&
                            Object.values(CHECKOUTFORM_VALIDATION_EXCLUDE_FIELD_TYPES).includes(
                                formField as CHECKOUTFORM_VALIDATION_EXCLUDE_FIELD_TYPES
                            )
                        )
                    ) {
                        if (formikRef.current) {
                            formikRef.current.validateField(formField)
                        }
                    }
                }
            })
        }
    }, [formikRef, props.initialData, state.userType])

    // if the billing frequency changes, recalculate the cost
    useEffect(() => {
        if (formikRef.current && isAddressComplete(formikRef.current.values)) {
            actions.calculatePrice(formikRef.current.values)
        }
    }, [actions, formikRef, state.billingFrequency])

    // for trial and expired trial user, set billing FN and LN to avoid validation error
    useEffect(() => {
        if ((isTrialUser || isExpiredTrialUser) && useSameForBilling && formikRef.current) {
            formikRef.current.setFieldValue(CHECKOUTFORM_FIELDS.BILLING_FIRST_NAME, props.initialData.FirstName)
            formikRef.current.setFieldValue(CHECKOUTFORM_FIELDS.BILLING_LAST_NAME, props.initialData.LastName)
        }
    }, [isTrialUser, useSameForBilling, props.initialData, isExpiredTrialUser, formikRef])

    // after submitting, if the purchase succeeds but the number order fails, then a modal shows up, so hide the loading spinner
    useEffect(() => {
        if (didFailToOrderPhoneNumber) {
            setIsSubmitting(false)
        }
    }, [didFailToOrderPhoneNumber])

    const accountInfoProps: AccountFormInfoComponentProps = {
        useSameForBilling,
        resetFatalError,
        canValidateAddress,
        formikRef,
        setCheckoutFormInlineError,
        removeCheckoutFormInlineError,
        inlineErrors,
        isSubmittingRef,
    }

    const billingInfoProps: BillingFormInfoComponentProps = {
        useSameForBilling,
        resetFatalError,
        canValidateAddress,
        setUseSameForBilling,
        trackBillingInfoStep,
        setHasAddressValidationErrors,
        formikRef,
        setCheckoutFormInlineError,
        removeCheckoutFormInlineError,
        inlineErrors,
    }

    const paymentInfoProps: PaymentFormInfoComponentProps = {
        resetFatalError,
        hasExistingPaymentInformation,
        setIsPaymentModalOpen,
        trackPaymentStep,
        setHasCardValidationErrors,
        setCheckoutFormInlineError,
        removeCheckoutFormInlineError,
        inlineErrors,
    }

    const checkoutSubmissionProps: CheckoutFormSubmissionComponentProps = {
        isSubmitting,
        preSubmit,
        isTabletOrMobileView: props.isTabletOrMobileView,
        setIsOptOut,
        isOptOut,
        contentNumberPicker: props.contentNumberPicker,
        inlineErrors,
    }

    const handleDidFailToOrderPhoneNumberModalClose = () => {
        navigate(GTC_VIEW_PATHS.CONNECT_NEXT_STEPS)
    }

    return (
        <>
            <Form>
                {!state.isUserLoggedIn && <ConnectedAccountFormInfo {...accountInfoProps} />}
                <ConnectedBillingFormInfo {...billingInfoProps} />
                <ConnectedPaymentFormInfo {...paymentInfoProps} />
                <ConnectedCheckoutFormSubmission {...checkoutSubmissionProps} />
            </Form>

            <ConnectedModal isOpen={isPaymentModalOpen} closeable onClose={() => setIsPaymentModalOpen(false)}>
                <div>
                    <h3>{state.content.changePaymentMethodModalHeader}</h3>
                    <div>
                        <ConvertStringToReact htmlString={state.content.changePaymentMethodModalSubheader} />
                    </div>
                    <CommonButton purpose="primary" onClick={() => setIsPaymentModalOpen(false)}>
                        {state.content.modalCloseButtonText}
                    </CommonButton>
                </div>
            </ConnectedModal>

            <ConnectedModal
                isOpen={didFailToOrderPhoneNumber}
                closeable
                onClose={() => handleDidFailToOrderPhoneNumberModalClose()}
            >
                <div data-qat="failed-to-order-phone-number-modal">
                    <h3>{state.content.numberPickerFailedToOrderPhoneNumberModalHeader}</h3>
                    <p>{state.content.numberPickerFailedToOrderPhoneNumberModalSubHeader}</p>
                    <CommonButton
                        data-qat="failed-to-order-phone-number-modal-close-button"
                        purpose="primary"
                        onClick={() => handleDidFailToOrderPhoneNumberModalClose()}
                    >
                        {state.content.numberPickerFailedToOrderPhoneNumberModalCloseButtonText}
                    </CommonButton>
                </div>
            </ConnectedModal>

            {isSubmitting && <LoadingSpinner wrapperModifier={checkoutStyles['submit-blocker']} />}
        </>
    )
}

export const ConnectedCheckoutForm = connectRedux(CheckoutForm, mapCheckoutFormState, mapCheckoutFormActions)
