import { AppState } from 'app-store'
import { getContentData } from '@gtc/state/selectors'
import { FormatCurrencyState } from './state'

export const mapFormatCurrencyState = (state: AppState): FormatCurrencyState => {
    const { Locale, CurrencyCode } = getContentData(state)
    return {
        Locale,
        CurrencyCode,
    }
}

export const mapFormatCurrencyActions = {}

export type FormatCurrencyActionsType = typeof mapFormatCurrencyActions
