import { AppState } from 'app-store'
import { locationActions } from '@common/state/location/actions'
import { flexActions } from '@common/state/flex/actions'
import { buyFormActions } from '@gtc/state/buy-form/actions'
import { checkoutActions } from '@gtc/state/checkout/actions'
import {
    getFatalError,
    getFlexState,
    getMarketingState,
    getCheckoutState as getCommonCheckoutState,
} from '@common/state/selectors'
import { Plan } from '@gtc/state/plan'
import { getCurrentPlan } from '@gtc/utils/get-current-plan'
import { fatalErrorActions } from '@common/state/fatal-error/actions'
import { marketingActions } from '@common/state/marketing/actions'
import { getLabelContent, getCheckoutState, getUserState, getBuyFormState, getPlanData } from '@gtc/state/selectors'
import { BuyFormData } from '@gtc/state/buy-form/state'
import { checkoutActions as commonCheckoutActions } from '@common/state/checkout/actions'
import { CheckoutFormState } from './state'

export const mapCheckoutFormState = (state: AppState): CheckoutFormState => {
    const {
        signIn,
        changePaymentMethodModalHeader,
        changePaymentMethodModalSubheader,
        modalCloseButtonText,
        showMe,
        numberPickerFailedToOrderPhoneNumberModalHeader,
        numberPickerFailedToOrderPhoneNumberModalSubHeader,
        numberPickerFailedToOrderPhoneNumberModalCloseButtonText,
    } = getLabelContent(state)

    const { selectedPlanSku, billingFrequency, didFailToOrderPhoneNumber } = getCheckoutState(state)
    const planData: Plan[] = getPlanData(state)
    const selectedPlan = getCurrentPlan(selectedPlanSku, planData)
    const { userInteractedWithFlexField, isCardValid, isCvvValid } = getFlexState(state)
    const {
        userType,
        existingPaymentInformation,
        loggedIn: { isUserLoggedIn },
    } = getUserState(state)
    const { clearEmail } = getBuyFormState(state)
    const { isFatalError } = getFatalError(state)
    const { isUserOptOut } = getMarketingState(state)
    const { inlineErrors } = getCommonCheckoutState(state)

    return {
        content: {
            signIn,
            changePaymentMethodModalHeader,
            changePaymentMethodModalSubheader,
            modalCloseButtonText,
            showMe,
            numberPickerFailedToOrderPhoneNumberModalHeader,
            numberPickerFailedToOrderPhoneNumberModalSubHeader,
            numberPickerFailedToOrderPhoneNumberModalCloseButtonText,
        },
        clearEmail,
        userInteractedWithFlexField,
        selectedPlan,
        isUserLoggedIn,
        isFatalError,
        userType,
        existingPaymentInformation,
        isUserOptOut,
        billingFrequency,
        didFailToOrderPhoneNumber,
        inlineErrors,
        isCardValid,
        isCvvValid,
    }
}

export const mapCheckoutFormActions = {
    setUserInteractedWithFlexFieldCvv: (interacted: boolean) =>
        flexActions.setUserInteractedWithFlexFieldCvv(interacted),
    setUserInteractedWithFlexField: (interacted: boolean) => flexActions.setUserInteractedWithFlexField(interacted),
    submitForm: (values: BuyFormData) => buyFormActions.submitForm(values),
    existingUserSubmitForm: (isAddonFlow: boolean) => buyFormActions.existingUserSubmitForm(isAddonFlow),
    validateAddress: (values: BuyFormData) => locationActions.validateAddress(values),
    calculatePrice: (values: BuyFormData) => checkoutActions.calculatePrice(values),
    setFatalError: (isFatalError: boolean) => fatalErrorActions.setFatalError(isFatalError),
    setIsUserOptOut: (isUserOutOut: boolean) => marketingActions.setIsUserOptOut(isUserOutOut),
    setCheckoutFormInlineError: (value: string) => commonCheckoutActions.setCheckoutFormInlineError(value),
    removeCheckoutFormInlineError: (value: string) => commonCheckoutActions.removeCheckoutFormInlineError(value),
}

export type CheckoutFormActionsType = typeof mapCheckoutFormActions
