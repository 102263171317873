import { PLATFORM } from '@common/constants'
import { useLoadScript } from './useLoadScript'

export const useFlexForm = () => {
    const flexScriptUrl =
        process.env.ENVIRONMENT !== PLATFORM.PROD
            ? 'https://testflex.cybersource.com/cybersource/assets/microform/0.11/flex-microform.min.js'
            : 'https://flex.cybersource.com/cybersource/assets/microform/0.11/flex-microform.min.js'

    return useLoadScript(flexScriptUrl)
}
