import { TrackingState } from '@common/state/tracking/state'
import { GTCTrackingContext } from '@gtc/connectedComponents/Tracking'
import { useContext, useMemo } from 'react'
import { useLocation } from 'react-router-dom'

export const useGTCTracking = () => {
    const location = useLocation()
    const trackingContext = useContext(GTCTrackingContext)
    if (!trackingContext) {
        throw new Error(
            'useGTCTrackingContext must only be used within GTCTrackingProvider component and its children.'
        )
    }

    const currentLocation = location.pathname
    const trackingWrapper = useMemo(
        () => ({
            track: (trackingInfo: TrackingState, component: string) => {
                const trackingData = {
                    ...trackingInfo,
                    eventData: { ...trackingInfo.eventData, currentLocation },
                }
                trackingContext.track(trackingData, component)
            },
        }),
        [currentLocation, trackingContext]
    )
    return trackingWrapper
}
