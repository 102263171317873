import { getFatalError, getMarketingState } from '@common/state/selectors'
import { AppState } from 'app-store'
import { getCheckoutState, getLabelContent } from '@gtc/state/selectors'
import { CheckoutFormSubmissionState } from './state'

export const mapCheckoutFormSubmissionState = (state: AppState): CheckoutFormSubmissionState => {
    const { completePurchase, pricingNote, optOut, optOutCheckboxLabel } = getLabelContent(state)
    const { isFatalError, fatalErrorBlocker } = getFatalError(state)
    const { totalLoading } = getCheckoutState(state)
    const { displayOptOut } = getMarketingState(state)
    return {
        content: { completePurchase, pricingNote, optOut, optOutCheckboxLabel },
        isFatalError,
        fatalErrorBlocker,
        totalLoading,
        displayOptOut,
    }
}

export const mapCheckoutFormSubmissionActions = {}

export type CheckoutFormSubmissionActionsType = typeof mapCheckoutFormSubmissionActions
