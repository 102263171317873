import React, { FunctionComponent } from 'react'
import { useSmoothScrollPolyfill } from '@common/hooks'
import { LoadAnalyticScripts } from '../LoadAnalyticScripts'
import { BoldChat, BoldchatOptions } from '../BoldChat'
import { LmiMaScript } from '../LmiMaScript'
import { ChatbotScript } from '../ChatbotScript'

export interface GlobalScriptsProps {
    analyticsProductKey?: string
    boldchatOptions?: BoldchatOptions
    shouldLoadTrustArc?: boolean
    lmiMaScriptUrl?: string
    siteType?: string
}

export const GlobalScripts: FunctionComponent<GlobalScriptsProps> = (props) => {
    // add all global scripts via hooks to this component
    const { analyticsProductKey = '', boldchatOptions, lmiMaScriptUrl = '', siteType = '' } = props

    useSmoothScrollPolyfill()
    return (
        <>
            {!!analyticsProductKey && <LoadAnalyticScripts gtmProductKey={analyticsProductKey} />}
            {!!boldchatOptions && <BoldChat options={boldchatOptions} />}
            {!!lmiMaScriptUrl && <LmiMaScript url={lmiMaScriptUrl} />}
            {!!siteType && <ChatbotScript siteType={siteType} />}
        </>
    )
}
