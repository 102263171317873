import { FieldProps } from 'formik'

export const setCheckoutInlineError = (
    fieldData: FieldProps,
    inlineErrors: { [key: string]: string } | Record<string, unknown>,
    setCall: (val: string) => void,
    removeCall: (val: string) => void,
    ignoreInlineError?: boolean
) => {
    const { field, meta } = fieldData
    if (ignoreInlineError) {
        if (inlineErrors && inlineErrors[field.name]) {
            removeCall(field.name)
        } else {
            return
        }
    }

    if (meta.error && ((inlineErrors && !inlineErrors[field.name]) || !inlineErrors)) {
        setCall(field.name)
    }

    if (!meta.error && inlineErrors && inlineErrors[field.name]) {
        removeCall(field.name)
    }
}
