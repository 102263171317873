import { MarketoLabelUserFields, MarketoUserInfo } from '@common/state/marketing/state'

export const makeMarketoObject = (values: MarketoLabelUserFields) => {
    const {
        firstName,
        lastName,
        email,
        phone,
        planKey,
        marketoSalesBrief,
        marketoSalesforceCampaignId,
        marketoProductName,
    } = values

    const marketoObject: MarketoUserInfo = {
        firstName,
        lastName,
        email,
        phone,
        planKey,
        salesBrief: marketoSalesBrief,
        campaignID: marketoSalesforceCampaignId,
        product: marketoProductName,
    }

    if (values.Street1) marketoObject.address = values.Street1
    if (values.City) marketoObject.city = values.City
    if (values.StateCode) marketoObject.state = values.StateCode
    if (values.ZipCode) marketoObject.zipcode = values.ZipCode
    if (values.ContactPhone) marketoObject.phone = values.ContactPhone
    // if (values.companySize) marketoObject.employees = values.companySize // TODO: Will be removed in a future change
    if (values.CompanyName) marketoObject.company = values.CompanyName

    return marketoObject
}
