import { call, takeEvery, put, select } from 'redux-saga/effects'
import { buyFormActions, BuyFormActionTypes } from '@gtc/state/buy-form/actions'
import { fatalErrorActions } from '@common/state/fatal-error/actions'
import { checkoutActions } from '@gtc/state/checkout/actions'
import { SubmitDataType, CheckoutState, PaymentDataReturnType } from '@gtc/state/checkout/state'
import { getPaymentItems } from '@gtc/utils/get-item-values'
import { getCheckoutState, getUserState, getLabelContent, getContentData, getCouponState } from '@gtc/state/selectors'
import { getLocationState } from '@common/state/selectors'
import { getSelectedAddons, getSelectedPlan } from '@gtc/saga/content/content-helpers'
import { MarketoUserInfo } from '@common/state/marketing/state'
import { marketingActions } from '@common/state/marketing/actions'
import { ContentData } from '@gtc/state/content-data'
import { CHECKOUTFORM_STEPS, TRACKING_COMPONENTS } from '@gtc/constants'
import { LocationState } from '@common/state/location/state'
import { SC_SITE, TRACKING_EVENTS } from '@common/constants'
import { gtcTrackingActions } from '@gtc/state/tracking/actions'
import { AbandonedCartDataType, UserState } from '@gtc/state/user/state'
import { ExistingBillingInfoType, ExistingUserInfoType } from '@common/state/user/state'
import { clearState, getActiveCouponServerData } from '@gtc/utils'
import { LabelContent } from '@gtc/state/label-content'
import { Plan } from '@gtc/state/plan'
import { Addon } from '@gtc/state/add-on'
import { CouponState } from '@gtc/state/coupon/state'
import { CouponServerData } from '@common/state/coupon/state'
import isEmpty from 'lodash/isEmpty'
import { userActions } from '@gtc/state/user/actions'
import { SubmitPaymentResponseData } from '@gtc/state/submit-payment/state'
import { submitPaymentSaga } from './submit-payment'
import { getAvailablePaymentMethodsSaga } from './get-payment-methods'

function* submitFormSaga(action: ReturnType<typeof buyFormActions.existingUserSubmitForm>) {
    const { isAddonFlow } = action.payload
    const componentName = TRACKING_COMPONENTS.EXISTING_USER_FORM_SUBMISSION_SAGA
    const steps: Record<CHECKOUTFORM_STEPS, boolean> = {
        [CHECKOUTFORM_STEPS.CREATE_FLEX_TOKEN]: true,
        [CHECKOUTFORM_STEPS.CREATE_USER_ACCOUNT]: true,
        [CHECKOUTFORM_STEPS.SAVE_BILLING_INFO]: true,
        [CHECKOUTFORM_STEPS.SAVE_PAYMENT_INFO]: true,
        [CHECKOUTFORM_STEPS.GET_AVAILABLE_PAYMENT_METHODS]: false,
        [CHECKOUTFORM_STEPS.SUBMIT_PAYMENT]: false,
    }
    // update the redux store to ensure consistency
    yield put(
        buyFormActions.updateSteps(CHECKOUTFORM_STEPS.CREATE_FLEX_TOKEN, steps[CHECKOUTFORM_STEPS.CREATE_FLEX_TOKEN])
    )
    yield put(
        buyFormActions.updateSteps(
            CHECKOUTFORM_STEPS.CREATE_USER_ACCOUNT,
            steps[CHECKOUTFORM_STEPS.CREATE_USER_ACCOUNT]
        )
    )
    yield put(
        buyFormActions.updateSteps(CHECKOUTFORM_STEPS.SAVE_BILLING_INFO, steps[CHECKOUTFORM_STEPS.SAVE_BILLING_INFO])
    )
    yield put(
        buyFormActions.updateSteps(CHECKOUTFORM_STEPS.SAVE_PAYMENT_INFO, steps[CHECKOUTFORM_STEPS.SAVE_PAYMENT_INFO])
    )

    const checkout: CheckoutState = yield select(getCheckoutState)
    const { addonUserSuccessUrl }: LabelContent = yield select(getLabelContent)
    const { locationInfo }: LocationState = yield select(getLocationState)

    const userState: UserState = yield select(getUserState)
    const {
        addressLine1 = '',
        city = '',
        postalCode = '',
        state: stateCode = '',
        phoneNumber = '',
    } = userState.existingBillingInformation as ExistingBillingInfoType

    const {
        UserName,
        Name: { FamilyName, GivenName },
    } = userState.existingUserInformation as ExistingUserInfoType

    const { submittoMarketo, marketoProductName, marketoSalesBrief, marketoSalesforceCampaignId }: ContentData =
        yield select(getContentData)

    const selectedPlan: Plan = yield call(getSelectedPlan)
    const selectedAddons: Addon[] = yield call(getSelectedAddons)
    const coupon: CouponState = yield select(getCouponState)
    const { isCouponValidAndApplied } = coupon
    const couponServerData: CouponServerData | null = yield call(getActiveCouponServerData, coupon, selectedPlan)
    const isCouponValid = isCouponValidAndApplied && couponServerData && !isEmpty(couponServerData)

    try {
        const upgradeFlow = isAddonFlow
        const items = getPaymentItems(
            selectedPlan,
            checkout.organizers,
            checkout.billingFrequency,
            selectedAddons,
            upgradeFlow
        )

        let paymentMethodKey = ''
        const getAvailablePaymentData: PaymentDataReturnType[] = yield call(getAvailablePaymentMethodsSaga)
        if (getAvailablePaymentData && getAvailablePaymentData[0]) {
            steps[CHECKOUTFORM_STEPS.GET_AVAILABLE_PAYMENT_METHODS] = true
            paymentMethodKey = getAvailablePaymentData[0].paymentMethodKey
        }
        yield put(
            buyFormActions.updateSteps(
                CHECKOUTFORM_STEPS.GET_AVAILABLE_PAYMENT_METHODS,
                steps[CHECKOUTFORM_STEPS.GET_AVAILABLE_PAYMENT_METHODS]
            )
        )

        if (steps[CHECKOUTFORM_STEPS.GET_AVAILABLE_PAYMENT_METHODS]) {
            const submitValues: SubmitDataType = {
                currency: locationInfo.currency,
                paymentMethodKey,
                products: items,
                grossAmount: checkout.calculatedTotal,
                couponCode: isCouponValid ? couponServerData?.couponCode : '',
                anniversaryDate: checkout.anniversaryDate,
            }

            const submitPaymentAction = checkoutActions.submitPayment(submitValues)
            const submitData: SubmitPaymentResponseData = yield call(submitPaymentSaga, submitPaymentAction)
            if (submitData) {
                steps[CHECKOUTFORM_STEPS.SUBMIT_PAYMENT] = true
                yield put(
                    gtcTrackingActions.track(
                        { event: TRACKING_EVENTS.PURCHASE, eventData: { invoiceNumber: submitData.invoiceNumber } },
                        componentName
                    )
                )
            }
            yield put(
                buyFormActions.updateSteps(CHECKOUTFORM_STEPS.SUBMIT_PAYMENT, steps[CHECKOUTFORM_STEPS.SUBMIT_PAYMENT])
            )
        }

        if (steps[CHECKOUTFORM_STEPS.SUBMIT_PAYMENT]) {
            const abandonedCartData: AbandonedCartDataType = {
                email: UserName,
                success: true,
            }
            yield put(userActions.submitAbandonedCart(abandonedCartData))
            yield put(gtcTrackingActions.track({ event: TRACKING_EVENTS.COMPLETE_BUY_FLOW }, componentName))
            if (submittoMarketo) {
                const { planKey } = selectedPlan
                const marketoData: MarketoUserInfo = {
                    firstName: GivenName,
                    lastName: FamilyName,
                    email: UserName,
                    planKey,
                    address: addressLine1,
                    city,
                    state: stateCode,
                    zipcode: postalCode,
                    phone: phoneNumber,
                    salesBrief: marketoSalesBrief,
                    campaignID: marketoSalesforceCampaignId,
                    product: marketoProductName,
                    sc_site: SC_SITE.CONNECT,
                }
                yield put(marketingActions.postToMarketo(marketoData))
            }
            yield put(
                gtcTrackingActions.track(
                    {
                        event: TRACKING_EVENTS.EMAIL_VALIDATION,
                        eventData: {
                            userEmail: UserName,
                            formName: TRACKING_COMPONENTS.CHECKOUT_PAGE,
                        },
                    },
                    componentName
                )
            )
            // delete saved state
            yield call(clearState)
            yield put(checkoutActions.setAddonComplete(true))
            window.location.href = addonUserSuccessUrl
        }
    } catch (e) {
        yield put(fatalErrorActions.setFatalError(true, { trackErrorMessage: 'existing user form submission failed' }))
    }
}

function* initializeSubmitFormSaga() {
    yield takeEvery(BuyFormActionTypes.EXISTING_USER_SUBMIT_BUY_FORM, submitFormSaga)
}

export default initializeSubmitFormSaga
