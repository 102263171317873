import { STORAGE_CONST } from '@common/constants'
import { BuySavedSessionState } from '@buy/state/checkout/state'

export const loadSessionState = () => {
    try {
        const serializedState = sessionStorage.getItem(STORAGE_CONST.GTC_COUPON_STORE)
        const language = sessionStorage.getItem(STORAGE_CONST.SAVED_LANGUAGE)
        if (serializedState === null && language === null) {
            return undefined
        }

        return {
            ...(serializedState && { coupon: JSON.parse(serializedState) }),
            ...(language && { language: JSON.parse(language) }),
        } as BuySavedSessionState
    } catch (err) {
        return undefined
    }
}

export const saveSessionState = (state: BuySavedSessionState) => {
    try {
        const serializedState = JSON.stringify(state)
        sessionStorage.setItem(STORAGE_CONST.GTC_COUPON_STORE, serializedState)
    } catch (err) {
        // fail silently
    }
}

export const clearSessionState = () => {
    try {
        const coupon = sessionStorage.getItem(STORAGE_CONST.GTC_COUPON_STORE)
        const language = sessionStorage.getItem(STORAGE_CONST.SAVED_LANGUAGE)

        if (coupon) sessionStorage.removeItem(STORAGE_CONST.GTC_COUPON_STORE)
        if (language) sessionStorage.removeItem(STORAGE_CONST.SAVED_LANGUAGE)
    } catch (err) {
        // fail silently
    }
}
