import { RESOLVE_USER_TYPES } from '@gtresolve/constants'
import { ResolveUserState } from './state'
import { ResolveUserActionTypes } from './actions'
import type { ResolveUserActions } from './actions'

export const initialUserState: ResolveUserState = {
    displayName: '',
    loadingExistingInfo: false,
    verifyingToken: false,
    userType: RESOLVE_USER_TYPES.NEW,
    loggedIn: {
        accessToken: undefined,
        isUserLoggedIn: false,
    },
    verifiedUserShouldLogIn: false,
}

export const userReducer = (
    previousState: ResolveUserState | undefined,
    action: ResolveUserActions
): ResolveUserState => {
    const state = previousState || initialUserState
    switch (action.type) {
        case ResolveUserActionTypes.SET_EXISTING_BILLING_INFO: {
            const { billingInfo } = action.payload
            return {
                ...state,
                existingBillingInformation: { ...billingInfo },
            }
        }
        case ResolveUserActionTypes.SET_EXISTING_USER_INFO: {
            const { userInfo } = action.payload
            return {
                ...state,
                existingUserInformation: { ...userInfo },
            }
        }
        case ResolveUserActionTypes.SET_EXISTING_ACCOUNT_INFO: {
            const { accountInfo } = action.payload
            return {
                ...state,
                existingAccountInformation: { ...accountInfo },
            }
        }
        case ResolveUserActionTypes.SET_DISPLAY_NAME: {
            return {
                ...state,
                displayName: action.payload.displayName,
            }
        }
        case ResolveUserActionTypes.SET_LOADING_EXISTING_INFO: {
            return {
                ...state,
                loadingExistingInfo: action.payload.loading,
            }
        }
        case ResolveUserActionTypes.SET_VERIFYING_TOKEN: {
            return {
                ...state,
                verifyingToken: action.payload.verifying,
            }
        }
        case ResolveUserActionTypes.SET_USER_TYPE: {
            return {
                ...state,
                userType: action.payload.type,
            }
        }
        case ResolveUserActionTypes.SET_EXISTING_PAYMENT_DETAILS: {
            const { info } = action.payload
            return {
                ...state,
                existingPaymentInformation: { ...info },
            }
        }
        case ResolveUserActionTypes.SET_ACCESS_TOKEN: {
            return {
                ...state,
                loggedIn: { ...state.loggedIn, accessToken: action.payload.accessToken },
            }
        }
        case ResolveUserActionTypes.SET_IS_USER_LOGGEDIN: {
            return {
                ...state,
                loggedIn: {
                    ...state.loggedIn,
                    isUserLoggedIn: action.payload.isUserLoggedIn,
                },
            }
        }
        case ResolveUserActionTypes.SET_VERIFY_USER_SHOULD_LOGIN: {
            return {
                ...state,
                verifiedUserShouldLogIn: action.payload.verifiedUserShouldLogIn,
            }
        }
        default:
            return state
    }
}
