import { LocationState } from '@common/state/location/state'
import { getLocationState } from '@common/state/selectors'
import { ResolveCheckoutState } from '@gtresolve/state/checkout/state'
import { getSelectedAddons, getSelectedPlan } from '@gtresolve/saga/content/content-helpers'
import { getCheckoutState, getContentData, getCouponState, getPlanData, getUserState } from '@gtresolve/state/selectors'
import { ResolveUserState } from '@gtresolve/state/user/state'
import isEmpty from 'lodash/isEmpty'
import { select, call } from 'redux-saga/effects'
import { ResolvePlan } from '@gtresolve/state/plan'
import { ResolveAddon } from '@gtresolve/state/add-on'
import { RESOLVE_USER_TYPES } from '@gtresolve/constants'
import { getActiveCouponServerData, getCouponTotalSavings, getSelectedTierIndex } from '@gtresolve/utils'
import { ResolveCouponState } from '@gtresolve/state/coupon/state'
import { ResolveCustomizeItemsData } from '@gtresolve/state/tracking/state'
import { ResolveContentData } from '@gtresolve/state/content-data'

export function* getCustomizeItemsData() {
    const planData: ResolvePlan[] = yield select(getPlanData)
    const { CurrencyCode }: ResolveContentData = yield select(getContentData)
    const { billingFrequency, organizers }: ResolveCheckoutState = yield select(getCheckoutState)
    const customizeItemsData: ResolveCustomizeItemsData = {
        planData,
        billingFrequency,
        currency: CurrencyCode,
        organizers,
    }
    return customizeItemsData
}

export function* getPageViewData() {
    const {
        loggedIn: { isUserLoggedIn },
    }: ResolveUserState = yield select(getUserState)
    const locationState: LocationState = yield select(getLocationState)
    const { locale, country, language } = locationState.locationInfo
    return { locale, country, isUserLoggedIn, language }
}

export function* getSessionInfo() {
    const {
        userType,
        loggedIn: { isUserLoggedIn },
    }: ResolveUserState = yield select(getUserState)
    return { isUserLoggedIn, userType, sessionLast: '', sessionNumber: '' }
}

export function* getCartInfo() {
    const { billingFrequency, organizers, currentVolumeDiscountPrice, selectedTierIndex }: ResolveCheckoutState =
        yield select(getCheckoutState)
    const locationState: LocationState = yield select(getLocationState)
    const { existingBillingInformation, userType, existingAccountInformation }: ResolveUserState = yield select(
        getUserState
    )
    const { currency } = locationState.locationInfo
    const selectedPlan: ResolvePlan = yield call(getSelectedPlan)
    const selectedAddons: ResolveAddon[] = yield call(getSelectedAddons)

    let tierIndex = selectedTierIndex
    if (userType === RESOLVE_USER_TYPES.ADDON && existingAccountInformation && !isEmpty(existingAccountInformation)) {
        const existingOrganizers = existingAccountInformation.quantity
        const totalOrganizers = organizers + existingOrganizers
        tierIndex = yield call(getSelectedTierIndex, totalOrganizers, billingFrequency, selectedPlan, currency)
    }

    const coupon: ResolveCouponState = yield select(getCouponState)
    const { couponProcessedDataPerSku, isCouponValidAndApplied, couponDoubleDipAmountForNewUser } = coupon
    const couponServerData = getActiveCouponServerData(coupon, selectedPlan)
    const totalCouponSavings = getCouponTotalSavings(
        isCouponValidAndApplied,
        couponProcessedDataPerSku,
        couponDoubleDipAmountForNewUser
    )

    return {
        plan: selectedPlan,
        addonList: selectedAddons,
        billingFrequency,
        organizers,
        currency,
        isExistingUser: !isEmpty(existingBillingInformation),
        tier: `Tier${tierIndex + 1}`,
        currentVolumeDiscountPrice,
        couponCode: couponServerData?.couponCode || '',
        couponProcessedDataPerSku,
        totalCouponSavings,
    }
}

export function* getPurchaseData(transactionID: string) {
    const { calculatedNetTotal, calculatedTax, promoCode }: ResolveCheckoutState = yield select(getCheckoutState)
    const { userType }: ResolveUserState = yield select(getUserState)
    return { calculatedNetTotal, calculatedTax, promoCode, userType, transactionID }
}

export function* getCompleteBuyData() {
    const { userType }: ResolveUserState = yield select(getUserState)
    return userType
}
