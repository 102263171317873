import { call, takeLatest, select, put, delay } from 'redux-saga/effects'
import { checkoutActions, CheckoutActionTypes } from '@gtc/state/checkout/actions'
import { CalculatePriceDataType, CheckoutState } from '@gtc/state/checkout/state'
import { calculatePrice } from '@gtc/services'
import { getBillingValues } from '@gtc/utils/get-billing-values'
import { getPaymentItems } from '@gtc/utils/get-item-values'
import { getSelectedPlan, getSelectedAddons } from '@gtc/saga/content/content-helpers'
import { getCheckoutState, getCouponState, getUserState } from '@gtc/state/selectors'
import { getLocationState } from '@common/state/selectors'
import { modalActions } from '@common/state/modal/actions'
import { fatalErrorActions } from '@common/state/fatal-error/actions'
import { TRACKING_COMPONENTS, USER_TYPES } from '@gtc/constants'
import { gtcTrackingActions } from '@gtc/state/tracking/actions'
import { TRACKING_EVENTS } from '@common/constants'
import { LocationState } from '@common/state/location/state'
import { UserState } from '@gtc/state/user/state'
import { Addon } from '@gtc/state/add-on'
import { Plan } from '@gtc/state/plan'
import { CalculatePriceAPIData, CalculatePriceAPIReturnType } from '@gtc/state/calculate-price'
import isEmpty from 'lodash/isEmpty'
import {
    getActiveCouponServerData,
    getCouponDoubleDipAmountForNewUser,
    getTotalProratedAmount,
    getTotalVolumeDiscount,
} from '@gtc/utils'
import { CouponState } from '@gtc/state/coupon/state'
import { CouponServerData } from '@common/state/coupon/state'
import { couponActions } from '@gtc/state/coupon/actions'

export function* calculatePriceSaga(action: ReturnType<typeof checkoutActions.calculatePrice>) {
    const componentName = TRACKING_COMPONENTS.CALCULATE_PRICE_SAGA
    try {
        yield put(checkoutActions.setTotalLoading(true))
        yield delay(1000)
        const checkout: CheckoutState = yield select(getCheckoutState)
        const { userType, existingBillingInformation }: UserState = yield select(getUserState)
        const { values, planSKU } = action.payload
        const selectedPlan: Plan = yield call(getSelectedPlan, planSKU)
        const selectedAddons: Addon[] = yield call(getSelectedAddons)
        const coupon: CouponState = yield select(getCouponState)
        const { isCouponValidAndApplied, couponProcessedDataPerSku } = coupon
        const couponServerData: CouponServerData | null = yield call(getActiveCouponServerData, coupon, selectedPlan)
        const isCouponValid = isCouponValidAndApplied && couponServerData && !isEmpty(couponServerData)

        if (userType === USER_TYPES.ADDON) {
            values.Street1 = existingBillingInformation?.addressLine1 || ''
            values.City = existingBillingInformation?.city || ''
            values.CountryCode = existingBillingInformation?.country || ''
            values.Email = existingBillingInformation?.email || ''
            values.FirstName = existingBillingInformation?.firstName || ''
            values.LastName = existingBillingInformation?.lastName || ''
            values.ZipCode = existingBillingInformation?.postalCode || ''
            values.StateCode = existingBillingInformation?.state || ''
        }

        const { locationInfo }: LocationState = yield select(getLocationState)

        const calculatePriceValues: CalculatePriceDataType = {
            address: getBillingValues(values),
            currency: locationInfo.currency,
            couponCode: isCouponValid ? couponServerData?.couponCode : '',
            cart: getPaymentItems(
                selectedPlan,
                checkout.organizers,
                checkout.billingFrequency,
                selectedAddons,
                userType === USER_TYPES.ADDON
            ),
        }

        const calculatePriceCreation: CalculatePriceAPIReturnType = yield call(calculatePrice, calculatePriceValues)
        if (calculatePriceCreation && calculatePriceCreation.data) {
            const {
                errorMessages,
                productPrices,
                netAmount,
                grossAmount,
                taxAmount,
                anniversaryDate,
            }: CalculatePriceAPIData = calculatePriceCreation.data
            if (isEmpty(errorMessages)) {
                yield put(
                    checkoutActions.setCalculatedPrice(
                        productPrices,
                        netAmount,
                        grossAmount,
                        taxAmount,
                        anniversaryDate === null ? '' : (anniversaryDate as string)
                    )
                )
                // since the selected plan has an item id of 0 is the product prices array, we need to get the first item from productPrice
                // check getPaymentItems function above for more info
                const [planProductPrice] = productPrices.filter((productPrice) => productPrice.itemId === 0)
                const { volumeDiscount } = planProductPrice
                if (volumeDiscount) {
                    const { amount } = volumeDiscount
                    const calculatedVolumeDiscount: number = yield call(
                        getTotalVolumeDiscount,
                        amount,
                        checkout.organizers,
                        false
                    )
                    yield put(checkoutActions.setCurrentVolumeDiscountPriceAndPercentage(calculatedVolumeDiscount))
                }
                // if add-on user, calculate the prorated amount
                if (userType === USER_TYPES.ADDON) {
                    const calculatedProratedAmount = getTotalProratedAmount(
                        productPrices,
                        checkout.organizers,
                        selectedAddons
                    )
                    yield put(checkoutActions.setProratedAmount(calculatedProratedAmount))
                }
                // coupon discount for DID
                if (isCouponValid && couponProcessedDataPerSku && userType !== USER_TYPES.ADDON) {
                    const couponDoubleDipAmountForNewUser: number = yield call(
                        getCouponDoubleDipAmountForNewUser,
                        productPrices
                    )
                    yield put(couponActions.setCouponDoubleDipAmountForNewUser(couponDoubleDipAmountForNewUser))
                }
            } else {
                yield put(
                    gtcTrackingActions.track(
                        {
                            event: TRACKING_EVENTS.ERROR_PURCHASE,
                            eventData: { errorMessage: 'failed to get price data' },
                        },
                        componentName
                    )
                )
                yield put(modalActions.setErrorModal('Unable to get price data'))
            }
        }
        yield put(checkoutActions.setTotalLoading(false))
    } catch {
        yield put(
            gtcTrackingActions.track(
                { event: TRACKING_EVENTS.ERROR_PURCHASE, eventData: { errorMessage: 'error in calculating price' } },
                componentName
            )
        )
        yield put(fatalErrorActions.setFatalError(true, { trackErrorMessage: 'error in calculating price' }))
        yield put(checkoutActions.setTotalLoading(false))
    }
}

function* initializeCalculatePriceSaga() {
    yield takeLatest(CheckoutActionTypes.CALCULATE_PRICE, calculatePriceSaga)
}

export default initializeCalculatePriceSaga
