import React, { FunctionComponent, useEffect } from 'react'
import { FieldProps } from 'formik'
import { setCheckoutInlineError } from '@common/utils'

export interface InlineErrorProps {
    fieldData: FieldProps
    inlineErrors: { [key: string]: string } | Record<string, unknown>
    setErrorCall: (val: string) => void
    removeErrorCall: (val: string) => void
    ignoreInlineError?: boolean
    children: React.ReactNode
}

export const InlineErrorView: FunctionComponent<InlineErrorProps> = ({
    fieldData,
    inlineErrors,
    setErrorCall,
    removeErrorCall,
    ignoreInlineError,
    children,
}) => {
    const { field, form, meta } = fieldData
    useEffect(() => {
        setCheckoutInlineError(fieldData, inlineErrors, setErrorCall, removeErrorCall, ignoreInlineError)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [field, form, meta])

    return <>{children}</>
}

export default InlineErrorView
