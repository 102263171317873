/* eslint-disable no-underscore-dangle */
// import { useEffect, useState } from 'react'
// import { useLoadScript } from '@common/hooks'

// BoldChat Customer Monitor HTML v5.00 (Website=GoTo.com - Nano,ChatButton=C&C/GoTo.com/GTC/NA,ChatInvitation=C&C - GoTo.com - AI)
export const useBoldChat = (/* URL: string */) => {
    // only load the boldchat script if the pageViewer doesn't exist on the window object
    /* const [shouldLoad, setShouldLoad] = useState(true)

    useEffect(() => {
        setShouldLoad(false)
    }, [])

    useLoadScript(`${URL}.js`, shouldLoad)
    */
    return undefined
}
