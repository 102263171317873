import { FormikProps } from 'formik'
import { useEffect } from 'react'

export const useClearField = <T>(
    shouldClearField: boolean,
    formikRef: React.RefObject<FormikProps<T>>,
    fieldName: string
) => {
    // clear email
    useEffect(() => {
        if (shouldClearField && formikRef.current) {
            formikRef.current.setFieldValue(fieldName, '')
            formikRef.current.setFieldTouched(fieldName, false)
        }
    }, [shouldClearField, fieldName, formikRef])
}
