import {
    deleteNumbersReservation,
    DeleteNumbersReservationRequest,
    DeleteNumbersReservationResponse,
    numbersApiUserId,
    NumbersOrderRequest,
    NumbersOrderResponse,
    orderNumbers,
    PhoneNumber,
    reserveNumbers,
    ReserveNumbersRequest,
    ReserveNumbersResponse,
} from '@gtc/services/numbersApi'
import { checkoutActions } from '@gtc/state/checkout/actions'
import { CheckoutState } from '@gtc/state/checkout/state'
import { LabelContent } from '@gtc/state/label-content'
import { getCheckoutState, getLabelContent } from '@gtc/state/selectors'
import { AxiosResponse } from 'axios'
import { call, put, select } from 'redux-saga/effects'

export function* deleteReservation(phoneNumberReservationId: string) {
    try {
        const requestData: DeleteNumbersReservationRequest = {
            reservationIds: [phoneNumberReservationId],
            userId: numbersApiUserId,
        }
        const response: AxiosResponse = yield call(deleteNumbersReservation, requestData)
        const responseData = response.data as DeleteNumbersReservationResponse
        if (!Array.isArray(responseData)) {
            throw new Error('Unknown error deleting phone number reservation')
        }
        yield put(checkoutActions.setPhoneNumberReservationId(''))
    } catch (e) {
        // TODO: NumberPicker: Track the error.
    }
}

export function* reservePhoneNumber(phoneNumber: PhoneNumber, vanityPhrase: string) {
    const labelContent: LabelContent = yield select(getLabelContent)
    try {
        yield put(checkoutActions.setReservingPhoneNumberError(''))
        const requestData: ReserveNumbersRequest = {
            phoneNumbers: [phoneNumber.e164Number],
            userId: numbersApiUserId,
        }
        const response: AxiosResponse = yield call(reserveNumbers, requestData)
        const responseData = response.data as ReserveNumbersResponse
        if (!Array.isArray(responseData)) {
            throw new Error() // See catch block below
        }
        const reservationId = responseData[0].id
        if (reservationId) {
            yield put(checkoutActions.setPhoneNumber(phoneNumber))
            yield put(checkoutActions.setPhoneNumberReservationId(reservationId))
            yield put(checkoutActions.setPhoneNumberVanityPhrase(vanityPhrase))
        } else {
            // If the reservation didn't succeed, it's possible that someone else already reserved this number
            const errorMessage = labelContent.numberPickerPhoneNumberNoLongerAvailable
            yield put(checkoutActions.setReservingPhoneNumberError(errorMessage))
        }
    } catch (e) {
        // TODO: NumberPicker: Track the error
        const errorMessage = labelContent.internalErrorMessage
        yield put(checkoutActions.setReservingPhoneNumberError(errorMessage))
    }
}

export function* orderPhoneNumber() {
    const { phoneNumber, phoneNumberReservationId }: CheckoutState = yield select(getCheckoutState)

    if (!phoneNumber) return

    const requestData: NumbersOrderRequest = {
        phoneNumbersToOrder: [
            {
                phoneNumber: phoneNumber.e164Number,
                reservationId: phoneNumberReservationId,
            },
        ],
        isTollfreeOutbound: true, // isTollfreeOutbound is an old property and should always be true now
        userId: numbersApiUserId,
    }

    try {
        const response: AxiosResponse = yield call(orderNumbers, requestData)
        const responseData = response.data as NumbersOrderResponse
        if (responseData.errorMessage || !Array.isArray(responseData.numbersOrdered)) {
            throw new Error()
        }
    } catch (e) {
        // TODO: NumberPicker: Track the error

        // Release the number so it will be available to choose again from the Admin Panel
        yield call(deleteReservation, phoneNumberReservationId)

        // Show a modal
        yield put(checkoutActions.setDidFailToOrderPhoneNumber(true))
    }
}
