/* eslint-disable react/require-default-props */
import { indexOfVanityPhrase } from '@gtc/utils/index-of-vanity-phrase'
import React from 'react'
import classNames from 'classnames'
import styles from './FormattedNumber.module.css'

interface IProps {
    phoneNumber: string
    vanityPhrase?: string
    modifier?: string
    showVanityLetters?: boolean
    qaTag?: string
}

export const FormattedPhoneNumber = (props: IProps) => {
    const { phoneNumber, vanityPhrase = '', modifier = '', showVanityLetters = false, qaTag = '' } = props
    const indexOfPhrase = indexOfVanityPhrase(phoneNumber, vanityPhrase)
    const vanityPhraseArray = vanityPhrase.toUpperCase().split('')

    return (
        <span className={modifier} data-qat={qaTag}>
            {phoneNumber.split('').map((digit, index) => {
                const shouldHighlightCharacter =
                    indexOfPhrase > -1 && index >= indexOfPhrase && index < indexOfPhrase + vanityPhrase.length
                const shouldInsertSpaceBeforeCharacter = index === 2 || index === 5 || index === 8
                const character =
                    shouldHighlightCharacter && showVanityLetters
                        ? vanityPhraseArray[index - indexOfPhrase] // show the vanity phrase letter
                        : digit // else show the original digit

                const className = classNames({
                    [styles.highlight]: shouldHighlightCharacter,
                    [styles.spaceBefore]: shouldInsertSpaceBeforeCharacter,
                })

                return (
                    <span className={className} key={`${qaTag}_${index.toString()}`}>
                        {character}
                    </span>
                )
            })}
        </span>
    )
}
