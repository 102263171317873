import React, { FunctionComponent, ButtonHTMLAttributes } from 'react'
import classNames from 'classnames'
import { Theme } from '@common/constants/theme-types'
import { CommonButton } from '../CommonButton'
import { LoadingSpinner } from '../LoadingSpinner'
import styles from './form-submit-button.module.css'

type Purpose = 'primary' | 'secondary'

export interface FormSubmitButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    purpose?: Purpose
    modifier?: string
    theme?: Theme
    text: string
    isSubmitting: boolean
    qaTag?: string
}

export const FormSubmitButton: FunctionComponent<FormSubmitButtonProps> = ({
    purpose = 'primary',
    modifier = '',
    theme = 'default',
    text,
    isSubmitting,
    ...rest
}) => {
    const submitClasslist = classNames({
        [styles.submit]: true,
        [styles['submit--submitting']]: isSubmitting,
        [modifier]: modifier,
    })

    return (
        <CommonButton modifier={submitClasslist} purpose={purpose} theme={theme} type="submit" {...rest}>
            {isSubmitting ? <LoadingSpinner disabled inline /> : text}
        </CommonButton>
    )
}
