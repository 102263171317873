import { ResolveSelectedAddon } from '@gtresolve/state/add-on'
import { ResolvePlan } from '@gtresolve/state/plan'
import { ResolveItems } from '@gtresolve/state/checkout/state'
import { BillingDetailedSubscriptionPlan } from '@common/state/billing/state'
import { PRODUCT_SKU, BILLING_TYPES } from '@common/constants'
import { ADDONS } from '@gtresolve/constants'
import { SubscriptionInfo } from './get-subscription-info'

export const getPaymentItems = (
    selectedPlan: ResolvePlan,
    organizers: number,
    selectedBillingFrequency: string,
    selectedAddons: ResolveSelectedAddon[],
    subscriptionInfo: SubscriptionInfo
) => {
    const items: ResolveItems[] = []
    const billingFrequency = selectedBillingFrequency === 'YEAR' ? BILLING_TYPES.ANNUAL : BILLING_TYPES.MONTHLY
    const contractLengthInMonth = billingFrequency === BILLING_TYPES.ANNUAL ? 12 : 1

    const planSubscription: ResolveItems = {
        itemId: 0,
        productSku: selectedPlan.sKU,
        paymentPlan: billingFrequency,
        quantity: organizers,
        contractLengthInMonth,
        subscriptionsToUpgrade: null,
    }

    if (subscriptionInfo.plan && subscriptionInfo.isReplacementBooking && !subscriptionInfo.isTrial) {
        if (!subscriptionInfo.canUpgradePlan) {
            planSubscription.productSku = subscriptionInfo.plan.productSku
            planSubscription.quantity = subscriptionInfo.plan.quantity
        }

        planSubscription.subscriptionsToUpgrade = [
            {
                subscriptionId: subscriptionInfo.plan.subscriptionId,
                quantity: subscriptionInfo.plan.quantity,
            },
        ]

        subscriptionInfo.addons.forEach((addon: BillingDetailedSubscriptionPlan) => {
            planSubscription.subscriptionsToUpgrade?.push({
                subscriptionId: addon.subscriptionId,
                quantity: addon.quantity,
            })
        })
    }

    if (subscriptionInfo.isReplacementBooking) items.push(planSubscription)

    // add addon products to the items object
    if (selectedAddons.length > 0) {
        selectedAddons.forEach((addon: ResolveSelectedAddon) => {
            const v2plan = items.find(
                (item) =>
                    (item.productSku === PRODUCT_SKU.GTR_REMOTE_SUPPORT_NAMED_V2 ||
                        item.productSku === PRODUCT_SKU.GTR_REMOTE_ACCESS) &&
                    !item.subscriptionsToUpgrade
            )
            if (v2plan && addon.sKU.includes(ADDONS.AGENT)) {
                v2plan.quantity += addon.quantity
            } else {
                items.push({
                    itemId: items.length,
                    productSku: addon.sKU,
                    paymentPlan: billingFrequency,
                    quantity: addon.quantity,
                    contractLengthInMonth,
                    subscriptionsToUpgrade: null,
                })
            }
        })
    }

    return items
}
