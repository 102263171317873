import React, { FunctionComponent } from 'react'

import { GenericInput } from './GenericInput'
import { MaskedInputProps, MaskedInput } from './MaskedInput'

export interface InputProps extends MaskedInputProps {
    ismasked?: boolean
}

export const Input: FunctionComponent<InputProps> = ({ ismasked, ...rest }) => {
    return ismasked ? <MaskedInput {...rest} /> : <GenericInput {...rest} />
}
