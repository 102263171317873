import { ResolveContainerAppState } from '@gtresolve/store'
// we need to complete this file, as we copy reducers from connect

export const getLabelContent = (state: ResolveContainerAppState) => state.resolve.content.labelContent
export const getCheckoutState = (state: ResolveContainerAppState) => state.resolve.checkout
export const getPlanData = (state: ResolveContainerAppState) => state.resolve.content.planData
export const getContentState = (state: ResolveContainerAppState) => state.resolve.content
export const getContentData = (state: ResolveContainerAppState) => state.resolve.content.contentData
export const getUserState = (state: ResolveContainerAppState) => state.resolve.user
export const getBuyFormState = (state: ResolveContainerAppState) => state.resolve.form
export const getCouponState = (state: ResolveContainerAppState) => state.resolve.coupon
