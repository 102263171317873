import { takeEvery, call } from 'redux-saga/effects'
import { buyTrackingActions, BuyTrackingActionTypes } from '@buy/state/tracking/actions'
import { trackCTAClick, trackEvent, trackGenericEvent, trackGenericFormEvent } from '@common/utils'
import { PRODUCT_FAMILY_KEY, TRACKING_EVENTS } from '@common/constants'
import {
    trackBeginCheckout,
    trackCompleteBuy,
    trackCustomizeViewItem,
    trackFormError,
    trackPurchase,
    trackPurchaseError,
    trackSessionInfo,
    trackViewContent,
    trackUserInformation,
    trackEmailValidation,
} from '@buy/utils'
import { BuyCartData, BuyPurchaseData, BuyCustomizeItemsData } from '@buy/state/tracking/state'
import { CTAClickData, PageViewContentData, SessionInfo } from '@common/state/tracking/state'
import {
    getCartInfo,
    getCompleteBuyData,
    getCustomizeItemsData,
    getPageViewData,
    getPurchaseData,
    getSessionInfo,
} from './track-helpers'

export function* buyTrackingSaga(action: ReturnType<typeof buyTrackingActions.track>) {
    const { trackingInfo } = action.payload
    const eventName = trackingInfo.event
    const trackData = trackingInfo.eventData || {}

    const errorMessage = (trackData.errorMessage as string) || ''
    const formErrors = (trackData.formErrors as string[]) || ['']
    const formIds = (trackData.formIds as string[]) || ['']
    const step = (trackData.step as number) || 0
    const ctaClickData: CTAClickData = {
        name: (trackData.name as string) || '',
        url: (trackData.url as string) || '',
    }
    const formName = (trackData.formName as string) || ''
    const pathName = (trackData.currentLocation as string) || ''
    const invoiceNumber = (trackData.invoiceNumber as string) || ''
    const userEmail: string = (trackData.userEmail as string) || ''
    const plan: string = (trackData.plan as string) || ''
    const buyType: string = (trackData.buyType as string) || ''

    switch (eventName) {
        case TRACKING_EVENTS.VIEW_ITEM: {
            const customizeItemsData: BuyCustomizeItemsData = yield call(getCustomizeItemsData)
            trackCustomizeViewItem(customizeItemsData)
            break
        }
        case TRACKING_EVENTS.VIEW_CONTENT: {
            const viewContentData: PageViewContentData = yield call(getPageViewData)
            trackViewContent(viewContentData, pathName)
            break
        }
        case TRACKING_EVENTS.SESSION_INFORMATION: {
            const sessionData: SessionInfo = yield call(getSessionInfo)
            trackSessionInfo(sessionData)
            break
        }
        case TRACKING_EVENTS.USER_INFORMATION: {
            const cartData: BuyCartData = yield call(getCartInfo)
            const completeBuyData: string = yield call(getCompleteBuyData)
            trackUserInformation(completeBuyData, userEmail, cartData)
            break
        }
        case TRACKING_EVENTS.BEGIN_CHECKOUT: {
            const cartData: BuyCartData = yield call(getCartInfo)
            trackBeginCheckout(cartData, step)
            break
        }
        case TRACKING_EVENTS.PURCHASE: {
            const cartData: BuyCartData = yield call(getCartInfo)
            const purchaseData: BuyPurchaseData = yield call(getPurchaseData, invoiceNumber)
            trackPurchase(cartData, purchaseData)
            break
        }
        case TRACKING_EVENTS.ERROR_PURCHASE: {
            trackPurchaseError(errorMessage, plan, buyType)
            break
        }
        case TRACKING_EVENTS.EMAIL_VALIDATION: {
            trackEmailValidation(formName, userEmail)
            break
        }
        case TRACKING_EVENTS.COMPLETE_BUY_FLOW: {
            const cartData: BuyCartData = yield call(getCartInfo)
            const completeBuyData: string = yield call(getCompleteBuyData)
            trackCompleteBuy(completeBuyData, userEmail, cartData)
            break
        }
        case TRACKING_EVENTS.START_CONTACT_SALES_FLOW: {
            trackGenericEvent(eventName, PRODUCT_FAMILY_KEY.G2R, pathName)
            break
        }
        case TRACKING_EVENTS.LOAD_FORM: {
            trackGenericFormEvent(eventName, pathName)
            break
        }
        case TRACKING_EVENTS.START_FORM: {
            trackGenericFormEvent(eventName, pathName)
            break
        }
        case TRACKING_EVENTS.ABANDON_FORM: {
            trackGenericFormEvent(eventName, pathName)
            break
        }
        case TRACKING_EVENTS.SUBMIT_FORM: {
            trackGenericFormEvent(eventName, pathName)
            break
        }
        case TRACKING_EVENTS.ERROR_FORM: {
            trackFormError(formIds, formErrors, pathName)
            break
        }
        case TRACKING_EVENTS.CLICK_CTA: {
            trackCTAClick(ctaClickData)
            break
        }
        default: {
            yield call(trackEvent, { event: eventName, eventData: trackData })
        }
    }
}

function* initializeBuyTrackingSaga() {
    yield takeEvery(BuyTrackingActionTypes.TRACK, buyTrackingSaga)
}

export default initializeBuyTrackingSaga
