import { call, put, takeEvery } from 'redux-saga/effects'
import { CheckoutActionTypes } from '@gtc/state/checkout/actions'
import { getAvailablePaymentMethods } from '@gtc/services'
import { PaymentDataReturnType } from '@gtc/state/checkout/state'
import { fatalErrorActions } from '@common/state/fatal-error/actions'
import { TRACKING_EVENTS } from '@common/constants'
import { gtcTrackingActions } from '@gtc/state/tracking/actions'
import { TRACKING_COMPONENTS } from '@gtc/constants'
import { GenericResponse } from '@common/state/generic-response/generic-response'

export function* getAvailablePaymentMethodsSaga() {
    const componentName = TRACKING_COMPONENTS.GET_AVAILABLE_PAYMENT_METHODS_SAGA
    try {
        const paymentMethodsCreation: GenericResponse = yield call(getAvailablePaymentMethods)
        if (paymentMethodsCreation.data) {
            const paymentData: PaymentDataReturnType[] = paymentMethodsCreation.data
            return paymentData
        }
    } catch (e) {
        yield put(
            gtcTrackingActions.track(
                {
                    event: TRACKING_EVENTS.ERROR_PURCHASE,
                    eventData: { errorMessage: 'failed to get available payment methods' },
                },
                componentName
            )
        )
        yield put(
            fatalErrorActions.setFatalError(true, { trackErrorMessage: 'failed to get available payment methods' })
        )
    }
    return null
}

function* initializeGetAvailablePaymentMethodsSaga() {
    yield takeEvery(CheckoutActionTypes.GET_AVAILABLE_PAYMENT_METHODS, getAvailablePaymentMethodsSaga)
}

export default initializeGetAvailablePaymentMethodsSaga
