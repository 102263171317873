import { EmailVerificationType } from '@common/services'
import { ActionUnion, createAction } from '@common/utils'
import { BillingDetailedSubscriptionPlan } from '../billing/state'

export enum AccountActionTypes {
    SET_EXISTING_ACCOUNT_SUBSCRIPTIONS = '[account-actions] set-existing-account-subscriptions',
    UPDATE_ACCOUNT_EMAIL_VERIFICATION = '[account-actions] update-email-verification',
    SET_LOADING_VERIFY_USER = '[account-actions] set-loading-verify-user',
}

export const accountActions = {
    setAccountSubscriptions: (subscriptions: BillingDetailedSubscriptionPlan[]) =>
        createAction(AccountActionTypes.SET_EXISTING_ACCOUNT_SUBSCRIPTIONS, { subscriptions }),
    updateAccountEmailVerification: (emailVerificationInfo: EmailVerificationType) =>
        createAction(AccountActionTypes.UPDATE_ACCOUNT_EMAIL_VERIFICATION, { emailVerificationInfo }),
    // TODO: Migrate gtc/userActions and gtResolve/userActions into accountActions
}

export type AccountActions = ActionUnion<typeof accountActions>
