import { Addon, SelectedAddon } from '@gtc/state/add-on'
import { Plan } from '@gtc/state/plan'

export const getCurrentAddons = (selectedAddons: SelectedAddon[], selectedPlan: Plan): Addon[] => {
    const { addons } = selectedPlan
    const myAddons = selectedAddons.reduce((mySelectedAddons: Addon[], localAddon: SelectedAddon) => {
        const matchingAddon = addons.find((add: Addon) => add.sKU === localAddon.sKU)
        if (matchingAddon) {
            matchingAddon.quantity = localAddon.quantity
            return [...mySelectedAddons, matchingAddon]
        }

        return mySelectedAddons
    }, [])
    return myAddons
}
