import { call, takeEvery, put, select } from 'redux-saga/effects'
import { fatalErrorActions } from '@common/state/fatal-error/actions'

import { lmiproCheckoutActions, LmiProCheckoutActionTypes } from '@lmipro/state/checkout/actions'
import { submitPayment } from '@lmipro/services'
import { TRACKING_EVENTS } from '@common/constants'
import { lmiproTrackingActions } from '@lmipro/state/tracking/actions'
import { LMIPRO_TRACKING_COMPONENTS } from '@lmipro/constants'
import { FlexState } from '@common/state/flex/state'
import { getFlexState } from '@common/state/selectors'
import { FATAL_ERROR_TYPE } from '@common/state/fatal-error/state'
import { GenericResponse } from '@common/state/generic-response/generic-response'

export function* submitPaymentSaga(action: ReturnType<typeof lmiproCheckoutActions.submitPayment>) {
    const componentName = LMIPRO_TRACKING_COMPONENTS.SUBMIT_PAYMENT_SAGA
    try {
        const { submitValues } = action.payload
        const submitCreation: GenericResponse = yield call(submitPayment, submitValues)
        if (submitCreation && submitCreation.data) {
            return submitCreation.data
        }
    } catch {
        const { keyInfo }: FlexState = yield select(getFlexState)
        yield put(
            lmiproTrackingActions.track(
                {
                    event: TRACKING_EVENTS.ERROR_PURCHASE,
                    eventData: {
                        errorMessage: 'failed to submit payment',
                    },
                },
                componentName
            )
        )
        yield put(
            fatalErrorActions.setFatalError(true, {
                trackErrorMessage: 'failed to submit payment',
                fatalErrorType: keyInfo?.scaToken?.isScaEnabledForMid ? FATAL_ERROR_TYPE.CARDINAL_PAYMENT : undefined,
            })
        )
    }
    return null
}

function* initializeSubmitPaymentSaga() {
    yield takeEvery(LmiProCheckoutActionTypes.SUBMIT_PAYMENT, submitPaymentSaga)
}

export default initializeSubmitPaymentSaga
