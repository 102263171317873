import { useGTM } from '@common/hooks'
import React, { FunctionComponent } from 'react'

export interface LoadAnalyticScripts {
    gtmProductKey: string
}

export const LoadAnalyticScripts: FunctionComponent<LoadAnalyticScripts> = (props) => {
    useGTM(props.gtmProductKey)
    return <></>
}
