import { CURRENCY } from '@common/constants'
import { LocationState } from '@common/state/location/state'
import { buyCheckoutActions, BuyCheckoutActionTypes } from '@buy/state/checkout/actions'
import { BuyBillingFrequencyVolumeDiscountRecords, BuyCheckoutState } from '@buy/state/checkout/state'
import { BuyPlan } from '@buy/state/plan'
import { getCheckoutState } from '@buy/state/selectors'
import { calculateVolumeDiscount } from '@buy/utils'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import { call } from 'redux-saga-test-plan/matchers'
import { put, select, takeEvery } from 'redux-saga/effects'
import { getLocationState } from '@common/state/selectors'
import { getSelectedPlan } from '../content/content-helpers'

export type CalculateVolumeDiscountSagaTriggerActionType =
    | ReturnType<typeof buyCheckoutActions.calculateVolumeDiscount>
    | ReturnType<typeof buyCheckoutActions.setSelectedPlan>

function* calculateVolumeDiscountSaga(action: CalculateVolumeDiscountSagaTriggerActionType) {
    yield put(buyCheckoutActions.setVolumeDiscountLoading(true))
    const selectedPlanSku =
        action.type === BuyCheckoutActionTypes.SET_SELECTED_PLAN ? action.payload.planSku : undefined
    const { locationInfo }: LocationState = yield select(getLocationState)
    const { currency: selectedCurrency = CURRENCY.USD } = locationInfo
    const selectedPlan: BuyPlan = yield call(getSelectedPlan, selectedPlanSku)
    const { volumeDiscount }: BuyCheckoutState = yield select(getCheckoutState)
    if (selectedPlanSku) {
        const buyBillingFrequencyVolumeDiscountRecords = get(volumeDiscount, [selectedPlanSku, selectedCurrency])
        // skip the calculation if the checkout store already has selected plan volume discount data in the required currency
        if (isEmpty(buyBillingFrequencyVolumeDiscountRecords)) {
            const volumeDiscountForSelectedPlanAndCurrency: BuyBillingFrequencyVolumeDiscountRecords = yield call(
                calculateVolumeDiscount,
                selectedPlan,
                selectedCurrency
            )
            yield put(
                buyCheckoutActions.setVolumeDiscount(
                    selectedPlan?.sKU,
                    selectedCurrency,
                    volumeDiscountForSelectedPlanAndCurrency
                )
            )
        }
    }
    yield put(buyCheckoutActions.setVolumeDiscountLoading(false))
}

function* initializeCalculateVolumeDiscount() {
    yield takeEvery(
        [BuyCheckoutActionTypes.CALCULATE_VOLUME_DISCOUNT, BuyCheckoutActionTypes.SET_SELECTED_PLAN],
        calculateVolumeDiscountSaga
    )
    /* note: we need to add currency here, if the user can change currency,
    that would automatically trigger this saga to calculate the volume discount
    for the selected plan in that currency.
    */
}

export default initializeCalculateVolumeDiscount
