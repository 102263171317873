import { call, put, delay, takeLatest } from 'redux-saga/effects'
import { AddressDataType } from '@common/state/location/state'
import { fatalErrorActions } from '@common/state/fatal-error/actions'
import commonServices from '@common/services'
import { locationActions, LocationActionTypes } from '@common/state/location/actions'
import { locationInitialData } from '@common/data'
import { GenericResponse } from '@common/state/generic-response/generic-response'

export function* validateAddressSaga(action: ReturnType<typeof locationActions.validateAddress>) {
    const { values } = action.payload
    let isAddressValid = true

    const addressObj: AddressDataType = {
        firstName: values.FirstName,
        lastName: values.LastName,
        email: values.Email,
        address: {
            addressLine1: values.Street1,
            city: values.City,
            postalCode: values.ZipCode.substr(0, 10),
            state: values.StateCode,
            country: values.CountryCode,
            phoneNumber: values.ContactPhone,
        },
        exportCompliance: true,
        validateAddress: true,
    }

    try {
        yield put(locationActions.setIsValidatingAddress(true))
        yield delay(1000)
        const validateResponse: GenericResponse = yield call(commonServices.getValidAddress, addressObj)
        // only take action if and only if api response returns 'INVALID' or 'EXPORT_FAILED'
        if (validateResponse && validateResponse.data) {
            switch (validateResponse.data.status) {
                case 'VALID':
                case 'INVALID': // WAE1-1798: Treat INVALID address as if it were VALID
                    yield put(locationActions.setValidAddress(true))
                    yield put(locationActions.setAddressErrors(locationInitialData.addressErrors))
                    break
                case 'VALID_UPDATED':
                    yield put(locationActions.setValidAddress(true))
                    yield put(locationActions.setCorrectedAddress(validateResponse.data.correctedAddress))
                    yield put(locationActions.setAddressErrors(locationInitialData.addressErrors))
                    break
                case 'EXPORT_FAILED':
                    yield put(locationActions.setValidAddress(false))
                    yield put(locationActions.setCorrectedAddress(locationInitialData.correctedAddress))
                    yield put(locationActions.setAddressErrors(validateResponse.data.errors))
                    isAddressValid = false
                    break
                default:
                    yield put(locationActions.setValidAddress(true))
                    break
            }
        }
    } catch (e) {
        // fail gracefully if any server issues arise
        yield put(locationActions.setValidAddress(true))
        yield put(fatalErrorActions.setFatalError(false, { trackErrorMessage: 'validate address failed' }))
    } finally {
        yield put(locationActions.setIsValidatingAddress(false))
    }

    return isAddressValid
}

function* initializeValidateAddressSaga() {
    yield takeLatest(LocationActionTypes.VALIDATE_ADDRESS, validateAddressSaga)
}

export default initializeValidateAddressSaga
