import { ValidationResponse } from '@common/state/flex/state'
import React, { FunctionComponent, useEffect, useState } from 'react'
import { getFlexMicroform } from '@common/utils'
import classNames from 'classnames'
import styles from './flex-field.css'

interface FlexFieldProps {
    id: string
    flexFieldType: string
    wrapperClass: string
    labelCopy: string
    valid: boolean
    hasError: boolean
    errorCopyEmpty: string
    errorCopyNotEmpty: string
    microformCreated: boolean
    onChange: (valid: boolean) => void
    onBlur: () => void
    shouldRefresh: boolean
}

export const FlexField: FunctionComponent<FlexFieldProps> = (props) => {
    const [needToRefresh, setNeedToRefresh] = useState(props.shouldRefresh)
    const [isFieldFocused, setIsFieldFocused] = useState(false)
    const [hasValue, setHasValue] = useState(false)
    const [hasMicroformAttachedEvents, setHasMicroformAttachedEvents] = useState(false)

    const displayErrorUI = !isFieldFocused && props.hasError

    // update the internal state to props.shouldRefresh
    useEffect(() => {
        setNeedToRefresh(props.shouldRefresh)
    }, [props.shouldRefresh])

    // Refresh the flex field
    useEffect(() => {
        if (needToRefresh && hasMicroformAttachedEvents) {
            setHasValue(false)
            setHasMicroformAttachedEvents(false)
            setNeedToRefresh(false)
        }
    }, [hasMicroformAttachedEvents, needToRefresh])

    // Create flex field and attach events
    useEffect(() => {
        const microformInstance = getFlexMicroform()
        if (props.microformCreated && microformInstance && !hasMicroformAttachedEvents) {
            const field = microformInstance.createField(props.flexFieldType)
            field.load(`#${props.id}`)

            field.on('change', (resp?: ValidationResponse) => {
                setHasValue(!resp?.empty)
                props.onChange(!!resp?.valid)
            })

            field.on('focus', () => {
                setIsFieldFocused(true)
            })

            field.on('blur', () => {
                setIsFieldFocused(false)
                if (props.onBlur) {
                    props.onBlur()
                }
            })

            setHasMicroformAttachedEvents(true)
        }
    }, [hasMicroformAttachedEvents, props, needToRefresh])

    const classList = classNames({
        [styles['ff-label']]: true,
        [styles['ff-label--focus']]: isFieldFocused,
        [styles['ff-label--has-value']]: hasValue,
        [styles['ff-label--error']]: displayErrorUI,
    })

    return (
        <>
            <div className={props.wrapperClass} aria-labelledby={`${props.id}-label`}>
                <div className={classList} id={`${props.id}-label`}>
                    {props.labelCopy}
                </div>
                <div
                    className={`${styles['flex-field']} ${isFieldFocused ? styles['flex-field--focus'] : ''}
                        ${displayErrorUI ? styles['flex-field--error'] : ''}
                        ${props.valid ? styles['flex-field--valid'] : ''}`}
                    id={props.id}
                />
            </div>
            {displayErrorUI && (
                <div className={styles['ff-error-message']}>
                    {hasValue ? props.errorCopyNotEmpty : props.errorCopyEmpty}
                </div>
            )}
        </>
    )
}
