import { combineReducers } from 'redux'
import { contentReducer } from '@gtc/state/content/reducer'
import { checkoutReducer } from '@gtc/state/checkout/reducer'
import { buyFormReducer } from '@gtc/state/buy-form/reducer'
import { userReducer } from '@gtc/state/user/reducer'
import { couponReducer } from '@gtc/state/coupon/reducer'

export const connectAppReducer = combineReducers({
    content: contentReducer,
    checkout: checkoutReducer,
    form: buyFormReducer,
    user: userReducer,
    coupon: couponReducer,
})

export type ConnectAppState = ReturnType<typeof connectAppReducer>

export type ConnectContainerAppState = {
    connect: ConnectAppState
}
