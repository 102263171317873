import { resolveCheckoutActions, ResolveCheckoutActionTypes } from '@gtresolve/state/checkout/actions'
import { ResolveCheckoutState } from '@gtresolve/state/checkout/state'
import { getCheckoutState } from '@gtresolve/state/selectors'
import { select } from 'redux-saga-test-plan/matchers'
import { put, takeLatest } from 'redux-saga/effects'

function* setBillingFrequencySaga(action: ReturnType<typeof resolveCheckoutActions.setBillingFrequency>) {
    const { frequency: billingFrequency } = action.payload
    const { organizers, selectedTierIndex }: ResolveCheckoutState = yield select(getCheckoutState)
    yield put(resolveCheckoutActions.getVolumeDiscount(organizers, selectedTierIndex, billingFrequency))
}

function* initializeSetBillingFrequencySaga() {
    yield takeLatest(ResolveCheckoutActionTypes.SET_BILLING_FREQUENCY, setBillingFrequencySaga)
}

export default initializeSetBillingFrequencySaga
