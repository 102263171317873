import { createStore, combineReducers, applyMiddleware, StoreEnhancer } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { composeWithDevTools } from 'redux-devtools-extension'
import { commonAppReducer } from '@common/store'
import commonSagas from '@common/sagas'
import { createThrottleMiddleware } from '@common/utils'
import { connectAppReducer, gtcThrottle } from '@gtc/store'
import gtcsagas from '@gtc/saga'
import gtResolveSagas from '@gtresolve/saga'
import lmiProSagas from '@lmipro/saga'
import { resolveAppReducer, gtResolveThrottle } from '@gtresolve/store'
import { lmiProAppReducer, lmiProThrottle } from '@lmipro/store'
import { PLATFORM } from '@common/constants'
import { buyAppReducer, buyThrottle } from '@buy/store'
import buySagas from '@buy/saga'

const createReducer = () => {
    return combineReducers({
        common: commonAppReducer,
        connect: connectAppReducer,
        resolve: resolveAppReducer,
        lmipro: lmiProAppReducer,
        buy: buyAppReducer,
    })
}

const sagaMiddleware = createSagaMiddleware({
    onError: (error, { sagaStack }) => {
        console.error(sagaStack)
        console.error(error.message)
    },
})

const throttleMiddleware = createThrottleMiddleware([gtcThrottle, gtResolveThrottle, lmiProThrottle, buyThrottle])

const middlewares = [sagaMiddleware, throttleMiddleware]

const composeEnhancers = composeWithDevTools({
    // Specify custom devTools options
    trace: true,
})
const enhancer: StoreEnhancer =
    process.env.ENVIRONMENT === PLATFORM.DEV
        ? composeEnhancers(applyMiddleware(...middlewares))
        : applyMiddleware(...middlewares)

const appReducer = createReducer()

export const appStore = createStore(appReducer, undefined, enhancer)

const appSagas = { ...gtcsagas, ...gtResolveSagas, ...lmiProSagas, ...commonSagas, ...buySagas }

const runSagas = () => {
    Object.values(appSagas).forEach((saga) => {
        sagaMiddleware.run(saga)
    })
}
runSagas()

export type AppState = ReturnType<typeof appReducer>
