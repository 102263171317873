import { CURRENCY } from '@common/constants'
import { LocationState } from '@common/state/location/state'
import { checkoutActions, CheckoutActionTypes } from '@gtc/state/checkout/actions'
import { BillingFrequencyVolumeDiscountRecords, CheckoutState } from '@gtc/state/checkout/state'
import { Plan } from '@gtc/state/plan'
import { getCheckoutState } from '@gtc/state/selectors'
import { calculateVolumeDiscount } from '@gtc/utils'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import { call } from 'redux-saga-test-plan/matchers'
import { put, select, takeEvery } from 'redux-saga/effects'
import { getLocationState } from '@common/state/selectors'
import { getSelectedPlan } from '../content/content-helpers'

export type CalculateVolumeDiscountSagaTriggerActionType =
    | ReturnType<typeof checkoutActions.calculateVolumeDiscount>
    | ReturnType<typeof checkoutActions.setSelectedPlan>

function* calculateVolumeDiscountSaga(action: CalculateVolumeDiscountSagaTriggerActionType) {
    yield put(checkoutActions.setVolumeDiscountLoading(true))
    const selectedPlanSku = action.type === CheckoutActionTypes.SET_SELECTED_PLAN ? action.payload.planSku : undefined
    const { locationInfo }: LocationState = yield select(getLocationState)
    const { currency: selectedCurrency = CURRENCY.USD } = locationInfo
    const selectedPlan: Plan = yield call(getSelectedPlan, selectedPlanSku)
    const { volumeDiscount }: CheckoutState = yield select(getCheckoutState)
    if (selectedPlanSku) {
        const billingFrequencyVolumeDiscountRecords = get(volumeDiscount, [selectedPlanSku, selectedCurrency])
        // skip the calculation if the checkout store already has selected plan volume discount data in the required currency
        if (isEmpty(billingFrequencyVolumeDiscountRecords)) {
            const volumeDiscountForSelectedPlanAndCurrency: BillingFrequencyVolumeDiscountRecords = yield call(
                calculateVolumeDiscount,
                selectedPlan,
                selectedCurrency
            )
            yield put(
                checkoutActions.setVolumeDiscount(
                    selectedPlan.sKU,
                    selectedCurrency,
                    volumeDiscountForSelectedPlanAndCurrency
                )
            )
        }
    }
    yield put(checkoutActions.setVolumeDiscountLoading(false))
}

function* initializeCalculateVolumeDiscount() {
    yield takeEvery(
        [CheckoutActionTypes.CALCULATE_VOLUME_DISCOUNT, CheckoutActionTypes.SET_SELECTED_PLAN],
        calculateVolumeDiscountSaga
    )
    /* note: we need to add currency here, if the user can change currency,
    that would automatically trigger this saga to calculate the volume discount
    for the selected plan in that currency.
    */
}

export default initializeCalculateVolumeDiscount
