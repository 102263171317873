import { isBuyApp, saveSessionState as saveCommonSessionState } from '@common/utils'
import { saveSessionState, saveState } from '@buy/utils'
import { AppState } from 'app-store'
import { MiddlewareAPI } from 'redux'
import { STORAGE_CONST } from '@common/constants'

export const buyThrottle = (store: MiddlewareAPI) => {
    // TODO : load isBuyApp based on product config
    if (isBuyApp()) {
        const appState: AppState = store.getState()
        const gtBuyStore = appState.buy
        const commonStore = appState.common
        const {
            organizers,
            billingFrequency,
            selectedPlanSku,
            selectedAddons,
            savedStateLoaded,
            purchaseComplete,
            addonComplete,
        } = gtBuyStore.checkout
        const { urlCouponCode } = gtBuyStore.coupon
        const { language } = commonStore.content
        if (savedStateLoaded && !purchaseComplete && !addonComplete) {
            saveState({
                checkout: {
                    organizers,
                    billingFrequency,
                    selectedPlanSku,
                    selectedAddons,
                },
            })
            if (urlCouponCode) {
                saveSessionState({
                    coupon: {
                        urlCouponCode,
                    },
                })
            }
            if (language) {
                saveCommonSessionState(STORAGE_CONST.SAVED_LANGUAGE, language)
            }
        }
    }
}
