// Import Dependencies
import React, { FunctionComponent } from 'react'
import classNames from 'classnames'

import warningImg from '@common/images/alert-warn.svg'
import errorImg from '@common/images/alert-inactive.svg'
// Import Local Styles
import styles from './error-message.module.css'
import { ConvertStringToReact } from '../ConvertStringToReact'

export interface ErrorMessageProps {
    errorMessage?: string
    warning?: boolean
}

export const ErrorMessage: FunctionComponent<ErrorMessageProps> = ({ errorMessage, warning = false, children }) => {
    const classList = classNames({
        [styles['error-message']]: true,
        [styles.warning]: warning,
    })
    return (
        <div className={classList}>
            <img src={warning ? warningImg : errorImg} alt="Alert" />
            <span>{errorMessage ? <ConvertStringToReact htmlString={errorMessage} /> : children}</span>
        </div>
    )
}
