import { LocationState } from '@common/state/location/state'
import { getLocationState } from '@common/state/selectors'
import { BuyCheckoutState } from '@buy/state/checkout/state'
import { getSelectedAddons, getSelectedPlan } from '@buy/saga/content/content-helpers'
import { getCheckoutState, getContentData, getCouponState, getPlanData, getUserState } from '@buy/state/selectors'
import { BuyUserState } from '@buy/state/user/state'
import isEmpty from 'lodash/isEmpty'
import { select, call } from 'redux-saga/effects'
import { BuyPlan } from '@buy/state/plan'
import { BuyAddon } from '@buy/state/add-on'
import { BUY_USER_TYPES } from '@buy/constants'
import { getActiveCouponServerData, getCouponTotalSavings, getSelectedTierIndex } from '@buy/utils'
import { BuyCouponState } from '@buy/state/coupon/state'
import { BuyCustomizeItemsData } from '@buy/state/tracking/state'
import { BuyContentData } from '@buy/state/content-data'

export function* getCustomizeItemsData() {
    const planData: BuyPlan[] = yield select(getPlanData)
    const { CurrencyCode }: BuyContentData = yield select(getContentData)
    const { billingFrequency, organizers }: BuyCheckoutState = yield select(getCheckoutState)
    const customizeItemsData: BuyCustomizeItemsData = {
        planData,
        billingFrequency,
        currency: CurrencyCode,
        organizers,
    }
    return customizeItemsData
}

export function* getPageViewData() {
    const {
        loggedIn: { isUserLoggedIn },
    }: BuyUserState = yield select(getUserState)
    const locationState: LocationState = yield select(getLocationState)
    const { locale, country, language } = locationState.locationInfo
    return { locale, country, isUserLoggedIn, language }
}

export function* getSessionInfo() {
    const {
        userType,
        loggedIn: { isUserLoggedIn },
    }: BuyUserState = yield select(getUserState)
    return { isUserLoggedIn, userType, sessionLast: '', sessionNumber: '' }
}

export function* getCartInfo() {
    const { billingFrequency, organizers, currentVolumeDiscountPrice, selectedTierIndex }: BuyCheckoutState =
        yield select(getCheckoutState)
    const locationState: LocationState = yield select(getLocationState)
    const { existingBillingInformation, userType, existingAccountInformation }: BuyUserState = yield select(
        getUserState
    )
    const { currency } = locationState.locationInfo
    const selectedPlan: BuyPlan = yield call(getSelectedPlan)
    const selectedAddons: BuyAddon[] = yield call(getSelectedAddons)

    let tierIndex = selectedTierIndex
    if (userType === BUY_USER_TYPES.ADDON && existingAccountInformation && !isEmpty(existingAccountInformation)) {
        const existingOrganizers = existingAccountInformation.quantity
        const totalOrganizers = organizers + existingOrganizers
        tierIndex = yield call(getSelectedTierIndex, totalOrganizers, billingFrequency, selectedPlan, currency)
    }

    const coupon: BuyCouponState = yield select(getCouponState)
    const { couponProcessedDataPerSku, isCouponValidAndApplied, couponDoubleDipAmountForNewUser } = coupon
    const couponServerData = getActiveCouponServerData(coupon, selectedPlan)
    const totalCouponSavings = getCouponTotalSavings(
        isCouponValidAndApplied,
        couponProcessedDataPerSku,
        couponDoubleDipAmountForNewUser
    )

    return {
        plan: selectedPlan,
        addonList: selectedAddons,
        billingFrequency,
        organizers,
        currency,
        isExistingUser: !isEmpty(existingBillingInformation),
        tier: `Tier${tierIndex + 1}`,
        currentVolumeDiscountPrice,
        couponCode: couponServerData?.couponCode || '',
        couponProcessedDataPerSku,
        totalCouponSavings,
    }
}

export function* getPurchaseData(transactionID: string) {
    const { calculatedNetTotal, calculatedTax, promoCode }: BuyCheckoutState = yield select(getCheckoutState)
    const { userType }: BuyUserState = yield select(getUserState)
    return { calculatedNetTotal, calculatedTax, promoCode, userType, transactionID }
}

export function* getCompleteBuyData() {
    const { userType }: BuyUserState = yield select(getUserState)
    return userType
}
