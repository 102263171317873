/* eslint-disable react/jsx-no-target-blank */
import React, { FunctionComponent } from 'react'
import { useBoldChat } from '@common/hooks'

export interface BoldchatOptions {
    URL: string
}

export interface BoldchatProps {
    options: BoldchatOptions
}

export const BoldChat: FunctionComponent<BoldchatProps> = () => {
    // const { URL } = options

    useBoldChat()

    return <>{/* <link rel="stylesheet" type="text/css" media="all" href={`${URL}.css`} /> */}</>
}
