import { COUNTRIES } from '@common/constants'

import { CHECKOUTFORM_STEPS } from '@gtc/constants'
import { ErrorContent } from '@gtc/state/checkout-form-container/state'
import { BuyFormData, BuyFormState } from '@gtc/state/buy-form/state'

export const errorContent: ErrorContent = {
    errorInvalidCharacter: '',
    errorInvalidCVV: '',
    errorInvalidEmail: '',
    errorInvalidExpirationDate: '',
    errorInvalidPassword: '',
    errorInvalidPhoneNumber: '',
    errorInvalidTaxNumber: '',
    errorRequiredAddress: '',
    errorRequiredCity: '',
    errorRequiredFirstName: '',
    errorRequiredLastName: '',
    errorRequiredPostalCode: '',
    errorRequiredState: '',
    errorInvalidCreditCardNumber: '',
    emailAddressAlreadyInUse: '',
    errorRequiredCVV: '',
    errorRequiredCountry: '',
    errorRequiredCreditCardNumber: '',
    errorRequiredEmail: '',
    errorRequiredExpDate: '',
    errorRequiredPassword: '',
    errorRequiredPhoneNumber: '',
    errorInvalidPostalCode: '',
    errorGenericMessage: '',
    errorInvalidAddress: '',
    passwordStrength: '',
    passwordStrengthLevel1: '',
    passwordStrengthLevel2: '',
    passwordStrengthLevel3: '',
    passwordStrengthLevel4: '',
    passwordStrengthTipDrawer: '',
    passwordStrengthTip1: '',
    passwordStrengthTip2: '',
    passwordStrengthTip3: '',
}
export const initialData: BuyFormData = {
    FirstName: '',
    LastName: '',
    Email: '',
    Street1: '',
    City: '',
    ZipCode: '',
    StateCode: '',
    CountryCode: COUNTRIES.US,
    ContactPhone: '',
    Password: '',
    BillingFirstName: '',
    BillingLastName: '',
    ExpirationDate: '',
}
export const buyFormStateInitialData: BuyFormState = {
    clearEmail: false,
    initialData,
    steps: {
        [CHECKOUTFORM_STEPS.CREATE_FLEX_TOKEN]: { completed: false, error: false },
        [CHECKOUTFORM_STEPS.CREATE_USER_ACCOUNT]: { completed: false, error: false },
        [CHECKOUTFORM_STEPS.SAVE_BILLING_INFO]: { completed: false, error: false },
        [CHECKOUTFORM_STEPS.SAVE_PAYMENT_INFO]: { completed: false, error: false },
        [CHECKOUTFORM_STEPS.GET_AVAILABLE_PAYMENT_METHODS]: { completed: false, error: false },
        [CHECKOUTFORM_STEPS.SUBMIT_PAYMENT]: { completed: false, error: false },
    },
}
