import { takeEvery, call, select } from 'redux-saga/effects'
import Cookies from 'js-cookie'
import { postMarketo } from '@common/services'
import { MarketoPayload } from '@common/state/marketing/state'
import { MARKETO_PLAN_TYPE, COOKIE_NAMES } from '@common/constants'
import { marketingActions, MarketingActionsTypes } from '@common/state/marketing/actions'
import { getLocationState, getMarketingState } from '@common/state/selectors'
import { LocationState } from '@common/state/location/state'

export function* postToMarketoSaga(action: ReturnType<typeof marketingActions.postToMarketo>) {
    try {
        // eslint-disable-next-line camelcase
        const { phone, address, city, state: userState, zipcode, sc_site, ...rest } = action.payload.marketoUserInfo
        // grab is optOut value

        const { isUserOptOut } = yield select(getMarketingState)
        const {
            locationInfo: { country, language },
        }: LocationState = yield select(getLocationState)
        // if no address, the user is trying
        const planType = zipcode ? MARKETO_PLAN_TYPE.BUY : MARKETO_PLAN_TYPE.TRIAL
        const payload: MarketoPayload = {
            mkto_trk: Cookies.get(COOKIE_NAMES.MARKETO_TRK) || '',
            lmi_ma: Cookies.get(COOKIE_NAMES.LMI_MA) || '',
            chop_id: Cookies.get(COOKIE_NAMES.CHOP_ID) || '',
            gclid: Cookies.get(COOKIE_NAMES.GCL_ID) || Cookies.get(COOKIE_NAMES.GCL_ID_2) || '',
            country,
            language,
            phone: phone || '',
            planType,
            sourceUrl: window.location.href,
            address: address || '',
            city: city || '',
            state: userState || '',
            zipcode: zipcode || '',
            optin: isUserOptOut ? '0' : '1',
            ...rest,
        }

        // eslint-disable-next-line camelcase
        const params = sc_site
            ? {
                  // eslint-disable-next-line camelcase
                  sc_site,
              }
            : undefined

        yield call(postMarketo, payload, params)
    } catch (err: any) {
        console.error(err.message)
    }
}

function* initializePostToMarketoSaga() {
    yield takeEvery(MarketingActionsTypes.POST_MARKETO, postToMarketoSaga)
}

export default initializePostToMarketoSaga
