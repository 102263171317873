import { lmiproCouponActions, LmiProCouponActionTypes } from '@lmipro/state/coupon/actions'
import { put, takeLatest } from 'redux-saga/effects'

function* attempUrlCouponSaga(action: ReturnType<typeof lmiproCouponActions.setUrlCouponCode>) {
    const { couponCode } = action.payload
    if (couponCode) {
        // if url promotion available
        yield put(lmiproCouponActions.getCouponDetails(couponCode as string))
    } else {
        yield put(lmiproCouponActions.attemptForCMSCoupon())
    }
}

export default function* initializeAttempUrlCouponSaga() {
    yield takeLatest(LmiProCouponActionTypes.SET_URL_COUPON_CODE, attempUrlCouponSaga)
}
