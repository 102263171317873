import { call, takeEvery } from 'redux-saga/effects'

import { UserActionTypes, userActions } from '@gtc/state/user/actions'
import authClass from '@common/utils/auth-class'
import { UrlParams } from '@common/utils'
import { getUpdatedPlanUrlParams } from '../content/content-helpers'

function* userLogin(action: ReturnType<typeof userActions.userLogin>) {
    try {
        const { pathname, search }: UrlParams = yield call(getUpdatedPlanUrlParams)
        const sso = action.payload.sso || false
        yield call(authClass.login, pathname, search, sso)
    } catch (e) {
        // no error
    }
}

function* initializeLogin() {
    yield takeEvery(UserActionTypes.USER_LOGIN, userLogin)
}

export default initializeLogin
