import { call, takeEvery, put, select } from 'redux-saga/effects'
import { resolveUserActions, ResolveUserActionTypes } from '@gtresolve/state/user/actions'
import commonServices, {
    verifyUser,
    getBillingSubscriptions,
    getBillingInfo,
    getExistingPaymentDetails,
} from '@common/services'
import { getLabelContent, getUserState } from '@gtresolve/state/selectors'
import { authClass, processExistingBillingInfo } from '@common/utils'
import { trackAbandonForm } from '@gtresolve/utils'
import { ResolveUserState } from '@gtresolve/state/user/state'
import {
    ExistingBillingInfoType,
    ExistingUserInfoType,
    ExistingAccountInfoType,
    ExistingPaymentInfoType,
} from '@common/state/user/state'
import { resolveCheckoutActions } from '@gtresolve/state/checkout/actions'
import { resolveBuyFormActions } from '@gtresolve/state/buy-form/actions'
import { ResolveBuyFormData } from '@gtresolve/state/buy-form/state'
import {
    PREFIX,
    TimePeriod,
    BILLING_TYPES,
    PRODUCT_TYPES,
    PRODUCT_SKU,
    COUNTRIES,
    SC_SITE,
    PRODUCT_FAMILY_NAME,
} from '@common/constants'
import { LabelContent } from '@gtresolve/state/label-content'
import { RESOLVE_USER_TYPES, RESOLVE_USER_TYPES_RESPONSE } from '@gtresolve/constants'
import { fatalErrorActions } from '@common/state/fatal-error/actions'
import isEmpty from 'lodash/isEmpty'
import partition from 'lodash/partition'
import { BillingDetailedSubscriptionPlan } from '@common/state/billing/state'
import { accountActions } from '@common/state/account/actions'
import { getCountryFromCookie, setCountryInCookie } from '@common/utils/cookie-utils'
import { GenericResponse } from '@common/state/generic-response/generic-response'
import { FEATURE_FLAGS } from '@common/constants/featureFlags'

function* userLoggedInSaga() {
    try {
        yield put(resolveUserActions.setLoadingExistingInformation(true))
        const {
            loggedIn: { accessToken },
        }: ResolveUserState = yield select(getUserState)
        const { paymentMethodErrorMessage, disabledUserError }: LabelContent = yield select(getLabelContent)

        // clear previous session on the server
        yield call(commonServices.clearUserSession)
        // get user info
        // eslint-disable-next-line camelcase
        const response: GenericResponse = yield call(
            verifyUser,
            accessToken?.access_token || '',
            PRODUCT_FAMILY_NAME.G2R
        )
        if (response && !isEmpty(response.data)) {
            const {
                User: { UserName, Name },
                isBanned,
                hasGroupSubscription,
            } = response.data

            if (hasGroupSubscription) {
                trackAbandonForm()
                window.location.href = process.env.ADMIN_RESOLVE_URL as string
            }

            const userInfo: ExistingUserInfoType = {
                UserName,
                Name,
            }

            const buyFormData: ResolveBuyFormData = {
                FirstName: Name?.GivenName,
                LastName: Name?.FamilyName,
                Email: UserName,
                Street1: '',
                City: '',
                ZipCode: '',
                StateCode: '',
                CountryCode: getCountryFromCookie() || COUNTRIES.US,
                ContactPhone: '',
                Password: '',
                BillingFirstName: '',
                BillingLastName: '',
                ExpirationDate: '',
                TaxNumber: '',
                TaxType: '',
                CompanyName: '',
            }

            // return error message if user is banned
            if (isBanned === true) {
                yield put(resolveUserActions.setUserType(RESOLVE_USER_TYPES.BANNED))
                yield put(resolveUserActions.setLoadingExistingInformation(false))
                yield put(
                    fatalErrorActions.setFatalError(
                        true,
                        { fatalErrorMessage: disabledUserError, trackErrorMessage: disabledUserError },
                        true
                    )
                )
                return
            }

            // USER_IDENTITY_NOT_FOUND implies that user has neither boss nor identity account
            // This is the state returned when a token has been invalidated, I dont know
            // if there is another use case or not
            if (response.data.Code === RESOLVE_USER_TYPES_RESPONSE.USER_IDENTITY_NOT_FOUND) {
                yield put(resolveUserActions.setUserType(RESOLVE_USER_TYPES.NEW))
                yield put(resolveUserActions.userLogout())
                return
            }

            // set up and display existing user account information
            yield put(resolveUserActions.setExistingUserInformation(userInfo))

            // USER_BOSS_NOT_FOUND implies that user has an identity account
            if (response.data.Code === RESOLVE_USER_TYPES_RESPONSE.USER_BOSS_NOT_FOUND) {
                yield put(resolveUserActions.setUserType(RESOLVE_USER_TYPES.IDENTITY_ONLY))
                yield put(resolveBuyFormActions.updateInitialFormState(buyFormData))
                yield put(resolveUserActions.setLoadingExistingInformation(false))
                return
            }

            // #region attempt to set up existing billing info
            const billingInfoRequest: GenericResponse = yield call(getBillingInfo)
            if (billingInfoRequest && !isEmpty(billingInfoRequest.data)) {
                // We get company name same as the email without the `@` in case we don't provide in the first place.
                // This is a known behavior for GTC. So, we check for GTR, we check if that's what we got and don't show the value
                const { email, companyName } = billingInfoRequest.data
                if (email.replace('@', '').replace('_', '') === companyName) {
                    billingInfoRequest.data.companyName = ''
                }

                const processedBillingData = processExistingBillingInfo(billingInfoRequest.data)
                const billingInfo: ExistingBillingInfoType = processedBillingData
                const currentCountry = getCountryFromCookie() || COUNTRIES.US

                if (billingInfo.state === 'XX') {
                    billingInfo.state = ''
                    yield put(resolveBuyFormActions.setStatesOptional(true))
                }

                // If address IS set, but GeoIP is different from existing user country,
                // reload such that buy form country matches that of GeoIP cookie
                if (billingInfo.addressLine1) {
                    if (billingInfo.country !== currentCountry) {
                        setCountryInCookie(billingInfo.country)
                        window.location.reload()
                    }
                } else {
                    billingInfo.country = currentCountry
                }

                billingInfo.firstName = Name.GivenName
                billingInfo.lastName = Name.FamilyName
                buyFormData.Street1 = billingInfo.addressLine1
                buyFormData.City = billingInfo.city
                buyFormData.CountryCode = billingInfo.country
                buyFormData.ZipCode = billingInfo.postalCode
                buyFormData.StateCode = billingInfo.state
                buyFormData.ContactPhone = billingInfo.phoneNumber
                buyFormData.TaxNumber = billingInfo.taxNumber || ''
                buyFormData.TaxType = billingInfo.federalTaxType || ''

                // all five fields must contain values in order to set existing billing information
                if (buyFormData.Street1 && buyFormData.City && buyFormData.CountryCode && buyFormData.ZipCode) {
                    yield put(resolveUserActions.setExistingBillingInformation(billingInfo))
                }
            }
            yield put(resolveBuyFormActions.updateInitialFormState(buyFormData))
            // #endregion

            // #region attempt to set up existing payment info
            const paymentInfoRequest: GenericResponse = yield call(getExistingPaymentDetails)
            if (paymentInfoRequest && !isEmpty(paymentInfoRequest.data)) {
                const currentDate = new Date()
                const currentYear = currentDate.getFullYear()
                const currentMonth = currentDate.getMonth()
                const ccInfo = paymentInfoRequest.data[0].creditCard
                if (
                    ccInfo.expirationYear < currentYear ||
                    (ccInfo.expirationYear === currentYear && ccInfo.expirationMonth < currentMonth)
                ) {
                    yield put(
                        fatalErrorActions.setFatalError(
                            true,
                            {
                                fatalErrorMessage: paymentMethodErrorMessage,
                                trackErrorMessage: paymentMethodErrorMessage,
                            },
                            true
                        )
                    )
                }
                const paymentInfo: ExistingPaymentInfoType = {
                    last4Digits: ccInfo.last4Digits,
                }
                yield put(resolveUserActions.setExistingPaymentDetails(paymentInfo))
            }
            // #endregion

            // if subscription is empty, mark as expired-user
            const subscriptionRequest: GenericResponse = yield call(getBillingSubscriptions)
            if (isEmpty(subscriptionRequest?.data)) {
                yield put(resolveUserActions.setUserType(RESOLVE_USER_TYPES.EXPIRED))
                yield put(resolveUserActions.setLoadingExistingInformation(false))
                return
            }

            const featureFlags: { data: { flags: string[] } } = yield call(
                commonServices.getFeatureFlags,
                SC_SITE.RESOLVE
            )

            const useNewResolveSkus = featureFlags?.data?.flags?.includes(FEATURE_FLAGS.RESOLVE_USE_NEW_SKUS)

            // set account subscription state
            yield put(accountActions.setAccountSubscriptions(subscriptionRequest.data))

            // partition subscriptions into two arrays - one for GTC and another for GTR
            const [resolveSubscriptions] = partition(
                subscriptionRequest.data,
                (plan: BillingDetailedSubscriptionPlan) => {
                    return plan.productSku.startsWith(PREFIX.GTR)
                }
            )

            const resolveTrialSubscription = resolveSubscriptions.find(
                (plan: BillingDetailedSubscriptionPlan) => plan.planType === PRODUCT_TYPES.TRIAL
            )

            // #region determine buy flow user state
            const selectedPlan = resolveSubscriptions?.find((plan: BillingDetailedSubscriptionPlan) => {
                if (useNewResolveSkus) {
                    return (
                        plan.productSku === PRODUCT_SKU.G2RE_BASIC_NAMED ||
                        plan.productSku === PRODUCT_SKU.G2RE_STANDARD_NAME ||
                        plan.productSku === PRODUCT_SKU.GTR_REMOTE_SUPPORT_NAMED_V2 ||
                        plan.productSku === PRODUCT_SKU.GTR_REMOTE_ACCESS ||
                        plan.productSku === PRODUCT_SKU.GTR_STANDARD_NAMED_V2
                    )
                }
                return (
                    plan.productSku === PRODUCT_SKU.G2RE_BASIC_NAMED ||
                    plan.productSku === PRODUCT_SKU.G2RE_STANDARD_NAME
                )
            })
            if (selectedPlan) {
                const {
                    productName,
                    paymentPlan,
                    startDate,
                    endDate,
                    currency,
                    renewalPrice,
                    autorenew,
                    productSku,
                    subscriptionId,
                    status,
                    planType,
                } = selectedPlan ?? {}

                const overallQuantity = resolveSubscriptions.reduce(
                    (accumulator: number, subItem: ExistingAccountInfoType) => {
                        if (subItem.productSku === productSku) {
                            return accumulator + subItem.quantity
                        }
                        return accumulator
                    },
                    0
                )

                const accountInfo: ExistingAccountInfoType = {
                    productName,
                    paymentPlan,
                    startDate,
                    endDate,
                    currency,
                    renewalPrice,
                    autorenew,
                    productSku,
                    subscriptionId,
                    status,
                    quantity: overallQuantity,
                    planType,
                }

                yield put(resolveUserActions.setExistingAccountInformation(accountInfo))
                yield put(resolveCheckoutActions.setSelectedPlan(productSku))
                yield put(
                    resolveCheckoutActions.setBillingFrequency(
                        paymentPlan === BILLING_TYPES.ANNUAL ? TimePeriod.Year : TimePeriod.Month
                    )
                )
                // if user already has the selected plan, mark as add-on flow
                yield put(resolveUserActions.setUserType(RESOLVE_USER_TYPES.ADDON))
                yield put(resolveCheckoutActions.setSelectedAddons([]))
            } else if (resolveTrialSubscription) {
                // otherwise, if user has any GTResolve trial subscription, mark as trialer
                yield put(resolveUserActions.setUserType(RESOLVE_USER_TYPES.TRIALER))
            } else {
                // otherwise, since user has at least one subscription, mark as cross product
                yield put(resolveUserActions.setUserType(RESOLVE_USER_TYPES.CROSSPRODUCT))
            }
            // #endregion

            yield put(resolveUserActions.setLoadingExistingInformation(false))
        }
    } catch (e) {
        yield put(resolveUserActions.setUserType(RESOLVE_USER_TYPES.NEW))
        yield put(resolveUserActions.setLoadingExistingInformation(false))
        yield call(authClass().logout)
    }
}

function* initializeLoggedInSaga() {
    yield takeEvery(ResolveUserActionTypes.GET_LOGGED_IN_USER_STATE, userLoggedInSaga)
}

export default initializeLoggedInSaga
