import { LabelContent } from '@buy/state/label-content'

export const labelContentInitialData: LabelContent = {
    addOns: '',
    agents: '',
    devices: '',
    quantityLabelLong: '',
    quantityLabel: '',
    quantityLabelPlural: '',
    email: '',
    firstName: '',
    lastName: '',
    password: '',
    useAccountInfo: '',
    address: '',
    country: '',
    city: '',
    postalCode: '',
    state: '',
    phone: '',
    checkout: '',
    header: '',
    customizePlan: '',
    errorMessageTitle: '',
    orderSummary: '',
    monthly: '',
    annual: '',
    save: '',
    user: '',
    year: '',
    month: '',
    months: '',
    subtotal: '',
    estimatedTax: '',
    tBD: '',
    todaysTotal: '',
    recurringAnnualCharge: '',
    recurringMonthlyCharge: '',
    autoRenewalNote: '',
    createAnAccount: '',
    billingInformation: '',
    passwordRequirements: '',
    paymentDetails: '',
    expirationDate: '',
    cVV: '',
    cVVHint: '',
    completePurchase: '',
    pricingNote: '',
    continue: '',
    changePlan: '',
    selectedPlan: '',
    signIn: '',
    errorCardinalChallenge: '',
    errorCardinalSetup: '',
    errorInvalidCharacter: '',
    errorInvalidCVV: '',
    errorInvalidEmail: '',
    errorInvalidExpirationDate: '',
    errorInvalidPassword: '',
    errorInvalidPhoneNumber: '',
    errorInvalidTaxNumber: '',
    errorRequiredAddress: '',
    errorRequiredCity: '',
    errorRequiredFirstName: '',
    errorRequiredLastName: '',
    errorRequiredPostalCode: '',
    errorRequiredState: '',
    estimatedTaxPopupInfo: '',
    quantityPopupInfo: '',
    yearAbbr: '',
    monthAbbr: '',
    questions: '',
    myAccount: '',
    signOut: '',
    pleaseSelect: '',
    headerPhone: '',
    optOutCheckboxLabel: '',
    optOut: '',
    cardNumber: '',
    errorInvalidCreditCardNumber: '',
    accountInformation: '',
    notYou: '',
    emailAddressAlreadyInUse: '',
    additionalUserLicences: '',
    howManyMore: '',
    existingUserLicencePlural: '',
    existingUserLicense: '',
    additional: '',
    newUserLicense: '',
    total: '',
    proratedAdjustment: '',
    today: '',
    modalCloseButtonText: '',
    changePlanMethodModalHeader: '',
    changePlanModalSubheader: '',
    changePaymentMethodModalHeader: '',
    changePaymentMethodModalSubheader: '',
    edit: '',
    newRecurringAnnualTotal: '',
    newRecurringMonthlyTotal: '',
    previousAnnualTotal: '',
    previousMonthlyTotal: '',
    billingCycleCredit: '',
    getStartedNote: '',
    creatingAccountLeftHeader: '',
    creatingAccountLeftContent: '',
    creatingAccountRightHeader: '',
    creatingAccountRightContent: '',
    getStartedButtonText: '',
    getStartedButtonUrl: '',
    gotoAdminUrl: '',
    savingWithAnnualBilling: '',
    errorBlockedPurchase: '',
    errorGenericMessage: '',
    errorRequiredBIC: '',
    errorRequiredCVV: '',
    errorRequiredCountry: '',
    errorRequiredCreditCardNumber: '',
    errorRequiredEmail: '',
    errorRequiredExpDate: '',
    errorRequiredPassword: '',
    errorRequiredPhoneNumber: '',
    errorSummaryMessage: '',
    errorTooManyRequests: '',
    errorInvalidPostalCode: '',
    internalErrorMessage: '',
    calculating: '',
    newUserLicensePlural: '',
    fees: '',
    changeNumberModalHeader: '',
    changeNumberModalContent: '',
    changePlanUrl: '',
    sEOPageTitle: '',
    showMe: '',
    errorInvalidAddress: '',
    paymentMethodErrorMessage: '',
    disabledUserError: '',
    addonUserSuccessUrl: '',
    percentageOffAnnualDiscount: '',
    percentageOffVolumeDiscount: '',
    volumeDiscountPopupText: '',
    percentageOffPromoDiscount: '',
    equal: '',
    bundles: '',
    countAbbr: '',
    discount: '',
    percentageDiscountPopupText: '',
    annualPlanNote: '',
    monthlyPlanNote: '',
    invalidQSCouponMessage: '',
    invalidQSCouponUsingCMSCouponMessage: '',
    couponValidMessage: '',
    dismiss: '',
    couponDisclaimer: '',
    couponAdjustmentSavings: '',
    couponAdjustmentSavingsPopupText: '',
    billedMonthlyPrice: '',
    billedAnnualPrice: '',
    secure: '',
    trustArcSupport: '',
    per: '',
    selectAddons: '',
    selectAddonsDescription: '',
    organizer: '',
    added: '',
    existingUsersModalContent: '',
    existingUsersModalHeader: '',
    existingUsersNewEmailButtonText: '',
    allrightsreservedDisclaimerLabel: '',
    termsandconditionsLabel: '',
    privacypolicyLabel: '',
    antiSpamPolicyLabel: '',
    trademarkLabel: '',
    sitemapLabel: '',
    termsandconditionsUrl: '',
    privacypolicyUrl: '',
    antiSpamPolicyUrl: '',
    trademarkUrl: '',
    sitemapUrl: '',
    taxTooltip: '',
    agentsVolumediscountTooltip: '',
    endpointsVolumediscountTooltip: '',
    mobileVolumediscountTooltip: '',
    cameraShareVolumediscountTooltip: '',
    endpointProtectionVolumediscountTooltip: '',
    proratedadjustmentTooltip: '',
    errorRequiredTaxNumber: '',
    taxBusiness: '',
    taxIndividual: '',
    taxNumber: '',
    gSTDisclaimer: '',
    vATDisclaimer: '',
    gSTOption: '',
    vATOption: '',
    companyName: '',
    optional: '',
    changeCountryContent: '',
    changeCountryHeader: '',
    modalCancel: '',
    modalReload: '',
    taxCodeGST: '',
    taxCodeVAT: '',
    upgradeClose: '',
    upgradeSubscriptionCredit: '',
    upgradeSubscriptionCreditTooltip: '',
    upgradeSubscriptionCreditNote: '',
    upgradeCreditErrorMessage: '',
    upgradeCreditErrorAssistance: '',
    totalVolumeDiscount: '',
    totalVolumeDiscountTooltip: '',
    passwordStrength: '',
    passwordStrengthLevel1: '',
    passwordStrengthLevel2: '',
    passwordStrengthLevel3: '',
    passwordStrengthLevel4: '',
    passwordStrengthTip1: '',
    passwordStrengthTip2: '',
    passwordStrengthTip3: '',
    passwordStrengthTipDrawer: '',
}
