import { ResolveContentData } from '@gtresolve/state/content-data'
import { LabelContent } from '@gtresolve/state/label-content'
import { ActionUnion, createAction } from '@common/utils'

export enum ResolveContentActionTypes {
    GET_LABEL_CONTENT = '[resolve-content-actions] get-label-content',
    SET_CONTENT = '[resolve-content-actions] set-content',
    SET_CONTENT_LOADED = '[resolve-content-actions] set-content-loaded',
    GET_COMPARE_MODAL_CONTENT = '[resolve-content-actions] get-compare-modal-content',
    SET_COMPARE_MODAL_CONTENT = '[resolve-content-actions] set-compare-modal-content',
    SET_COUPON_DISCLAIMER = '[resolve-content-actions] set-coupon-disclaimer',
}

export const contentActions = {
    getLabelContent: (upgrade?: boolean) => createAction(ResolveContentActionTypes.GET_LABEL_CONTENT, { upgrade }),
    setContent: (content: ResolveContentData, labels: LabelContent, filteredPlans: [], allPlans: []) =>
        createAction(ResolveContentActionTypes.SET_CONTENT, {
            content,
            labels,
            filteredPlans,
            allPlans,
        }),
    getCompareModalContent: (PlanGroupdId: string) =>
        createAction(ResolveContentActionTypes.GET_COMPARE_MODAL_CONTENT, PlanGroupdId),
    setCompareModalContent: (content: []) =>
        createAction(ResolveContentActionTypes.SET_COMPARE_MODAL_CONTENT, { content }),
    setContentLoaded: (loaded: boolean) => createAction(ResolveContentActionTypes.SET_CONTENT_LOADED, { loaded }),
    setCouponDisclaimer: (couponDisclaimer: string) =>
        createAction(ResolveContentActionTypes.SET_COUPON_DISCLAIMER, { couponDisclaimer }),
}

// where do the get functions go

export type ContentActions = ActionUnion<typeof contentActions>
