import { call, takeEvery } from 'redux-saga/effects'

import { BuyUserActionTypes } from '@buy/state/user/actions'
import { logout } from '@common/utils'

function* userLogout() {
    try {
        const { pathname, search } = window.location
        yield call(logout, pathname, search)
    } catch (e) {
        // no error
    }
}

function* initializeLogout() {
    yield takeEvery(BuyUserActionTypes.USER_LOGOUT, userLogout)
}

export default initializeLogout
