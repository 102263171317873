export enum TRACKING_EVENTS {
    VIEW_CONTENT = 'view_content',
    SESSION_INFORMATION = 'session_information',
    USER_INFORMATION = 'user_information',
    VIEW_PROMOTION = 'view_promotion',
    SELECT_PROMOTION = 'select_promotion',
    VIEW_ITEM = 'view_item',
    ADD_TO_CART = 'add_to_cart',
    REMOVE_FROM_CART = 'remove_from_cart',
    BEGIN_CHECKOUT = 'begin_checkout',
    PURCHASE = 'purchase',
    ERROR_PURCHASE = 'error_purchase',
    LOAD_FORM = 'load_form',
    START_FORM = 'start_form',
    ABANDON_FORM = 'abandon_form',
    SUBMIT_FORM = 'submit_form',
    ERROR_FORM = 'error_form',
    ERROR_ACCOUNT = 'error_account',
    SUBMIT_CHATBOT = 'submit_chatbot',
    LOGIN = 'login',
    START_BUY_FLOW = 'start_buy_flow',
    COMPLETE_BUY_FLOW = 'complete_buy_flow',
    START_CONTACT_SALES_FLOW = 'start_contact_sales_flow',
    COMPLETE_CONTACT_SALES_FLOW = 'complete_contact_sales_flow',
    CLICK_CTA = 'click_cta',
    FATAL_ERROR = 'fatal_error',
    EMAIL_VALIDATION = 'email_validation',
    CARDINAL_SECURITY_MODAL = 'checkout_security_modal',
    CARDINAL_LOADED = 'security_modal_loaded',
    CARDINAL_INITIATED = 'security_modal_challenge',
    CARDINAL_SUCCESS = 'security_modal_success',
    CARDINAL_FAIL = 'security_modal_fail',
}
