import { ErrorContent } from '@gtc/state/checkout-form-container/state'
import * as Yup from 'yup'
import zxcvbn from 'zxcvbn'
import { isDate } from 'date-fns/isDate'
import { parse } from 'date-fns/parse'
import { isValidPostalCode } from '@common/utils/isValidPostalCode'

const isValidDate = (date: string | Date) => {
    return date instanceof Date && !Number.isNaN(date.getTime())
}

const emailValidation = (errorInvalidEmail: string, errorRequiredEmail?: string) => {
    return Yup.string()
        .required(errorRequiredEmail || errorInvalidEmail)
        .min(2, errorInvalidEmail)
        .email(errorInvalidEmail)
        .matches(
            new RegExp(
                "^((?!(.+\\.\\.+))(?!(.+\\.@+))(?!(^\\.))([a-zA-Z_0-9-+!#\\$\\.\\*\\^']{1,63}))@((?:[a-zA-Z_0-9-]+\\.)*[a-zA-Z_0-9][a-zA-Z_0-9-]*)\\.([a-z]{2,})$"
            ),
            errorInvalidEmail
        )
}

const nameValidation = (requiredErrorMessage: string, invalidCharacterErrorMessage: string) => {
    return (
        Yup.string()
            .required(requiredErrorMessage)
            .min(2, requiredErrorMessage)
            // a to z, latin characters from dec 192 t0 214, 216 to 246 and 248 to 255,
            // https://blog.robertelder.org/character-ranges-class-negation-regular-expressions/
            .matches(new RegExp(/^[A-Za-zÀ-ÖØ-öø-ÿ- .']+$/), invalidCharacterErrorMessage)
    )
}
export const passwordValidation = () => {
    return Yup.string()
        .required()
        .test((pw) => zxcvbn(pw).score >= 3)
}

const addressValidation = (errorRequiredMessage: string, errorInvalidMessage: string) => {
    return Yup.string()
        .required(errorRequiredMessage)
        .matches(new RegExp('^[A-Za-z0-9- #,.\\/]+$'), errorInvalidMessage)
}

// const companySizeValidation = (errorRequiredMessage: string) => {
//     return Yup.string().required(errorRequiredMessage)
// }

const countryValidation = (errorRequiredMessage: string) => {
    return Yup.string().required(errorRequiredMessage)
}

const zipcodeValidation = (country: string, errorRequiredPostalCode: string, errorInvalidPostalCode: string) => {
    return Yup.string()
        .required(errorRequiredPostalCode)
        .min(3, errorInvalidPostalCode)
        .max(12, errorInvalidPostalCode)
        .test('ZipCodeRegEx', errorInvalidPostalCode, (value) => isValidPostalCode(country, value as string))
}

const contactPhoneValidation = (errorInvalidCharacter: string, errorRequiredPhoneNumber: string) => {
    return Yup.string()
        .required(errorRequiredPhoneNumber)
        .matches(new RegExp('^(?=(?:\\D*\\d){6}).*$'), errorInvalidCharacter)
}

const expDateValidation = (errorInvalidExpirationDate: string, errorRequiredExpDate: string) => {
    const lastMonthDate = new Date()
    lastMonthDate.setMonth(lastMonthDate.getMonth() - 1)

    const parseDateString = (_value: string, originalValue: string) => {
        const parsedDate = isDate(originalValue) ? originalValue : parse(originalValue, 'MM/yy', new Date())
        const expiredMonthDate = new Date()
        expiredMonthDate.setMonth(expiredMonthDate.getMonth() - 2)
        return isValidDate(parsedDate) ? parsedDate : expiredMonthDate
    }
    return Yup.date()
        .required(errorRequiredExpDate)
        .min(lastMonthDate, errorInvalidExpirationDate)
        .transform(parseDateString)
}

export const CheckoutSchema = (country: string, errorContent: ErrorContent) => {
    const {
        errorInvalidPhoneNumber,
        errorInvalidCharacter,
        errorInvalidEmail,
        errorInvalidExpirationDate,
        errorRequiredAddress,
        errorRequiredCity,
        errorRequiredFirstName,
        errorRequiredLastName,
        errorRequiredPostalCode,
        errorRequiredState,
        errorRequiredCountry,
        errorRequiredEmail,
        errorRequiredExpDate,
        errorRequiredPhoneNumber,
        errorInvalidPostalCode,
    } = errorContent

    return Yup.object().shape({
        Email: emailValidation(errorInvalidEmail, errorRequiredEmail),
        FirstName: nameValidation(errorRequiredFirstName, errorInvalidCharacter),
        LastName: nameValidation(errorRequiredLastName, errorInvalidCharacter),
        Password: passwordValidation(),
        BillingFirstName: nameValidation(errorRequiredFirstName, errorInvalidCharacter),
        BillingLastName: nameValidation(errorRequiredLastName, errorInvalidCharacter),
        Street1: addressValidation(errorRequiredAddress, errorInvalidCharacter),
        City: addressValidation(errorRequiredCity, errorInvalidCharacter),
        CountryCode: countryValidation(errorRequiredCountry),
        StateCode: addressValidation(errorRequiredState, errorInvalidCharacter),
        ZipCode: zipcodeValidation(country, errorRequiredPostalCode, errorInvalidPostalCode),
        ContactPhone: contactPhoneValidation(errorInvalidPhoneNumber, errorRequiredPhoneNumber),
        ExpirationDate: expDateValidation(errorInvalidExpirationDate, errorRequiredExpDate),
    })
}
