import { ActionUnion, createAction } from '@common/utils'
import { Token } from '@getgo/auth-client'
import { ResolveBuyFormData } from '@gtresolve/state/buy-form/state'
import {
    ExistingBillingInfoType,
    ExistingUserInfoType,
    ExistingAccountInfoType,
    ExistingPaymentInfoType,
} from '@common/state/user/state'
import { ResolveAbandonedCartDataType, ResolveUserDataType } from './state'

export enum ResolveUserActionTypes {
    CHECK_EXISTING_USER = '[resolve-user-actions] check-existing-user',
    CREATE_NEW_USER = '[resolve-user-actions] create-new-user',
    GET_LOGGED_IN_USER_STATE = '[resolve-user-actions] get-user-logged-in-state',
    SET_EXISTING_BILLING_INFO = '[resolve-user-actions] set-existing-billing-info',
    SET_EXISTING_USER_INFO = '[resolve-user-actions] set-existing-user-info',
    SET_EXISTING_ACCOUNT_INFO = '[resolve-user-actions] set-existing-account-info',
    SET_DISPLAY_NAME = '[resolve-user-actions] set-display-name',
    SET_LOADING_EXISTING_INFO = '[resolve-user-actions] set-loading-existing-info',
    SET_VERIFYING_TOKEN = '[resolve-user-actions] set-verifying-token',
    SET_USER_TYPE = '[resolve-user-actions] set-user-type',
    SET_EXISTING_PAYMENT_DETAILS = '[resolve-user-actions] set-existing-payment-details',
    SET_ACCESS_TOKEN = '[resolve-user-actions] set-access-token',
    GET_ACCESS_TOKEN = '[resolve-user-actions] get-access-token',
    SET_IS_USER_LOGGEDIN = '[resolve-user-actions] set-is-user-loggedin',
    USER_LOGIN = '[resolve-user-actions] user-login',
    USER_LOGOUT = '[resolve-user-actions] user-logout',
    SET_VERIFY_USER_SHOULD_LOGIN = '[resolve-user-actions] get-verify-user-should-login',
    SUBMIT_ABANDONED_CART = '[resolve-user-actions] submit-abandoned-cart',
}

export const resolveUserActions = {
    checkExistingUser: (email: string, form?: ResolveBuyFormData) =>
        createAction(ResolveUserActionTypes.CHECK_EXISTING_USER, { email, form }),
    createNewUser: (userValues: ResolveUserDataType) =>
        createAction(ResolveUserActionTypes.CREATE_NEW_USER, { userValues }),
    getLoggedInUserState: () => createAction(ResolveUserActionTypes.GET_LOGGED_IN_USER_STATE),
    setExistingBillingInformation: (billingInfo: ExistingBillingInfoType) =>
        createAction(ResolveUserActionTypes.SET_EXISTING_BILLING_INFO, { billingInfo }),
    setLoadingExistingInformation: (loading: boolean) =>
        createAction(ResolveUserActionTypes.SET_LOADING_EXISTING_INFO, { loading }),
    setVerifyingToken: (verifying: boolean) => createAction(ResolveUserActionTypes.SET_VERIFYING_TOKEN, { verifying }),
    setExistingUserInformation: (userInfo: ExistingUserInfoType) =>
        createAction(ResolveUserActionTypes.SET_EXISTING_USER_INFO, { userInfo }),
    setExistingAccountInformation: (accountInfo: ExistingAccountInfoType) =>
        createAction(ResolveUserActionTypes.SET_EXISTING_ACCOUNT_INFO, { accountInfo }),
    setDisplayName: (displayName: string) => createAction(ResolveUserActionTypes.SET_DISPLAY_NAME, { displayName }),
    setUserType: (type: string) => createAction(ResolveUserActionTypes.SET_USER_TYPE, { type }),
    setExistingPaymentDetails: (info: ExistingPaymentInfoType) =>
        createAction(ResolveUserActionTypes.SET_EXISTING_PAYMENT_DETAILS, { info }),
    getAccessToken: () => createAction(ResolveUserActionTypes.GET_ACCESS_TOKEN),
    userLogin: (component?: string, pathname?: string, search?: string, sso?: boolean) =>
        createAction(ResolveUserActionTypes.USER_LOGIN, { pathname, search, sso }, { component }),
    userLogout: (component?: string) => createAction(ResolveUserActionTypes.USER_LOGOUT, {}, { component }),
    setAccessToken: (accessToken: Token) => createAction(ResolveUserActionTypes.SET_ACCESS_TOKEN, { accessToken }),
    setIsUserLoggedIn: (isUserLoggedIn: boolean) =>
        createAction(ResolveUserActionTypes.SET_IS_USER_LOGGEDIN, {
            isUserLoggedIn,
        }),
    setVerifyShouldUserLogIn: (verifiedUserShouldLogIn: boolean) =>
        createAction(ResolveUserActionTypes.SET_VERIFY_USER_SHOULD_LOGIN, { verifiedUserShouldLogIn }),
    submitAbandonedCart: (abandonedCartData: ResolveAbandonedCartDataType) =>
        createAction(ResolveUserActionTypes.SUBMIT_ABANDONED_CART, { abandonedCartData }),
}

export type ResolveUserActions = ActionUnion<typeof resolveUserActions>
