export enum TimePeriod {
    Month = 'MONTH',
    Year = 'YEAR',
}

export enum BillingPeriod {
    ANNUAL = 'annual',
    MONTHLY = 'monthly',
}

export enum BillingPeriodKey {
    ANNUAL = 'AnnualBilling',
    MONTHLY = 'MonthlyBilling',
    ANNUALCONTRACT = 'MonthlyBillingWithAnnualContract',
}
