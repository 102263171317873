import { couponActions, CouponActionTypes } from '@gtc/state/coupon/actions'
import { CouponProcessedDataPerSku } from '@gtc/state/coupon/state'
import { GetProductItemsParameters, ProcessCouponDataParameters, ProductDetails } from '@gtc/state/coupon-util'
import { getCouponItemPriceMap, getProductItemsMap, processCouponData } from '@gtc/utils'
import { call, put, takeLatest } from 'redux-saga/effects'
import { TimePeriod, COUPON_DISCOUNT_TYPE } from '@common/constants'

function* calculateCouponPriceSaga(action: ReturnType<typeof couponActions.calculateCouponPrice>) {
    const { couponCalculationData } = action.payload
    const {
        couponServerData,
        couponSkuValidity,
        organizers,
        billingFrequency,
        selectedCurrency,
        selectedAddons,
        selectedPlan,
        selectedTierIndex,
    } = couponCalculationData
    const { couponDiscountType } = couponServerData
    const getProductItemsArguments: GetProductItemsParameters = {
        organizers,
        billingFrequency,
        selectedCurrency,
        selectedAddons,
        selectedPlan,
        selectedTierIndex,
    }
    const isAnnual = billingFrequency === TimePeriod.Year
    const productItemsMap: Map<string, ProductDetails> = yield call(getProductItemsMap, getProductItemsArguments)
    const couponItemsMap: Map<string, number> = yield call(
        getCouponItemPriceMap,
        couponServerData,
        selectedCurrency,
        isAnnual
    )
    const processCouponDataArguments: ProcessCouponDataParameters = {
        couponDiscountType,
        productItemsMap,
        couponItemsMap,
        couponSkuValidity,
    }
    const couponProcessedDataPerSku: CouponProcessedDataPerSku | null = yield call(
        processCouponData,
        processCouponDataArguments
    )
    if (couponServerData.couponDiscountType === COUPON_DISCOUNT_TYPE.REPRICE) {
        yield put(
            couponActions.checkVolumeDiscountAgainstRepriceCoupon({
                couponServerData,
                couponProcessedDataPerSku,
                selectedPlan,
            })
        )
    }
    yield put(couponActions.setCouponProcessedData(couponProcessedDataPerSku))
}

export default function* initializeCalculateCouponPriceSaga() {
    yield takeLatest(CouponActionTypes.CALCULATE_COUPON_PRICE, calculateCouponPriceSaga)
}
