import { resolveCouponActions, ResolveCouponActionTypes } from '@gtresolve/state/coupon/actions'
import { put, takeLatest } from 'redux-saga/effects'

function* attempUrlCouponSaga(action: ReturnType<typeof resolveCouponActions.setUrlCouponCode>) {
    const { couponCode } = action.payload
    if (couponCode) {
        // if url promotion available
        yield put(resolveCouponActions.getCouponDetails(couponCode as string))
    } else {
        yield put(resolveCouponActions.attemptForCMSCoupon())
    }
}

export default function* initializeAttempUrlCouponSaga() {
    yield takeLatest(ResolveCouponActionTypes.SET_URL_COUPON_CODE, attempUrlCouponSaga)
}
