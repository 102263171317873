import { takeLatest, put, call } from 'redux-saga/effects'

import { lmiproUserActions, LmiProUserActionTypes } from '@lmipro/state/user/actions'
import { fatalErrorActions } from '@common/state/fatal-error/actions'
import authClass from '@common/utils/auth-class'
import { saveToken, appInsightsTrackEvent } from '@common/utils'
import commonServices from '@common/services'
import { Token } from '@getgo/auth-client'

function* getAccessToken() {
    try {
        yield put(lmiproUserActions.setVerifyingToken(true))
        const newAccessToken: Token | undefined = yield call(authClass.authInit)
        // if new token save it
        if (newAccessToken) {
            yield put(lmiproUserActions.setAccessToken(newAccessToken))
            yield call(saveToken, newAccessToken)
        } else {
            try {
                yield call(commonServices.clearUserSession)
            } catch (e: any) {
                appInsightsTrackEvent(e, { errormessage: e.message })
            }
        }
        yield put(lmiproUserActions.setIsUserLoggedIn(!!newAccessToken))
        // to check if this action ran
        yield put(lmiproUserActions.setVerifyShouldUserLogIn(true))
        yield put(lmiproUserActions.setVerifyingToken(false))
    } catch (e: any) {
        yield put(fatalErrorActions.setFatalError(true, { trackErrorMessage: 'failed to get access token' }))
        yield put(lmiproUserActions.setVerifyingToken(false))
        appInsightsTrackEvent(e, { errormessage: e.message })
    }
}

function* initializeGetAccessToken() {
    yield takeLatest(LmiProUserActionTypes.GET_ACCESS_TOKEN, getAccessToken)
}

export default initializeGetAccessToken
