import { locationActions } from '@common/state/location/actions'
import { getLocationState } from '@common/state/selectors'
import { AppState } from 'app-store'
import { BuyFormData } from '@gtc/state/buy-form/state'
import { checkoutActions } from '@gtc/state/checkout/actions'
import { getContentData, getLabelContent, getUserState } from '@gtc/state/selectors'
import { SC_SITE } from '@common/constants'
import { BillingFormInfoState } from './state'

export const mapBillingFormInfoState = (state: AppState): BillingFormInfoState => {
    const {
        billingInformation,
        useAccountInfo,
        firstName,
        lastName,
        address,
        country,
        countryPopupText,
        city,
        state: addressState,
        pleaseSelect,
        postalCode,
        phone,
    } = getLabelContent(state)
    const location = getLocationState(state)

    const {
        existingBillingInformation,
        loggedIn: { isUserLoggedIn },
    } = getUserState(state)
    const { DefaultCountry } = getContentData(state)
    return {
        content: {
            billingInformation,
            useAccountInfo,
            firstName,
            lastName,
            address,
            country,
            countryPopupText,
            city,
            state: addressState,
            pleaseSelect,
            postalCode,
            phone,
        },
        location,
        isUserLoggedIn,
        existingBillingInformation,
        DefaultCountry,
    }
}

export const mapBillingFormInfoActions = {
    getCountries: () => locationActions.getCountries(SC_SITE.CONNECT),
    calculatePrice: (values: BuyFormData) => checkoutActions.calculatePrice(values),
}
export type BillingFormInfoActionsType = typeof mapBillingFormInfoActions
