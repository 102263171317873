/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/click-events-have-key-events */
// Generated with util/create-component.js
import React, { useEffect } from 'react'
import Close from '@common/images/icon-close.svg'
import { LoadingSpinner } from '../LoadingSpinner'
import styles from './modalDrawer.module.css'

interface IProps {
    children: React.ReactNode
    id: string
    handleDismiss?: () => void
    headerText: string
    closeText: string
    actionButtons?: React.ReactNode
    qaTag?: string
    showLoadingSpinner?: boolean
    allowEscToDismiss?: boolean
    scrollingDivRef?: React.RefObject<HTMLDivElement> // other code needs this ref to be able to tell the div to scroll to top if there's an error
}

export const ModalDrawer: React.FC<IProps> = (props: IProps) => {
    const { qaTag = '', allowEscToDismiss = true, handleDismiss } = props

    // Escape key dismisses dialog if allowEscToDismiss is true
    useEffect(() => {
        const listenForEscapeKey = (ev: globalThis.KeyboardEvent) => {
            if (ev.key === 'Escape' || ev.key === 'Esc') {
                if (handleDismiss && allowEscToDismiss) {
                    handleDismiss()
                }
            }
        }
        document.addEventListener('keydown', listenForEscapeKey)
        return () => document.removeEventListener('keydown', listenForEscapeKey)
    }, [handleDismiss, allowEscToDismiss])

    // While the modal is visible, prevent the page behind it from scrolling, otherwise
    // we get double scrollbars whenever the modal and the page behind it both have scrollable content
    useEffect(() => {
        const originalOverflowY = document.body.style.overflowY
        document.body.style.overflowY = 'hidden'
        return () => {
            document.body.style.overflowY = originalOverflowY
        }
    }, [])

    return (
        <div id={props.id} data-qat={qaTag} className={styles.wrapper}>
            <div className={styles.blocker} role="dialog" data-qat={`${qaTag}_blocker`}>
                <div className={styles.modal} data-qat={`${qaTag}_content`}>
                    <div className={styles.closeButtonContainer}>
                        <button
                            onClick={handleDismiss}
                            className={styles.closeButton}
                            data-qat={`${qaTag}_close`}
                            title={props.closeText}
                        >
                            <img src={Close} alt={props.closeText} />
                        </button>
                    </div>
                    <div className={styles.modalContentContainer} ref={props.scrollingDivRef}>
                        <div className={styles.modalHeader} data-qat={`${qaTag}_header`}>
                            {props.headerText}
                        </div>
                        <div className={styles.modalBody} data-qat={`${qaTag}_body`}>
                            {props.children}
                        </div>
                        {props.actionButtons && (
                            <div className={styles.modalFooter} data-qat={`${qaTag}_footer`}>
                                {props.actionButtons}
                            </div>
                        )}
                    </div>
                    {props.showLoadingSpinner && (
                        <div data-qat={`${qaTag}_disabled`}>
                            <LoadingSpinner />
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}
