import { ActionUnion, createAction } from '@common/utils'
import { CURRENCY, TimePeriod } from '@common/constants'
import { PhoneNumber } from '@gtc/services/numbersApi'
import { SelectedAddon } from '../add-on'
import {
    BillingInfoDataType,
    PaymentDataType,
    SubmitDataType,
    PlanAddonPriceItem,
    BillingFrequencyVolumeDiscountRecords,
} from './state'
import { BuyFormData } from '../buy-form/state'

export enum CheckoutActionTypes {
    SET_LOADING = '[checkout-actions] set-loading',
    SET_PLAN_ID = '[checkout-actions] set-plan-id',
    SET_BILLING_FREQUENCY = '[checkout-actions] set-billing-frequency',
    CLEAR_ADDONS = '[checkout-actions] clear-addons',
    SET_SELECTED_ADDONS = '[checkout-actions] set-selected-addons',
    SET_ORGANIZERS = '[checkout-actions] set-organizers',
    SET_SELECTED_PLAN = '[checkout-actions] set-selected-plan',
    SET_EXISTING_USER_MODAL_OPEN = '[checkout-actions] set-existing-user-modal',
    CREATE_BILLING_INFO = '[checkout-actions] create-billing-info',
    CREATE_PAYMENT = '[checkout-actions] create-payment',
    SUBMIT_PAYMENT = '[checkout-actions] submit-payment',
    GET_AVAILABLE_PAYMENT_METHODS = '[checkout-actions] get-available-payment-methods',
    CALCULATE_PRICE = '[checkout-actions] calculate-price',
    SET_CALCULATED_PRICE = '[checkout-actions] set-calculated-price',
    GET_SAVED_STATE = '[checkout-actions] get-saved-state',
    SET_SAVED_STATE_LOADED = '[checkout-actions] set-saved-state-loaded',
    RESET_CHECKOUT_STATE = '[checkout-actions] reset-state',
    SET_EXISTING_ORGANIZERS = '[checkout-actions] set-existing-organizers',
    SET_PURCHASE_COMPLETE = '[checkout-actions] set-purchase-complete',
    SET_LOADING_TOTAL = '[checkout-actions] set-loading-total',
    SET_ADDON_COMPLETE = '[checkout-actions] set-addon-complete',
    SET_SELECTED_TIER_INDEX = '[checkout-actions] set-selected-tier-index',
    CALCULATE_VOLUME_DISCOUNT = '[checkout-actions] calculate-volume-discount',
    SET_VOLUME_DISCOUNT = '[checkout-actions] set-volume-discount',
    SET_VOLUME_DISCOUNT_LOADING = '[checkout-actions] set-volume-discount-loading',
    GET_VOLUME_DISCOUNT = '[checkout-actions] get-volume-discount',
    SET_CURRENT_VOLUME_DISCOUNT_PRICE_AND_PERCENTAGE = '[checkout-actions] set-current-volume-discount-price-and-percentage',
    ADJUST_CURRENT_VOLUME_DISCOUNT_FOR_REPRICE_COUPON = '[checkout-actions] adjust-current-volume-discount-for-reprice-coupon',
    SET_PRORATED_AMOUNT = '[checkout-actions] set-prorated-amount',
    SET_IS_CHOOSING_PHONE_NUMBER = '[checkout-actions] set-is-choosing-phone-number',
    RESERVE_PHONE_NUMBER = '[checkout-actions] reserve-phone-number',
    SET_PHONE_NUMBER_RESERVATION_ID = '[checkout-actions] set-phone-number-reservation-id',
    SET_PHONE_NUMBER_VANITY_PHRASE = '[checkout-actions] set-phone-number-vanity-phrase',
    SET_PHONE_NUMBER = '[checkout-actions] set-phone-number',
    SET_RESERVING_PHONE_NUMBER = '[checkout-actions] set-reserving-phone-number',
    SET_RESERVING_PHONE_NUMBER_ERROR = '[checkout-actions] set-reserving-phone-number-error',
    SET_DID_FAIL_TO_ORDER_PHONE_NUMBER = '[checkout-actions] set-did-fail-to-order-phone-number',
    SET_AUTO_LOGIN = '[checkout-form-actions] set-auto-login',
}

export const checkoutActions = {
    setLoading: (loading: boolean) => createAction(CheckoutActionTypes.SET_LOADING, { loading }),
    setPurchaseComplete: (complete: boolean) => createAction(CheckoutActionTypes.SET_PURCHASE_COMPLETE, { complete }),
    setBillingFrequency: (frequency: TimePeriod) =>
        createAction(CheckoutActionTypes.SET_BILLING_FREQUENCY, { frequency }),
    clearAddons: () => createAction(CheckoutActionTypes.CLEAR_ADDONS),
    setSelectedPlan: (planSku: string) => createAction(CheckoutActionTypes.SET_SELECTED_PLAN, { planSku }),
    setSelectedAddons: (addons: SelectedAddon[]) => createAction(CheckoutActionTypes.SET_SELECTED_ADDONS, { addons }),
    setOrganizers: (organizers: number) => createAction(CheckoutActionTypes.SET_ORGANIZERS, { organizers }),
    createBillingInfo: (billingValues: BillingInfoDataType) =>
        createAction(CheckoutActionTypes.CREATE_BILLING_INFO, {
            billingValues,
        }),
    createPayment: (paymentValues: PaymentDataType) =>
        createAction(CheckoutActionTypes.CREATE_PAYMENT, {
            paymentValues,
        }),
    submitPayment: (submitValues: SubmitDataType) =>
        createAction(CheckoutActionTypes.SUBMIT_PAYMENT, {
            submitValues,
        }),
    getPaymentMethods: () => createAction(CheckoutActionTypes.GET_AVAILABLE_PAYMENT_METHODS),
    calculatePrice: (values: BuyFormData, component?: string, planSKU?: string) =>
        createAction(
            CheckoutActionTypes.CALCULATE_PRICE,
            {
                values,
                planSKU,
            },
            { component }
        ),
    setCalculatedPrice: (
        productPrices: PlanAddonPriceItem[],
        net: number,
        total: number,
        tax: number,
        anniversaryDate: string
    ) =>
        createAction(CheckoutActionTypes.SET_CALCULATED_PRICE, {
            productPrices,
            net,
            total,
            tax,
            anniversaryDate,
        }),
    getSavedState: (urlPlan: string) =>
        createAction(CheckoutActionTypes.GET_SAVED_STATE, {
            urlPlan,
        }),
    setSavedStateLoaded: (savedStateLoaded: boolean, component: string) =>
        createAction(
            CheckoutActionTypes.SET_SAVED_STATE_LOADED,
            {
                savedStateLoaded,
            },
            { component }
        ),
    setTotalLoading: (loading: boolean) =>
        createAction(CheckoutActionTypes.SET_LOADING_TOTAL, {
            loading,
        }),
    setAddonComplete: (addonComplete: boolean) =>
        createAction(CheckoutActionTypes.SET_ADDON_COMPLETE, {
            addonComplete,
        }),
    resetCheckoutState: () => createAction(CheckoutActionTypes.RESET_CHECKOUT_STATE),
    setSelectedTierIndex: (selectedTierIndex: number, organizers: number) =>
        createAction(CheckoutActionTypes.SET_SELECTED_TIER_INDEX, { selectedTierIndex, organizers }),
    calculateVolumeDiscount: () => createAction(CheckoutActionTypes.CALCULATE_VOLUME_DISCOUNT),
    setVolumeDiscount: (
        selectedPlanSku: string,
        currency: CURRENCY,
        volumeDiscountForSelectedPlanAndCurrency: BillingFrequencyVolumeDiscountRecords
    ) =>
        createAction(CheckoutActionTypes.SET_VOLUME_DISCOUNT, {
            selectedPlanSku,
            currency,
            volumeDiscountForSelectedPlanAndCurrency,
        }),
    setVolumeDiscountLoading: (volumeDiscountLoading: boolean) =>
        createAction(CheckoutActionTypes.SET_VOLUME_DISCOUNT_LOADING, { volumeDiscountLoading }),
    getVolumeDiscount: (organizers: number, selectedTierIndex: number, billingFrequency: TimePeriod) =>
        createAction(CheckoutActionTypes.GET_VOLUME_DISCOUNT, {
            organizers,
            selectedTierIndex,
            billingFrequency,
        }),
    setCurrentVolumeDiscountPriceAndPercentage: (currentVolumeDiscountPrice: number) =>
        createAction(CheckoutActionTypes.SET_CURRENT_VOLUME_DISCOUNT_PRICE_AND_PERCENTAGE, {
            currentVolumeDiscountPrice,
        }),
    setProratedAmount: (proratedAmount: number) =>
        createAction(CheckoutActionTypes.SET_PRORATED_AMOUNT, { proratedAmount }),
    adjustCurrentVolumeDiscountForRepriceCoupon: () =>
        createAction(CheckoutActionTypes.ADJUST_CURRENT_VOLUME_DISCOUNT_FOR_REPRICE_COUPON),
    setIsChoosingPhoneNumber: (isChoosingPhoneNumber: boolean) =>
        createAction(CheckoutActionTypes.SET_IS_CHOOSING_PHONE_NUMBER, { isChoosingPhoneNumber }),
    reservePhoneNumber: (phoneNumber: PhoneNumber, vanityPhrase: string) =>
        createAction(CheckoutActionTypes.RESERVE_PHONE_NUMBER, { phoneNumber, vanityPhrase }),
    setPhoneNumberReservationId: (phoneNumberReservationId: string) =>
        createAction(CheckoutActionTypes.SET_PHONE_NUMBER_RESERVATION_ID, { phoneNumberReservationId }),
    setPhoneNumberVanityPhrase: (phoneNumberVanityPhrase: string) =>
        createAction(CheckoutActionTypes.SET_PHONE_NUMBER_VANITY_PHRASE, { phoneNumberVanityPhrase }),
    setPhoneNumber: (phoneNumber: PhoneNumber) => createAction(CheckoutActionTypes.SET_PHONE_NUMBER, { phoneNumber }),
    setReservingPhoneNumber: (reservingPhoneNumber: boolean) =>
        createAction(CheckoutActionTypes.SET_RESERVING_PHONE_NUMBER, { reservingPhoneNumber }),
    setReservingPhoneNumberError: (reservingPhoneNumberError: string) =>
        createAction(CheckoutActionTypes.SET_RESERVING_PHONE_NUMBER_ERROR, { reservingPhoneNumberError }),
    setDidFailToOrderPhoneNumber: (didFailToOrderPhoneNumber: boolean) =>
        createAction(CheckoutActionTypes.SET_DID_FAIL_TO_ORDER_PHONE_NUMBER, { didFailToOrderPhoneNumber }),
    setAutoLogin: (autoLogin?: string) =>
        createAction(CheckoutActionTypes.SET_AUTO_LOGIN, {
            autoLogin,
        }),
}

export type CheckoutActions = ActionUnion<typeof checkoutActions>
