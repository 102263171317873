/* eslint-disable react/require-default-props */
import React, { ChangeEvent, useState } from 'react'
import classNames from 'classnames'
import { RadioInputFieldWithBorder } from '@common/components/RadioInputFieldWithBorder'
import Pencil from '@common/images/icon-modify-on.svg'
import { PhoneNumber } from '@gtc/services/numbersApi'
import InfoView from '@common/components/InfoView'
import styles from './NumberPickerOptions.module.css'
import { NumberPickerContent } from '../NumberPicker.types'
import { NumberPickerSummary } from '../NumberPickerSummary/NumberPickerSummary'

interface NumberPickerOptionsProp {
    content: NumberPickerContent
    isMobileView: boolean
    handleOptionChange: (useNewNumber: boolean) => void
    handleEdit: () => void
    number?: PhoneNumber
    isChoosingPhoneNumber?: boolean
    showMissingPhoneNumberError?: boolean
    vanityPhrase: string
}

export const NumberPickerOptions: React.FC<NumberPickerOptionsProp> = (props) => {
    const {
        content,
        isMobileView,
        isChoosingPhoneNumber = true,
        showMissingPhoneNumberError = false,
        vanityPhrase,
    } = props

    const [useNewNumber, setUseNewNumber] = useState(isChoosingPhoneNumber)

    const handleEditClicked = () => {
        props.handleEdit()
    }

    const handleOptionChanged = (e: ChangeEvent<HTMLInputElement>) => {
        const option = e.target.value === 'newnumber'
        setUseNewNumber(option)
        props.handleOptionChange(option)
    }

    const optionsWrapperClassName = classNames(
        { [styles.optionsWrapper]: true },
        { [styles.optionsWrapperDesktop]: !isMobileView },
        { [styles.optionsWrapperMobile]: isMobileView }
    )

    return (
        <div className={styles.wrapper} data-qat="numberpicker_options">
            <div className={optionsWrapperClassName}>
                <div className={styles.optionContainer}>
                    <RadioInputFieldWithBorder
                        id="numberpicker_options_radio_newnumber"
                        qaTag="numberpicker_options_radio_newnumber"
                        name="numberpicker_options_radio_numberPickerType"
                        value="newnumber"
                        defaultChecked={useNewNumber}
                        onChange={handleOptionChanged}
                        label={content.localOption}
                        labelModifier={styles.firstRadioInputFieldWithBorderLabelModifier}
                    />
                </div>
                <div className={styles.optionContainer}>
                    <RadioInputFieldWithBorder
                        id="numberpicker_options_radio_portnumber"
                        qaTag="numberpicker_options_radio_portnumber"
                        name="numberpicker_options_radio_numberPickerType"
                        value="portnumber"
                        defaultChecked={!useNewNumber}
                        onChange={handleOptionChanged}
                        label={content.portNumber}
                    />
                </div>
            </div>
            {useNewNumber ? (
                <div>
                    <div className={styles.yourNumber}>{content.yourNumber}</div>
                    <NumberPickerSummary
                        content={props.content}
                        number={props.number}
                        vanityPhrase={vanityPhrase}
                        showMissingPhoneNumberError={showMissingPhoneNumberError}
                    />
                    <button
                        type="button"
                        className={styles.editButton}
                        onClick={handleEditClicked}
                        data-qat="numberpicker_options_summary_edit"
                    >
                        <img src={Pencil} alt="pencil" />
                        {props.number ? content.editNumber : content.chooseNumber}
                    </button>
                </div>
            ) : (
                <InfoView>
                    <div data-qat="numberpicker_options_port_instructions">{content.portInstructions}</div>
                </InfoView>
            )}
        </div>
    )
}
