import { useEffect, useState } from 'react'

export const usePageLoad = (onPageLoad: () => void, shouldExecute = true) => {
    const [pageLoaded, setPageLoaded] = useState(false)
    useEffect(() => {
        if (!pageLoaded && shouldExecute) {
            onPageLoad()
            setPageLoaded(true)
        }
    }, [onPageLoad, pageLoaded, shouldExecute])
}
