import React, { FunctionComponent } from 'react'

// Import Local Styles
import styles from './box-wrapper.module.css'

export interface BoxWrapperProps {
    modifier?: string
}

export const BoxWrapper: FunctionComponent<BoxWrapperProps> = ({ children, modifier, ...props }) => {
    return (
        <section className={`${styles.wrapper} ${modifier || ''}`} {...props}>
            {children}
        </section>
    )
}
