// export enum COMPANY_SIZE {
//     tier1,
//     tier2,
//     tier3,
//     tier4,
//     tier5,
//     tier6,
// }

export enum COMPANY_TIERS {
    ECOM = 'Ecomm',
    CONTACT = 'Contact',
    UNAVAILABLE = 'Unavailable',
}

export enum COMPANY_LABEL_TIERS {
    tier1 = 'tier1',
    tier2 = 'tier2',
    tier3 = 'tier3',
    tier4 = 'tier4',
    tier5 = 'tier5',
    tier6 = 'tier6',
}
