import { select, put, takeLeading, call } from 'redux-saga/effects'
import queryString from 'query-string'
import { loadSessionState, loadState } from '@lmipro/utils'
import { LmiProCheckoutActionTypes, lmiproCheckoutActions } from '@lmipro/state/checkout/actions'
import { getCheckoutState, getPlanData } from '@lmipro/state/selectors'
import { getSelectedPlan } from '@lmipro/saga/content/content-helpers'
import { LmiProPlan } from '@lmipro/state/plan'
import { TimePeriod, SC_SITE, FEATURE_FLAGS, LMIPRO_REDIRECT_PATHS, PRODUCT_SKU } from '@common/constants'
import { LmiProCheckoutState, LmiProSavedSessionState, LmiProSavedStoreState } from '@lmipro/state/checkout/state'
import { getCurrentAddons } from '@lmipro/utils/get-current-addons'
import { LmiProAddon } from '@lmipro/state/add-on'
import { lmiproCouponActions } from '@lmipro/state/coupon/actions'
import { contentActions } from '@common/state/content/actions'
import { ADDONS } from '@lmipro/constants'
import commonServices from '@common/services'

function* loadSavedStateSaga(action: ReturnType<typeof lmiproCheckoutActions.getSavedState>) {
    try {
        const savedState: LmiProSavedStoreState | undefined = yield call(loadState)
        const savedSessionState: LmiProSavedSessionState | undefined = yield call(loadSessionState)
        const checkout: LmiProCheckoutState = yield select(getCheckoutState)
        const planData: LmiProPlan[] = yield select(getPlanData)
        const selectedPlan: LmiProPlan = yield call(getSelectedPlan)
        const { urlPlan } = action.payload
        const {
            billingFrequency: queryBillingFrequency,
            minUsers,
            couponCode: queryCouponCode,
        } = queryString.parse(window.location.search)
        // grab url param for billing frequecy or saved one
        const preselectedBillingFrequency = queryBillingFrequency || savedState?.checkout?.billingFrequency
        // if min users was passed via url or saved in the store
        const preselectedMinUsers = Number(minUsers) || savedState?.checkout?.organizers
        const featureFlags: { data: { flags: string[] } } = yield call(commonServices.getFeatureFlags, SC_SITE.LOGMEIN)
        const useNewLmiProSkus = featureFlags?.data?.flags?.includes(FEATURE_FLAGS.LMIPRO_USE_NEW_SKUS)

        // grab url param for couponCode first and if not available go for saved one
        let preSavedCouponCode = (queryCouponCode as string) || savedSessionState?.coupon?.urlCouponCode
        // try to apply coupon code and also save the coupon code in state
        preSavedCouponCode = preSavedCouponCode || ''
        yield put(lmiproCouponActions.setUrlCouponCode(preSavedCouponCode))

        yield put(contentActions.setLanguage(savedSessionState?.language || ''))

        // if the value of the preselected frequency is an acceptable value and is not the same
        // as what is already saved, update it
        if (
            (preselectedBillingFrequency === TimePeriod.Month || preselectedBillingFrequency === TimePeriod.Year) &&
            preselectedBillingFrequency !== checkout.billingFrequency
        ) {
            yield put(lmiproCheckoutActions.setBillingFrequency(preselectedBillingFrequency))
        }

        if (preselectedMinUsers) {
            yield put(lmiproCheckoutActions.setOrganizers(preselectedMinUsers))
        }
        // grab url param for plan or saved in store
        if (urlPlan) {
            const preselectedPlan = urlPlan.replace(/-/g, ' ')
            // check if plan name is different
            if (urlPlan && urlPlan?.toLowerCase() !== selectedPlan.shortName.toLowerCase()) {
                // make sure plan exists in the plans data api call
                const foundPlan = planData.find(
                    (pln: LmiProPlan) =>
                        pln.shortName.split(' ').join('').toLocaleLowerCase() === preselectedPlan.toLowerCase()
                )
                if (foundPlan) {
                    yield put(lmiproCheckoutActions.setSelectedPlan(foundPlan.sKU))
                } else {
                    // if plan is provided in url but does not exist in plan data list, redirect to error page
                    window.location.href = `${process.env.LOGMEIN_MARKETING_SITE_URL}${LMIPRO_REDIRECT_PATHS.LMIPRO_REDIRECT_PRO}/404`
                    return
                }
            }
        } else if (savedState?.checkout?.selectedPlanSku) {
            // make sure plan exists in the plans data api call
            const foundPlan = planData.find((pln: LmiProPlan) => pln.sKU === savedState?.checkout?.selectedPlanSku)
            if (foundPlan) {
                yield put(lmiproCheckoutActions.setSelectedPlan(foundPlan.sKU))
            }
        } else if (!savedState?.checkout?.selectedPlanSku) {
            // if no plan is selected, default to popular plan ( Individual plan )
            const foundPlan = planData.find((pln: LmiProPlan) => pln.sKU === PRODUCT_SKU.LMIPRO_INDIVIDUAL_NAME)
            if (foundPlan) {
                yield put(lmiproCheckoutActions.setSelectedPlan(foundPlan.sKU))
            } else {
                // if popular plan doesn't exist in plan data list, default to useNewLmiProSkus ? v2 plan : first plan
                yield put(lmiproCheckoutActions.setSelectedPlan(planData?.[useNewLmiProSkus ? 1 : 0]?.sKU ?? 0))
            }
        }

        if (savedState) {
            const { selectedAddons } = savedState.checkout

            const plan: LmiProPlan = yield call(getSelectedPlan)
            // if a plan was selected
            if (plan.name) {
                // find matching addon in the plan from preselected addon
                const foundAddons: LmiProAddon[] = yield call(getCurrentAddons, selectedAddons, plan)
                yield put(lmiproCheckoutActions.setSelectedAddons(foundAddons))
                // find and update Agent addon quantity
                const agentAddons = foundAddons.find((addon) => addon.sKU.includes(ADDONS.AGENT))
                if (agentAddons) {
                    yield put(lmiproCheckoutActions.setAgents(agentAddons.quantity + plan.userAgentsIncludedAmount))
                }
                // find and update Endpoint addon quantity
                const endPointAddons = foundAddons.find(
                    (addon) => addon.sKU.includes(ADDONS.ENDPOINT) && addon.sKU !== ADDONS.ENDPOINT_PROTECTION_ADDON
                )
                if (endPointAddons) {
                    yield put(
                        lmiproCheckoutActions.setEndpoints(
                            endPointAddons.quantity * ADDONS.ENDPOINTS_ADDONS_INTERVAL + plan.endpointsIncludedAmount
                        )
                    )
                }
                // find and update extra addon selection
                const extraAddons = foundAddons.filter(
                    (addon) =>
                        addon.sKU === ADDONS.MOBILE_ADDON ||
                        addon.sKU === ADDONS.CAMERA_SHARE_ADDON ||
                        addon.sKU === ADDONS.ENDPOINT_PROTECTION_ADDON
                )
                if (extraAddons) {
                    yield put(lmiproCheckoutActions.setExtraAddons(extraAddons))
                }
            }
        }
        yield put(lmiproCheckoutActions.setSavedStateLoaded(true, 'saved state saga'))
    } catch (e) {
        // fail silently
    }
}

function* initializeLoadSavedStateSaga() {
    yield takeLeading(LmiProCheckoutActionTypes.GET_SAVED_STATE, loadSavedStateSaga)
}

export default initializeLoadSavedStateSaga
