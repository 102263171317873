import { ActionUnion, createAction } from '@common/utils'
import { CHECKOUTFORM_STEPS } from '@gtc/constants'
import { BuyFormData } from './state'

export enum BuyFormActionTypes {
    SUBMIT_BUY_FORM = '[buy-form-actions] submit-exp',
    RESET_BUY_FORM_AFFECTED_STATES = '[buy-form-actions] reset-affected-states',
    RESET_BUY_FORM = '[buy-form-actions] reset-checkout-form',
    EXISTING_USER_SUBMIT_BUY_FORM = '[buy-form-actions] existing-user-submit-exp',
    UPDATE_INITIAL_FORM_STATE = '[buy-form-actions] update-initial-form-state',
    SET_CLEAR_EMAIL = '[buy-form-actions] set-clear-email',
    UPDATE_STEPS = '[buy-form-actions] update-steps',
}

export const buyFormActions = {
    submitForm: (values: BuyFormData) => createAction(BuyFormActionTypes.SUBMIT_BUY_FORM, { values }),
    existingUserSubmitForm: (isAddonFlow: boolean) =>
        createAction(BuyFormActionTypes.EXISTING_USER_SUBMIT_BUY_FORM, { isAddonFlow }),
    resetCheckoutFormAffectedStates: () => createAction(BuyFormActionTypes.RESET_BUY_FORM_AFFECTED_STATES),
    updateInitialFormState: (data: BuyFormData) =>
        createAction(BuyFormActionTypes.UPDATE_INITIAL_FORM_STATE, {
            data,
        }),
    resetForm: () => createAction(BuyFormActionTypes.RESET_BUY_FORM),
    setClearEmail: (clear: boolean) => createAction(BuyFormActionTypes.SET_CLEAR_EMAIL, { clear }),
    updateSteps: (stepName: CHECKOUTFORM_STEPS, stepResult: boolean) =>
        createAction(BuyFormActionTypes.UPDATE_STEPS, {
            stepName,
            stepResult,
        }),
}

export type BuyFormActions = ActionUnion<typeof buyFormActions>
