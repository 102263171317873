import get from 'lodash/get'
import { Plan } from '@gtc/state/plan'
import React, { FunctionComponent } from 'react'
import classNames from 'classnames'
import { getCouponPrices } from '@gtc/utils'
import { CouponState } from '@gtc/state/coupon/state'
import { FormatCurrency, FormatDecimalCurrency } from '@common/components'

// Import Local Styles
import styles from './card.module.css'

export interface CardProps {
    onClick: () => void
    selected: boolean
    plan: Plan
    cardLabels: CardLabels
    organizers?: number
    selectedTierIndex: number
    currencyCode: string
    coupon: CouponState
    logo: string
    hideCheckCircle: boolean
    locale: string
    title: string
    modifier?: string
    splitSubtext?: string
}

export interface CardLabels {
    monthly: string
    annual: string
    user: string
    year: string
    month: string
    newLicenseCopy: string
    billedMonthlyPrice: string
    billedAnnualPrice: string
    organizer?: string
    per?: string
}

export const Card: FunctionComponent<CardProps> = (props) => {
    const {
        selected,
        cardLabels,
        organizers,
        plan,
        selectedTierIndex,
        currencyCode,
        coupon,
        logo,
        hideCheckCircle,
        locale,
        title,
        modifier = '',
        splitSubtext,
    } = props
    const tierLevel = `tier${selectedTierIndex + 1}`
    const baseTierMonthlyPrice: number = get(plan, ['pricingInfo', currencyCode, 'monthly', tierLevel, 'price'], 0)
    const baseTierAnnualPrice: number = get(plan, ['pricingInfo', currencyCode, 'annual', tierLevel, 'price'], 0)

    const { couponMonthlyPrice, couponAnnualPrice } = getCouponPrices(
        plan,
        baseTierMonthlyPrice,
        baseTierAnnualPrice,
        coupon
    )

    const buttonClassList = classNames({
        [styles.card]: true,
        [`${styles['card--selected']}`]: selected,
        [modifier]: !!modifier,
    })

    const subtextClassList = classNames({
        [styles['split-subtext']]: true,
        [styles['split-subtext--custom']]: !!splitSubtext,
    })

    return (
        <button
            className={buttonClassList}
            onClick={() => {
                props.onClick()
            }}
        >
            {!hideCheckCircle && (
                <div className={styles.circle}>
                    <span />
                </div>
            )}

            <div className={`${styles.icon}`}>
                <img src={logo} alt="daisy-logo" />
            </div>
            <h3>{title}</h3>
            <span>
                {!!organizers && organizers} {cardLabels.newLicenseCopy}
            </span>
            <div className={styles.split}>
                <div className={styles['split-wrapper']}>
                    <div className={styles['split-title']}>{cardLabels.monthly}</div>
                    <div className={styles['split-price']}>
                        <FormatDecimalCurrency price={couponMonthlyPrice} Locale={locale} CurrencyCode={currencyCode} />
                    </div>
                    {organizers && (
                        <div className={styles['split-subtext']}>
                            /{cardLabels.organizer ? cardLabels.organizer : cardLabels.user} /{cardLabels.month}
                        </div>
                    )}
                    <div className={subtextClassList}>
                        {!!splitSubtext && <span>{splitSubtext} </span>}
                        {cardLabels.billedMonthlyPrice.split('{')[0]}
                        {organizers && (
                            <FormatCurrency
                                value={couponMonthlyPrice}
                                Locale={locale}
                                CurrencyCode={currencyCode}
                                decimals={false}
                            />
                        )}
                        {cardLabels.billedMonthlyPrice.split('}')[1]}
                    </div>
                </div>
                <span className={styles['split-line']} />
                <div className={styles['split-wrapper']}>
                    <div className={styles['split-title']}>{cardLabels.annual}</div>
                    <div className={styles['split-price']}>
                        <FormatDecimalCurrency price={couponAnnualPrice} Locale={locale} CurrencyCode={currencyCode} />
                    </div>
                    {organizers && (
                        <div className={styles['split-subtext']}>
                            /{cardLabels.organizer ? cardLabels.organizer : cardLabels.user} /{cardLabels.month}
                        </div>
                    )}
                    <div className={subtextClassList}>
                        {!!splitSubtext && <span>{splitSubtext} </span>}
                        {cardLabels.billedAnnualPrice.split('{')[0]}
                        {organizers && (
                            <FormatCurrency
                                value={couponAnnualPrice * 12}
                                Locale={locale}
                                CurrencyCode={currencyCode}
                                decimals={false}
                            />
                        )}
                        {cardLabels.billedAnnualPrice.split('}')[1]}
                    </div>
                </div>
            </div>
        </button>
    )
}
