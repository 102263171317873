import get from 'lodash/get'
import { formatDate } from '@common/utils'
import { LmiProAddon, LmiProSelectedAddon } from '@lmipro/state/add-on'
import { LmiProPlan } from '@lmipro/state/plan'
import { PRODUCT_PRICE_ITEM_TYPE, ADDON_SKUS } from '@lmipro/constants'
import { PRODUCT_SKU } from '@common/constants'
import isEmpty from 'lodash/isEmpty'
import { LmiProCouponProcessedDataPerSku } from '@lmipro/state/coupon/state'
import { getAddonVolumeDiscount } from '@lmipro/utils/calculate-volume-discount'
import { LmiProProductPriceItem } from '@lmipro/state/calculate-price'

export const getAddonPrice = (addon: LmiProAddon, annual: boolean, currency: string, currentTier: string) => {
    const price: number = get(addon, ['pricingInfo', currency, annual ? 'annual' : 'monthly', currentTier, 'price'], 0)
    return annual ? price * 12 : price
}

export const calculateTotalPriceWithAddons = (
    calculatedPrice: number,
    selectedAddons: (LmiProSelectedAddon | null)[],
    selectedPlan: LmiProPlan,
    isAnnual: boolean,
    currency: string,
    currentTier: string
) => {
    let total = calculatedPrice
    selectedAddons.forEach((addon) => {
        if (addon) {
            const currAddon = selectedPlan.addons.find((x) => x.sKU === addon.sKU)
            if (currAddon) {
                const { discountPrice } = getAddonVolumeDiscount(
                    currAddon,
                    addon.quantity,
                    currency,
                    isAnnual ? 'annual' : 'monthly',
                    currentTier
                )
                if (
                    addon.sKU === ADDON_SKUS.AGENT_ADDON &&
                    (selectedPlan.sKU === PRODUCT_SKU.LMIPRO_REMOTE_SUPPORT_NAMED_V2 ||
                        selectedPlan.sKU === PRODUCT_SKU.LMIPRO_REMOTE_SUPPORT_NAMED_V2)
                ) {
                    total -= discountPrice
                } else {
                    total += getAddonPrice(currAddon, isAnnual, currency, currentTier) * addon.quantity - discountPrice
                }
            }
        }
    })
    return total
}

export const formattedEndDate = (isAnnual: boolean, locale: string) => {
    const date = new Date()
    if (isAnnual) {
        date.setFullYear(date.getFullYear() + 1)
    } else {
        date.setMonth(date.getMonth() + 1)
    }

    const formattedExpDate = formatDate(date, 'UTC', locale)

    return formattedExpDate
}

export const getFeesFromProductPrice = (productPrices: LmiProProductPriceItem[] | undefined): number => {
    let feeAmount = 0
    if (productPrices && !isEmpty(productPrices)) {
        const [feePriceItem] = productPrices.filter(
            (productPriceItem) => productPriceItem.type === PRODUCT_PRICE_ITEM_TYPE.fee
        )
        feeAmount = feePriceItem && !isEmpty(feePriceItem) ? feePriceItem.listPrice : 0
    }
    return feeAmount
}
// renewal billing amount is the  sum of ((unitprice-proratedamount) of plan * organizers + (unirprice-proratedamount) of addon * number of selected addons)
export const getRenewalAmountForAddonUser = (
    productPrices: LmiProProductPriceItem[] | undefined,
    organizers: number,
    selectedAddons: LmiProAddon[]
): number => {
    let renewalAmount = 0
    const selectedAddonQuantityMap = new Map(selectedAddons.map((addon) => [addon.sKU, addon.quantity]))
    if (productPrices && !isEmpty(productPrices)) {
        productPrices.forEach((productPriceItem) => {
            if (productPriceItem.type === PRODUCT_PRICE_ITEM_TYPE.product) {
                if (productPriceItem.itemId === 0) {
                    renewalAmount += (productPriceItem.unitPrice - productPriceItem.proratedAmount) * organizers
                } else {
                    const { productSKU } = productPriceItem
                    renewalAmount +=
                        (productPriceItem.unitPrice - productPriceItem.proratedAmount) *
                        (selectedAddonQuantityMap.get(productSKU) || 0)
                }
            }
        })
    }
    return renewalAmount
}

export const getCouponTotalSavings = (
    isCouponAppliedToPlan: boolean,
    couponProcessedDataPerSku: LmiProCouponProcessedDataPerSku | null,
    couponDoubleDipAmountForNewUser: number
): number => {
    if (couponProcessedDataPerSku && isCouponAppliedToPlan) {
        const totalCouponSavings = Object.keys(couponProcessedDataPerSku).reduce((total, productSku) => {
            const productCouponData = couponProcessedDataPerSku[productSku]
            if (productCouponData && !isEmpty(productCouponData)) {
                return total + productCouponData.discountTotalSavings
            }
            return total
        }, 0)
        return totalCouponSavings + couponDoubleDipAmountForNewUser
    }
    return couponDoubleDipAmountForNewUser
}
