import { LmiProContainerAppState } from '@lmipro/store'
// we need to complete this file, as we copy reducers from connect

export const getLabelContent = (state: LmiProContainerAppState) => state.lmipro.content.labelContent
export const getCheckoutState = (state: LmiProContainerAppState) => state.lmipro.checkout
export const getPlanData = (state: LmiProContainerAppState) => state.lmipro.content.planData
export const getContentState = (state: LmiProContainerAppState) => state.lmipro.content
export const getContentData = (state: LmiProContainerAppState) => state.lmipro.content.contentData
export const getUserState = (state: LmiProContainerAppState) => state.lmipro.user
export const getBuyFormState = (state: LmiProContainerAppState) => state.lmipro.form
export const getCouponState = (state: LmiProContainerAppState) => state.lmipro.coupon
