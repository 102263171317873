import { lmiproCheckoutActions, LmiProCheckoutActionTypes } from '@lmipro/state/checkout/actions'
import { LmiProCheckoutState } from '@lmipro/state/checkout/state'
import { getCheckoutState } from '@lmipro/state/selectors'
import { select } from 'redux-saga-test-plan/matchers'
import { put, takeLatest } from 'redux-saga/effects'

function* setBillingFrequencySaga(action: ReturnType<typeof lmiproCheckoutActions.setBillingFrequency>) {
    const { frequency: billingFrequency } = action.payload
    const { organizers, selectedTierIndex }: LmiProCheckoutState = yield select(getCheckoutState)
    yield put(lmiproCheckoutActions.getVolumeDiscount(organizers, selectedTierIndex, billingFrequency))
}

function* initializeSetBillingFrequencySaga() {
    yield takeLatest(LmiProCheckoutActionTypes.SET_BILLING_FREQUENCY, setBillingFrequencySaga)
}

export default initializeSetBillingFrequencySaga
