import { ModalState } from './state'
import { ModalActionTypes } from './actions'
import type { ModalActions } from './actions'

export const initialModalState: ModalState = {
    isModalClosable: true,
    isModalOpen: false,
    errorMessage: 'ErrorGenericMessage',
}

export const modalReducer = (previousState: ModalState | undefined, action: ModalActions): ModalState => {
    const state = previousState || initialModalState
    switch (action.type) {
        case ModalActionTypes.SET_MODAL_OPEN: {
            return {
                ...state,
                isModalOpen: action.payload.isModalOpen,
            }
        }
        case ModalActionTypes.SET_ERROR_MODAL: {
            return {
                ...state,
                isModalOpen: true,
                errorMessage: action.payload.errorMessage,
            }
        }
        default:
            return state
    }
}
