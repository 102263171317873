import { takeEvery, call } from 'redux-saga/effects'
import { trackingActions, TrackingActionTypes } from '@common/state/tracking/actions'
import { trackEvent } from '@common/utils'

export function* trackingSaga(action: ReturnType<typeof trackingActions.track>) {
    const { trackingInfo } = action.payload
    const trackData = trackingInfo.eventData ? trackingInfo.eventData : {}
    yield call(trackEvent, { event: trackingInfo.event, eventData: trackData })
}

function* initializeTrackingSaga() {
    yield takeEvery(TrackingActionTypes.TRACK, trackingSaga)
}

export default initializeTrackingSaga
