import { call, takeEvery, put, select, take } from 'redux-saga/effects'
import { buyFormActions, BuyFormActionTypes } from '@buy/state/buy-form/actions'
import { fatalErrorActions } from '@common/state/fatal-error/actions'
import { flexActions, FlexActionTypes } from '@common/state/flex/actions'
import { buyUserActions } from '@buy/state/user/actions'
import { buyCheckoutActions } from '@buy/state/checkout/actions'
import { BuyUserDataType, BuyUserState, BuyUserAccountType, BuyAbandonedCartDataType } from '@buy/state/user/state'
import {
    BuyBillingInfoDataType,
    BuyCreditCardDataType,
    BuyCheckoutState,
    BuySubmitWithCardAddDataType,
    BuySubmitWithCardAddData,
} from '@buy/state/checkout/state'
import { createTokenSaga } from '@common/sagas/flex/create-flex-token'
import { createUserSaga } from '@buy/saga/user/create-user'
import { getBillingValues } from '@buy/utils/get-billing-values'
import { getSelectedAddons, getSelectedPlan } from '@buy/saga/content/content-helpers'
import { getPaymentItems } from '@buy/utils/get-item-values'
import {
    getBuyFormState,
    getCheckoutState,
    getContentData,
    getCouponState,
    getUserState,
    getPlanData,
} from '@buy/state/selectors'
import { getFlexState, getLocationState } from '@common/state/selectors'
import { CHECKOUTFORM_STEPS, BUY_TRACKING_COMPONENTS, BUY_USER_TYPES } from '@buy/constants'
import { marketingActions } from '@common/state/marketing/actions'
import { MarketoUserInfo } from '@common/state/marketing/state'
import { makeMarketoObject } from '@common/utils'
import { LocationState } from '@common/state/location/state'
import { FlexState } from '@common/state/flex/state'
import { BuyContentData } from '@buy/state/content-data'
import { buyTrackingActions } from '@buy/state/tracking/actions'
import { PRODUCTS, SC_SITE, TimePeriod, TRACKING_EVENTS } from '@common/constants'
import { clearState, getActiveCouponServerData, getSubscriptionInfo } from '@buy/utils'
import { BuyPlan } from '@buy/state/plan'
import { BuyAddon } from '@buy/state/add-on'
import { BuyFormState } from '@buy/state/buy-form/state'
import { BuyCouponState } from '@buy/state/coupon/state'
import { CouponServerData } from '@common/state/coupon/state'
import isEmpty from 'lodash/isEmpty'
import { FatalErrorMessageType, FATAL_ERROR_TYPE } from '@common/state/fatal-error/state'
import { BuySubmitPaymentResponseData } from '@buy/state/submit-payment/state'
import { abandonedCartSaga } from '@buy/saga/user/abandoned-cart'
import { createBillingInfoSaga } from './create-billing-info'
import { submitPaymentSaga } from './submit-payment'
import { createAndSubmitPaymentSaga } from './create-and-submit-payment'

declare const Cardinal: any

function* submitFormSaga(action: ReturnType<typeof buyFormActions.submitForm>) {
    const componentName = BUY_TRACKING_COMPONENTS.FORM_SUBMISSION_SAGA
    const steps: Record<CHECKOUTFORM_STEPS, boolean> = {
        [CHECKOUTFORM_STEPS.CREATE_FLEX_TOKEN]: false,
        [CHECKOUTFORM_STEPS.CARDINAL_TRIGGER_BIN_PROCESS]: false,
        [CHECKOUTFORM_STEPS.CREATE_USER_ACCOUNT]: false,
        [CHECKOUTFORM_STEPS.SAVE_BILLING_INFO]: false,
        [CHECKOUTFORM_STEPS.SAVE_PAYMENT_INFO]: false,
        [CHECKOUTFORM_STEPS.CARDINAL_SEND_CHALLENGE]: false,
        [CHECKOUTFORM_STEPS.GET_AVAILABLE_PAYMENT_METHODS]: false,
        [CHECKOUTFORM_STEPS.SUBMIT_PAYMENT]: false,
    }
    const {
        FirstName: firstName,
        LastName: lastName,
        Email: email,
        Street1,
        ZipCode,
        City,
        StateCode,
        Password: password,
        ContactPhone,
        ExpirationDate,
        CompanyName,
    } = action.payload.values
    const checkout: BuyCheckoutState = yield select(getCheckoutState)
    const { userType }: BuyUserState = yield select(getUserState)
    const { locationInfo }: LocationState = yield select(getLocationState)
    const { fingerprint, keyInfo, isCardinalSetupComplete }: FlexState = yield select(getFlexState)
    const { submittoMarketo, marketoProductName, marketoSalesBrief, marketoSalesforceCampaignId }: BuyContentData =
        yield select(getContentData)
    const buyFormState: BuyFormState = yield select(getBuyFormState)
    const userAccountAlreadyCreated =
        buyFormState.steps.createUserAccount.completed ||
        userType === BUY_USER_TYPES.TRIALER ||
        userType === BUY_USER_TYPES.EXPIRED ||
        userType === BUY_USER_TYPES.CROSSPRODUCT
    steps[CHECKOUTFORM_STEPS.CREATE_USER_ACCOUNT] = userAccountAlreadyCreated

    const selectedPlan: BuyPlan = yield call(getSelectedPlan)
    const selectedAddons: BuyAddon[] = yield call(getSelectedAddons)
    const coupon: BuyCouponState = yield select(getCouponState)
    const { isCouponValidAndApplied } = coupon
    const couponServerData: CouponServerData | null = yield call(getActiveCouponServerData, coupon, selectedPlan)
    const isCouponValid = isCouponValidAndApplied && couponServerData && !isEmpty(couponServerData)
    const isScaEnabledForMid = keyInfo?.scaToken?.isScaEnabledForMid
    const errorMessage: FatalErrorMessageType = {
        trackErrorMessage: 'regular form submission failed',
        fatalErrorType: isScaEnabledForMid ? FATAL_ERROR_TYPE.CARDINAL_PAYMENT : undefined,
    }

    try {
        const planData: BuyPlan[] = yield select(getPlanData)
        const billingValues: BuyBillingInfoDataType = getBillingValues(action.payload.values)
        const subscriptionInfo = getSubscriptionInfo(TimePeriod.Month, selectedPlan, planData)
        const items = getPaymentItems(selectedPlan, 1, checkout.billingFrequency, selectedAddons, subscriptionInfo)

        const userValues: BuyUserDataType = {
            email,
            password,
            firstName,
            lastName,
            phoneNumber: ContactPhone,
            country: billingValues.country,
            fingerPrintSessionId: fingerprint,
            // TODO: load based on product
            productName: PRODUCTS.RESOLVE,
        }
        // #region Get flex token
        const flexTokenAction = flexActions.createToken(ExpirationDate)
        const tokenData: string = yield call(createTokenSaga, flexTokenAction)
        if (tokenData) {
            steps[CHECKOUTFORM_STEPS.CREATE_FLEX_TOKEN] = true
        } else {
            yield put(
                buyTrackingActions.track(
                    {
                        event: TRACKING_EVENTS.ERROR_PURCHASE,
                        eventData: { errorMessage: 'failed to create flex token' },
                    },
                    componentName
                )
            )
            yield put(
                fatalErrorActions.setFatalError(true, {
                    fatalErrorType: FATAL_ERROR_TYPE.CARDINAL_SETUP,
                })
            )
        }

        // if Cardinal script is not set up by now, this indicates setup went wrong. Throw fatal error
        if (isScaEnabledForMid && !isCardinalSetupComplete) {
            steps[CHECKOUTFORM_STEPS.CREATE_FLEX_TOKEN] = false
            yield put(
                fatalErrorActions.setFatalError(true, {
                    fatalErrorType: FATAL_ERROR_TYPE.CARDINAL_SETUP,
                })
            )
        }

        yield put(
            buyFormActions.updateSteps(
                CHECKOUTFORM_STEPS.CREATE_FLEX_TOKEN,
                steps[CHECKOUTFORM_STEPS.CREATE_FLEX_TOKEN]
            )
        )
        // #endregion

        // #region trigger Cardinal bin.process. Necessary for PSD2 flow.
        if (steps[CHECKOUTFORM_STEPS.CREATE_FLEX_TOKEN]) {
            const { token }: FlexState = yield select(getFlexState)
            const mask = token?.data?.number
            if (isScaEnabledForMid && mask) {
                const maskCardBin = mask.slice(0, 6)
                const { Status } = yield Cardinal.trigger('bin.process', maskCardBin)
                if (Status) {
                    steps[CHECKOUTFORM_STEPS.CARDINAL_TRIGGER_BIN_PROCESS] = true
                } else {
                    yield put(
                        fatalErrorActions.setFatalError(true, {
                            fatalErrorType: FATAL_ERROR_TYPE.CARDINAL_SETUP,
                        })
                    )
                }
            } else {
                // Bypass if SCA is not enabled.
                steps[CHECKOUTFORM_STEPS.CARDINAL_TRIGGER_BIN_PROCESS] = true
            }

            yield put(
                buyFormActions.updateSteps(
                    CHECKOUTFORM_STEPS.CARDINAL_TRIGGER_BIN_PROCESS,
                    steps[CHECKOUTFORM_STEPS.CARDINAL_TRIGGER_BIN_PROCESS]
                )
            )
        }
        // #endregion

        // #region Create user account
        if (!userAccountAlreadyCreated) {
            const createUserAction = buyUserActions.createNewUser(userValues)
            const userData: BuyUserAccountType = yield call(createUserSaga, createUserAction)
            if (userData) {
                steps[CHECKOUTFORM_STEPS.CREATE_USER_ACCOUNT] = true
                yield put(buyCheckoutActions.setAutoLogin(userData.xAutoLoginTicket))
            }
            yield put(
                buyFormActions.updateSteps(
                    CHECKOUTFORM_STEPS.CREATE_USER_ACCOUNT,
                    steps[CHECKOUTFORM_STEPS.CREATE_USER_ACCOUNT]
                )
            )
        }
        // #endregion

        // #region Save billing info
        if (
            steps[CHECKOUTFORM_STEPS.CREATE_USER_ACCOUNT] &&
            steps[CHECKOUTFORM_STEPS.CREATE_FLEX_TOKEN] &&
            steps[CHECKOUTFORM_STEPS.CARDINAL_TRIGGER_BIN_PROCESS]
        ) {
            const extraBillingValues = { ...billingValues }
            extraBillingValues.phoneNumber = ContactPhone

            const createBillingInfoAction = buyCheckoutActions.createBillingInfo(extraBillingValues)
            const savedBillingData: boolean = yield call(createBillingInfoSaga, createBillingInfoAction)
            if (savedBillingData) {
                steps[CHECKOUTFORM_STEPS.SAVE_BILLING_INFO] = true
            }
            yield put(
                buyFormActions.updateSteps(
                    CHECKOUTFORM_STEPS.SAVE_BILLING_INFO,
                    steps[CHECKOUTFORM_STEPS.SAVE_BILLING_INFO]
                )
            )
        }
        // #endregion

        // #region intialialize BOSS variables
        let paymentMethodKey = ''
        let paymentKey = ''
        const flexFormData: BuyCreditCardDataType = {
            expirationMonth: ExpirationDate.substr(0, 2),
            expirationYear: `20${ExpirationDate.substr(3, 5)}`,
            flexResponse: tokenData,
            flexKeyId: keyInfo.flexKey.keyId,
            firstName,
            lastName,
            email,
        }
        // //#endregion

        // #region process purchase - 1st purchase call - create and save payment method
        if (steps[CHECKOUTFORM_STEPS.SAVE_BILLING_INFO] && steps[CHECKOUTFORM_STEPS.CREATE_FLEX_TOKEN]) {
            const submitValues: BuySubmitWithCardAddDataType = {
                country: locationInfo.country,
                currency: locationInfo.currency,
                products: items,
                grossAmount: checkout.calculatedTotal,
                couponCode: isCouponValid ? couponServerData?.couponCode : '',
                anniversaryDate: checkout.anniversaryDate,
                cardinalJwt: keyInfo?.scaToken?.tokenString,
                referenceId: keyInfo?.scaToken?.referenceId,
                flexFormData,
                fingerprintSessionId: fingerprint,
            }

            const createAndSubmitPaymentAction = buyCheckoutActions.createAndSubmitPayment(submitValues)
            const createAndSubmitPaymentData: BuySubmitWithCardAddData = yield call(
                createAndSubmitPaymentSaga,
                createAndSubmitPaymentAction
            )
            let isChallengeRequired = false
            if (createAndSubmitPaymentData) {
                isChallengeRequired = (createAndSubmitPaymentData.isScaRequired && isScaEnabledForMid) || false
                paymentMethodKey = createAndSubmitPaymentData?.paymentMethodKey
                paymentKey = createAndSubmitPaymentData?.paymentKey
            }

            const payerAuthEnrollment = createAndSubmitPaymentData?.payerAuthEnrollment
            if (isChallengeRequired && payerAuthEnrollment) {
                // if Cardinal challenge is required, prompt challenge modal
                const { acsUrl, paReq, authenticationTransactionId } = payerAuthEnrollment
                Cardinal.continue(
                    'cca',
                    {
                        AcsUrl: acsUrl,
                        Payload: paReq,
                    },
                    {
                        OrderDetails: {
                            TransactionId: authenticationTransactionId,
                        },
                    }
                )
                yield put(
                    buyTrackingActions.track(
                        {
                            event: TRACKING_EVENTS.CARDINAL_SECURITY_MODAL,
                            eventData: {
                                securityModalAction: TRACKING_EVENTS.CARDINAL_INITIATED,
                            },
                        },
                        componentName
                    )
                )
                steps[CHECKOUTFORM_STEPS.CARDINAL_SEND_CHALLENGE] = true
                yield put(
                    buyFormActions.updateSteps(
                        CHECKOUTFORM_STEPS.CARDINAL_SEND_CHALLENGE,
                        steps[CHECKOUTFORM_STEPS.CARDINAL_SEND_CHALLENGE]
                    )
                )
            } else {
                // otherwise if challenge is NOT required, payment process is complete. Proceed to post-submit_payment steps
                if (createAndSubmitPaymentData) {
                    steps[CHECKOUTFORM_STEPS.SUBMIT_PAYMENT] = true
                    yield put(
                        buyTrackingActions.track(
                            {
                                event: TRACKING_EVENTS.PURCHASE,
                                eventData: { invoiceNumber: createAndSubmitPaymentData.invoiceNumber },
                            },
                            componentName
                        )
                    )
                }
                yield put(
                    buyFormActions.updateSteps(
                        CHECKOUTFORM_STEPS.SUBMIT_PAYMENT,
                        steps[CHECKOUTFORM_STEPS.SUBMIT_PAYMENT]
                    )
                )
            }
        }
        // #endregion

        // #region process purchase - 2nd purchase call
        if (steps[CHECKOUTFORM_STEPS.CARDINAL_SEND_CHALLENGE]) {
            // wait for cardinal challenge to be defeated
            yield take(FlexActionTypes.SET_CHALLENGE_COMPLETE_JWT)
            const { cardinalChallengeCompleteJwt }: FlexState = yield select(getFlexState)

            const submitValues: BuySubmitWithCardAddDataType = {
                country: locationInfo.country,
                currency: locationInfo.currency,
                products: items,
                grossAmount: checkout.calculatedTotal,
                couponCode: isCouponValid ? couponServerData?.couponCode : '',
                anniversaryDate: checkout.anniversaryDate,
                referenceId: keyInfo?.scaToken?.referenceId,
                flexFormData,
                fingerprintSessionId: fingerprint,
                isScaCompleted: true,
                isWithCardAddAfterSca: true,
                cardinalJwt: cardinalChallengeCompleteJwt,
                paymentKey,
                paymentMethodKey,
            }

            const submitPaymentAction = buyCheckoutActions.submitPayment(submitValues)
            const submitData: BuySubmitPaymentResponseData = yield call(submitPaymentSaga, submitPaymentAction)
            if (submitData) {
                steps[CHECKOUTFORM_STEPS.SUBMIT_PAYMENT] = true
                yield put(
                    buyTrackingActions.track(
                        { event: TRACKING_EVENTS.PURCHASE, eventData: { invoiceNumber: submitData.invoiceNumber } },
                        componentName
                    )
                )
            }

            yield put(
                buyFormActions.updateSteps(CHECKOUTFORM_STEPS.SUBMIT_PAYMENT, steps[CHECKOUTFORM_STEPS.SUBMIT_PAYMENT])
            )
        }
        // #endregion

        // #region Set purchase complete
        if (steps[CHECKOUTFORM_STEPS.SUBMIT_PAYMENT]) {
            const abandonedCartData: BuyAbandonedCartDataType = {
                email,
                success: true,
            }

            // Use "call" instead of "put" to 1) avoid the debounce and 2) wait for the promise to finish before proceeding
            const abandonCartAction = buyUserActions.submitAbandonedCart(abandonedCartData)
            yield call(abandonedCartSaga, abandonCartAction)

            yield put(buyUserActions.setDisplayName(firstName))
            yield put(
                buyTrackingActions.track(
                    { event: TRACKING_EVENTS.COMPLETE_BUY_FLOW, eventData: { userEmail: email } },
                    componentName
                )
            )
            if (submittoMarketo) {
                const { planKey } = selectedPlan ?? {}
                const marketoData: MarketoUserInfo = makeMarketoObject({
                    firstName,
                    lastName,
                    email,
                    planKey,
                    Street1,
                    City,
                    StateCode,
                    ZipCode,
                    ContactPhone,
                    marketoSalesBrief,
                    marketoSalesforceCampaignId,
                    marketoProductName,
                    CompanyName,
                })
                // TODO: load based on product
                marketoData.sc_site = SC_SITE.RESOLVE
                yield put(marketingActions.postToMarketo(marketoData))
            }
            yield put(
                buyTrackingActions.track(
                    {
                        event: TRACKING_EVENTS.EMAIL_VALIDATION,
                        eventData: {
                            userEmail: email,
                            formName: BUY_TRACKING_COMPONENTS.CHECKOUT_PAGE,
                        },
                    },
                    componentName
                )
            )
            // delete saved state
            yield call(clearState)
            yield put(buyCheckoutActions.setPurchaseComplete(true))
            return
        }
        // #endregion

        // Trigger form submission error if any of the steps are missed
        yield put(fatalErrorActions.setFatalError(true, errorMessage))
    } catch (e) {
        yield put(fatalErrorActions.setFatalError(true, errorMessage))
    }
}

function* initializeSubmitFormSaga() {
    yield takeEvery(BuyFormActionTypes.SUBMIT_BUY_FORM, submitFormSaga)
}

export default initializeSubmitFormSaga
