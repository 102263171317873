import { buyCouponActions, BuyCouponActionTypes } from '@buy/state/coupon/actions'
import { BuyCouponProcessedDataPerSku } from '@buy/state/coupon/state'
import { GetBuyProductItemsParameters, BuyProcessCouponDataParameters, BuyProductDetails } from '@buy/state/coupon-util'
import { getCouponItemPriceMap, getProductItemsMap, processCouponData } from '@buy/utils'
import { call, put, takeLatest } from 'redux-saga/effects'
import { TimePeriod, COUPON_DISCOUNT_TYPE } from '@common/constants'

function* calculateCouponPriceSaga(action: ReturnType<typeof buyCouponActions.calculateCouponPrice>) {
    const { couponCalculationData } = action.payload
    const {
        couponServerData,
        couponSkuValidity,
        organizers,
        billingFrequency,
        selectedCurrency,
        selectedAddons,
        selectedPlan,
        selectedTierIndex,
    } = couponCalculationData
    const { couponDiscountType } = couponServerData
    const getProductItemsArguments: GetBuyProductItemsParameters = {
        organizers,
        billingFrequency,
        selectedCurrency,
        selectedAddons,
        selectedPlan,
        selectedTierIndex,
    }
    const isAnnual = billingFrequency === TimePeriod.Year
    const productItemsMap: Map<string, BuyProductDetails> = yield call(getProductItemsMap, getProductItemsArguments)
    const couponItemsMap: Map<string, number> = yield call(
        getCouponItemPriceMap,
        couponServerData,
        selectedCurrency,
        isAnnual
    )
    const processCouponDataArguments: BuyProcessCouponDataParameters = {
        couponDiscountType,
        productItemsMap,
        couponItemsMap,
        couponSkuValidity,
    }
    const couponProcessedDataPerSku: BuyCouponProcessedDataPerSku | null = yield call(
        processCouponData,
        processCouponDataArguments
    )
    if (couponServerData.couponDiscountType === COUPON_DISCOUNT_TYPE.REPRICE) {
        yield put(
            buyCouponActions.checkVolumeDiscountAgainstRepriceCoupon({
                couponServerData,
                couponProcessedDataPerSku,
                selectedPlan,
            })
        )
    }
    yield put(buyCouponActions.setCouponProcessedData(couponProcessedDataPerSku))
}

export default function* initializeCalculateCouponPriceSaga() {
    yield takeLatest(BuyCouponActionTypes.CALCULATE_COUPON_PRICE, calculateCouponPriceSaga)
}
