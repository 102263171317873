import { call, takeEvery, put } from 'redux-saga/effects'
import { flexActions, FlexActionTypes } from '@common/state/flex/actions'
import { fatalErrorActions } from '@common/state/fatal-error/actions'
import commonServices from '@common/services'
import { getGUID } from '@common/utils/getGUID'
import { GenericResponse } from '@common/state/generic-response/generic-response'

export function* flexFormSaga(action: ReturnType<typeof flexActions.getFlexToken>) {
    const { data, iterator } = action.payload
    try {
        const response: GenericResponse = yield call(commonServices.getFlexTokenCall, data)
        if (response && response.data) {
            const guid = getGUID()
            yield put(flexActions.setFingerPrint(guid))
            yield put(flexActions.setKeyInfo(response.data))
        }
    } catch (e) {
        if (iterator < 3) {
            yield put(flexActions.getFlexToken(data, iterator + 1))
        } else {
            yield put(fatalErrorActions.setFatalError(true, { trackErrorMessage: 'could not get flex token' }))
        }
    }
}

function* initializeFlexFormSaga() {
    yield takeEvery(FlexActionTypes.GET_FLEX_TOKEN, flexFormSaga)
}

export default initializeFlexFormSaga
