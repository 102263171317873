import React, { AnchorHTMLAttributes, FunctionComponent } from 'react'
import classNames from 'classnames'

import styles from './common-link.module.css'

export interface CommonLinkProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
    modifier?: string
    arrow?: boolean
}

export const CommonLink: FunctionComponent<CommonLinkProps> = ({ arrow, modifier = '', children, ...props }) => {
    const classList = classNames({
        [styles.link]: true,
        [styles['link--arrow']]: arrow,
        [modifier]: modifier,
    })

    return (
        <a {...props} className={classList}>
            {children}
        </a>
    )
}
