import { call, takeEvery, put } from 'redux-saga/effects'
import { checkoutActions, CheckoutActionTypes } from '@gtc/state/checkout/actions'
import { createBillingInfo } from '@gtc/services'
import { fatalErrorActions } from '@common/state/fatal-error/actions'
import { TRACKING_EVENTS } from '@common/constants'
import { gtcTrackingActions } from '@gtc/state/tracking/actions'
import { TRACKING_COMPONENTS } from '@gtc/constants'
import { GenericResponse } from '@common/state/generic-response/generic-response'

export function* createBillingInfoSaga(action: ReturnType<typeof checkoutActions.createBillingInfo>) {
    const componentName = TRACKING_COMPONENTS.CREATE_BILLING_INFO_SAGA
    try {
        const { billingValues } = action.payload
        const billingResponse: GenericResponse = yield call(createBillingInfo, billingValues)
        if (billingResponse.status === 200 || billingResponse.status === 204) {
            return true
        }
    } catch {
        yield put(
            gtcTrackingActions.track(
                {
                    event: TRACKING_EVENTS.ERROR_PURCHASE,
                    eventData: { errorMessage: 'failed to save billing info' },
                },
                componentName
            )
        )
        yield put(fatalErrorActions.setFatalError(true, { trackErrorMessage: 'failed to save billing info' }))
    }
    return false
}

function* initializeCreateBillingInfoSaga() {
    yield takeEvery(CheckoutActionTypes.CREATE_BILLING_INFO, createBillingInfoSaga)
}

export default initializeCreateBillingInfoSaga
