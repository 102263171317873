/* eslint-disable react/require-default-props */
import React, { ChangeEvent } from 'react'
import classNames from 'classnames'
import styles from './radioInputFieldWithBorder.module.css'

interface RadioInputFieldWithBorderProps {
    id?: string
    modifier?: string
    qaTag?: string
    label?: string | React.ReactNode
    name?: string
    defaultChecked?: boolean
    value?: string
    labelModifier?: string
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void
}

export const RadioInputFieldWithBorder: React.FC<RadioInputFieldWithBorderProps> = ({
    id = '',
    label = '',
    labelModifier = '',
    qaTag = '',
    modifier = '',
    name = '',
    defaultChecked,
    value = '',
    onChange,
}) => {
    const wrapperClassList = classNames({
        [styles.wrapper]: true,
        [modifier]: modifier,
    })

    return (
        <div className={wrapperClassList}>
            <input
                type="radio"
                id={id}
                name={name}
                defaultChecked={defaultChecked}
                data-qat={qaTag ? `${qaTag}-checkbox` : ''}
                onChange={onChange}
                value={value}
            />
            {/* Put the label after the input so that the css selector for highlighting the label after the focused checkbox will work */}
            <label className={labelModifier} htmlFor={id}>
                <span>{label}</span>
            </label>
        </div>
    )
}
