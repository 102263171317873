import { call, takeEvery, select } from 'redux-saga/effects'
import { accountActions, AccountActionTypes } from '@common/state/account/actions'
import { AccountState } from '@common/state/account/state'
import { getAccountState } from '@common/state/selectors'
import isEmpty from 'lodash/isEmpty'
import { updateUser } from '@common/services'

export function* updateAccountEmailVerifificationSaga(
    action: ReturnType<typeof accountActions.updateAccountEmailVerification>
) {
    try {
        const { emailCode, emailToken, accessToken } = action.payload?.emailVerificationInfo
        const { accountSubscriptions }: AccountState = yield select(getAccountState)

        // if an existing user only has non-trial subscription, user's email must be verified
        if (emailCode && emailToken && accessToken && !isEmpty(accountSubscriptions)) {
            yield call(updateUser, {
                emailCode,
                emailToken,
                accessToken,
            })
        }
    } catch (e) {
        // fail silently
    }
}

function* initializeUpdateAccountEmailVerificationSaga() {
    yield takeEvery(AccountActionTypes.UPDATE_ACCOUNT_EMAIL_VERIFICATION, updateAccountEmailVerifificationSaga)
}

export default initializeUpdateAccountEmailVerificationSaga
