import { call, takeEvery, put } from 'redux-saga/effects'
import { resolveCheckoutActions, ResolveCheckoutActionTypes } from '@gtresolve/state/checkout/actions'
import { savePayment } from '@gtresolve/services'
import { fatalErrorActions } from '@common/state/fatal-error/actions'
import { TRACKING_EVENTS } from '@common/constants'
import { resolveTrackingActions } from '@gtresolve/state/tracking/actions'
import { RESOLVE_TRACKING_COMPONENTS } from '@gtresolve/constants'
import { GenericResponse } from '@common/state/generic-response/generic-response'

export function* createPaymentSaga(action: ReturnType<typeof resolveCheckoutActions.createPayment>) {
    const componentName = RESOLVE_TRACKING_COMPONENTS.CREATE_PAYMENT_SAGA
    try {
        const { paymentValues } = action.payload
        const savePaymentResponse: GenericResponse = yield call(savePayment, paymentValues)
        if (savePaymentResponse.status === 200 || savePaymentResponse.status === 204) {
            return true
        }
    } catch {
        yield put(
            resolveTrackingActions.track(
                {
                    event: TRACKING_EVENTS.ERROR_PURCHASE,
                    eventData: { errorMessage: 'failed to save payment info' },
                },
                componentName
            )
        )
        yield put(fatalErrorActions.setFatalError(true, { trackErrorMessage: 'failed to save payment info' }))
    }
    return false
}

function* initializeCreatePaymentSaga() {
    yield takeEvery(ResolveCheckoutActionTypes.CREATE_PAYMENT, createPaymentSaga)
}

export default initializeCreatePaymentSaga
