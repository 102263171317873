/* eslint-disable react/require-default-props */
import React, { ChangeEvent } from 'react'
import { RadioInputFieldWithBorder } from '@common/components/RadioInputFieldWithBorder'
import { PhoneNumber } from '@gtc/services/numbersApi'
import { Skeleton } from '@common/components/Skeleton'
import { ErrorView } from '@common/components/ErrorView'
import { NumberPickerContent, NumberPickerSummary } from '@gtc/components/NumberPicker'
import styles from './SearchResults.module.css'

interface IProps {
    handleSelectNumber: (number: PhoneNumber) => void
    numberList: PhoneNumber[]
    defaultSelectedNumber?: PhoneNumber
    loading: boolean
    content: NumberPickerContent
    isLocalNumber: boolean
    errorMessage?: string
    vanityPhrase: string
}

export const SearchResults = (props: IProps) => {
    const {
        numberList,
        handleSelectNumber,
        defaultSelectedNumber,
        loading,
        content,
        isLocalNumber,
        errorMessage,
        vanityPhrase,
    } = props

    const handleOptionChanged = (e: ChangeEvent<HTMLInputElement>) => {
        const selectedNumber = numberList.find((number) => number.e164Number === e.target.value) as PhoneNumber
        handleSelectNumber(selectedNumber)
    }

    return (
        <div className={styles.wrapper} data-qat="numberpicker_modal_searchresults">
            {errorMessage && numberList.length > 0 && (
                <div className={styles.errorSaving} data-qat="numberpicker_modal_saving_error">
                    <ErrorView>{errorMessage}</ErrorView>
                </div>
            )}

            <h3 className={styles.searchResultsHeading}>{content.chooseAvailableNumber}</h3>

            {errorMessage && numberList.length === 0 && (
                <div className={styles.errorSearching} data-qat="numberpicker_modal_searchresults_error">
                    <ErrorView>{errorMessage}</ErrorView>
                </div>
            )}

            {!errorMessage && loading && (
                <div className={styles.containerLoading} data-qat="numberpicker_modal_searchresults_loading">
                    {[...Array(10).fill('')].map((_value: string, index: number) => (
                        <div key={`skeleton_${index.toString()}`}>
                            <Skeleton id={`skeleton_${index}`} size={isLocalNumber ? 'xxlarge' : 'xlarge'} />
                        </div>
                    ))}
                </div>
            )}

            {!errorMessage && !loading && numberList.length === 0 && (
                <div className={styles.noResultsWrapper} data-qat="numberpicker_modal_searchresults_noresults">
                    <ErrorView>{content.noResults}</ErrorView>
                </div>
            )}

            {!loading && numberList.length > 0 && (
                <div className={styles.containerResults} data-qat="numberpicker_modal_searchresults_resultslist">
                    {numberList.map((number, index) => (
                        <div key={number.e164Number}>
                            <RadioInputFieldWithBorder
                                id={`numberpicker_radio_${index}`}
                                qaTag={`numberpicker_radio_${index}`}
                                name="numberPickerType"
                                defaultChecked={
                                    defaultSelectedNumber && defaultSelectedNumber.e164Number === number.e164Number
                                }
                                label={
                                    <NumberPickerSummary
                                        number={number}
                                        vanityPhrase={vanityPhrase}
                                        content={content}
                                        showBadge={false}
                                        qaTag={`numberpicker_modal_radio_label_${index}`}
                                    />
                                }
                                value={number.e164Number}
                                onChange={handleOptionChanged}
                            />
                        </div>
                    ))}
                </div>
            )}
        </div>
    )
}
