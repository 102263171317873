import React, { FunctionComponent } from 'react'
import { useChatbot } from '@common/hooks'

export interface ChatbotScriptProps {
    siteType: string
}

export const ChatbotScript: FunctionComponent<ChatbotScriptProps> = ({ siteType }) => {
    useChatbot(siteType)
    return <></>
}
