import { Checkbox, ConvertStringToReact, FormSubmitButton, TrustArcLogo } from '@common/components'
import { connectRedux, GoToComponentProps } from '@common/utils'
import { NumberPickerContent } from '@gtc/components/NumberPicker'
import { ConnectedOrderSummary } from '@gtc/connectedComponents'
import {
    CheckoutFormSubmissionActionsType,
    mapCheckoutFormSubmissionActions,
    mapCheckoutFormSubmissionState,
} from '@gtc/connectedComponents/CheckoutFormSubmission/connector'
import { CheckoutFormSubmissionState } from '@gtc/connectedComponents/CheckoutFormSubmission/state'

import React, { FunctionComponent, useState } from 'react'
import paymentStyles from '../PaymentFormInfo/payment-info.module.css'

export interface CheckoutFormSubmissionComponentProps {
    isSubmitting: boolean
    preSubmit: () => void
    isTabletOrMobileView?: boolean | undefined
    setIsOptOut: (value: React.SetStateAction<boolean>) => void
    isOptOut: boolean
    contentNumberPicker: NumberPickerContent
    inlineErrors: { [key: string]: string } | Record<string, unknown>
}
type CheckoutFormSubmissionProps = GoToComponentProps<
    CheckoutFormSubmissionState,
    CheckoutFormSubmissionActionsType,
    CheckoutFormSubmissionComponentProps
>

export const CheckoutFormSubmission: FunctionComponent<CheckoutFormSubmissionProps> = ({ state, props }) => {
    const { content, isFatalError, fatalErrorBlocker, totalLoading, displayOptOut: initialDisplayOptOut } = state
    const { isSubmitting, preSubmit, isTabletOrMobileView, setIsOptOut, isOptOut, contentNumberPicker, inlineErrors } =
        props
    const [displayOptOut, setDisplayOptOut] = useState(initialDisplayOptOut)
    return (
        <>
            {!isTabletOrMobileView ? (
                <>
                    <FormSubmitButton
                        purpose="primary"
                        modifier={paymentStyles.submit}
                        disabled={
                            isSubmitting ||
                            isFatalError ||
                            fatalErrorBlocker ||
                            totalLoading ||
                            (inlineErrors && !!Object.keys(inlineErrors).length)
                        }
                        isSubmitting={isSubmitting}
                        type="button"
                        onClick={() => {
                            preSubmit()
                        }}
                        text={content.completePurchase}
                    />
                    <div className={paymentStyles.terms}>
                        <ConvertStringToReact htmlString={content.pricingNote} />{' '}
                        <button
                            className={paymentStyles['optout-button']}
                            onClick={() => setDisplayOptOut(!displayOptOut)}
                            type="button"
                        >
                            {content.optOut}
                        </button>
                        {displayOptOut && (
                            <Checkbox
                                label={content.optOutCheckboxLabel}
                                checked={isOptOut}
                                onchange={() => setIsOptOut(!isOptOut)}
                                modifier={paymentStyles['optout-checkbox']}
                                qaTag="checkout-form-optout"
                            />
                        )}
                    </div>
                </>
            ) : (
                <>
                    <ConnectedOrderSummary isTabletOrMobileView contentNumberPicker={contentNumberPicker} />
                    <FormSubmitButton
                        purpose="primary"
                        modifier={paymentStyles.submit}
                        disabled={
                            isSubmitting ||
                            isFatalError ||
                            fatalErrorBlocker ||
                            totalLoading ||
                            (inlineErrors && !!Object.keys(inlineErrors).length)
                        }
                        isSubmitting={isSubmitting}
                        type="button"
                        onClick={() => {
                            preSubmit()
                        }}
                        text={content.completePurchase}
                    />
                    <div className={paymentStyles.terms}>
                        <ConvertStringToReact htmlString={content.pricingNote} />{' '}
                        <button
                            className={paymentStyles['optout-button']}
                            onClick={() => setDisplayOptOut(!displayOptOut)}
                            type="button"
                        >
                            {content.optOut}
                        </button>
                        {displayOptOut && (
                            <Checkbox
                                label={content.optOutCheckboxLabel}
                                checked={isOptOut}
                                onchange={() => setIsOptOut(!isOptOut)}
                                modifier={paymentStyles['optout-checkbox']}
                                qaTag="checkout-form-optout"
                            />
                        )}
                    </div>
                    <TrustArcLogo />
                </>
            )}
        </>
    )
}

export const ConnectedCheckoutFormSubmission = connectRedux(
    CheckoutFormSubmission,
    mapCheckoutFormSubmissionState,
    mapCheckoutFormSubmissionActions
)
