export enum PRODUCT_FAMILY_KEY {
    G2M = 'G2M',
    G2W = 'G2W',
    G2T = 'G2T',
    G2C = 'G2C',
    G2R = 'G2RV',
    LMIPRO = 'lmip',
}

export enum PRODUCT_FAMILY_NAME {
    G2C = 'GoToConnect',
    G2R = 'GoToResolve',
    LMIPRO = 'Pro', // TODO: WAE1-3569: Update this value if needed
}

export enum PRODUCT_SKU {
    G2C_JUST_TALK = 'G2CJustTalk',
    G2C = 'G2C',
    G2RE_BASIC_NAMED = 'GTRBasicNamed',
    G2RE_STANDARD_NAME = 'GTRStandardNamed',
    GTR_REMOTE_SUPPORT_NAMED_V2 = 'GTRRemoteSupportNamed-v2',
    GTR_STANDARD_NAMED_V2 = 'GTRStandardNamed-v2',
    GTR_REMOTE_ACCESS = 'GTRRemoteAccess',
    // TODO: WAE1-3569: Update these names to use LMI specific values
    LMIPRO_BASIC_NAMED = 'GTRBasicNamed',
    LMIPRO_STANDARD_NAME = 'GTRStandardNamed',
    LMIPRO_REMOTE_SUPPORT_NAMED_V2 = 'GTRRemoteSupportNamed-v2',
    LMIPRO_STANDARD_NAMED_V2 = 'GTRStandardNamed-v2',
    LMIPRO_REMOTE_ACCESS = 'GTRRemoteAccess',
    LMIPRO_INDIVIDUAL_NAME = 'Pro2',
    LMIPRO_SMALL_BUSSINESS_NAME = 'Pro5',
    LMIPRO_POWER_USER_NAME = 'Pro10',
    LMIPRO_POWER_USER_25_NAME = 'Pro25',
    LMIPRO_POWER_USER_50_NAME = 'Pro50',
}

export enum PRODUCTS {
    CONNECT = 'connect',
    RESOLVE = 'resolve',
    LMIPRO = 'logmeinpro', // TODO: WAE1-3569: Update this value if needed
}

export enum SC_SITE {
    RESOLVE = 'gotoresolve',
    CONNECT = 'gotoconnect',
    LOGMEIN = 'logmein',
    BUY = 'buy',
}

export enum PREFIX {
    G2C = 'G2C',
    GTR = 'GTR',
}
