import { connectRedux, GoToComponentProps } from '@common/utils'
import {
    CheckoutFormContainerActionsType,
    mapCheckoutFormContainerActions,
    mapCheckoutFormContainerState,
} from '@gtc/connectedComponents/CheckoutFormContainer/connector'
import { CheckoutFormContainerState } from '@gtc/state/checkout-form-container/state'
import { BuyFormData } from '@gtc/state/buy-form/state'
import { CheckoutSchema } from '@gtc/utils'
import { Formik, FormikProps } from 'formik'
import React, { FunctionComponent, useEffect, useRef } from 'react'
import { NumberPickerContent } from '@gtc/components/NumberPicker'
import { ConnectedCheckoutForm } from '../CheckoutForm'

type CheckoutFormContainerProps = GoToComponentProps<
    CheckoutFormContainerState,
    CheckoutFormContainerActionsType,
    {
        isTabletOrMobileView?: boolean
        isMobileView?: boolean
        setHasAddressValidationErrors: React.Dispatch<React.SetStateAction<boolean>>
        setGeneralFieldErrors: React.Dispatch<React.SetStateAction<boolean>>
        setHasCardValidationErrors: React.Dispatch<React.SetStateAction<boolean>>
        hasAddressValidationErrors: boolean
        generalFieldErrors: boolean
        hasCardValidationErrors: boolean
        contentNumberPicker: NumberPickerContent
    }
>

export const CheckoutFormContainer: FunctionComponent<CheckoutFormContainerProps> = ({ state, props, actions }) => {
    const formikRef = useRef<FormikProps<BuyFormData>>(null)
    const isSubmittingRef = useRef<boolean>(false)
    // save form data when component unmounts
    useEffect(() => {
        const getFormValues = () => {
            return formikRef.current?.values
        }
        return () => {
            const formValues = getFormValues()
            if (formValues) {
                actions.updateInitialFormState(formValues)
            }
        }
    }, [actions])
    return (
        <Formik
            initialValues={state.initialData}
            validationSchema={() => CheckoutSchema(state.initialData.CountryCode, state.errorContent)}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={(_values, formikBag) => {
                formikBag.setSubmitting(false)
            }}
            innerRef={formikRef}
        >
            {(formikProps) => {
                if (formikProps.isSubmitting) {
                    isSubmittingRef.current = true
                }

                if (isSubmittingRef.current && !formikProps.isSubmitting) {
                    isSubmittingRef.current = false
                }
                return (
                    <ConnectedCheckoutForm
                        {...props}
                        initialData={state.initialData}
                        errorContent={state.errorContent}
                        formikRef={formikRef}
                        isSubmittingRef={isSubmittingRef}
                    />
                )
            }}
        </Formik>
    )
}

export const ConnectCheckoutFormContainer = connectRedux(
    CheckoutFormContainer,
    mapCheckoutFormContainerState,
    mapCheckoutFormContainerActions
)
