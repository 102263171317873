import { BuyAddon, BuySelectedAddon } from '@buy/state/add-on'
import { BuyPlan } from '@buy/state/plan'
import { ADDONS } from '@buy/constants'
import { BillingDetailedSubscriptionPlan } from '@common/state/billing/state'
import isEmpty from 'lodash/isEmpty'

export const getCurrentAddons = (selectedAddons: BuySelectedAddon[], selectedPlan: BuyPlan): BuyAddon[] => {
    const { addons } = selectedPlan ?? {}
    const myAddons = selectedAddons.reduce((mySelectedAddons: BuyAddon[], localAddon: BuySelectedAddon) => {
        const matchingAddon = addons.find((add: BuyAddon) => add.sKU === localAddon.sKU)
        if (matchingAddon) {
            matchingAddon.quantity = localAddon.quantity
            return [...mySelectedAddons, matchingAddon]
        }

        return mySelectedAddons
    }, [])
    return myAddons
}

export const getExtraAddons = (addons: BuyAddon[]) => {
    return addons.filter(
        (addon) =>
            addon.sKU === ADDONS.MOBILE_ADDON ||
            addon.sKU === ADDONS.CAMERA_SHARE_ADDON ||
            addon.sKU === ADDONS.ENDPOINT_PROTECTION_ADDON
    )
}

export const getCurrentAgent = (addons: BuyAddon[]) => {
    return addons.filter((addon) => addon.sKU.includes(ADDONS.AGENT))[0]
}

export const getCurrentEndpoint = (addons: BuyAddon[]) => {
    return addons.filter(
        (addon) => addon.sKU.includes(ADDONS.ENDPOINT) && addon.sKU !== ADDONS.ENDPOINT_PROTECTION_ADDON
    )[0]
}

export const getUserSubscribedAccumulatedAddon = (subscriptions: BillingDetailedSubscriptionPlan[]) => {
    const userSubscribedAddonsQuantity = subscriptions.reduce((prev, curr) => {
        /* eslint-disable no-param-reassign */
        prev += curr.quantity
        return prev
    }, 0)

    const userSubscribedAccumulatedAddon = { ...subscriptions[subscriptions.length - 1] }
    userSubscribedAccumulatedAddon.quantity = userSubscribedAddonsQuantity

    return userSubscribedAccumulatedAddon
}

export const getAccumulatedUserSubscriptions = (
    subscriptions: BillingDetailedSubscriptionPlan[],
    planData: BuyPlan[]
) => {
    const selectedPlan: BuyPlan[] = planData.filter((plan: BuyPlan) => {
        return !!subscriptions.find((sub: BillingDetailedSubscriptionPlan) => plan.sKU === sub.productSku)
    })

    // if user does not have any GoToResolve subscriptions, exit
    if (isEmpty(selectedPlan)) {
        return subscriptions
    }

    const currentAgent = getCurrentAgent(selectedPlan[0].addons)
    const currentEndpoint = getCurrentEndpoint(selectedPlan[0].addons)
    const accumulatedSubscriptions = []

    const userSubscribedAgents = subscriptions.filter(
        (sub: BillingDetailedSubscriptionPlan) => sub.productSku === currentAgent.sKU
    )
    const userSubscribedEndpoints = subscriptions.filter(
        (sub: BillingDetailedSubscriptionPlan) => sub.productSku === currentEndpoint.sKU
    )

    if (userSubscribedAgents.length) {
        const userSubscribedAgent = getUserSubscribedAccumulatedAddon(userSubscribedAgents)
        accumulatedSubscriptions.push(userSubscribedAgent)
    }

    if (userSubscribedEndpoints.length) {
        const userSubscribedEndpoint = getUserSubscribedAccumulatedAddon(userSubscribedEndpoints)
        accumulatedSubscriptions.push(userSubscribedEndpoint)
    }

    accumulatedSubscriptions.push(
        ...subscriptions.filter((sub) => sub.productSku !== currentAgent.sKU && sub.productSku !== currentEndpoint.sKU)
    )

    return accumulatedSubscriptions
}

export const getUserSubscribedAddons = (
    accountSubscriptions: BillingDetailedSubscriptionPlan[],
    selectedPlan: BuyPlan
) => {
    if (!selectedPlan || !accountSubscriptions.length) return {}

    const currentAgent = getCurrentAgent(selectedPlan.addons)
    const currentEndpoint = getCurrentEndpoint(selectedPlan.addons)

    return {
        disablePlanSelection: false,
        disableExtraAddons: false,
        userSubscribedAgent: accountSubscriptions.find((sub) => sub.productSku === currentAgent.sKU),
        userSubscribedEndpoint: accountSubscriptions.find((sub) => sub.productSku === currentEndpoint.sKU),
    }
}

export const getIncludedAddons = (accountSubscriptions: BillingDetailedSubscriptionPlan[], selectedPlan: BuyPlan) => {
    if (!selectedPlan) return { includedEndpoints: 0, includedAgents: 0 }
    const { userSubscribedAgent, userSubscribedEndpoint } =
        accountSubscriptions && getUserSubscribedAddons(accountSubscriptions, selectedPlan)

    const includedAgents = userSubscribedAgent
        ? selectedPlan.userAgentsIncludedAmount + userSubscribedAgent.quantity
        : selectedPlan.userAgentsIncludedAmount

    const includedEndpoints = userSubscribedEndpoint
        ? selectedPlan.endpointsIncludedAmount + ADDONS.ENDPOINTS_ADDONS_INTERVAL * userSubscribedEndpoint.quantity
        : selectedPlan.endpointsIncludedAmount

    return { includedEndpoints, includedAgents }
}
