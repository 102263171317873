export enum COUPON_TYPE {
    FLEXIBLE = 'Flexible',
    FIX = 'Fix',
}
export enum COUPON_DISCOUNT_TYPE {
    REPRICE = 'Reprice',
    AMOUNTOFF = 'AmountOff',
    PERCENTAGEOFF = 'PercentageOff',
}

export enum COUPON_ACTION {
    NEW_PURCHASE = 'NewPurchase',
}

export enum COUPON_PAYMENT_PLAN {
    UPFRONTMONTHLY = 'UpfrontMonthly',
    UPFRONTANNUAL = 'UpfrontAnnual',
}
