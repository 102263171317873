import { TRACKING_EVENTS } from '@common/constants'
import { usePageLoad } from '@common/hooks'
import { TRACKING_COMPONENTS } from '@gtc/constants'
import { useGTCTracking } from './useGTCTracking'

export interface BillingPageTrackingParams {
    contentLoaded?: boolean
    isUserLoggedIn?: boolean
}
export const useBillingPageTracking = (params: BillingPageTrackingParams) => {
    const { track } = useGTCTracking()
    const componentName = TRACKING_COMPONENTS.BILLING_PAGE
    const trackCTAClick = (name: string, url: string) => {
        track({ event: TRACKING_EVENTS.CLICK_CTA, eventData: { name, url } }, componentName)
    }
    usePageLoad(() => {
        track({ event: TRACKING_EVENTS.SESSION_INFORMATION }, componentName)
    }, params.contentLoaded && params.isUserLoggedIn)

    return { trackCTAClick }
}
