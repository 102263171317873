import { PRODUCT_PRICE_ITEM_TYPE } from '@gtc/constants'
import { Addon } from '@gtc/state/add-on'
import { ProductPriceItem } from '@gtc/state/calculate-price'

export const getTotalProratedAmount = (
    productPrices: ProductPriceItem[],
    organizers: number,
    selectedAddons: Addon[]
): number => {
    let proratedAmount = 0
    const selectedAddonQuantityMap = new Map(selectedAddons.map((addon) => [addon.sKU, addon.quantity]))
    if (productPrices) {
        productPrices.forEach((productPriceItem) => {
            if (productPriceItem.type === PRODUCT_PRICE_ITEM_TYPE.product) {
                if (productPriceItem.itemId === 0) {
                    proratedAmount += productPriceItem.proratedAmount * organizers
                } else {
                    const { productSKU } = productPriceItem
                    proratedAmount += productPriceItem.proratedAmount * (selectedAddonQuantityMap.get(productSKU) || 0)
                }
            }
        })
    }
    return proratedAmount
}
