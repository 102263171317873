export interface PhoneInfo {
    phoneNumberStatus?: PHONE_NUMBER_STATUS
    phoneNumberType?: PHONE_NUMBER_TYPE
}

export enum PHONE_NUMBER_STATUS {
    ENTERED = 'entered',
    NOT_ENTERED = 'not entered',
}

export enum PHONE_NUMBER_TYPE {
    LOCAL = 'local',
    TOLL_FREE = 'toll-free',
}
