import React, { FC, useState, useEffect } from 'react'
import classNames from 'classnames'
import zxcvbn from 'zxcvbn'

import { ReactComponent as TipIcon } from '../../images/icon-pw-tip.svg?react'
import { ReactComponent as ChevronDown } from '../../images/icon-chevron-down-blue.svg?react'
import { ReactComponent as ChevronUp } from '../../images/icon-chevron-up-blue.svg?react'

import styles from './passwordStrengthIndicator.module.css'

interface PasswordStrengthIndicatorProps {
    id: string
    value: string | number | readonly string[]
    touched: boolean
    error?: string
    genericPasswordLabel: string
    passwordStrength: string
    passwordStrengthLevel1: string
    passwordStrengthLevel2: string
    passwordStrengthLevel3: string
    passwordStrengthLevel4: string
    passwordStrengthTip1: string
    passwordStrengthTip2: string
    passwordStrengthTip3: string
    passwordStrengthTipDrawer: string
    isSubmittingRef: React.RefObject<boolean>
    isFocused: boolean
}

export const PasswordStrengthIndicator: FC<PasswordStrengthIndicatorProps> = ({
    id,
    value = '',
    touched,
    error,
    passwordStrength,
    passwordStrengthLevel1,
    passwordStrengthLevel2,
    passwordStrengthLevel3,
    passwordStrengthLevel4,
    genericPasswordLabel,
    passwordStrengthTipDrawer,
    passwordStrengthTip1,
    passwordStrengthTip2,
    passwordStrengthTip3,
    isSubmittingRef,
    isFocused,
}) => {
    const [showTips, setShowTips] = useState(false)
    const [pwStrength, setPWStrength] = useState(0)

    const displayGenericError = touched && error

    const parsedValue = String(value)

    const isSubmitting = isSubmittingRef?.current

    useEffect(() => {
        const { score } = zxcvbn(parsedValue)
        if (score === 0 && parsedValue.length > 0) {
            setPWStrength(1)
        } else {
            setPWStrength(score)
        }
    }, [parsedValue])

    useEffect(() => {
        if ((error && !isFocused) || (error && isSubmitting)) {
            setShowTips(true)
        }
    }, [error, setShowTips, isFocused, isSubmitting])

    const strengthClasslist = classNames({
        [styles.strength]: true,
        [styles['strength--weak']]: pwStrength === 1,
        [styles['strength--fair']]: pwStrength === 2,
        [styles['strength--good']]: pwStrength === 3,
        [styles['strength--strong']]: pwStrength === 4,
    })

    const subheadClasslist = classNames({
        [styles.subhead]: true,
        [styles['subhead--error']]: displayGenericError,
    })

    const tiphClasslist = classNames({
        [styles['tip-drawer']]: true,
        [styles['tip-drawer--open']]: showTips,
    })

    return (
        <div className={strengthClasslist}>
            <div className={styles['strength-bar']}>
                <div />
                <div />
                <div />
                <div />
            </div>
            <div>
                <label className={styles['strength-bar-label']} htmlFor={id}>
                    {passwordStrength}:{' '}
                </label>
                <label className={styles['strength-bar-strength']} htmlFor={id}>
                    {
                        [
                            '',
                            passwordStrengthLevel1,
                            passwordStrengthLevel2,
                            passwordStrengthLevel3,
                            passwordStrengthLevel4,
                        ][pwStrength]
                    }
                </label>
            </div>
            {displayGenericError && <span className={subheadClasslist}>{genericPasswordLabel}</span>}
            <div className={tiphClasslist}>
                <div
                    className={styles['tip-drawer-header']}
                    onClick={() => setShowTips(!showTips)}
                    onKeyDown={() => setShowTips(!showTips)}
                    role="presentation"
                >
                    <TipIcon /> {passwordStrengthTipDrawer} {showTips ? <ChevronUp /> : <ChevronDown />}
                </div>
                <div className={styles['tip-drawer-body']}>
                    <ul>
                        <li>{passwordStrengthTip1}</li>
                        <li>{passwordStrengthTip2}</li>
                        <li>{passwordStrengthTip3}</li>
                    </ul>
                </div>
            </div>
        </div>
    )
}
