import { TRACKING_EVENTS } from '@common/constants'
import { usePageLoad } from '@common/hooks'
import { TRACKING_COMPONENTS } from '@gtc/constants'
import { useGTCTracking } from './useGTCTracking'

export interface CustomizePageTrackingParams {
    planName: string
}

export const useCustomizePageTracking = (params: CustomizePageTrackingParams) => {
    const componentName = TRACKING_COMPONENTS.CUSTOMIZE_PAGE
    const { track } = useGTCTracking()
    const trackCustomizeViewClick = () => {
        track({ event: TRACKING_EVENTS.VIEW_ITEM }, componentName)
    }
    usePageLoad(() => {
        track({ event: TRACKING_EVENTS.VIEW_CONTENT }, componentName)
        track({ event: TRACKING_EVENTS.BEGIN_CHECKOUT, eventData: { step: 1 } }, componentName)
        track({ event: TRACKING_EVENTS.START_BUY_FLOW }, componentName)
    }, !!params.planName)
    return { trackCustomizeViewClick }
}
