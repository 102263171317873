// import dependencies
import React, { FunctionComponent, useMemo, useEffect, useState } from 'react'

import { ToggleButton, Tooltip, TrustArcLogo } from '@common/components'
import { TimePeriod, COUPON_DISCOUNT_TYPE, BillingPeriodKey } from '@common/constants'
import { getSessionState, saveSessionState, connectRedux, GoToComponentProps, formatDate } from '@common/utils'
import Phone from '@common/images/icon-phone.svg'

import { ConnectedFormatCurrency } from '@gtc/connectedComponents'
import { USER_TYPES } from '@gtc/constants'
import {
    mapOrderSummaryState,
    mapOrderSummaryActions,
    OrderSummaryActionsType,
} from '@gtc/connectedComponents/OrderSummary/connector'
import { OrderSummaryState } from '@gtc/connectedComponents/OrderSummary/state'
import { calculateTotalPriceWithAddons, getCouponTotalSavings, getFeesFromProductPrice } from '@gtc/utils'
import { MemoOrderSummaryAddon } from '@gtc/components'

import isEmpty from 'lodash/isEmpty'
import toggleStyles from '@common/components/ToggleButton/toggle-button.module.css'
import { NumberPickerContent, NumberPickerSummary } from '@gtc/components/NumberPicker'
import classNames from 'classnames'
import styles from './order-summary.module.css'

type OrderSummaryProps = GoToComponentProps<
    OrderSummaryState,
    OrderSummaryActionsType,
    {
        isTabletOrMobileView?: boolean
        isMobileView?: boolean
        contentNumberPicker: NumberPickerContent
    }
>

const OrderSummary: FunctionComponent<OrderSummaryProps> = ({ state, actions, props }) => {
    const {
        billingFrequency,
        selectedPlan,
        organizers,
        selectedAddons,
        content,
        CurrencyCode,
        currentVolumeDiscountPrice,
        baseTierAnnualPrice,
        baseTierMonthlyPrice,
        currentTierPrice,
        calculatedProratedAmount,
        productPrices,
        couponProcessedDataPerSku,
        isCouponValidAndApplied,
        couponCode,
        couponDiscountType,
        couponDoubleDipAmountForNewUser,
        isChoosingPhoneNumber,
        phoneNumber,
        phoneNumberVanityPhrase,
        userEmail,
    } = state

    // coupon discounts
    const planCouponData = couponProcessedDataPerSku ? couponProcessedDataPerSku[selectedPlan.sKU] : null
    const isCouponAppliedtoPlan = isCouponValidAndApplied && !isEmpty(planCouponData)
    const planCouponDiscountPercentage = planCouponData?.discountPercentage || 0
    const planCouponDiscountAmount = planCouponData?.discountTotalSavings || 0
    const isPercentageCoupon = couponDiscountType === COUPON_DISCOUNT_TYPE.PERCENTAGEOFF
    const totalCouponDiscountSavings = getCouponTotalSavings(
        isCouponValidAndApplied,
        couponProcessedDataPerSku,
        couponDoubleDipAmountForNewUser
    )
    const isRepriceCouponAndApplied = couponDiscountType === COUPON_DISCOUNT_TYPE.REPRICE && isCouponAppliedtoPlan
    const isAnnual = billingFrequency === TimePeriod.Year

    const [isAnnualOnly, setIsAnnualOnly] = useState(false)
    const [toggleContent, setToggleContent] = useState({})

    // tier 1 (base tier) price to calculate discounts off
    const calculatedBasePrice = isAnnual ? baseTierMonthlyPrice * 12 : baseTierMonthlyPrice
    const basePriceTotal = calculatedBasePrice * organizers

    // coupon processed current Tier price
    const couponProcessedMonthlyCurrentTierPrice = isRepriceCouponAndApplied ? baseTierMonthlyPrice : currentTierPrice
    const couponProcessedAnnualCurrentTierPrice = isRepriceCouponAndApplied ? baseTierAnnualPrice : currentTierPrice

    // current selected tier to calculate total off
    const calculatedMonthlyPrice = organizers * couponProcessedMonthlyCurrentTierPrice
    const calculatedAnnualPrice = organizers * couponProcessedAnnualCurrentTierPrice * 12
    const calculatedTotalPriceForPlan = isAnnual ? calculatedAnnualPrice : calculatedMonthlyPrice

    // annual discount off the selected tier
    const annualDiscountAmount = (baseTierMonthlyPrice - baseTierAnnualPrice) * organizers * 12

    // annual only price (no discount)
    const basePriceTotalAnnualOnly = basePriceTotal - annualDiscountAmount

    // total price of plan plus addons (volume discount is already accounted in the plan)
    const currentTotalPrice = useMemo(
        () =>
            calculateTotalPriceWithAddons(
                calculatedTotalPriceForPlan,
                selectedAddons,
                selectedPlan,
                isAnnual,
                CurrencyCode
            ),
        [calculatedTotalPriceForPlan, isAnnual, selectedAddons, selectedPlan, CurrencyCode]
    )

    // total savings (add the prorated discount only if its negative, otherwise its not a saving anymore)
    const saving =
        (isAnnual ? annualDiscountAmount : 0) +
        currentVolumeDiscountPrice +
        (calculatedProratedAmount < 0 ? Math.abs(calculatedProratedAmount) : 0) +
        totalCouponDiscountSavings

    const subtotalWithCouponDiscounts = currentTotalPrice - totalCouponDiscountSavings
    const subtotal = state.calculatedNetTotal ? state.calculatedNetTotal : subtotalWithCouponDiscounts
    const total = state.calculatedTotal ? state.calculatedTotal : subtotal
    // use the BOSS calculate price API to get the fees
    const fees = getFeesFromProductPrice(productPrices)

    // labels and state display
    const isAddonFlow = state.isUserLoggedIn && state.userType === USER_TYPES.ADDON
    const displayProrated =
        isAddonFlow && calculatedProratedAmount !== 0 && state.anniversaryDate && state.anniversaryDate.length > 1

    const isPlural = organizers > 1

    const setToggleContentState = (obj: Record<any, string | number>) => {
        setToggleContent((prevState) => {
            return {
                ...prevState,
                ...obj,
            }
        })
    }

    useEffect(() => {
        if (
            selectedPlan.billingPeriods.length === 1 &&
            selectedPlan.billingPeriods.find((item) => item.key === BillingPeriodKey.ANNUAL)
        ) {
            setIsAnnualOnly(true)
        } else {
            setIsAnnualOnly(false)
        }
        selectedPlan.billingPeriods.forEach((item) => {
            if (item.key === BillingPeriodKey.ANNUAL) {
                setToggleContentState({ label2: item.name })
            } else {
                setToggleContentState({ label1: item.name })
            }
        })
        setToggleContentState({ callout: content.save, discountAmount: annualDiscountAmount })
    }, [selectedPlan, annualDiscountAmount, content.save])

    const addonsContent = {
        year: content.year,
        month: content.month,
        percentageOffPromoDiscount: content.percentageOffPromoDiscount,
    }

    const returningLicenseTotal = state.existingAccountInformation?.quantity
        ? state.existingAccountInformation.quantity + organizers
        : organizers
    const returningLicenceCopy = ` ${
        isPlural ? content.newUserLicensePlural : content.newUserLicense
    } (${returningLicenseTotal} ${content.total})`
    const newLicenseCopy = ` ${isPlural ? content.quantityLabelPlural : content.quantityLabel}`

    const anniversaryDate = displayProrated ? formatDate(new Date(state.anniversaryDate), 'UTC', state.Locale) : ''
    const proratedTooltip = `${content.billingCycleCredit} ${anniversaryDate} - ${content.today}`
    const discountLineClasses = `${styles.small} ${styles.teal}`

    useEffect(() => {
        const qualtricsDataGtc = getSessionState('qualtrics_data_gtc')
        saveSessionState('qualtrics_data_gtc', {
            ...(!qualtricsDataGtc ? {} : qualtricsDataGtc),
            // id: '', // added on GTM side
            sku: selectedPlan.sKU,
            purchase_term: isAnnual ? 'annual' : 'monthly',
            purchase_type: isAddonFlow ? 'addon' : 'new',
            order_total: calculatedTotalPriceForPlan,
            // referral_channel: '', // added on GTM side
            promo_code_applied: couponCode ?? '',
            userEmail,
        })
    }, [selectedPlan.sKU, isAnnual, isAddonFlow, calculatedTotalPriceForPlan, couponCode, userEmail])

    return (
        <div>
            <div className={styles.wrapper}>
                <h2 className={styles.header}>{content.orderSummary}</h2>
                {!isAnnualOnly && (
                    <ToggleButton
                        content={toggleContent}
                        onChangeFn={() => actions.setBillingFrequency(isAnnual ? TimePeriod.Month : TimePeriod.Year)}
                        disabled={isAddonFlow}
                        isOn={isAnnual}
                        modifier={`${toggleStyles['wrapper--order-summary']} ${styles.togglePadding}`}
                        id={`toggle-${Math.random()}`}
                        isLarge
                    />
                )}

                {isChoosingPhoneNumber && (
                    <div className={classNames([styles.section, styles.phoneSummary])} data-qat="phone-summary">
                        <div>
                            <img src={Phone} alt={props.contentNumberPicker.phone} />
                        </div>
                        <div>
                            <NumberPickerSummary
                                content={props.contentNumberPicker}
                                number={phoneNumber}
                                qaTag="ordersummary_numberpickersummary"
                                vanityPhrase={phoneNumberVanityPhrase}
                            />
                        </div>
                    </div>
                )}
                <div className={styles.section} data-qat="plan-summary">
                    <div className={styles.row}>
                        <span className={styles.item} data-qat="line-item-name">
                            {`${state.selectedPlan.groupName} ${state.selectedPlan.name}`}
                        </span>
                        <span className={styles.item} data-qat="line-item-subtotal">
                            <ConnectedFormatCurrency value={isAnnualOnly ? basePriceTotalAnnualOnly : basePriceTotal} />
                        </span>
                    </div>
                    <div className={styles.row}>
                        <span className={styles.small} data-qat="line-item-per-period-price">
                            <ConnectedFormatCurrency value={calculatedBasePrice} /> <span>/{content.user}</span>
                            <span>/{isAnnual ? content.year : content.month}</span>
                        </span>
                    </div>
                    <div className={styles.row}>
                        <span className={styles.small} data-qat="line-item-quantity">
                            {organizers}
                            {isAddonFlow && state.existingAccountInformation ? returningLicenceCopy : newLicenseCopy}
                        </span>
                    </div>
                </div>
                {!isAnnualOnly && isAnnual && (
                    <div className={styles.section} data-qat="annual-discount">
                        <div className={styles.row}>
                            <span className={discountLineClasses} data-qat="annual-savings-label">
                                {content.percentageOffAnnualDiscount}
                            </span>
                            <span className={discountLineClasses} data-qat="annual-discount-price">
                                -<ConnectedFormatCurrency value={annualDiscountAmount} />
                            </span>
                        </div>
                    </div>
                )}
                {currentVolumeDiscountPrice > 0 && (
                    <div className={styles.section} data-qat="volume-discount">
                        <div className={styles.row}>
                            <span className={discountLineClasses}>
                                {content.percentageOffVolumeDiscount}
                                <Tooltip content={content.volumeDiscountPopupText} />
                            </span>
                            <span className={discountLineClasses} data-qat="volume-discount-price">
                                -<ConnectedFormatCurrency value={currentVolumeDiscountPrice} />
                            </span>
                        </div>
                    </div>
                )}
                {isCouponAppliedtoPlan && (
                    <div className={styles.section} data-qat="coupon-discount">
                        <div className={styles.row}>
                            <span className={discountLineClasses}>
                                <span data-qat="coupon-discount-percentage">
                                    {isPercentageCoupon ? (
                                        `${planCouponDiscountPercentage}%`
                                    ) : (
                                        <ConnectedFormatCurrency value={planCouponDiscountAmount} />
                                    )}
                                    &nbsp;
                                </span>
                                {content.percentageOffPromoDiscount}
                            </span>
                            <span className={discountLineClasses} data-qat="coupon-discount-price">
                                -
                                <ConnectedFormatCurrency value={planCouponDiscountAmount} />
                            </span>
                        </div>
                    </div>
                )}
                {couponDoubleDipAmountForNewUser > 0 && (
                    <div className={styles.section} data-qat="coupon-weird-discount">
                        <div className={styles.row}>
                            <span className={discountLineClasses}>
                                {content.couponAdjustmentSavings}
                                <Tooltip content={content.couponAdjustmentSavingsPopupText} />
                            </span>
                            <span className={discountLineClasses} data-qat="coupon-weird-discount-price">
                                -
                                <ConnectedFormatCurrency value={couponDoubleDipAmountForNewUser} />
                            </span>
                        </div>
                    </div>
                )}
                {displayProrated && (
                    <div className={styles.section}>
                        <div className={styles.row}>
                            <span className={discountLineClasses} data-qat="prorated_label">
                                {content.proratedAdjustment}
                                <Tooltip content={proratedTooltip} />
                            </span>
                            <span className={discountLineClasses} data-qat="prorated_amount">
                                <ConnectedFormatCurrency value={calculatedProratedAmount} />
                            </span>
                        </div>
                    </div>
                )}

                <MemoOrderSummaryAddon
                    selectedAddons={selectedAddons}
                    planAddons={selectedPlan.addons}
                    isAnnual={isAnnual}
                    currency={CurrencyCode}
                    content={addonsContent}
                    couponProcessedDataPerSku={couponProcessedDataPerSku}
                    isCouponValidAndApplied={isCouponValidAndApplied}
                    isPercentageCoupon={isPercentageCoupon}
                />

                <div className={`${styles.section} ${styles.total} ${styles.totalPadding}`} data-qat="totals-summary">
                    <div className={`${styles.row}  ${styles['row--subtotal']}`} data-qat="order-fees">
                        <span data-qat="label">
                            {content.fees} <Tooltip content={content.feesPopupText} />
                        </span>
                        <span data-qat="amount">
                            {state.totalLoading ? (
                                '--'
                            ) : (
                                <>{state.calculatedTax ? <ConnectedFormatCurrency value={fees} /> : content.tBD}</>
                            )}
                        </span>
                    </div>
                    <div className={`${styles.row}  ${styles['row--subtotal']}`} data-qat="order-subtotal">
                        <span data-qat="label">{content.subtotal}</span>
                        <span data-qat="amount">
                            {state.totalLoading ? '--' : <ConnectedFormatCurrency value={subtotal} />}
                        </span>
                    </div>
                    <div className={`${styles.row}  ${styles['row--subtotal']}`} data-qat="order-estimated-tax">
                        <span data-qat="label">
                            {content.estimatedTax} <Tooltip content={content.estimatedTaxPopupInfo} />
                        </span>

                        <span data-qat="amount">
                            {state.totalLoading ? (
                                '--'
                            ) : (
                                <>
                                    {state.calculatedTax ? (
                                        <ConnectedFormatCurrency value={state.calculatedTax} />
                                    ) : (
                                        content.tBD
                                    )}
                                </>
                            )}
                        </span>
                    </div>
                    <div className={`${styles.row}  ${styles['row--total']}`} data-qat="order-total">
                        <span data-qat="label">
                            {content.total} {CurrencyCode}
                        </span>
                        <span data-qat="amount">
                            {state.totalLoading ? (
                                `${content.calculating}...`
                            ) : (
                                <ConnectedFormatCurrency value={total} />
                            )}
                        </span>
                    </div>
                    {!isAnnualOnly && saving > 0 && (
                        <div className={styles.row} data-qat="total-saving">
                            <span className={styles.totalSavings}>{content.totalSavings}</span>
                            <span className={styles.totalSavings} data-qat="volume-discount-price">
                                <ConnectedFormatCurrency value={saving} />
                            </span>
                        </div>
                    )}
                </div>
            </div>
            {!props.isTabletOrMobileView && <TrustArcLogo label={content.trustArcSupport} />}
        </div>
    )
}

export const ConnectedOrderSummary = connectRedux(OrderSummary, mapOrderSummaryState, mapOrderSummaryActions)

export default ConnectedOrderSummary
