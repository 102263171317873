import axios from 'axios'
import { SC_SITE } from '@common/constants'
import {
    BuyBillingInfoDataType,
    BuyPaymentDataType,
    BuySubmitDataType,
    BuyCalculatePriceDataType,
    BuySubmitWithCardAddDataType,
} from '@buy/state/checkout/state'
import { BuyUserDataType, BuyAbandonedCartPayload } from '@buy/state/user/state'
import { getContentLanguage } from '@buy/utils/language-helpers'

export interface BuyPaymentMethodDataType {
    accessToken?: string
    billingAccountKey?: string
}

export interface BuyCardHeadersType {
    AccessToken: string
    BillingAccountKey: string
    PaymentMethodKey: string
}

export interface BuyLabelDataType {
    id: string
    plan: string
    promoCode: string
    accessToken: string
    timezone: string
}

export interface apiQueryParamsType {
    /* eslint-disable camelcase */
    sc_site: string
    sc_lang?: string
}

export const apiQueryParams = {
    // TODO: load based on product
    sc_site: SC_SITE.RESOLVE,
    sc_lang: 'en',
}

export const setLanguageParam = (queryParams: apiQueryParamsType) => {
    return {
        ...queryParams,
        sc_lang: getContentLanguage(),
    }
}

/* APP DATA */

// get buy flow api data

export function getBuyLabelData(): Promise<any> {
    const updatedQueryParams = setLanguageParam(apiQueryParams)
    return axios.post('/ecommapi/content/buy/gtr', null, { params: updatedQueryParams })
}

// get available payment  methods
export function getAvailablePaymentMethods() {
    return axios.post('/ecommapi/purchase/GetAvailablePaymentMethods', null, {
        withCredentials: true,
    })
}

// create user account
export function createUserAccount(data: BuyUserDataType): Promise<any> {
    return axios.post('/ecommapi/identity/CreateAccount/', data, {
        withCredentials: true,
        params: apiQueryParams,
    })
}

// create billing information
export function createBillingInfo(data: BuyBillingInfoDataType): Promise<any> {
    return axios.post('/ecommapi/purchase/SetBillingInfo', data, {
        withCredentials: true,
    })
}

// calculate payment
export function calculatePrice(data: BuyCalculatePriceDataType): Promise<any> {
    return axios.post('/ecommapi/purchase/CalculatePrice', data)
}

// create payment info
export function savePayment(data: BuyPaymentDataType) {
    return axios.post('/ecommapi/purchase/SavePaymentMethodV3', data, {
        withCredentials: true,
    })
}

// submit payment with card add
// api reference: https://confluence.ops.expertcity.com/display/MMM/API+-+Public+-+Process+Purchase+With+Card+Add+V3
export function submitPaymentWithCardAdd(data: BuySubmitWithCardAddDataType): Promise<any> {
    return axios.post('/ecommapi/purchase/ProcessPurchaseWithCardAddV3', data, {
        withCredentials: true,
    })
}

// submit payment
export function submitPayment(data: BuySubmitDataType | BuySubmitWithCardAddDataType) {
    return axios.post('/ecommapi/purchase/ProcessPurchaseV3', data, {
        withCredentials: true,
    })
}

export function validateTaxNumber(country: string, taxNumber: string, federalTaxType?: string) {
    return axios.get('/ecommapi/purchase/ValidateTaxNumber', {
        params: {
            country,
            taxNumber,
            federalTaxType,
        },
    })
}

// cart abandonment
export function submitAbandonedCart(data: BuyAbandonedCartPayload) {
    return axios.post('/ecommapi/form/abandonment', data, { params: apiQueryParams })
}

export default {
    getBuyLabelData,
    calculatePrice,
    createUserAccount,
    createBillingInfo,
    submitPaymentWithCardAdd,
}
