import { BoxWrapper, ConvertStringToReact, InputField, InlineErrorView } from '@common/components'
import { useClearField } from '@common/hooks'
import { connectRedux, GoToComponentProps } from '@common/utils'
import { CHECKOUTFORM_FIELDS, CHECKOUTFORM_STEPS } from '@gtc/constants'
import {
    AccountFormInfoActionsType,
    mapAccountFormInfoActions,
    mapAccountFormInfoState,
} from '@gtc/connectedComponents/AccountFormInfo/connector'
import { AccountFormInfoState } from '@gtc/connectedComponents/AccountFormInfo/state'
import { BuyFormData } from '@gtc/state/buy-form/state'
import { manuallyValidateFieldData } from '@gtc/utils'
import { Field, FieldProps, FormikProps } from 'formik'
import React, { FunctionComponent } from 'react'
import { PasswordValidation } from '@gtc/components/PasswordValidation'
import passwordValidationStyles from '@gtc/components/PasswordValidation/password-validation.module.css'
import accountStyles from './account-info.module.css'

export interface AccountFormInfoComponentProps {
    useSameForBilling: boolean
    resetFatalError: () => void
    canValidateAddress: (values: BuyFormData) => void
    formikRef: React.RefObject<FormikProps<BuyFormData>>
    setCheckoutFormInlineError: (value: string) => void
    removeCheckoutFormInlineError: (value: string) => void
    inlineErrors: { [key: string]: string } | Record<string, unknown>
    isSubmittingRef: React.RefObject<boolean>
}

type AccountFormInfoProps = GoToComponentProps<
    AccountFormInfoState,
    AccountFormInfoActionsType,
    AccountFormInfoComponentProps
>
export const AccountFormInfo: FunctionComponent<AccountFormInfoProps> = ({ state, actions, props }) => {
    const {
        useSameForBilling,
        resetFatalError,
        canValidateAddress,
        formikRef,
        setCheckoutFormInlineError,
        removeCheckoutFormInlineError,
        inlineErrors,
        isSubmittingRef,
    } = props
    const { content, errorContent, clearEmail, steps } = state
    const createUserAccountStep = steps[CHECKOUTFORM_STEPS.CREATE_USER_ACCOUNT]
    const userAccountCreated = createUserAccountStep.completed && !createUserAccountStep.error

    const userLogIn = () => actions.userLogIn()
    const existingUserCheck = (email: string, form: BuyFormData) => {
        actions.checkExistingUser(email, form)
    }

    // clear email
    useClearField(clearEmail, formikRef, CHECKOUTFORM_FIELDS.EMAIL)

    return (
        <BoxWrapper>
            <div className="account-info">
                <h3>
                    <ConvertStringToReact htmlString={content.createAnAccount} />{' '}
                    <button
                        className={accountStyles.signin}
                        onClick={userLogIn}
                        type="button"
                        data-qat="login-account-button"
                    >
                        {content.signIn}
                    </button>
                </h3>
                <div className={accountStyles.grid}>
                    <Field name={CHECKOUTFORM_FIELDS.FIRST_NAME}>
                        {({ form, field, meta }: FieldProps) => {
                            return (
                                <InlineErrorView
                                    fieldData={{ form, field, meta }}
                                    inlineErrors={inlineErrors}
                                    setErrorCall={setCheckoutFormInlineError}
                                    removeErrorCall={removeCheckoutFormInlineError}
                                >
                                    <InputField
                                        name={field.name}
                                        value={field.value}
                                        autoFocus
                                        error={meta.error}
                                        id="first-name"
                                        label={content.firstName}
                                        maxLength={50}
                                        type="text"
                                        wrapperClass={accountStyles['first-name']}
                                        onBlur={async (e) => {
                                            await form.setFieldValue(field.name, e.target.value.trim())
                                            field.onBlur(e)
                                            form.validateField(field.name)
                                            resetFatalError()
                                            canValidateAddress(form.values)
                                        }}
                                        onChange={async (e) => {
                                            field.onChange(e)
                                            if (useSameForBilling) {
                                                await form.setFieldValue(
                                                    CHECKOUTFORM_FIELDS.BILLING_FIRST_NAME,
                                                    e.target.value
                                                )
                                            }
                                        }}
                                        valid={manuallyValidateFieldData({ form, field, meta })}
                                        disabled={userAccountCreated}
                                    />
                                </InlineErrorView>
                            )
                        }}
                    </Field>

                    <Field name={CHECKOUTFORM_FIELDS.LAST_NAME}>
                        {({ form, field, meta }: FieldProps) => {
                            return (
                                <InlineErrorView
                                    fieldData={{ form, field, meta }}
                                    inlineErrors={inlineErrors}
                                    setErrorCall={setCheckoutFormInlineError}
                                    removeErrorCall={removeCheckoutFormInlineError}
                                >
                                    <InputField
                                        name={field.name}
                                        value={field.value}
                                        error={meta.error}
                                        id="last-name"
                                        label={content.lastName}
                                        type="text"
                                        wrapperClass={accountStyles['last-name']}
                                        onBlur={async (e) => {
                                            await form.setFieldValue(field.name, e.target.value.trim())
                                            field.onBlur(e)
                                            form.validateField(field.name)
                                            resetFatalError()
                                            canValidateAddress(form.values)
                                        }}
                                        onChange={async (e) => {
                                            field.onChange(e)
                                            if (useSameForBilling) {
                                                await form.setFieldValue(
                                                    CHECKOUTFORM_FIELDS.BILLING_LAST_NAME,
                                                    e.target.value
                                                )
                                            }
                                        }}
                                        valid={manuallyValidateFieldData({ form, field, meta })}
                                        disabled={userAccountCreated}
                                    />
                                </InlineErrorView>
                            )
                        }}
                    </Field>

                    <Field name={CHECKOUTFORM_FIELDS.EMAIL}>
                        {({ form, field, meta }: FieldProps) => {
                            return (
                                <InlineErrorView
                                    fieldData={{ form, field, meta }}
                                    inlineErrors={inlineErrors}
                                    setErrorCall={setCheckoutFormInlineError}
                                    removeErrorCall={removeCheckoutFormInlineError}
                                >
                                    <InputField
                                        error={meta.error}
                                        id="login__email"
                                        label={content.email}
                                        name={field.name}
                                        value={field.value}
                                        type="email"
                                        wrapperClass={accountStyles.email}
                                        onBlur={async (e) => {
                                            await form.setFieldValue(field.name, e.target.value.trim())
                                            field.onBlur(e)
                                            form.validateField(field.name)
                                            if (!meta.error && e.target.value.length > 0) {
                                                existingUserCheck(e.target.value, form.values)
                                                canValidateAddress(form.values)
                                            }

                                            resetFatalError()
                                        }}
                                        onChange={(e) => {
                                            field.onChange(e)
                                            form.validateField(field.name)
                                        }}
                                        valid={manuallyValidateFieldData({ form, field, meta })}
                                        disabled={userAccountCreated}
                                    />
                                </InlineErrorView>
                            )
                        }}
                    </Field>

                    <Field name={CHECKOUTFORM_FIELDS.PASSWORD}>
                        {({ form, field, meta }: FieldProps) => (
                            <InlineErrorView
                                fieldData={{ form, field, meta }}
                                inlineErrors={inlineErrors}
                                setErrorCall={setCheckoutFormInlineError}
                                removeErrorCall={removeCheckoutFormInlineError}
                            >
                                <PasswordValidation
                                    id="login__password"
                                    label={content.password}
                                    name={field.name}
                                    error={meta.error}
                                    genericPasswordLabel={
                                        field.value.length > 0
                                            ? errorContent.errorInvalidPassword
                                            : errorContent.errorRequiredPassword
                                    }
                                    onBlur={(e) => {
                                        field.onBlur(e)
                                        form.validateField(field.name)
                                        resetFatalError()
                                    }}
                                    onChange={(e) => {
                                        field.onChange(e)
                                    }}
                                    value={field.value}
                                    valid={manuallyValidateFieldData({ form, field, meta })}
                                    touched={meta.touched}
                                    disabled={userAccountCreated}
                                    modifier={passwordValidationStyles['wrapper--compact']}
                                    passwordStrength={errorContent.passwordStrength}
                                    passwordStrengthLevel1={errorContent.passwordStrengthLevel1}
                                    passwordStrengthLevel2={errorContent.passwordStrengthLevel2}
                                    passwordStrengthLevel3={errorContent.passwordStrengthLevel3}
                                    passwordStrengthLevel4={errorContent.passwordStrengthLevel4}
                                    passwordStrengthTipDrawer={errorContent.passwordStrengthTipDrawer}
                                    passwordStrengthTip1={errorContent.passwordStrengthTip1}
                                    passwordStrengthTip2={errorContent.passwordStrengthTip2}
                                    passwordStrengthTip3={errorContent.passwordStrengthTip3}
                                    isSubmittingRef={isSubmittingRef}
                                />
                            </InlineErrorView>
                        )}
                    </Field>
                </div>
            </div>
        </BoxWrapper>
    )
}

export const ConnectedAccountFormInfo = connectRedux(
    AccountFormInfo,
    mapAccountFormInfoState,
    mapAccountFormInfoActions
)
