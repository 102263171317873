/* eslint-disable react/require-default-props */
import React from 'react'
import classNames from 'classnames'
import styles from './skeleton.module.css'

interface IProps {
    id: string
    size?: 'xxsmall' | 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge' | 'xxlarge' | 'xxxlarge'
}

export const Skeleton: React.FC<IProps> = ({ id = '', size = 'medium' }) => {
    const className = classNames({
        [styles.wrapper]: true,
        [styles[`size-${size}`]]: true,
    })
    return <div id={id} className={className} />
}
