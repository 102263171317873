import React, { FunctionComponent, memo } from 'react'

import { Addon } from '@gtc/state/add-on'
import { getAddonPrice } from '@gtc/utils'
import { ConnectedFormatCurrency } from '@gtc/connectedComponents'

// Import Local Styles
import { CouponProcessedDataPerSku } from '@gtc/state/coupon/state'
import isEmpty from 'lodash/isEmpty'
import styles from './order-summary-addons.css'

export interface OrderSummaryAddonsProps {
    selectedAddons: Addon[]
    planAddons: Addon[]
    isAnnual: boolean
    currency: string
    content: {
        year: string
        month: string
        percentageOffPromoDiscount: string
    }
    couponProcessedDataPerSku: CouponProcessedDataPerSku | null
    isCouponValidAndApplied: boolean
    isPercentageCoupon: boolean
}

export const OrderSummaryAddons: FunctionComponent<OrderSummaryAddonsProps> = ({
    selectedAddons,
    planAddons,
    isAnnual,
    currency,
    content,
    couponProcessedDataPerSku,
    isCouponValidAndApplied,
    isPercentageCoupon,
}) => {
    return (
        <>
            {selectedAddons.map((selected: Addon) => {
                const addon: Addon | undefined = planAddons.find((x) => x.name === selected.name)
                if (!addon) return null
                const addonCouponData = couponProcessedDataPerSku ? couponProcessedDataPerSku[addon.sKU] : null
                const isCouponAppliedtoAddon =
                    isCouponValidAndApplied && couponProcessedDataPerSku && addonCouponData && !isEmpty(addonCouponData)
                const addonCouponDiscountPercentage = addonCouponData?.discountPercentage || 0
                const addonCouponDiscountAmount = addonCouponData?.discountTotalSavings || 0
                const discountLineClasses = `${styles.small} ${styles.teal}`
                const addonPrice = getAddonPrice(addon, isAnnual, currency)
                return (
                    <div className={styles.section} key={addon.name} data-qat={`addon-${addon.sKU}-summary`}>
                        <div className={styles.row}>
                            <span className={styles.item} data-qat="line-item-name">
                                {addon.name}
                            </span>
                            <span className={styles.item} data-qat="line-item-subtotal">
                                <ConnectedFormatCurrency value={addonPrice * selected.quantity} />
                            </span>
                        </div>
                        <div className={styles.row}>
                            <span className={styles.small} data-qat="line-item-per-period-price">
                                <ConnectedFormatCurrency value={addonPrice} /> <span>/{addon.quantityLabelShort}</span>
                                <span>/{isAnnual ? content.year : content.month}</span>
                            </span>
                        </div>
                        <div className={styles.row}>
                            <span className={styles.small} data-qat="line-item-quantity">
                                {selected.quantity}{' '}
                                {selected.quantity > 1 ? addon.quantityLabelShortPlural : addon.quantityLabelShort}
                            </span>
                        </div>
                        {isCouponAppliedtoAddon && (
                            <div className={styles.row}>
                                <span className={discountLineClasses}>
                                    <span data-qat="coupon-discount-percentage">
                                        {isPercentageCoupon ? (
                                            `${addonCouponDiscountPercentage}%`
                                        ) : (
                                            <ConnectedFormatCurrency value={addonCouponDiscountAmount} />
                                        )}
                                        &nbsp;
                                    </span>
                                    {content.percentageOffPromoDiscount}
                                </span>
                                <span className={discountLineClasses} data-qat="coupon-discount-price">
                                    -
                                    <ConnectedFormatCurrency value={addonCouponDiscountAmount} />
                                </span>
                            </div>
                        )}
                    </div>
                )
            })}
        </>
    )
}

export const MemoOrderSummaryAddon = memo(OrderSummaryAddons)
