import { LmiProPlan } from '@lmipro/state/plan'

export const getCurrentPlan = (planSku: string, planData: LmiProPlan[]) => {
    // TODO: WAE1-3631: Refactor to choose most popular plan.
    const selectedPlan = planData.find((plan: LmiProPlan) => plan.sKU === planSku)
    if (!selectedPlan) {
        return planData[0]
    }
    return selectedPlan
}

export const getPlanNameSet = (planData: LmiProPlan[]) =>
    planData.reduce((accSet, plan) => {
        accSet.add(plan.name.toLowerCase())
        return accSet
    }, new Set<string>())
