export enum LMIPRO_TRIAL_ERROR_CODES {
    INVALID_CODE = 'invalid.token.code',
    TOKEN_EXPIRED = 'token.expired',
}

export enum LMIPRO_TRIAL_ERROR_STATE {
    TIMEOUT = 'timeout',
    ALREADY_USER = 'already.user',
    EXPIRED_CODE = 'expired.code',
    WRONG_CODE = 'wrong.code',
    NO_PROVISIONING = 'failed.provisioning',
    USER_BANNED = 'banned',
    GENERIC_ERROR = 'generic.error',
    DECISION_MAKER_FAIL = 'decision.maker.fail',
    NO_ERROR = 'none',
}

export enum LMIPRO_TRIAL_ERROR_IDS {
    USER_BANNED = 'USER_BANNED',
    EXISTING_TRIAL = 'EXISTING_TRIAL',
    EXISTING_USER = 'EXISTING_USER',
    GENERIC_ERROR = 'GENERIC_ERROR',
    ADDRESS_ERROR = 'ADDRESS_ERROR',
    CARD_ERROR = 'CARD_ERROR',
}

export enum LMIPRO_TRIAL_START_ERROR_CODES {
    EXPIRED_TRIAL = 'restart.trialsubscription',
}
