import { ActionUnion, createAction } from '@common/utils'
import { CouponServerData } from '@common/state/coupon/state'
import {
    BuyCalculateCouponPriceActionPayload,
    BuyCheckVolumeDiscountAgainstRepriceCouponActionPayload,
    BuyValidateCouponActionPayload,
} from '../coupon-util'
import { BuyCouponProcessedDataPerSku } from './state'

export enum BuyCouponActionTypes {
    SET_APPLY_COUPON_ATTEMPT_MADE = '[buy-coupon-actions] check-apply-coupon-attempt-made',
    GET_COUPON_DETAILS = '[buy-coupon-actions] get-coupon-details',
    CALCULATE_COUPON_PRICE = '[buy-coupon-actions] calculate-coupon-price',
    VALIDATE_COUPON = '[buy-coupon-actions] validate-coupon',
    SET_COUPON_PROCESSED_DATA = '[buy-coupon-actions] set-coupon-processed-data',
    SET_COUPON_SERVER_DATA = '[buy-coupon-actions] set-coupon-server-data',
    CHECK_CMS_COUPON = '[buy-coupon-actions] check-cms-coupon',
    RESET_COUPON_DATA = '[buy-coupon-actions] reset-coupon-data',
    SET_COUPON_VALID_AND_APPLIED = '[buy-coupon-actions] set-coupon-valid-and-applied',
    SET_COUPON_DOUBLE_DIP_AMOUNT_FOR_NEW_USER = '[buy-coupon-actions] set-coupon-double-dip-amount-for-new-user',
    SET_URL_COUPON_ACTIVE = '[buy-coupon-actions] set-url-coupon-active',
    OBSERVE_VALIDATE_CMS_COUPON = '[buy-coupon-actions] observe-validate-cms-coupon',
    CHECK_VOLUME_DISCOUNT_AGAINST_REPRICE_COUPON = '[buy-coupon-actions] check-volume-discount-against-reprice-coupon',
    ATTEMPT_FOR_CMS_COUPON = '[buy-coupon-actions] attempt-for-cms-coupon',
    SET_URL_COUPON_CODE = '[buy-coupon-actions] set-url-coupon-code',
}

export const buyCouponActions = {
    getCouponDetails: (couponCode: string) => createAction(BuyCouponActionTypes.GET_COUPON_DETAILS, { couponCode }),
    validateCoupon: (couponValidationData: BuyValidateCouponActionPayload, isAttemptingUrlCoupon: boolean) =>
        createAction(BuyCouponActionTypes.VALIDATE_COUPON, { couponValidationData, isAttemptingUrlCoupon }),
    calculateCouponPrice: (couponCalculationData: BuyCalculateCouponPriceActionPayload) =>
        createAction(BuyCouponActionTypes.CALCULATE_COUPON_PRICE, { couponCalculationData }),
    setCouponProcessedData: (couponProcessedDataPerSku: BuyCouponProcessedDataPerSku | null) =>
        createAction(BuyCouponActionTypes.SET_COUPON_PROCESSED_DATA, { couponProcessedDataPerSku }),
    setCouponServerData: (couponServerData: CouponServerData) =>
        createAction(BuyCouponActionTypes.SET_COUPON_SERVER_DATA, { couponServerData }),
    checkCMSCoupon: (selectedPlanSku: string) =>
        createAction(BuyCouponActionTypes.CHECK_CMS_COUPON, { selectedPlanSku }),
    resetCouponData: () => createAction(BuyCouponActionTypes.RESET_COUPON_DATA),
    setCouponValidAndApplied: (isCouponValidAndApplied: boolean) =>
        createAction(BuyCouponActionTypes.SET_COUPON_VALID_AND_APPLIED, { isCouponValidAndApplied }),
    setCouponDoubleDipAmountForNewUser: (couponDoubleDipAmountForNewUser: number) =>
        createAction(BuyCouponActionTypes.SET_COUPON_DOUBLE_DIP_AMOUNT_FOR_NEW_USER, {
            couponDoubleDipAmountForNewUser,
        }),
    setUrlCouponActive: (isUrlCouponActive: boolean) =>
        createAction(BuyCouponActionTypes.SET_URL_COUPON_ACTIVE, { isUrlCouponActive }),
    observeValidateCMSCoupon: (couponServerData: CouponServerData) =>
        createAction(BuyCouponActionTypes.OBSERVE_VALIDATE_CMS_COUPON, { couponServerData }),
    checkVolumeDiscountAgainstRepriceCoupon: (
        checkVolumeDiscountData: BuyCheckVolumeDiscountAgainstRepriceCouponActionPayload
    ) =>
        createAction(BuyCouponActionTypes.CHECK_VOLUME_DISCOUNT_AGAINST_REPRICE_COUPON, {
            checkVolumeDiscountData,
        }),
    attemptForCMSCoupon: () => createAction(BuyCouponActionTypes.ATTEMPT_FOR_CMS_COUPON),
    setUrlCouponCode: (couponCode: string) => createAction(BuyCouponActionTypes.SET_URL_COUPON_CODE, { couponCode }),
}

export type BuyCouponActions = ActionUnion<typeof buyCouponActions>
