export const saveSessionState = (key: string, value: string | number | Record<string, unknown>) => {
    try {
        sessionStorage.setItem(key, JSON.stringify(value))
    } catch (err) {
        // fail silently
    }
}

export const getSessionState = (key: string) => {
    const sessionItem = sessionStorage.getItem(key)
    if (sessionItem) {
        return JSON.parse(sessionItem)
    }
    return ''
}
