import { combineReducers, CombinedState } from 'redux'
import { fatalErrorReducer, initialFatalErrorState } from '@common/state/fatal-error/reducer'
import { modalReducer, initialModalState } from '@common/state/modal/reducer'
import { flexReducer, initialFlexState } from '@common/state/flex/reducer'
import { locationReducer, initialLocationState } from '@common/state/location/reducer'
import { FlexState } from '@common/state/flex/state'
import { FatalErrorState } from '@common/state/fatal-error/state'
import { ModalState } from '@common/state/modal/state'
import { LocationState } from '@common/state/location/state'
import { initialMarketingState, marketingReducer } from '@common/state/marketing/reducer'
import { MarketingState } from '@common/state/marketing/state'
import { accountReducer, initialAccountState } from '@common/state/account/reducer'
import { contentReducer, initialContentState } from '@common/state/content/reducer'
import { ContentState } from '@common/state/content/state'
import { checkoutReducer, initialCheckoutState } from '@common/state/checkout/reducer'
import { CheckoutState } from '@common/state/checkout/state'

export const commonAppReducer = combineReducers({
    fatalError: fatalErrorReducer,
    modal: modalReducer,
    flex: flexReducer,
    location: locationReducer,
    marketing: marketingReducer,
    account: accountReducer,
    content: contentReducer,
    checkout: checkoutReducer,
})

export const commonCombinedState = {
    fatalError: initialFatalErrorState,
    modal: initialModalState,
    flex: initialFlexState,
    location: initialLocationState,
    marketing: initialMarketingState,
    account: initialAccountState,
    content: initialContentState,
    checkout: initialCheckoutState,
}

export type CommonAppState = ReturnType<typeof commonAppReducer>

export type BaseAppState = {
    common: CommonAppState
}

export type CommonAppCombinedState = CombinedState<{
    fatalError: FatalErrorState
    modal: ModalState
    flex: FlexState
    location: LocationState
    marketing: MarketingState
    content: ContentState
    checkout: CheckoutState
}>
