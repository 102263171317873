import { BuyContainerAppState } from '@buy/store'
// we need to complete this file, as we copy reducers from connect

export const getLabelContent = (state: BuyContainerAppState) => state.buy.content.labelContent
export const getCheckoutState = (state: BuyContainerAppState) => state.buy.checkout
export const getPlanData = (state: BuyContainerAppState) => state.buy.content.planData
export const getContentState = (state: BuyContainerAppState) => state.buy.content
export const getContentData = (state: BuyContainerAppState) => state.buy.content.contentData
export const getUserState = (state: BuyContainerAppState) => state.buy.user
export const getBuyFormState = (state: BuyContainerAppState) => state.buy.form
export const getCouponState = (state: BuyContainerAppState) => state.buy.coupon
