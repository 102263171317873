import { STORAGE_CONST } from '@common/constants'
import { ResolveSavedStoreState } from '@gtresolve/state/checkout/state'

export const loadState = () => {
    try {
        const serializedState = localStorage.getItem(STORAGE_CONST.GTR_STORE)

        if (serializedState === null) {
            return undefined
        }

        return JSON.parse(serializedState) as ResolveSavedStoreState
    } catch (err) {
        return undefined
    }
}

export const saveState = (state: ResolveSavedStoreState) => {
    try {
        const serializedState = JSON.stringify(state)
        localStorage.setItem(STORAGE_CONST.GTR_STORE, serializedState)
    } catch (err) {
        // fail silently
    }
}

export const clearState = () => {
    try {
        const serializedState = localStorage.getItem(STORAGE_CONST.GTR_STORE)

        if (serializedState) localStorage.removeItem(STORAGE_CONST.GTR_STORE)
    } catch (err) {
        // fail silently
    }
}
