export interface FatalErrorState {
    isFatalError: boolean
    fatalErrorMessage?: string
    fatalErrorBlocker: boolean
    fatalErrorType?: FATAL_ERROR_TYPE
}

export interface FatalErrorMessageType {
    fatalErrorMessage?: string
    trackErrorMessage?: string
    fatalErrorType?: FATAL_ERROR_TYPE
}

export enum FATAL_ERROR_TYPE {
    CARDINAL_SETUP = 'cardinal-setup',
    CARDINAL_CHALLENGE = 'cardinal-challenge',
    CARDINAL_PAYMENT = 'cardinal-payment',
}
