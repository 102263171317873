import { takeEvery, put, call } from 'redux-saga/effects'
import { fatalErrorActions } from '@common/state/fatal-error/actions'
import { flexActions, FlexActionTypes } from '@common/state/flex/actions'
import { setFlexMicroform } from '@common/utils'

export function* clearFlexMicroformSaga() {
    try {
        yield call(setFlexMicroform, null)
        yield put(flexActions.resetFlexState())
    } catch (e) {
        yield put(fatalErrorActions.setFatalError(true, { trackErrorMessage: 'could not clear flex microform' }))
    }
}

function* initializeClearFlexMicroformSaga() {
    yield takeEvery(FlexActionTypes.CLEAR_FLEX_MICROFORM_INSTANCE, clearFlexMicroformSaga)
}

export default initializeClearFlexMicroformSaga
