import { CURRENCY, TimePeriod } from '@common/constants'
import { LocationState } from '@common/state/location/state'
import { getLocationState } from '@common/state/selectors'
import { buyCheckoutActions, BuyCheckoutActionTypes } from '@buy/state/checkout/actions'
import { buyCouponActions, BuyCouponActionTypes } from '@buy/state/coupon/actions'
import { BuyCheckoutState } from '@buy/state/checkout/state'
import { BuyValidateCouponActionPayload } from '@buy/state/coupon-util'
import { BuyPlan } from '@buy/state/plan'
import { getCheckoutState, getCouponState } from '@buy/state/selectors'
import { select } from 'redux-saga-test-plan/matchers'
import { call, put, takeLatest } from 'redux-saga/effects'
import { BuyCouponState } from '@buy/state/coupon/state'
import { CouponServerData } from '@common/state/coupon/state'
import { getAvailableCouponServerData } from '@buy/utils'
import { getSelectedPlan } from '../content/content-helpers'

type PartialValidateCouponActionPayload = Omit<BuyValidateCouponActionPayload, 'couponServerData'>

type ObserveValidateCouponAction =
    | ReturnType<typeof buyCouponActions.setCouponServerData>
    | ReturnType<typeof buyCheckoutActions.setSelectedPlan>
    | ReturnType<typeof buyCheckoutActions.setBillingFrequency>
    | ReturnType<typeof buyCheckoutActions.setSelectedTierIndex> // this covers both organizers and selected tier index
    | ReturnType<typeof buyCheckoutActions.setSelectedAddons>
    | ReturnType<typeof buyCouponActions.observeValidateCMSCoupon> // this action is used to only trigger the below saga and avoid going to reducer

type CouponDataActionType =
    | ReturnType<typeof buyCouponActions.setCouponServerData>
    | ReturnType<typeof buyCouponActions.observeValidateCMSCoupon>

function* observeValidateCouponSaga(action: ObserveValidateCouponAction) {
    try {
        const { organizers, selectedTierIndex, selectedPlanSku, selectedAddons, billingFrequency }: BuyCheckoutState =
            yield select(getCheckoutState)

        const coupon: BuyCouponState = yield select(getCouponState)
        const { locationInfo }: LocationState = yield select(getLocationState)
        const { currency = CURRENCY.USD } = locationInfo

        const selectedPlan: BuyPlan = yield call(getSelectedPlan, selectedPlanSku)
        const [couponServerDataFromState, isUrlCouponData]: [CouponServerData | null, boolean] = yield call(
            getAvailableCouponServerData,
            coupon,
            selectedPlan
        )

        const validateCouponActionPartialPayload: PartialValidateCouponActionPayload = {
            organizers,
            selectedTierIndex,
            selectedPlan,
            selectedAddons,
            billingFrequency: billingFrequency as TimePeriod,
            selectedCurrency: currency,
        }

        const isCouponDataAction =
            action.type === BuyCouponActionTypes.SET_COUPON_SERVER_DATA ||
            action.type === BuyCouponActionTypes.OBSERVE_VALIDATE_CMS_COUPON

        if (couponServerDataFromState && !isCouponDataAction) {
            const validateCouponActionPayload: BuyValidateCouponActionPayload = {
                ...validateCouponActionPartialPayload,
                couponServerData: couponServerDataFromState,
            }
            switch (action.type) {
                case BuyCheckoutActionTypes.SET_SELECTED_PLAN: {
                    const { planSku } = action.payload
                    const selectedPlanByAction: BuyPlan = yield call(getSelectedPlan, planSku)
                    validateCouponActionPayload.selectedPlan = selectedPlanByAction
                    break
                }
                case BuyCheckoutActionTypes.SET_SELECTED_ADDONS: {
                    const { addons } = action.payload
                    validateCouponActionPayload.selectedAddons = addons
                    break
                }
                case BuyCheckoutActionTypes.SET_SELECTED_TIER_INDEX: {
                    const { organizers: payloadOrganizers, selectedTierIndex: payloadTierIndex } = action.payload
                    validateCouponActionPayload.organizers = payloadOrganizers
                    validateCouponActionPayload.selectedTierIndex = payloadTierIndex
                    break
                }
                case BuyCheckoutActionTypes.SET_BILLING_FREQUENCY: {
                    const { frequency } = action.payload
                    validateCouponActionPayload.billingFrequency = frequency
                    break
                }
                default: {
                    break // do nothing
                }
            }
            // if url coupon is available, attempt to validate the url coupon again before checking for CMS coupon
            yield put(buyCouponActions.validateCoupon(validateCouponActionPayload, isUrlCouponData))
        } else if (isCouponDataAction) {
            const { payload } = action as CouponDataActionType
            const { couponServerData } = payload
            const validateCouponActionPayload: BuyValidateCouponActionPayload = {
                ...validateCouponActionPartialPayload,
                couponServerData,
            }
            // isUrlCoupon should be true or false based on the action type
            const isUrlCoupon = action.type === BuyCouponActionTypes.SET_COUPON_SERVER_DATA
            yield put(buyCouponActions.validateCoupon(validateCouponActionPayload, isUrlCoupon))
        }
    } catch (e) {
        console.error(e)
    }
}

export default function* initializeObserveValidateCouponSaga() {
    yield takeLatest(
        [
            BuyCouponActionTypes.SET_COUPON_SERVER_DATA,
            BuyCheckoutActionTypes.SET_SELECTED_PLAN,
            BuyCheckoutActionTypes.SET_ORGANIZERS,
            BuyCheckoutActionTypes.SET_SELECTED_ADDONS,
            BuyCheckoutActionTypes.SET_BILLING_FREQUENCY,
            BuyCheckoutActionTypes.SET_SELECTED_TIER_INDEX,
            BuyCouponActionTypes.OBSERVE_VALIDATE_CMS_COUPON,
        ],
        observeValidateCouponSaga
    )
}
