import { call, takeEvery, put } from 'redux-saga/effects'
import { lmiproUserActions, LmiProUserActionTypes } from '@lmipro/state/user/actions'
import lmiproServices from '@lmipro/services'
import { LmiProUserAccountType } from '@lmipro/state/user/state'
import { fatalErrorActions } from '@common/state/fatal-error/actions'
import { TRACKING_EVENTS } from '@common/constants'
import { lmiproTrackingActions } from '@lmipro/state/tracking/actions'
import { LMIPRO_TRACKING_COMPONENTS } from '@lmipro/constants'
import { GenericResponse } from '@common/state/generic-response/generic-response'

export function* createUserSaga(action: ReturnType<typeof lmiproUserActions.createNewUser>) {
    const componentName = LMIPRO_TRACKING_COMPONENTS.CREATE_USER_SAGA
    try {
        const { userValues } = action.payload
        const userCreationResponse: GenericResponse = yield call(lmiproServices.createUserAccount, userValues)
        if (userCreationResponse.data) {
            const userData: LmiProUserAccountType = userCreationResponse.data
            return userData
        }
    } catch (e) {
        yield put(
            lmiproTrackingActions.track(
                {
                    event: TRACKING_EVENTS.ERROR_PURCHASE,
                    eventData: { errorMessage: 'failed to create user account' },
                },
                componentName
            )
        )
        yield put(fatalErrorActions.setFatalError(true, { trackErrorMessage: 'failed to create user account' }))
    }
    return null
}

function* initializeCreateUserSaga() {
    yield takeEvery(LmiProUserActionTypes.CREATE_NEW_USER, createUserSaga)
}

export default initializeCreateUserSaga
