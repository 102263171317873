import { ADDON_SKUS, ADDONS } from '@buy/constants'
import { BILLING_TYPES, PRODUCT_TYPES, PRODUCT_SKU, TimePeriod } from '@common/constants'
import { BillingDetailedSubscriptionPlan } from '@common/state/billing/state'
import { BuyPlan } from '@buy/state/plan'

interface PlanSubscriptions {
    basic?: BillingDetailedSubscriptionPlan
    standard?: BillingDetailedSubscriptionPlan
    trial?: BillingDetailedSubscriptionPlan
}

// get the remaining days + 1 so that today is included
const getRemainingDays = (end: string) => {
    const today = new Date().setHours(0, 0, 0, 0)
    const lastday = +new Date(end)

    return Math.floor((lastday - today) / (1000 * 60 * 60 * 24)) + 1
}

export interface SubscriptionInfo {
    plan?: BillingDetailedSubscriptionPlan
    addons: BillingDetailedSubscriptionPlan[]
    includedAgents: number
    includedEndpoints: number
    remainingDays: number
    canUpgradePlan: boolean
    canUpgradeCameraShare: boolean
    canUpgradeEndpointProtection: boolean
    canUpgradeMobile: boolean
    isReplacementBooking: boolean
    billingFrequency: TimePeriod
    isTrial: boolean
}

export const getSubscriptionInfo = (
    billingFrequency: TimePeriod,
    selectedPlan: BuyPlan,
    plans: BuyPlan[],
    accountSubscriptions?: BillingDetailedSubscriptionPlan[]
) => {
    const planSubscriptions: PlanSubscriptions = {}
    const subscriptionInfo: SubscriptionInfo = {
        addons: [],
        includedAgents: 0,
        includedEndpoints: 0,
        remainingDays: 0,
        canUpgradePlan: true,
        canUpgradeCameraShare: true,
        canUpgradeEndpointProtection: true,
        canUpgradeMobile: true,
        isReplacementBooking: true,
        billingFrequency,
        isTrial: false,
    }

    if (!selectedPlan) {
        return subscriptionInfo
    }

    subscriptionInfo.includedAgents = selectedPlan.userAgentsIncludedAmount
    subscriptionInfo.includedEndpoints = selectedPlan.endpointsIncludedAmount

    if (!accountSubscriptions) {
        return subscriptionInfo
    }

    accountSubscriptions.forEach((subscription) => {
        if (subscription.status === 'Active') {
            switch (subscription.productSku) {
                case PRODUCT_SKU.G2RE_STANDARD_NAME:
                case PRODUCT_SKU.GTR_STANDARD_NAMED_V2:
                    planSubscriptions.standard = subscription
                    break

                case PRODUCT_SKU.G2RE_BASIC_NAMED:
                case PRODUCT_SKU.GTR_REMOTE_SUPPORT_NAMED_V2:
                case PRODUCT_SKU.GTR_REMOTE_ACCESS:
                    planSubscriptions.basic = subscription
                    break

                case ADDON_SKUS.STANDARD_AGENT_ADDON:
                case ADDON_SKUS.BASIC_AGENT_ADDON:
                    subscriptionInfo.addons.push(subscription)
                    subscriptionInfo.includedAgents += subscription.quantity
                    break

                case ADDON_SKUS.STANDARD_ENDPOINT_ADDON:
                case ADDON_SKUS.BASIC_ENDPOINT_ADDON:
                    subscriptionInfo.addons.push(subscription)
                    subscriptionInfo.includedEndpoints += subscription.quantity * ADDONS.ENDPOINTS_ADDONS_INTERVAL
                    break

                case ADDON_SKUS.MOBILE_ADDON:
                    subscriptionInfo.addons.push(subscription)
                    subscriptionInfo.canUpgradeMobile = false
                    break

                case ADDON_SKUS.CAMERA_SHARE_ADDON:
                    subscriptionInfo.addons.push(subscription)
                    subscriptionInfo.canUpgradeCameraShare = false
                    break

                case ADDON_SKUS.ENDPOINT_PROTECTION_ADDON:
                    subscriptionInfo.addons.push(subscription)
                    subscriptionInfo.canUpgradeEndpointProtection = false
                    break

                default:
                    break
            }

            if (subscription.planType === PRODUCT_TYPES.TRIAL) {
                planSubscriptions.trial = subscription
            }
        }
    })

    if (planSubscriptions.standard) {
        subscriptionInfo.plan = planSubscriptions.standard
        subscriptionInfo.canUpgradePlan = false
    } else if (planSubscriptions.basic) {
        subscriptionInfo.plan = planSubscriptions.basic
    } else if (planSubscriptions.trial) {
        subscriptionInfo.plan = planSubscriptions.trial
        subscriptionInfo.isTrial = true
    }

    if (subscriptionInfo.plan && !subscriptionInfo.isTrial) {
        const subscriptionBilling =
            subscriptionInfo.plan.paymentPlan === BILLING_TYPES.MONTHLY ? TimePeriod.Month : TimePeriod.Year

        subscriptionInfo.isReplacementBooking =
            subscriptionInfo.plan.productSku !== selectedPlan.sKU ||
            subscriptionInfo.billingFrequency !== subscriptionBilling

        subscriptionInfo.remainingDays = getRemainingDays(subscriptionInfo.plan.endDate)

        if (subscriptionInfo.plan.productSku !== selectedPlan.sKU) {
            const subscribedPlan = plans.find((plan) => plan.sKU === subscriptionInfo.plan?.productSku)

            if (subscribedPlan) {
                const includedAgents =
                    subscriptionInfo.includedAgents -
                    selectedPlan.userAgentsIncludedAmount +
                    subscribedPlan.userAgentsIncludedAmount
                const includedEndpoints =
                    subscriptionInfo.includedEndpoints -
                    selectedPlan.endpointsIncludedAmount +
                    subscribedPlan.endpointsIncludedAmount

                subscriptionInfo.includedAgents =
                    selectedPlan.userAgentsIncludedAmount > includedAgents
                        ? selectedPlan.userAgentsIncludedAmount
                        : includedAgents
                subscriptionInfo.includedEndpoints =
                    selectedPlan.endpointsIncludedAmount > includedEndpoints
                        ? selectedPlan.endpointsIncludedAmount
                        : includedEndpoints
            }
        }
    }

    return subscriptionInfo
}
