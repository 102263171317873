export enum COUNTRIES {
    US = 'US',
    CA = 'CA',
}

export enum RESTRICTED_COUNTRIES {
    CU = 'CU',
    IR = 'IR',
    KP = 'KP',
    SD = 'SD',
    SY = 'SY',
}

type TaxCountries = {
    [key: string]: string
}

export const TAX_COUNTRIES: TaxCountries = {
    CH: 'CHE',
    GR: 'EL',
}
