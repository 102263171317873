export function formatDate(date: Date, type: string, locale: string) {
    const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' }
    let datestamp = date

    if (type !== 'UTC') {
        const year = Number(date.toString().substring(0, 4))

        const month = Number(date.toString().substring(5, 7))

        const day = Number(date.toString().substring(8, 10))

        datestamp = new Date(Date.UTC(year, month, day))
    }

    const formattedDate = new Intl.DateTimeFormat(locale, options).format(datestamp)

    return formattedDate
}
