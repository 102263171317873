import { BoxWrapper, Checkbox, InputField, Select, Tooltip, InlineErrorView } from '@common/components'
import { BuyFormData } from '@gtc/state/buy-form/state'

import { Field, FieldProps, FormikProps } from 'formik'
import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react'
import isEmpty from 'lodash/isEmpty'
import { manuallyValidateFieldData } from '@gtc/utils'
import { connectRedux, GoToComponentProps } from '@common/utils'
import { BillingFormInfoState } from '@gtc/connectedComponents/BillingFormInfo/state'
import {
    BillingFormInfoActionsType,
    mapBillingFormInfoActions,
    mapBillingFormInfoState,
} from '@gtc/connectedComponents/BillingFormInfo/connector'
import { COUNTRIES, DIRECTIONS, LANGUAGE } from '@common/constants'
import { Address } from '@common/state/location/state'
import { CHECKOUTFORM_FIELDS } from '@gtc/constants'
import selectStyles from '@common/components/Select/select.module.css'
import { useAddressAutoFill } from '@common/hooks/useAddressAutoFill'
import { GooglePlace } from '@common/state/addressAutoFill/state'
import { getAddressFromGooglePlace } from '@common/utils/getAddressFromGooglePlace'
import billingStyles from './billing-info.module.css'

export interface BillingFormInfoComponentProps {
    useSameForBilling: boolean
    resetFatalError: () => void
    canValidateAddress: (values: BuyFormData) => void
    setUseSameForBilling: (useSame: boolean) => void
    trackBillingInfoStep: () => void
    setHasAddressValidationErrors: (value: React.SetStateAction<boolean>) => void
    formikRef: React.RefObject<FormikProps<BuyFormData>>
    setCheckoutFormInlineError: (value: string) => void
    removeCheckoutFormInlineError: (value: string) => void
    inlineErrors: { [key: string]: string } | Record<string, unknown>
}
type BillingFormInfoProps = GoToComponentProps<
    BillingFormInfoState,
    BillingFormInfoActionsType,
    BillingFormInfoComponentProps
>

export const BillingFormInfo: FunctionComponent<BillingFormInfoProps> = ({ state, actions, props }) => {
    const { content, existingBillingInformation, isUserLoggedIn, location } = state

    // Do not set allowAddressAutofill back to true until bug WAE1-1721 is solved.
    // (See comment replies on that ticket for root cause of the bug.)
    const allowAddressAutofill = false

    const {
        useSameForBilling,
        resetFatalError,
        canValidateAddress,
        setUseSameForBilling,
        trackBillingInfoStep,
        setHasAddressValidationErrors,
        formikRef,
        setCheckoutFormInlineError,
        removeCheckoutFormInlineError,
        inlineErrors,
    } = props
    const [countryCode, setCountryCode] = useState(state.DefaultCountry)
    const streetFieldRef = useRef<HTMLInputElement>(null)
    // Note: Google Places API only allows searching in up to 5 countries at a time
    const autoFillCountries = [COUNTRIES.US.toLowerCase()]
    const autoFillLanguage = LANGUAGE.EN

    const selectCountry = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setCountryCode(e.target.value)
    }
    const selectedCountry = state.location.countries.find((country) => {
        return country.abbreviation === COUNTRIES.US
    })

    const setAddressErrors = useCallback(
        (errorMessage: string) => {
            if (formikRef.current) {
                formikRef.current.setFieldError('Street1', errorMessage)
                formikRef.current.setFieldError('City', errorMessage)
                formikRef.current.setFieldError('StateCode', errorMessage)
                formikRef.current.setFieldError('ZipCode', errorMessage)
            }
        },
        [formikRef]
    )

    const setAddressValues = useCallback(
        (address: Address) => {
            if (formikRef.current) {
                formikRef.current.setFieldValue('Street1', address.addressLine1)
                formikRef.current.setFieldValue('City', address.city)
                formikRef.current.setFieldValue('StateCode', address.state)
                formikRef.current.setFieldValue('ZipCode', address.postalCode)
            }
        },
        [formikRef]
    )

    const getFormValuesWithUpdatedAddress = useCallback(
        (correctedAddress: Address) => {
            if (formikRef.current) {
                const currentFormValues = formikRef.current.values
                const { addressLine1: Street1, city: City, state: StateCode, postalCode: ZipCode } = correctedAddress
                const updatedAddressValues = { ...currentFormValues, Street1, City, StateCode, ZipCode }
                return updatedAddressValues
            }
            return null
        },
        [formikRef]
    )

    // effects
    // get list of countries
    useEffect(() => {
        if (!location.isCountriesLoaded) {
            actions.getCountries()
        }
    }, [actions, location.isCountriesLoaded])

    // show & reset address errors
    useEffect(() => {
        if (!location.validAddress && location.addressErrors.length !== 0) {
            setHasAddressValidationErrors(true)
            setAddressErrors(' ') // use a space to get the fields to outline in red but not show a message under them since there will be an error displayed at the top of the form
        } else if (location.validAddress && location.addressErrors.length === 0) {
            setHasAddressValidationErrors(false)
            setAddressErrors('')
        }
    }, [location, setAddressErrors, setHasAddressValidationErrors])

    // update with corrected address
    useEffect(() => {
        if (location.validAddress && location.correctedAddress.city && formikRef.current) {
            setAddressValues(location.correctedAddress)
            // we need to do this because, formik setFieldValue is async in nature and doesn't return a promise
            // so we can't assume we have updated values within formikRef
            const updatedFormValues = getFormValuesWithUpdatedAddress(location.correctedAddress)
            if (updatedFormValues) {
                actions.calculatePrice(updatedFormValues)
            }
        }
    }, [
        actions,
        location.validAddress,
        location.correctedAddress,
        formikRef,
        setAddressValues,
        getFormValuesWithUpdatedAddress,
    ])

    const handleAutoFillAddress = (place: GooglePlace) => {
        const addr = getAddressFromGooglePlace(place)
        setAddressValues(addr)
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        canValidateAddress(formikRef.current!.values)
    }

    useAddressAutoFill({
        language: autoFillLanguage,
        streetFieldRef,
        countries: autoFillCountries,
        onChangePlace: handleAutoFillAddress,
        shouldLoad: allowAddressAutofill,
    })

    return (
        <BoxWrapper>
            <div className="billing-info">
                <h3>{content.billingInformation}</h3>
                {!isUserLoggedIn || isEmpty(existingBillingInformation) ? (
                    <>
                        <div className={billingStyles['checkbox-wrapper']}>
                            <Checkbox
                                checked={useSameForBilling}
                                id="useSameForBilling"
                                onchange={() => setUseSameForBilling(!useSameForBilling)}
                                label={content.useAccountInfo}
                                qaTag="checkout-form-same-for-billing"
                            />
                        </div>
                        <div className={billingStyles.grid}>
                            <>
                                <Field name={CHECKOUTFORM_FIELDS.BILLING_FIRST_NAME}>
                                    {({ form, field, meta }: FieldProps) => {
                                        return (
                                            <InlineErrorView
                                                fieldData={{ form, field, meta }}
                                                inlineErrors={inlineErrors}
                                                setErrorCall={setCheckoutFormInlineError}
                                                removeErrorCall={removeCheckoutFormInlineError}
                                                ignoreInlineError={useSameForBilling}
                                            >
                                                <InputField
                                                    autoComplete="cc-first-name"
                                                    disabled={useSameForBilling}
                                                    error={useSameForBilling ? undefined : meta.error}
                                                    id="name-on-card"
                                                    label={content.firstName}
                                                    name={field.name}
                                                    type="text"
                                                    valid={
                                                        useSameForBilling
                                                            ? undefined
                                                            : manuallyValidateFieldData({ form, field, meta })
                                                    }
                                                    wrapperClass={billingStyles['cc-first-name']}
                                                    value={useSameForBilling ? form.values.FirstName : field.value}
                                                    onBlur={async (e) => {
                                                        await form.setFieldValue(field.name, e.target.value.trim())
                                                        field.onBlur(e)
                                                        form.validateField(field.name)
                                                        canValidateAddress(form.values)
                                                        resetFatalError()
                                                    }}
                                                    onChange={(e) => {
                                                        field.onChange(e)
                                                    }}
                                                    data-qat="billing-first-name"
                                                />
                                            </InlineErrorView>
                                        )
                                    }}
                                </Field>
                                <Field name={CHECKOUTFORM_FIELDS.BILLING_LAST_NAME}>
                                    {({ form, field, meta }: FieldProps) => {
                                        return (
                                            <InlineErrorView
                                                fieldData={{ form, field, meta }}
                                                inlineErrors={inlineErrors}
                                                setErrorCall={setCheckoutFormInlineError}
                                                removeErrorCall={removeCheckoutFormInlineError}
                                                ignoreInlineError={useSameForBilling}
                                            >
                                                <InputField
                                                    autoComplete="cc-last-name"
                                                    id="name-on-card2"
                                                    disabled={useSameForBilling}
                                                    error={useSameForBilling ? undefined : meta.error}
                                                    label={content.lastName}
                                                    name={field.name}
                                                    type="text"
                                                    value={useSameForBilling ? form.values.LastName : field.value}
                                                    valid={
                                                        useSameForBilling
                                                            ? undefined
                                                            : manuallyValidateFieldData({ form, field, meta })
                                                    }
                                                    wrapperClass={billingStyles['cc-last-name']}
                                                    onBlur={async (e) => {
                                                        await form.setFieldValue(field.name, e.target.value.trim())
                                                        field.onBlur(e)
                                                        form.validateField(field.name)
                                                        canValidateAddress(form.values)
                                                        resetFatalError()
                                                    }}
                                                    onChange={(e) => {
                                                        field.onChange(e)
                                                    }}
                                                    data-qat="billing-last-name"
                                                />
                                            </InlineErrorView>
                                        )
                                    }}
                                </Field>
                            </>
                            <div className={billingStyles['country-wrapper']}>
                                <Field name={CHECKOUTFORM_FIELDS.COUNTRY_CODE}>
                                    {({ form, field }: FieldProps) => {
                                        return (
                                            <Select
                                                disabled
                                                name={field.name}
                                                label={content.country}
                                                onChange={(e) => {
                                                    selectCountry(e)
                                                    field.onChange(e)
                                                }}
                                                onBlur={() => {
                                                    canValidateAddress(form.values)
                                                }}
                                                value={field.value}
                                                selected={!!countryCode}
                                                wrapperClass={billingStyles.country}
                                                modifier={selectStyles['select-wrapper--compact']}
                                            >
                                                {location.countries.length === 0 ? (
                                                    <option key="countries-loading" value="">
                                                        Loading
                                                    </option>
                                                ) : (
                                                    location.countries.map((country) => (
                                                        <option
                                                            key={`country-${country.abbreviation}`}
                                                            value={country.abbreviation}
                                                        >
                                                            {country.name}
                                                        </option>
                                                    ))
                                                )}
                                            </Select>
                                        )
                                    }}
                                </Field>
                                <div className={billingStyles['tooltip-holder']}>
                                    <Tooltip direction={DIRECTIONS.RIGHT} content={content.countryPopupText} />
                                </div>
                            </div>
                            <Field name={CHECKOUTFORM_FIELDS.STREET1}>
                                {({ form, field, meta }: FieldProps) => {
                                    return (
                                        <InlineErrorView
                                            fieldData={{ form, field, meta }}
                                            inlineErrors={inlineErrors}
                                            setErrorCall={setCheckoutFormInlineError}
                                            removeErrorCall={removeCheckoutFormInlineError}
                                        >
                                            <InputField
                                                // Disable Chrome's address auto complete dropdown
                                                // Note: Use "new-password" because "off" doesn't work in Chrome
                                                // See: https://developer.mozilla.org/en-US/docs/Web/Security/Securing_your_site/Turning_off_form_autocompletion#preventing_autofilling_with_autocompletenew-password
                                                autoComplete={allowAddressAutofill ? 'new-password' : 'street-address'}
                                                inputRef={streetFieldRef}
                                                name={field.name}
                                                value={field.value}
                                                error={meta.error}
                                                id="street-address"
                                                label={content.address}
                                                type="text"
                                                valid={manuallyValidateFieldData({ form, field, meta })}
                                                wrapperClass={billingStyles.address}
                                                onBlur={async (e) => {
                                                    await form.setFieldValue(field.name, e.target.value.trim())
                                                    field.onBlur(e)
                                                    form.validateField(field.name)
                                                    canValidateAddress(form.values)
                                                    resetFatalError()
                                                    trackBillingInfoStep()
                                                }}
                                                onChange={(e) => {
                                                    field.onChange(e)
                                                }}
                                                data-qat="billing-address"
                                            />
                                        </InlineErrorView>
                                    )
                                }}
                            </Field>
                            <Field name={CHECKOUTFORM_FIELDS.CITY}>
                                {({ field, form, meta }: FieldProps) => {
                                    return (
                                        <InlineErrorView
                                            fieldData={{ form, field, meta }}
                                            inlineErrors={inlineErrors}
                                            setErrorCall={setCheckoutFormInlineError}
                                            removeErrorCall={removeCheckoutFormInlineError}
                                        >
                                            <InputField
                                                error={meta.error}
                                                name={field.name}
                                                // Disable Chrome's address auto complete dropdown
                                                // Note: Use "new-password" because "off" doesn't work in Chrome
                                                // See: https://developer.mozilla.org/en-US/docs/Web/Security/Securing_your_site/Turning_off_form_autocompletion#preventing_autofilling_with_autocompletenew-password
                                                autoComplete={allowAddressAutofill ? 'new-password' : 'address-level2'}
                                                id="city"
                                                label={content.city}
                                                type="text"
                                                value={field.value}
                                                valid={manuallyValidateFieldData({ field, form, meta })}
                                                wrapperClass={billingStyles.city}
                                                onBlur={async (e) => {
                                                    await form.setFieldValue(field.name, e.target.value.trim())
                                                    field.onBlur(e)
                                                    form.validateField(field.name)
                                                    canValidateAddress(form.values)
                                                    resetFatalError()
                                                }}
                                                onChange={(e) => {
                                                    field.onChange(e)
                                                }}
                                                data-qat="billing-city"
                                            />
                                        </InlineErrorView>
                                    )
                                }}
                            </Field>
                            <Field name={CHECKOUTFORM_FIELDS.STATE_CODE}>
                                {({ form, field, meta }: FieldProps) => {
                                    return (
                                        <InlineErrorView
                                            fieldData={{ form, field, meta }}
                                            inlineErrors={inlineErrors}
                                            setErrorCall={setCheckoutFormInlineError}
                                            removeErrorCall={removeCheckoutFormInlineError}
                                        >
                                            <>
                                                {selectedCountry && selectedCountry.states ? (
                                                    <Select
                                                        error={meta.error}
                                                        name={field.name}
                                                        value={field.value}
                                                        label={content.state}
                                                        onChange={(e) => {
                                                            field.onChange(e)
                                                        }}
                                                        onBlur={async (e) => {
                                                            field.onBlur(e)
                                                            form.validateField(field.name)
                                                            canValidateAddress(form.values)
                                                            resetFatalError()
                                                        }}
                                                        valid={manuallyValidateFieldData({ form, field, meta })}
                                                        wrapperClass={billingStyles.state}
                                                        modifier={selectStyles['select-wrapper--compact']}
                                                    >
                                                        <option>{content.pleaseSelect}</option>
                                                        {selectedCountry.states.map((countryState) => (
                                                            <option
                                                                key={`state-${countryState.code}`}
                                                                value={countryState.code}
                                                            >
                                                                {countryState.name}
                                                            </option>
                                                        ))}
                                                    </Select>
                                                ) : (
                                                    <InputField
                                                        error={meta.error}
                                                        name={field.name}
                                                        id="state2"
                                                        label={content.state}
                                                        type="text"
                                                        valid={manuallyValidateFieldData({ form, field, meta })}
                                                        wrapperClass={billingStyles.state}
                                                        onBlur={async (e) => {
                                                            await form.setFieldValue(field.name, e.target.value.trim())
                                                            field.onBlur(e)
                                                            form.validateField(field.name)
                                                            canValidateAddress(form.values)
                                                            resetFatalError()
                                                        }}
                                                        onChange={(e) => {
                                                            field.onChange(e)
                                                        }}
                                                    />
                                                )}
                                            </>
                                        </InlineErrorView>
                                    )
                                }}
                            </Field>
                            <Field name={CHECKOUTFORM_FIELDS.ZIP_CODE}>
                                {({ form, field, meta }: FieldProps) => {
                                    return (
                                        <InlineErrorView
                                            fieldData={{ form, field, meta }}
                                            inlineErrors={inlineErrors}
                                            setErrorCall={setCheckoutFormInlineError}
                                            removeErrorCall={removeCheckoutFormInlineError}
                                        >
                                            <InputField
                                                error={meta.error}
                                                name={field.name}
                                                // Disable Chrome's address auto complete dropdown
                                                // Note: Use "new-password" because "off" doesn't work in Chrome
                                                // See: https://developer.mozilla.org/en-US/docs/Web/Security/Securing_your_site/Turning_off_form_autocompletion#preventing_autofilling_with_autocompletenew-password
                                                autoComplete={allowAddressAutofill ? 'new-password' : 'postal-code'}
                                                id="zipcode"
                                                label={content.postalCode}
                                                type="text"
                                                valid={manuallyValidateFieldData({ form, field, meta })}
                                                wrapperClass={billingStyles.zipcode}
                                                value={field.value}
                                                onBlur={async (e) => {
                                                    await form.setFieldValue(field.name, e.target.value.trim())
                                                    field.onBlur(e)
                                                    form.validateField(field.name)
                                                    canValidateAddress(form.values)
                                                    resetFatalError()
                                                }}
                                                onChange={(e) => {
                                                    field.onChange(e)
                                                }}
                                                data-qat="billing-postal-code"
                                            />
                                        </InlineErrorView>
                                    )
                                }}
                            </Field>
                            <Field name={CHECKOUTFORM_FIELDS.CONTACT_PHONE}>
                                {({ form, field, meta }: FieldProps) => {
                                    return (
                                        <InlineErrorView
                                            fieldData={{ form, field, meta }}
                                            inlineErrors={inlineErrors}
                                            setErrorCall={setCheckoutFormInlineError}
                                            removeErrorCall={removeCheckoutFormInlineError}
                                        >
                                            <InputField
                                                error={meta.error}
                                                name={field.name}
                                                id="contact-number"
                                                label={content.phone}
                                                type="tel"
                                                value={field.value}
                                                valid={manuallyValidateFieldData({ form, field, meta })}
                                                wrapperClass={billingStyles.phone}
                                                onBlur={async (e) => {
                                                    await form.setFieldValue(field.name, e.target.value.trim())
                                                    field.onBlur(e)
                                                    form.validateField(field.name)
                                                    resetFatalError()
                                                }}
                                                onChange={(e) => {
                                                    field.onChange(e)
                                                }}
                                                data-qat="billing-contact-number"
                                            />
                                        </InlineErrorView>
                                    )
                                }}
                            </Field>
                        </div>
                    </>
                ) : (
                    existingBillingInformation && (
                        <div className={billingStyles['existing-address']}>
                            <div>
                                {existingBillingInformation.firstName} {existingBillingInformation.lastName}
                            </div>
                            <div>{existingBillingInformation.addressLine1}</div>
                            <div>
                                {existingBillingInformation.city} {existingBillingInformation.state}{' '}
                                {existingBillingInformation.postalCode}
                            </div>
                            <div>{existingBillingInformation.phoneNumber}</div>
                        </div>
                    )
                )}
            </div>
        </BoxWrapper>
    )
}

export const ConnectedBillingFormInfo = connectRedux(
    BillingFormInfo,
    mapBillingFormInfoState,
    mapBillingFormInfoActions
)
