import { PRODUCT_PATH } from '@common/constants'

export const isConnectApp = () => {
    return window.location.pathname.toLocaleLowerCase().includes(PRODUCT_PATH.CONNECT)
}

export const isResolveApp = () => {
    return window.location.pathname.toLocaleLowerCase().includes(PRODUCT_PATH.RESOLVE)
}

export const isLmiProApp = () => {
    return window.location.pathname.toLocaleLowerCase().includes(PRODUCT_PATH.LOGMEINPRO)
}

export const isBuyApp = () => {
    return window.location.pathname.toLocaleLowerCase().includes(PRODUCT_PATH.BUY)
}
