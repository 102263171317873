import React, { FunctionComponent } from 'react'
import { useLoadScript } from '@common/hooks'

export interface LmiMaScriptProps {
    url: string
}

export const LmiMaScript: FunctionComponent<LmiMaScriptProps> = ({ url }) => {
    useLoadScript(url)
    return <></>
}
