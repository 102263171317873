import { TrackingState } from '@common/state/tracking/state'
import { gtcTrackingActions } from '@gtc/state/tracking/actions'

export const mapGTCTrackingState = () => {
    return {}
}

export const mapGTCTrackingActions = {
    track: (trackingInfo: TrackingState, component: string) => gtcTrackingActions.track(trackingInfo, component),
}

export type GTCTrackingActionsType = typeof mapGTCTrackingActions

export interface GTCTrackingContextType {
    track: typeof mapGTCTrackingActions.track
}
