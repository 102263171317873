import { couponActions, CouponActionTypes } from '@gtc/state/coupon/actions'
import { put, takeLatest, take } from 'redux-saga/effects'
import { select } from 'redux-saga-test-plan/matchers'
import { getCheckoutState } from '@gtc/state/selectors'
import { CheckoutState } from '@gtc/state/checkout/state'
import isEmpty from 'lodash/isEmpty'
import { checkoutActions, CheckoutActionTypes } from '@gtc/state/checkout/actions'

function* attemptCMSCouponSaga() {
    // check if there's a coupon available from CMS
    let { selectedPlanSku }: CheckoutState = yield select(getCheckoutState)
    if (isEmpty(selectedPlanSku)) {
        const selectedPlanAction: ReturnType<typeof checkoutActions.setSelectedPlan> = yield take(
            CheckoutActionTypes.SET_SELECTED_PLAN
        )
        selectedPlanSku = selectedPlanAction.payload.planSku
    }
    yield put(couponActions.checkCMSCoupon(selectedPlanSku))
}

export default function* initializeAttemptCMSCouponSaga() {
    yield takeLatest(CouponActionTypes.ATTEMPT_FOR_CMS_COUPON, attemptCMSCouponSaga)
}
