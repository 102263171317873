import { buyCouponActions, BuyCouponActionTypes } from '@buy/state/coupon/actions'
import { put, takeLatest, take } from 'redux-saga/effects'
import { select } from 'redux-saga-test-plan/matchers'
import { getCheckoutState } from '@buy/state/selectors'
import { BuyCheckoutState } from '@buy/state/checkout/state'
import isEmpty from 'lodash/isEmpty'
import { buyCheckoutActions, BuyCheckoutActionTypes } from '@buy/state/checkout/actions'

function* attemptCMSCouponSaga() {
    // check if there's a coupon available from CMS
    let { selectedPlanSku }: BuyCheckoutState = yield select(getCheckoutState)
    if (isEmpty(selectedPlanSku)) {
        const selectedPlanAction: ReturnType<typeof buyCheckoutActions.setSelectedPlan> = yield take(
            BuyCheckoutActionTypes.SET_SELECTED_PLAN
        )
        selectedPlanSku = selectedPlanAction.payload.planSku
    }
    yield put(buyCouponActions.checkCMSCoupon(selectedPlanSku))
}

export default function* initializeAttemptCMSCouponSaga() {
    yield takeLatest(BuyCouponActionTypes.ATTEMPT_FOR_CMS_COUPON, attemptCMSCouponSaga)
}
