import axios from 'axios'
import { SC_SITE } from '@common/constants'
import {
    ResolveBillingInfoDataType,
    ResolvePaymentDataType,
    ResolveSubmitDataType,
    ResolveCalculatePriceDataType,
    ResolveSubmitWithCardAddDataType,
} from '@gtresolve/state/checkout/state'
import { ResolveUserDataType, ResolveAbandonedCartPayload } from '@gtresolve/state/user/state'
import { getContentLanguage } from '@gtresolve/utils/language-helpers'

export interface ResolvePaymentMethodDataType {
    accessToken?: string
    billingAccountKey?: string
}

export interface ResolveCardHeadersType {
    AccessToken: string
    BillingAccountKey: string
    PaymentMethodKey: string
}

export interface ResolveLabelDataType {
    id: string
    plan: string
    promoCode: string
    accessToken: string
    timezone: string
}

export interface apiQueryParamsType {
    /* eslint-disable camelcase */
    sc_site: string
    sc_lang?: string
}

export const apiQueryParams = {
    sc_site: SC_SITE.RESOLVE,
    sc_lang: 'en',
}

export const setLanguageParam = (queryParams: apiQueryParamsType) => {
    return {
        ...queryParams,
        sc_lang: getContentLanguage(),
    }
}

/* APP DATA */

// get buy flow api data

export function getResolveLabelData(): Promise<any> {
    const updatedQueryParams = setLanguageParam(apiQueryParams)
    return axios.post('/ecommapi/content/buy/gtr', null, { params: updatedQueryParams })
}

// get available payment  methods
export function getAvailablePaymentMethods() {
    return axios.post('/ecommapi/purchase/GetAvailablePaymentMethods', null, {
        withCredentials: true,
    })
}

// create user account
export function createUserAccount(data: ResolveUserDataType): Promise<any> {
    return axios.post('/ecommapi/identity/CreateAccount/', data, {
        withCredentials: true,
        params: apiQueryParams,
    })
}

// create billing information
export function createBillingInfo(data: ResolveBillingInfoDataType): Promise<any> {
    return axios.post('/ecommapi/purchase/SetBillingInfo', data, {
        withCredentials: true,
    })
}

// calculate payment
export function calculatePrice(data: ResolveCalculatePriceDataType): Promise<any> {
    return axios.post('/ecommapi/purchase/CalculatePrice', data)
}

// create payment info
export function savePayment(data: ResolvePaymentDataType) {
    return axios.post('/ecommapi/purchase/SavePaymentMethodV3', data, {
        withCredentials: true,
    })
}

// submit payment with card add
// api reference: https://confluence.ops.expertcity.com/display/MMM/API+-+Public+-+Process+Purchase+With+Card+Add+V3
export function submitPaymentWithCardAdd(data: ResolveSubmitWithCardAddDataType): Promise<any> {
    return axios.post('/ecommapi/purchase/ProcessPurchaseWithCardAddV3', data, {
        withCredentials: true,
    })
}

// submit payment
export function submitPayment(data: ResolveSubmitDataType | ResolveSubmitWithCardAddDataType) {
    return axios.post('/ecommapi/purchase/ProcessPurchaseV3', data, {
        withCredentials: true,
    })
}

export function validateTaxNumber(country: string, taxNumber: string, federalTaxType?: string) {
    return axios.get('/ecommapi/purchase/ValidateTaxNumber', {
        params: {
            country,
            taxNumber,
            federalTaxType,
        },
    })
}

// cart abandonment
export function submitAbandonedCart(data: ResolveAbandonedCartPayload) {
    return axios.post('/ecommapi/form/abandonment', data, { params: apiQueryParams })
}

export default {
    getResolveLabelData,
    calculatePrice,
    createUserAccount,
    createBillingInfo,
    submitPaymentWithCardAdd,
}
