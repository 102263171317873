import { ActionUnion, createAction } from '@common/utils'
import { TrackingState } from '@common/state/tracking/state'

// TODO : Should be changed based on product config
export enum BuyTrackingActionTypes {
    // TRACK = '[resolve-tracking] resolve-track-event',
    TRACK = '[buy-tracking] buy-track-event',
}

export const buyTrackingActions = {
    track: (trackingInfo: TrackingState, component: string) =>
        createAction(BuyTrackingActionTypes.TRACK, { trackingInfo }, { component }),
}

export type ResolveTrackingActions = ActionUnion<typeof buyTrackingActions>
