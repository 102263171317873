import React, { FunctionComponent } from 'react'
import { formatCurrency } from '@common/utils/price-utils'

export interface FormatCurrencyProps {
    Locale: string
    CurrencyCode: string
    value: number
    decimals?: boolean
}

export const FormatCurrency: FunctionComponent<FormatCurrencyProps> = ({
    Locale,
    CurrencyCode,
    value,
    decimals = true,
}) => {
    const formattedValue = formatCurrency(value, Locale, CurrencyCode, decimals)
    return <>{formattedValue}</>
}
