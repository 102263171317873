import { stringify } from 'query-string'

/**
 * Normally the paramsSerializer that axios uses by default would format an array in a querystring parameter with brackets, like this:
 *   ?reservationIds[]=RSRV-40143&reservationIds[]=RSRV-40144
 * But the numebrs api wants a string array in a querystring to be without brackets, like this:
 *   ?reservationIds=RSRV-40143&reservationIds=RSRV-40144
 */
export const stringifyParamsUsingArrayFormatNone = (parameters: Record<string, any>) =>
    stringify(parameters, { arrayFormat: 'none' })
