import { getFatalError, getFlexState } from '@common/state/selectors'
import { AppState } from 'app-store'
import { flexActions } from '@common/state/flex/actions'
import { getLabelContent, getUserState } from '@gtc/state/selectors'
import { PaymentFormInfoState } from './state'

export const mapPaymentFormInfoState = (state: AppState): PaymentFormInfoState => {
    const {
        paymentDetails,
        edit,
        cardNumber,
        expirationDate,
        cVVHint,
        cVV,
        cVVPopupInfo,
        errorInvalidCreditCardNumber,
        errorRequiredCreditCardNumber,
        errorInvalidCVV,
        errorRequiredCVV,
        secure,
    } = getLabelContent(state)
    const { isFatalError } = getFatalError(state)
    const { existingPaymentInformation } = getUserState(state)
    const { isCardValid, isCvvValid, userInteractedWithFlexField, userInteractedWithFlexFieldCvv, microformCreated } =
        getFlexState(state)

    return {
        content: { paymentDetails, edit, cardNumber, expirationDate, cVVHint, cVV, cVVPopupInfo, secure },
        errorContent: {
            errorInvalidCreditCardNumber,
            errorRequiredCreditCardNumber,
            errorInvalidCVV,
            errorRequiredCVV,
        },
        isFatalError,
        existingPaymentInformation,
        isCardValid,
        isCvvValid,
        userInteractedWithFlexField,
        userInteractedWithFlexFieldCvv,
        microformCreated: !!microformCreated,
    }
}

export const mapPaymentFormInfoActions = {
    setCardValid: (valid: boolean) => flexActions.setCardValid(valid),
    setCvvValid: (valid: boolean) => flexActions.setCvvValid(valid),
    setUserInteractedWithFlexField: (interacted: boolean) => flexActions.setUserInteractedWithFlexField(interacted),
    setUserInteractedWithFlexFieldCvv: (interacted: boolean) =>
        flexActions.setUserInteractedWithFlexFieldCvv(interacted),
}

export type PaymentFormInfoActionsType = typeof mapPaymentFormInfoActions
