// Import Dependencies
import React from 'react'
import { ErrorView } from '@common/components'

type ErrorBoundaryProps = Record<string, unknown>

type ErrorBoundaryState = {
    hasError: boolean
}

export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
        super(props)
        this.state = {
            hasError: false,
        }
    }

    componentDidCatch() {
        // Display loading spinner
        // there is no alternative hook for this at the time being, but we need to monitor and convert this component to function component later
        this.setState({ hasError: true })
    }

    render() {
        return (
            <>
                {this.state.hasError ? (
                    <ErrorView>
                        Something went wrong! Please try again. If you're still experiencing problems please call us at
                        1 866 890 8931.
                    </ErrorView>
                ) : (
                    this.props.children
                )}
            </>
        )
    }
}
