import React from 'react'
import classNames from 'classnames'
import { NumberPickerContent } from '@gtc/components/NumberPicker/NumberPicker.types'
import { PLATFORM } from '@common/constants'
import styles from './Navigation.module.css'

interface IProps {
    isLocalNumber: boolean
    isBasicNumber: boolean
    content: NumberPickerContent
    handleClickLocal: () => void
    handleClickTollFree: () => void
    handleClickBasic: () => void
    handleClickVanity: () => void
}

export const Navigation = ({ isLocalNumber, isBasicNumber, content, ...props }: IProps) => {
    // TODO: NumberPicker: Remove this check in a future numberpicker story when Vanity Search is ready
    const enableVanityPhrase =
        process.env.ENVIRONMENT !== PLATFORM.PROD &&
        (window.localStorage.getItem('NumberPickerVanityPhrase') === 'true' ||
            window.location.search.includes('NumberPickerVanityPhrase=true'))

    return (
        <div className={styles.wrapper} data-qat="numberpicker_modal_navigation">
            <div className={styles.subnav}>
                <button
                    data-qat="numberpicker_modal_navigation_local"
                    className={classNames({ [styles.subnavButton]: true, [styles.subnavButtonActive]: isLocalNumber })}
                    disabled={isLocalNumber}
                    onClick={props.handleClickLocal}
                >
                    {content.localNumber}
                </button>
                <button
                    data-qat="numberpicker_modal_navigation_tollfree"
                    className={classNames({ [styles.subnavButton]: true, [styles.subnavButtonActive]: !isLocalNumber })}
                    disabled={!isLocalNumber}
                    onClick={props.handleClickTollFree}
                >
                    {content.tollFreeNumber}
                </button>
            </div>

            {enableVanityPhrase && (
                <div className={styles.numberTypeContainer}>
                    <>
                        <label htmlFor="numberpicker_modal_navigation_basic" className={styles.numberTypeRadioButton}>
                            <input
                                checked={isBasicNumber}
                                id="numberpicker_modal_navigation_basic"
                                data-qat="numberpicker_modal_navigation_basic"
                                onChange={props.handleClickBasic}
                                type="radio"
                            />
                            {content.basic}
                        </label>
                        <label htmlFor="numberpicker_modal_navigation_vanity" className={styles.numberTypeRadioButton}>
                            <input
                                checked={!isBasicNumber}
                                data-qat="numberpicker_modal_navigation_vanity"
                                id="numberpicker_modal_navigation_vanity"
                                type="radio"
                                onChange={props.handleClickVanity}
                            />
                            {content.vanity}
                        </label>
                    </>
                </div>
            )}
        </div>
    )
}
