import { ActionUnion, createAction } from '@common/utils'

export enum CheckoutActionTypes {
    SET_CHECKOUT_INLINE_ERROR = '[checkout-actions] set-inline-error',
    REMOVE_CHECKOUT_INLINE_ERROR = '[checkout-actions] remove-inline-error',
}

export const checkoutActions = {
    setCheckoutFormInlineError: (error: string) =>
        createAction(CheckoutActionTypes.SET_CHECKOUT_INLINE_ERROR, { error }),
    removeCheckoutFormInlineError: (error: string) =>
        createAction(CheckoutActionTypes.REMOVE_CHECKOUT_INLINE_ERROR, { error }),
}

export type CheckoutActions = ActionUnion<typeof checkoutActions>
