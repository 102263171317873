import { buyFormStateInitialData } from '@gtresolve/data'
import { ResolveBuyFormState } from './state'
import { ResolveBuyFormActionTypes } from './actions'
import type { BuyFormActions } from './actions'

export const buyFormInitialState: ResolveBuyFormState = buyFormStateInitialData

export const buyFormReducer = (
    previousState: ResolveBuyFormState | undefined,
    action: BuyFormActions
): ResolveBuyFormState => {
    const state = previousState || buyFormInitialState
    switch (action.type) {
        // need to add actions from the form components here
        case ResolveBuyFormActionTypes.RESET_BUY_FORM: {
            return buyFormInitialState
        }
        case ResolveBuyFormActionTypes.UPDATE_INITIAL_FORM_STATE: {
            const { data } = action.payload
            return {
                ...state,
                initialData: { ...data },
            }
        }
        case ResolveBuyFormActionTypes.SET_CLEAR_EMAIL:
            return {
                ...state,
                clearEmail: action.payload.clear,
            }
        case ResolveBuyFormActionTypes.UPDATE_STEPS:
            return {
                ...state,
                steps: {
                    ...state.steps,
                    [action.payload.stepName]: {
                        completed: action.payload.stepResult,
                        error: !action.payload.stepResult,
                    },
                },
            }
        case ResolveBuyFormActionTypes.SET_STATES_OPTIONAL:
            return {
                ...state,
                statesOptional: action.payload.optional,
            }

        default:
            return state
    }
}
