import { LabelContent } from '@gtc/state/label-content'

export const labelContentInitialData: LabelContent = {
    quantityLabelLong: '',
    quantityLabel: '',
    quantityLabelPlural: '',
    email: '',
    firstName: '',
    lastName: '',
    password: '',
    useAccountInfo: '',
    address: '',
    country: '',
    city: '',
    postalCode: '',
    state: '',
    phone: '',
    checkout: '',
    header: '',
    customizePlan: '',
    errorMessageTitle: '',
    orderSummary: '',
    monthly: '',
    annual: '',
    save: '',
    user: '',
    year: '',
    month: '',
    subtotal: '',
    estimatedTax: '',
    tBD: '',
    todaysTotal: '',
    recurringAnnualCharge: '',
    recurringMonthlyCharge: '',
    autoRenewalNote: '',
    createAnAccount: '',
    billingInformation: '',
    passwordRequirements: '',
    paymentDetails: '',
    expirationDate: '',
    cVV: '',
    cVVHint: '',
    completePurchase: '',
    pricingNote: '',
    continue: '',
    changePlan: '',
    selectedPlan: '',
    signIn: '',
    errorInvalidCharacter: '',
    errorInvalidCVV: '',
    errorInvalidEmail: '',
    errorInvalidExpirationDate: '',
    errorInvalidPassword: '',
    errorInvalidPhoneNumber: '',
    errorInvalidTaxNumber: '',
    errorRequiredAddress: '',
    errorRequiredCity: '',
    errorRequiredFirstName: '',
    errorRequiredLastName: '',
    errorRequiredPostalCode: '',
    errorRequiredState: '',
    estimatedTaxPopupInfo: '',
    quantityPopupInfo: '',
    cVVPopupInfo: '',
    yearAbbr: '',
    monthAbbr: '',
    questions: '',
    myAccount: '',
    signOut: '',
    pleaseSelect: '',
    headerPhone: '',
    optOutCheckboxLabel: '',
    optOut: '',
    cardNumber: '',
    errorInvalidCreditCardNumber: '',
    accountInformation: '',
    notYou: '',
    emailAddressAlreadyInUse: '',
    additionalUserLicences: '',
    howManyMore: '',
    existingUserLicencePlural: '',
    existingUserLicense: '',
    additional: '',
    newUserLicense: '',
    total: '',
    proratedAdjustment: '',
    today: '',
    modalCloseButtonText: '',
    changePlanMethodModalHeader: '',
    changePlanModalSubheader: '',
    changePaymentMethodModalHeader: '',
    changePaymentMethodModalSubheader: '',
    edit: '',
    newRecurringAnnualTotal: '',
    newRecurringMonthlyTotal: '',
    previousAnnualTotal: '',
    previousMonthlyTotal: '',
    billingCycleCredit: '',
    getStartedNote: '',
    creatingAccountLeftHeader: '',
    creatingAccountLeftContent: '',
    creatingAccountRightHeader: '',
    creatingAccountRightContent: '',
    getStartedButtonText: '',
    getStartedButtonUrl: '',
    savingWithAnnualBilling: '',
    errorBlockedPurchase: '',
    errorGenericMessage: '',
    errorRequiredBIC: '',
    errorRequiredCVV: '',
    errorRequiredCountry: '',
    errorRequiredCreditCardNumber: '',
    errorRequiredEmail: '',
    errorRequiredExpDate: '',
    errorRequiredPassword: '',
    errorRequiredPhoneNumber: '',
    errorSummaryMessage: '',
    errorTooManyRequests: '',
    errorInvalidPostalCode: '',
    internalErrorMessage: '',
    calculating: '',
    newUserLicensePlural: '',
    fees: '',
    feesPopupText: '',
    changeNumberModalHeader: '',
    changeNumberModalContent: '',
    changePlanUrl: '',
    sEOPageTitle: '',
    notAvailableModalHeader: '',
    notAvailableModalContent: '',
    notAvailableModalButtonText: '',
    notAvailableModalButtonUrl: '',
    showMe: '',
    errorInvalidAddress: '',
    countryPopupText: '',
    paymentMethodErrorMessage: '',
    disabledUserError: '',
    addonUserSuccessUrl: '',
    percentageOffAnnualDiscount: '',
    percentageOffVolumeDiscount: '',
    totalSavings: '',
    volumeDiscountPopupText: '',
    percentageOffPromoDiscount: '',
    invalidQSCouponMessage: '',
    invalidQSCouponUsingCMSCouponMessage: '',
    couponValidMessage: '',
    dismiss: '',
    couponDisclaimer: '',
    couponAdjustmentSavings: '',
    couponAdjustmentSavingsPopupText: '',
    billedMonthlyPrice: '',
    billedAnnualPrice: '',
    secure: '',
    trustArcSupport: '',
    numberPickerLocalNumber: '',
    numberPickerLocalOption: '',
    numberPickerPortNumber: '',
    numberPickerYourNumber: '',
    numberPickerLocal: '',
    numberPickerTollFree: '',
    numberPickerTollFreeNumber: '',
    numberPickerChooseNumber: '',
    numberPickerEditNumber: '',
    numberPickerPleaseSelectNumber: '',
    numberPickerNoNumberSelected: '',
    numberPickerPhone: '',
    numberPickerPhoneNumberNoLongerAvailable: '',
    numberPickerChooseAvailableNumber: '',
    numberPickerNoResults: '',
    numberPickerMainNumber: '',
    numberPickerMainNumberSubText: '',
    numberPickerPortInstructions: '',
    numberPickerAreaCode: '',
    numberPickerAreaCodeHeader: '',
    numberPickerAreaCodeSubHeader: '',
    numberPickerBasic: '',
    numberPickerCancel: '',
    numberPickerClose: '',
    numberPickerReset: '',
    numberPickerSave: '',
    numberPickerSearch: '',
    numberPickerVanity: '',
    numberPickerVanityPhrase: '',
    numberPickerVanityPhraseDescriptionLine1: '',
    numberPickerVanityPhraseDescriptionLine2: '',
    numberPickerVanityPhraseDescriptionLine3: '',
    numberPickerVanityPhrasePlaceholder: '',
    numberPickerFailedToOrderPhoneNumberModalHeader: '',
    numberPickerFailedToOrderPhoneNumberModalSubHeader: '',
    numberPickerFailedToOrderPhoneNumberModalCloseButtonText: '',
    allrightsreservedDisclaimerLabel: '',
    termsandconditionsLabel: '',
    privacypolicyLabel: '',
    antiSpamPolicyLabel: '',
    trademarkLabel: '',
    sitemapLabel: '',
    termsandconditionsUrl: '',
    privacypolicyUrl: '',
    antiSpamPolicyUrl: '',
    trademarkUrl: '',
    sitemapUrl: '',
    passwordStrength: '',
    passwordStrengthLevel1: '',
    passwordStrengthLevel2: '',
    passwordStrengthLevel3: '',
    passwordStrengthLevel4: '',
    passwordStrengthTip1: '',
    passwordStrengthTip2: '',
    passwordStrengthTip3: '',
    passwordStrengthTipDrawer: '',
}
