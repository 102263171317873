import React, { useEffect, useState, FunctionComponent } from 'react'
import { ResolveFooterLabels } from '@gtresolve/state/footer-labels'
import { CommonLink, CustomDropdown } from '@common/components'
import classNames from 'classnames'
import { LANGUAGE_CODES } from '@common/constants/languages'

import { detokenizeYear } from '@common/utils/detoken-year'
import { TrustArc } from '../TrustArc'
// Import Local Styles
import styles from './footer.module.css'

export interface FooterProps {
    content: ResolveFooterLabels
    theme?: 'dark' | 'light'
    showLanguageSelector?: boolean
    languageCode?: string
    setLanguage?: (lang: string) => void
    purpose?: 'primary' | 'secondary'
    modifier?: string
}

export const Footer: FunctionComponent<FooterProps> = ({
    content,
    theme = 'light',
    languageCode,
    setLanguage,
    purpose = 'primary',
    modifier = '',
}) => {
    const [launchTrustArc, setlaunchTrustArc] = useState(false)
    const [languageOptions, setLanguageOptions] = useState<Array<string>>([])
    const [language, setLanguageState] = useState<string>('')

    useEffect(() => {
        setlaunchTrustArc(true)
    }, [])

    useEffect(() => {
        if (languageCode && setLanguage) {
            const languageCodes: string[] = Object.values(LANGUAGE_CODES)
            const languages: string[] = Object.keys(LANGUAGE_CODES)

            // If user has selected a language, we need to show that language to be top of list
            const codeIndex = languageCodes.indexOf(languageCode)
            languages.unshift(languages[codeIndex])
            languages.splice(codeIndex + 1, 1)

            setLanguageOptions(languages)
            setLanguageState(languages[0])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [languageCode])

    const footerStyle = classNames({
        [styles.footer]: true,
        [styles['footer--dark']]: theme === 'dark',
        [styles[`footer--${theme}-primary`]]: purpose === 'primary',
        [modifier]: true,
    })

    const footerClasslist = classNames({
        [styles.footerLink]: true,
    })

    const languageIconClasslist = classNames({
        [styles['language-selector-icon']]: true,
        [styles['language-selector-icon--dark']]: theme === 'dark',
    })

    const languageSelectorClasslist = classNames({
        [styles['language-selector-active']]: true,
        [styles['language-selector-active--dark']]: theme === 'dark',
    })

    const languageOptionsContainerClasslist = classNames({
        [styles['language-selector-options-wrapper']]: true,
        [styles['language-selector-options-wrapper--dark']]: theme === 'dark',
    })

    return (
        <>
            <footer className={footerStyle}>
                <ul>
                    <li>{detokenizeYear(content.allrightsreservedDisclaimerLabel)}</li>
                    <li>
                        <CommonLink modifier={footerClasslist} href={content.termsandconditionsUrl}>
                            {content.termsandconditionsLabel}
                        </CommonLink>
                    </li>
                    <li>
                        <CommonLink modifier={footerClasslist} href={content.privacypolicyUrl}>
                            {content.privacypolicyLabel}
                        </CommonLink>
                    </li>
                    <li>
                        <CommonLink modifier={footerClasslist} href={content.antiSpamPolicyUrl}>
                            {content.antiSpamPolicyLabel}
                        </CommonLink>
                    </li>
                    <li>
                        <CommonLink modifier={footerClasslist} href={content.trademarkUrl}>
                            {content.trademarkLabel}
                        </CommonLink>
                    </li>
                    <li>
                        <span id="teconsent" />
                    </li>
                    <li>
                        <CommonLink modifier={footerClasslist} href={content.sitemapUrl}>
                            {content.sitemapLabel}
                        </CommonLink>
                    </li>
                    {language && (
                        <li className={styles['language-selector-wrapper--active']}>
                            <CustomDropdown
                                name="languages"
                                onChange={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                                    if (setLanguage)
                                        setLanguage(LANGUAGE_CODES[(event.target as HTMLElement).innerText])
                                }}
                                value={language}
                                additionalClass={styles['language-selector-select']}
                                options={languageOptions}
                                optionsContainerClass={languageOptionsContainerClasslist}
                                optionsClass={styles['language-selector-option']}
                                customizeTheme="secondary"
                                selectorClass={languageSelectorClasslist}
                                iconClass={languageIconClasslist}
                                showAtTop
                            />
                        </li>
                    )}
                </ul>
            </footer>

            {/* Keep TrustArc outside of <footer> so it won't inherit styles from footer.module.css */}
            {launchTrustArc ? <TrustArc language={languageCode} /> : null}
        </>
    )
}
