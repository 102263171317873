import initializeLoadContentSaga from './content/load-content'
import initializeSubmitFormSaga from './checkout-form/form-submission'
import initializeAbandonedCartSaga from './user/abandoned-cart'
import initializeExistingUserSaga from './user/existing-user'
import initializeCreateUserSaga from './user/create-user'
import initializeGetAccessToken from './user/get-token'
import initializeLogin from './user/login'
import initializeLogout from './user/logout'
import initializeLoadSavedStateSaga from './saved-state/saved-state'
import initializeCalculatePriceSaga from './checkout-form/calculate-price'
import initializeCreateBillingInfoSaga from './checkout-form/create-billing-info'
import initializeCreatePaymentSaga from './checkout-form/create-payment'
import initializeCreateAndSubmitPaymentSaga from './checkout-form/create-and-submit-payment'
import initializeGetPaymentMethodSaga from './checkout-form/get-payment-methods'
import initializeSubmitPaymentSaga from './checkout-form/submit-payment'
import initializeUserLoggedInSaga from './user/user-logged-in'
import initializeExistingUserSubmitFormSaga from './checkout-form/existing-user-form-submission'
import initializeResetCheckoutFormAffectedStatesSaga from './checkout-form/reset-checkout-form-affected-states'
import initializeBuyTrackingSaga from './tracking/track-event'
import initializeCalculateVolumeDiscountSaga from './checkout-form/calculate-volume-discount'
import initializeGetSelectedTier from './checkout-form/get-selected-tier'
import initializeGetVolumeDiscountSaga from './checkout-form/get-volume-discount'
import initializeSetBillingFrequencySaga from './checkout-form/set-billing-frequency'
import initializeValidateCouponSaga from './coupon/validate-coupon'
import initializeGetCouponDetailsSaga from './coupon/get-coupon-details'
import initializeCalculateCouponPriceSaga from './coupon/calculate-coupon-price'
import initializeObserveValidateCouponSaga from './coupon/observe-validate-coupon'
import initializeAttempUrlCouponSaga from './coupon/attempt-url-coupon'
import initialCheckCMSCouponSaga from './coupon/check-cms-coupon'
import initializeCheckVolumeDiscountAgainstRepriceCouponSaga from './coupon/check-volume-discount-against-reprice-coupon'
import initializeAttemptCMSCouponSaga from './coupon/attempt-cms-coupon'

export const buySagas = {
    buyContentSaga: initializeLoadContentSaga,
    buySubmitFormSaga: initializeSubmitFormSaga,
    buyAbandonedCartSaga: initializeAbandonedCartSaga,
    buyExistingUserSaga: initializeExistingUserSaga,
    buyGetAccessToken: initializeGetAccessToken,
    buyUserLoginSaga: initializeLogin,
    buyUserLogoutSaga: initializeLogout,
    buyCreateUserSaga: initializeCreateUserSaga,
    buyCalculatePriceSaga: initializeCalculatePriceSaga,
    buyCreateBillingInfoSaga: initializeCreateBillingInfoSaga,
    buyCreatePaymentSaga: initializeCreatePaymentSaga,
    buyCreateAndSubmitPaymentSaga: initializeCreateAndSubmitPaymentSaga,
    buyGetPaymentSaga: initializeGetPaymentMethodSaga,
    buySubmitPaymentSaga: initializeSubmitPaymentSaga,
    buyLoadSavedStateSaga: initializeLoadSavedStateSaga,
    buyUserLoggedInSaga: initializeUserLoggedInSaga,
    buyExistingUserSubmitFormSaga: initializeExistingUserSubmitFormSaga,
    buyResetCheckoutFormAffectedStates: initializeResetCheckoutFormAffectedStatesSaga,
    buyTrackingSaga: initializeBuyTrackingSaga,
    buyCalculateVolumeDiscountSaga: initializeCalculateVolumeDiscountSaga,
    buyGetVolumeDiscountSaga: initializeGetVolumeDiscountSaga,
    buyGetSelectedTierSaga: initializeGetSelectedTier,
    buySetBillingFrequencySaga: initializeSetBillingFrequencySaga,
    buyValidateCouponSaga: initializeValidateCouponSaga,
    buyGetCouponDetailsSaga: initializeGetCouponDetailsSaga,
    buyCalculateCouponPriceSaga: initializeCalculateCouponPriceSaga,
    buyObserveValidateCouponSaga: initializeObserveValidateCouponSaga,
    buyAttemptUrlCouponSaga: initializeAttempUrlCouponSaga,
    buyHeckCMSCouponSaga: initialCheckCMSCouponSaga,
    buyCheckVolumeDiscountAgainstRepriceCouponSaga: initializeCheckVolumeDiscountAgainstRepriceCouponSaga,
    buyAttemptCMSCouponSaga: initializeAttemptCMSCouponSaga,
}

export default buySagas
