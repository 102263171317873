import { call, takeEvery } from 'redux-saga/effects'
import { resolveUserActions, ResolveUserActionTypes } from '@gtresolve/state/user/actions'
import authClass from '@common/utils/auth-class'

function* userLogin(action: ReturnType<typeof resolveUserActions.userLogin>) {
    try {
        const pathname = action.payload.pathname || window.location.pathname
        const search = action.payload.search || window.location.search
        const sso = action.payload.sso || false
        yield call(authClass.login, pathname, search, sso)
    } catch (e) {
        // no error
    }
}

function* initializeLogin() {
    yield takeEvery(ResolveUserActionTypes.USER_LOGIN, userLogin)
}

export default initializeLogin
