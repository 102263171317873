import React, { FunctionComponent } from 'react'
import parse from 'html-react-parser'
import sanitizeHTML from 'sanitize-html'

export interface ConvertStringToReactProps {
    htmlString: string
}

export const ConvertStringToReact: FunctionComponent<ConvertStringToReactProps> = ({ htmlString }) => {
    const sanitizeHtmlString = sanitizeHTML(htmlString, {
        allowedSchemes: ['http', 'https', 'mailto', 'tel'],
    })
    return <>{parse(sanitizeHtmlString)}</>
}
