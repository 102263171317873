import { call, takeEvery, put, select } from 'redux-saga/effects'
import { userActions, UserActionTypes } from '@gtc/state/user/actions'
import { submitAbandonedCart } from '@gtc/services'
import { fatalErrorActions } from '@common/state/fatal-error/actions'
import { COOKIE_NAMES } from '@common/constants'
import Cookies from 'js-cookie'
import { getCheckoutState, getUserState } from '@gtc/state/selectors'
import { CheckoutState } from '@gtc/state/checkout/state'
import { AbandonedCartPayload, UserState } from '@gtc/state/user/state'
import { LocationState } from '@common/state/location/state'
import { getLocationState } from '@common/state/selectors'

export function* abandonedCartSaga(action: ReturnType<typeof userActions.submitAbandonedCart>) {
    const { isTrial, email, success, form } = action.payload.abandonedCartData
    const { locationInfo }: LocationState = yield select(getLocationState)
    const data: AbandonedCartPayload = {
        isTrial,
        success,
        email,
        mkto_trk: Cookies.get(COOKIE_NAMES.MARKETO_TRK) || '',
        country: locationInfo?.country,
    }

    // Include billing and plan information if cart is abandoned in buy form (1st abandoned cart call only)
    if (!isTrial && !success) {
        const { existingBillingInformation, existingUserInformation }: UserState = yield select(getUserState)
        const { selectedPlanSku, selectedAddons, organizers, billingFrequency, calculatedNetTotal }: CheckoutState =
            yield select(getCheckoutState)

        data.plan = selectedPlanSku
        data.quantity = organizers
        data.billing = billingFrequency
        data.firstName = form?.FirstName || existingUserInformation?.Name?.GivenName
        data.lastName = form?.LastName || existingUserInformation?.Name?.FamilyName
        data.phone = form?.ContactPhone || existingBillingInformation?.phoneNumber
        data.language = locationInfo?.language

        if (calculatedNetTotal && calculatedNetTotal > 0) {
            data.totalPrice = calculatedNetTotal
        }

        if (selectedAddons && selectedAddons.length) {
            const [{ quantity: addOnQuantity }] = selectedAddons
            data.addOnQuantity = addOnQuantity
        }
    }

    try {
        yield call(submitAbandonedCart, data)
    } catch (e) {
        yield put(fatalErrorActions.setFatalError(true, { trackErrorMessage: 'failed to post abandoned email api' }))
    }
}

function* initializeAbandonedCartSaga() {
    yield takeEvery(UserActionTypes.SUBMIT_ABANDONED_CART, abandonedCartSaga)
}

export default initializeAbandonedCartSaga
