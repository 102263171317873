import React, { FunctionComponent } from 'react'
import classNames from 'classnames'

// Import Local Styles
import styles from './select.module.css'

export interface SelectProps {
    error?: string
    hint?: string
    wrapperClass?: string
    valid?: boolean
    label: string | Element | React.ReactNode
    selected?: boolean
    qaTag?: string
    modifier?: string
    customArrow?: boolean
    name?: string
    disabled?: boolean
    onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void
    onBlur?: (event: React.FocusEvent<HTMLSelectElement>) => void
    value?: string | number
    children?: React.ReactNode
    style?: any
}

export const Select: FunctionComponent<SelectProps> = ({
    name,
    label,
    error,
    hint,
    children,
    disabled = false,
    value,
    selected,
    wrapperClass = '',
    qaTag = '',
    valid,
    onChange,
    onBlur,
    modifier = '',
    customArrow,
    style,
}) => {
    const onChangeFn = (ev: React.ChangeEvent<HTMLSelectElement>) => {
        ev.preventDefault()
        if (onChange) onChange(ev)
    }

    const onBlurFn = (ev: React.FocusEvent<HTMLSelectElement>) => {
        ev.preventDefault()
        if (onBlur) onBlur(ev)
    }

    const outerWrapperClassList = classNames({
        [wrapperClass]: wrapperClass,
        [modifier]: !!modifier,
    })

    const innerWrapperClassList = classNames({
        [styles['select-wrapper']]: true,
        [styles.selected]: selected,
        [styles.error]: error,
        [styles.valid]: valid,
        [styles.disabled]: disabled,
    })

    const arrowStyles = classNames({
        [styles['select-arrow']]: !customArrow,
        [styles['select-arrow--custom']]: !!customArrow,
    })

    return (
        <div className={outerWrapperClassList} style={style}>
            {label && (
                <label className={styles['select-label']} htmlFor={name}>
                    {label}
                </label>
            )}
            <div className={innerWrapperClassList}>
                {children && (
                    <select
                        className={styles.select}
                        disabled={disabled}
                        id={name}
                        name={name}
                        onBlur={(ev) => onBlurFn(ev)}
                        onChange={(ev) => onChangeFn(ev)}
                        value={value}
                    >
                        {children}
                    </select>
                )}
                <span className={arrowStyles} />
            </div>
            {error && (
                <div className={styles['error-message']} data-qat={qaTag ? `${qaTag}-error-message` : ''}>
                    {error}
                </div>
            )}
            {hint && <div className={styles.hint}>{hint}</div>}
        </div>
    )
}
