import { call, takeEvery, put } from 'redux-saga/effects'
import { resolveUserActions, ResolveUserActionTypes } from '@gtresolve/state/user/actions'
import commonServices, { EmailType, EmailCheckResponse } from '@common/services'
import { fatalErrorActions } from '@common/state/fatal-error/actions'
import { resolveBuyFormActions } from '@gtresolve/state/buy-form/actions'

export function* existingUserSaga(action: ReturnType<typeof resolveUserActions.checkExistingUser>) {
    const data: EmailType = { emailAddress: action.payload.email.toLowerCase() }
    try {
        const response: EmailCheckResponse = yield call(commonServices.canUserAuthenticate, data)
        if (response && response.data) {
            if (response.data.canUserAuthenticate) {
                yield put(resolveBuyFormActions.setClearEmail(true))
                return true
            }

            return false
        }
    } catch (e) {
        yield put(fatalErrorActions.setFatalError(true, { trackErrorMessage: 'failed to verify email' }))
        return false
    }
    return false
}

function* initializeExistingUserSaga() {
    yield takeEvery(ResolveUserActionTypes.CHECK_EXISTING_USER, existingUserSaga)
}

export default initializeExistingUserSaga
