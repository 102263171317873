const defaultExpression = '^[A-Za-z0-9- ]+$'

const regexExpressions: Record<string, string[]> = {
    US: ['^\\d{5}$', '^\\d{5}-\\d{4}$', '^\\d{9}$'],
    CA: ['^[a-zA-Z]\\d[a-zA-Z]\\s?\\d[a-zA-Z]\\d$'],
}

export const isValidPostalCode = (country = '', postalCode = '') => {
    const countryUpperCase = country.toUpperCase()
    const countryExpressions = regexExpressions[countryUpperCase] || [defaultExpression]

    for (let i = 0; i < countryExpressions.length; i += 1) {
        const regExp = new RegExp(countryExpressions[i])
        if (regExp.test(postalCode)) {
            return true
        }
    }

    return false
}
