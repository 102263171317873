import { TimePeriod } from '@common/constants'
import { BuyCheckoutState } from './state'
import { BuyCheckoutActionTypes } from './actions'
import type { BuyCheckoutActions } from './actions'

export const initialCheckoutState: BuyCheckoutState = {
    isLoading: true,
    selectedAddons: [],
    submitting: false,
    organizers: 1,
    promoCode: '',
    billingFrequency: TimePeriod.Year,
    calculatedTax: 0,
    calculatedTotal: 0,
    calculatedNetTotal: 0,
    calculatedCredit: 0,
    anniversaryDate: '',
    selectedPlanSku: '',
    savedStateLoaded: false,
    purchaseComplete: false,
    addonComplete: false,
    totalLoading: false,
    selectedTierIndex: 0, // the default selected tier is Tier1, since selectedTierIndex is zero based index.
    currentVolumeDiscountPrice: 0,
    volumeDiscountLoading: false,
    volumeDiscount: {},
    calculatedProratedAmount: 0,
    extraAddons: [],
    endpoints: 0,
    agents: 0,
    taxType: '',
    autoLogin: undefined,
}

export const checkoutReducer = (
    previousState: BuyCheckoutState | undefined,
    action: BuyCheckoutActions
): BuyCheckoutState => {
    const state = previousState || initialCheckoutState
    switch (action.type) {
        case BuyCheckoutActionTypes.SET_LOADING:
            return {
                ...state,
                isLoading: action.payload.loading,
            }
        case BuyCheckoutActionTypes.SET_PURCHASE_COMPLETE:
            return {
                ...state,
                purchaseComplete: action.payload.complete,
            }
        case BuyCheckoutActionTypes.SET_BILLING_FREQUENCY:
            return {
                ...state,
                billingFrequency: action.payload.frequency,
            }
        case BuyCheckoutActionTypes.CLEAR_ADDONS:
            return {
                ...state,
                selectedAddons: [],
            }
        case BuyCheckoutActionTypes.SET_SELECTED_PLAN:
            return {
                ...state,
                selectedPlanSku: action.payload.planSku,
            }
        case BuyCheckoutActionTypes.SET_SELECTED_ADDONS: {
            const { addons } = action.payload
            return {
                ...state,
                selectedAddons: [...addons],
            }
        }
        case BuyCheckoutActionTypes.SET_ORGANIZERS:
            return {
                ...state,
                organizers: action.payload.organizers,
            }
        case BuyCheckoutActionTypes.SET_CALCULATED_PRICE:
            return {
                ...state,
                productPrices: action.payload.productPrices,
                calculatedNetTotal: action.payload.net,
                calculatedTotal: action.payload.total,
                calculatedTax: action.payload.tax,
                calculatedCredit: action.payload.creditback,
                anniversaryDate: action.payload.anniversaryDate,
            }
        case BuyCheckoutActionTypes.SET_SAVED_STATE_LOADED:
            return {
                ...state,
                savedStateLoaded: action.payload.savedStateLoaded,
            }
        case BuyCheckoutActionTypes.SET_LOADING_TOTAL:
            return {
                ...state,
                totalLoading: action.payload.loading,
            }
        case BuyCheckoutActionTypes.SET_ADDON_COMPLETE:
            return {
                ...state,
                addonComplete: action.payload.addonComplete,
            }
        case BuyCheckoutActionTypes.SET_SELECTED_TIER_INDEX: {
            const { selectedTierIndex } = action.payload
            return {
                ...state,
                selectedTierIndex,
            }
        }
        case BuyCheckoutActionTypes.SET_VOLUME_DISCOUNT: {
            const { selectedPlanSku, currency, volumeDiscountForSelectedPlanAndCurrency } = action.payload
            const selectedPlanSkuVolumeDiscount = state.volumeDiscount[selectedPlanSku] || {}
            return {
                ...state,
                volumeDiscount: {
                    ...state.volumeDiscount,
                    [selectedPlanSku]: {
                        ...selectedPlanSkuVolumeDiscount,
                        [currency]: volumeDiscountForSelectedPlanAndCurrency,
                    },
                },
            }
        }
        case BuyCheckoutActionTypes.SET_VOLUME_DISCOUNT_LOADING: {
            const { volumeDiscountLoading } = action.payload
            return { ...state, volumeDiscountLoading }
        }
        case BuyCheckoutActionTypes.SET_CURRENT_VOLUME_DISCOUNT_PRICE_AND_PERCENTAGE: {
            const { currentVolumeDiscountPrice } = action.payload
            return { ...state, currentVolumeDiscountPrice }
        }
        case BuyCheckoutActionTypes.ADJUST_CURRENT_VOLUME_DISCOUNT_FOR_REPRICE_COUPON: {
            return { ...state, currentVolumeDiscountPrice: 0 }
        }
        case BuyCheckoutActionTypes.SET_PRORATED_AMOUNT: {
            const { proratedAmount } = action.payload
            return { ...state, calculatedProratedAmount: proratedAmount }
        }
        case BuyCheckoutActionTypes.RESET_CHECKOUT_STATE: {
            return initialCheckoutState
        }
        case BuyCheckoutActionTypes.SET_EXTRA_ADDON: {
            const { addons = [] } = action.payload
            return {
                ...state,
                extraAddons: addons,
            }
        }
        case BuyCheckoutActionTypes.SET_ENDPOINTS: {
            return {
                ...state,
                endpoints: action.payload.endpoints,
            }
        }
        case BuyCheckoutActionTypes.SET_AGENTS: {
            return {
                ...state,
                agents: action.payload.agents,
            }
        }
        case BuyCheckoutActionTypes.SET_TAX_TYPE: {
            return {
                ...state,
                taxType: action.payload.type,
            }
        }
        case BuyCheckoutActionTypes.SET_AUTO_LOGIN: {
            return {
                ...state,
                autoLogin: action.payload.autoLogin,
            }
        }

        default:
            return state
    }
}
