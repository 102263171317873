import { useTrustArc } from '@common/hooks'
import React, { FunctionComponent, useEffect, useState } from 'react'
import styles from './trustarc.module.css'

export interface TrustArcProps {
    language?: string
}

export const TrustArc: FunctionComponent<TrustArcProps> = ({ language = '' }) => {
    const [selectedLang, setSelectedLang] = useState(language)

    useEffect(() => {
        if (language !== selectedLang) {
            setSelectedLang(language)
            window.location.reload()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [language])

    useTrustArc(language)

    return <div id="consent_blackbar" className={styles.trustarc} />
}
