import { combineReducers } from 'redux'
import { contentReducer } from '@lmipro/state/content/reducer'
import { checkoutReducer } from '@lmipro/state/checkout/reducer'
import { buyFormReducer } from '@lmipro/state/buy-form/reducer'
import { userReducer } from '@lmipro/state/user/reducer'
import { couponReducer } from '@lmipro/state/coupon/reducer'

export const lmiProAppReducer = combineReducers({
    content: contentReducer,
    checkout: checkoutReducer,
    form: buyFormReducer,
    user: userReducer,
    coupon: couponReducer,
})

export type LmiProAppState = ReturnType<typeof lmiProAppReducer>
export type LmiProContainerAppState = {
    lmipro: LmiProAppState
}
