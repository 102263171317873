import { call, takeEvery, put } from 'redux-saga/effects'
import { userActions, UserActionTypes } from '@gtc/state/user/actions'
import commonServices, { EmailType, EmailCheckResponse } from '@common/services'
import { fatalErrorActions } from '@common/state/fatal-error/actions'
import { buyFormActions } from '@gtc/state/buy-form/actions'
import { AbandonedCartDataType } from '@gtc/state/user/state'

export function* existingUserSaga(action: ReturnType<typeof userActions.checkExistingUser>) {
    const data: EmailType = { emailAddress: action.payload.email.toLowerCase() }
    const { form } = action.payload
    try {
        const response: EmailCheckResponse = yield call(commonServices.canUserAuthenticate, data)
        if (response && response.data) {
            if (response.data.canUserAuthenticate) {
                yield put(buyFormActions.setClearEmail(true))
                return true
            }
            yield put(buyFormActions.setClearEmail(false))
            const abandonedCartData: AbandonedCartDataType = {
                email: data.emailAddress,
                success: false,
                isTrial: false,
                form,
            }
            yield put(userActions.submitAbandonedCart(abandonedCartData))
            return false
        }
    } catch (e) {
        yield put(fatalErrorActions.setFatalError(true, { trackErrorMessage: 'failed to verify email' }))
        return false
    }
    return false
}

function* initializeExistingUserSaga() {
    yield takeEvery(UserActionTypes.CHECK_EXISTING_USER, existingUserSaga)
}

export default initializeExistingUserSaga
