import get from 'lodash/get'
import { CURRENCY } from '@common/constants'
import {
    ResolveBillingFrequencyVolumeDiscountRecords,
    ResolveVolumeDiscountPerTier,
} from '@gtresolve/state/checkout/state'
import { ResolvePlan } from '@gtresolve/state/plan'
import { ResolvePrice, ResolvePricing, ResolveTiers } from '@gtresolve/state/price'
import { ResolveAddon } from '@gtresolve/state/add-on'

export const getDiscount = (basePrice: number, newPrice: number): number => {
    return basePrice - newPrice
}

export const getDiscountPercentage = (basePrice: number, newPrice: number): number => {
    return Math.floor(((basePrice - newPrice) / basePrice) * 100)
}

const processPricingTier = (pricingTierKeys: string[], pricingTiers: ResolveTiers): ResolveVolumeDiscountPerTier[] => {
    let basePrice = 0
    return pricingTierKeys.reduce((pricingTierAccumulator, pricingTier, pricingTierIndex) => {
        const pricingTierAccumulatorCopy = [...pricingTierAccumulator]
        const pricingTierData = pricingTiers[pricingTier as keyof ResolveTiers]
        const tierPrice = pricingTierData.price
        if (pricingTierIndex === 0) {
            basePrice = tierPrice
        }

        const volumeDiscountPerTier: ResolveVolumeDiscountPerTier = {
            discountPricePerUnit: getDiscount(basePrice, tierPrice),
        }
        pricingTierAccumulatorCopy.push(volumeDiscountPerTier)
        return pricingTierAccumulatorCopy
    }, [] as ResolveVolumeDiscountPerTier[])
}

const processCurrencyPricing = (
    pricingKeys: string[],
    currencyPricing: ResolvePricing
): ResolveBillingFrequencyVolumeDiscountRecords => {
    return pricingKeys.reduce((billingFrequencyAccumulator, billingFrequency) => {
        const billingFrequencyAccumulatorCopy = { ...billingFrequencyAccumulator }
        const pricingTiers = currencyPricing[billingFrequency as keyof ResolvePricing]
        const pricingTierKeys = Object.keys(pricingTiers)
        billingFrequencyAccumulatorCopy[billingFrequency] = processPricingTier(pricingTierKeys, pricingTiers)
        return billingFrequencyAccumulatorCopy
    }, {} as ResolveBillingFrequencyVolumeDiscountRecords)
}

export const calculateVolumeDiscount = (
    selectedPlan: ResolvePlan,
    selectedCurrency: CURRENCY
): ResolveBillingFrequencyVolumeDiscountRecords => {
    const { pricingInfo } = selectedPlan ?? {}
    const currencyPricing = pricingInfo[selectedCurrency as keyof ResolvePrice] as ResolvePricing
    const pricingKeys = Object.keys(currencyPricing)
    return processCurrencyPricing(pricingKeys, currencyPricing)
}

export const getTotalVolumeDiscount = (discountPerUser: number, organizers: number, annualize: boolean) => {
    const volumeDiscountTimesOrganizer = discountPerUser * organizers

    const totalVolumeDiscount = annualize ? volumeDiscountTimesOrganizer * 12 : volumeDiscountTimesOrganizer

    return totalVolumeDiscount
}

export const getAddonVolumeDiscount = (
    addon: ResolveAddon,
    quantity: number,
    currencyCode: string,
    billingFrequencyKey: string,
    _currentTier: string,
    subscribedAddonQuantity?: number
) => {
    const discountPercentagesForQuantity = () => {
        const basePrice = get(addon, ['pricingInfo', currencyCode, billingFrequencyKey, 'tier1', 'price'], 0)
        const tierTwoPrice = get(addon, ['pricingInfo', currencyCode, billingFrequencyKey, 'tier2', 'price'], 0)
        const tierThreePrice = get(addon, ['pricingInfo', currencyCode, billingFrequencyKey, 'tier3', 'price'], 0)
        const tierFourPrice = get(addon, ['pricingInfo', currencyCode, billingFrequencyKey, 'tier4', 'price'], 0)
        const tierFivePrice = get(addon, ['pricingInfo', currencyCode, billingFrequencyKey, 'tier5', 'price'], 0)

        if (quantity <= get(addon, ['pricingInfo', currencyCode, billingFrequencyKey, 'tier1', 'max'], 0))
            return { actualPercentage: 0, tierIndex: 1 }

        if (quantity <= get(addon, ['pricingInfo', currencyCode, billingFrequencyKey, 'tier2', 'max'], 0)) {
            const percent = ((basePrice - tierTwoPrice) / basePrice) * 100
            return { actualPercentage: Math.floor(percent), tierIndex: 2 }
        }

        if (quantity <= get(addon, ['pricingInfo', currencyCode, billingFrequencyKey, 'tier3', 'max'], 0)) {
            const percent = ((basePrice - tierThreePrice) / basePrice) * 100
            return { actualPercentage: Math.floor(percent), tierIndex: 3 }
        }

        if (quantity <= get(addon, ['pricingInfo', currencyCode, billingFrequencyKey, 'tier4', 'max'], 0)) {
            const percent = ((basePrice - tierFourPrice) / basePrice) * 100
            return { actualPercentage: Math.floor(percent), tierIndex: 4 }
        }

        const percent = ((basePrice - tierFivePrice) / basePrice) * 100
        return { actualPercentage: Math.floor(percent), tierIndex: 5 }
    }

    // WAE-401 :: While showing the percentage value in line item, we need to round it off to the `floor`. But for the discount amount, need to `round` it to closest
    const { actualPercentage, tierIndex } = discountPercentagesForQuantity()

    const price = get(addon, ['pricingInfo', currencyCode, billingFrequencyKey, `tier${tierIndex}`, 'price'], 0)
    const isAnnual = billingFrequencyKey === 'annual'

    const discountPrice =
        Math.floor((actualPercentage / 100) * price * (isAnnual ? 12 : 1)) * (subscribedAddonQuantity || quantity)

    return { discountPrice, tierIndex }
}
