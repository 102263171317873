import { ActionUnion, createAction } from '@common/utils'

export enum ContentActionTypes {
    SET_LANGUAGE = '[common-content-actions] set-language',
}

export const contentActions = {
    setLanguage: (language: string) => createAction(ContentActionTypes.SET_LANGUAGE, { language }),
}

export type ContentActions = ActionUnion<typeof contentActions>
