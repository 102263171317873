import { resolveCouponActions, ResolveCouponActionTypes } from '@gtresolve/state/coupon/actions'
import { put, takeLatest, take } from 'redux-saga/effects'
import { select } from 'redux-saga-test-plan/matchers'
import { getCheckoutState } from '@gtresolve/state/selectors'
import { ResolveCheckoutState } from '@gtresolve/state/checkout/state'
import isEmpty from 'lodash/isEmpty'
import { resolveCheckoutActions, ResolveCheckoutActionTypes } from '@gtresolve/state/checkout/actions'

function* attemptCMSCouponSaga() {
    // check if there's a coupon available from CMS
    let { selectedPlanSku }: ResolveCheckoutState = yield select(getCheckoutState)
    if (isEmpty(selectedPlanSku)) {
        const selectedPlanAction: ReturnType<typeof resolveCheckoutActions.setSelectedPlan> = yield take(
            ResolveCheckoutActionTypes.SET_SELECTED_PLAN
        )
        selectedPlanSku = selectedPlanAction.payload.planSku
    }
    yield put(resolveCouponActions.checkCMSCoupon(selectedPlanSku))
}

export default function* initializeAttemptCMSCouponSaga() {
    yield takeLatest(ResolveCouponActionTypes.ATTEMPT_FOR_CMS_COUPON, attemptCMSCouponSaga)
}
