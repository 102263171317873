import { isLmiProApp, saveSessionState as saveCommonSessionState } from '@common/utils'
import { saveSessionState, saveState } from '@lmipro/utils'
import { AppState } from 'app-store'
import { MiddlewareAPI } from 'redux'
import { STORAGE_CONST } from '@common/constants'

export const lmiProThrottle = (store: MiddlewareAPI) => {
    if (isLmiProApp()) {
        const appState: AppState = store.getState()
        const lmiProStore = appState.lmipro
        const commonStore = appState.common
        const {
            organizers,
            billingFrequency,
            selectedPlanSku,
            selectedAddons,
            savedStateLoaded,
            purchaseComplete,
            addonComplete,
        } = lmiProStore.checkout
        const { urlCouponCode } = lmiProStore.coupon
        const { language } = commonStore.content
        if (savedStateLoaded && !purchaseComplete && !addonComplete) {
            saveState({
                checkout: {
                    organizers,
                    billingFrequency,
                    selectedPlanSku,
                    selectedAddons,
                },
            })
            if (urlCouponCode) {
                saveSessionState({
                    coupon: {
                        urlCouponCode,
                    },
                })
            }
            if (language) {
                saveCommonSessionState(STORAGE_CONST.SAVED_LANGUAGE, language)
            }
        }
    }
}
