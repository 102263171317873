import { useRef, useEffect } from 'react'

export const useIsMountedRef = () => {
    const isMountedRef = useRef(false)
    useEffect(() => {
        isMountedRef.current = true
        return () => {
            isMountedRef.current = false
        }
    }, [])
    return isMountedRef
}
