import React, { FunctionComponent, useState, useEffect, useMemo, useCallback } from 'react'

// import components
import {
    Tooltip,
    BoxWrapper,
    CommonButton,
    LicenseSelector,
    ConvertStringToReact,
    TrustArcLogo,
    CardSelector,
} from '@common/components'
import { connectRedux, formatDate, GoToComponentProps } from '@common/utils'
import { ConnectedModal } from '@common/connectedComponents/Modal'
import Pencil from '@common/images/icon-modify-on.svg'

import { Addon, SelectedAddon } from '@gtc/state/add-on'
import {
    CustomizeActionsType,
    mapCustomizeActions,
    mapCustomizeState,
} from '@gtc/connectedComponents/Customize/connector'
import { isAddressComplete } from '@gtc/utils'
import { CustomizeState } from '@gtc/connectedComponents/Customize/state'
import { LICENSECAP, NUMBERSCAP, USER_TYPES, ADDONS } from '@gtc/constants'
import { useCustomizePageTracking } from '@gtc/hooks'
import { Card, CardLabels } from '@common/components/Card'
import { BuyFormData } from '@gtc/state/buy-form/state'
import { NumberPickerContent, NumberPickerModal, NumberPickerOptions } from '@gtc/components/NumberPicker'
import logo from '@common/images/goto-connect-logo.svg'
import icon from '@common/images/goto-connect-icon.svg'
import { usePhoneNumberSearch } from '@gtc/hooks/usePhoneNumberSearch'
import { DIRECTIONS } from '@common/constants'
import { ConnectedOrderSummary } from '../OrderSummary'
import styles from './customize.module.css'

type CustomizeProps = GoToComponentProps<
    CustomizeState,
    CustomizeActionsType,
    {
        nextSteps: () => void
        isTabletOrMobileView?: boolean
        isMobileView?: boolean
        isNumberPickerFeatureEnabled: boolean
        contentNumberPicker: NumberPickerContent
        showMissingPhoneNumberError: boolean
        setShowMissingPhoneNumberError: (showMissingPhoneNumberError: boolean) => void
    }
>

const Customize: FunctionComponent<CustomizeProps> = ({ state, actions, props }) => {
    const {
        isNumberPickerFeatureEnabled,
        contentNumberPicker,
        showMissingPhoneNumberError,
        setShowMissingPhoneNumberError,
    } = props
    const { phoneNumber, phoneNumberVanityPhrase, reservingPhoneNumber, reservingPhoneNumberError } = state
    const { setIsChoosingPhoneNumber, reservePhoneNumber } = actions

    const [additionalPhoneNumbers, setAdditionalPhoneNumbers] = useState(NUMBERSCAP.MIN)
    const [initStateLoaded, setInitStateLoaded] = useState(false)
    const [isPlanModalOpen, setIsPlanModalOpen] = useState(false)
    const [isEditPhoneModalOpen, setEditPhoneModalOpen] = useState(false)
    const [showCards, setShowCards] = useState(false)
    const [alreadyTrackedChangePlan, setAlreadyTrackedChangePlan] = useState(false)
    const [isNumberPickerModalOpen, setIsNumberPickerModalOpen] = useState(false)
    const {
        phoneNumberList,
        phoneNumberListLoading,
        phoneNumberListError,
        setPhoneNumberListError,
        handlePhoneNumberSearch,
    } = usePhoneNumberSearch({ content: { internalErrorMessage: state.content.internalErrorMessage } })

    const planAdditonalPhoneNumbersAddon = useMemo(
        () => state.selectedPlan.addons.find((addon: Addon) => addon.sKU === ADDONS.ADDITIONAL_NUMBERS),
        [state.selectedPlan]
    )

    const addedAdditonalPhoneNumbersAddon = useMemo(
        () => state.selectedAddons.find((addon: SelectedAddon) => addon.sKU === ADDONS.ADDITIONAL_NUMBERS),
        [state.selectedAddons]
    )

    const {
        monthly,
        annual,
        user,
        year,
        month,
        quantityLabel,
        quantityLabelPlural,
        billedMonthlyPrice,
        billedAnnualPrice,
    } = state.content

    const newLicenseCopy = ` ${state.organizers > 1 ? quantityLabelPlural : quantityLabel}`

    const cardLabels: CardLabels = {
        monthly,
        annual,
        user,
        year,
        month,
        newLicenseCopy,
        billedMonthlyPrice,
        billedAnnualPrice,
    }

    useEffect(() => {
        if (state.isUserLoggedIn && state.selectedAddons.length > 0 && state.userType === USER_TYPES.ADDON) {
            actions.setSelectedAddons([])
        }
    }, [actions, state.isUserLoggedIn, state.selectedAddons, state.userType])

    useEffect(() => {
        const selectAddon = (addon: Addon) => {
            const localAddons = [{ sKU: addon.sKU, quantity: additionalPhoneNumbers }]
            actions.setSelectedAddons(localAddons)
        }

        if (additionalPhoneNumbers > 0) {
            if (
                planAdditonalPhoneNumbersAddon &&
                addedAdditonalPhoneNumbersAddon?.quantity !== additionalPhoneNumbers
            ) {
                selectAddon(planAdditonalPhoneNumbersAddon)
            }
        } else if (additionalPhoneNumbers === 0) {
            actions.setSelectedAddons([])
        }
    }, [actions, additionalPhoneNumbers, planAdditonalPhoneNumbersAddon, addedAdditonalPhoneNumbersAddon])

    useEffect(() => {
        if (state.selectedAddons.length > 0 && !initStateLoaded) {
            setAdditionalPhoneNumbers(addedAdditonalPhoneNumbersAddon?.quantity || NUMBERSCAP.MIN)
            setInitStateLoaded(true)
        }
    }, [addedAdditonalPhoneNumbersAddon?.quantity, initStateLoaded, state.selectedAddons.length])

    const recalculatePrice = useCallback(
        (initialData: BuyFormData, organizers: number, additionalNumbers: NUMBERSCAP, planSKU?: string) => {
            if (isAddressComplete(initialData) && organizers <= LICENSECAP.MAX && additionalNumbers <= NUMBERSCAP.MAX) {
                actions.calculatePrice(initialData, planSKU)
            }
        },
        [actions]
    )

    useEffect(() => {
        recalculatePrice(state.initialData, state.organizers, additionalPhoneNumbers)
    }, [state.initialData, state.organizers, additionalPhoneNumbers, recalculatePrice, state.billingFrequency])

    const isAddonFlow = state.isUserLoggedIn && state.userType === USER_TYPES.ADDON

    const fullPlanName = state.selectedPlan.name

    // tracking
    const { trackCustomizeViewClick } = useCustomizePageTracking({
        planName: state.selectedPlan.name,
    })

    const orderedPlans = [...state.planData]
    orderedPlans.reverse()

    const handleChangePlanClick = () => {
        // Only track first click of "Change Plan", according to WAE1-174
        if (!alreadyTrackedChangePlan) {
            trackCustomizeViewClick()
            setAlreadyTrackedChangePlan(true)
        }
        setShowCards(!showCards)
    }

    useEffect(() => {
        // Once the user saves the phone number successfully, close the number picker modal drawer
        setIsNumberPickerModalOpen(false)
    }, [phoneNumber])

    const resetFatalPhoneNumberError = () => {
        actions.setFatalError(false)
        setShowMissingPhoneNumberError(false)
    }

    const resetErrorsInNumberPickerModal = () => {
        actions.setReservingPhoneNumberError('')
        setPhoneNumberListError('')
    }

    const handleChoosePhoneNumber = () => {
        resetFatalPhoneNumberError()
        setIsNumberPickerModalOpen(true)
    }

    const handleOptionChange = (isChoosingPhoneNumber: boolean) => {
        resetFatalPhoneNumberError()
        setIsChoosingPhoneNumber(isChoosingPhoneNumber)
    }

    return (
        <div className="customize">
            <BoxWrapper data-qat="customize-plan-header">
                <div className={styles['flex-header']}>
                    <div>
                        <div className={styles.label}>
                            <img src={logo} alt="logo" className={styles.logo} />
                            <h3>{fullPlanName}</h3>
                        </div>
                        <span>{state.selectedPlan.description}</span>
                    </div>
                    {isAddonFlow ? (
                        <button onClick={() => setIsPlanModalOpen(true)}>
                            <img src={Pencil} alt="pencil" />
                            {!props.isMobileView ? state.content.changePlan : state.content.edit}
                        </button>
                    ) : (
                        <button onClick={handleChangePlanClick}>
                            <img src={Pencil} alt="pencil" />
                            {!props.isMobileView ? state.content.changePlan : state.content.edit}
                        </button>
                    )}
                </div>
                {showCards && (
                    <CardSelector>
                        {orderedPlans.map((plan) => (
                            <Card
                                key={plan.sKU}
                                cardLabels={cardLabels}
                                organizers={state.organizers}
                                plan={plan}
                                selected={state.selectedPlan.sKU === plan.sKU}
                                selectedTierIndex={state.selectedTierIndex}
                                currencyCode={state.CurrencyCode}
                                coupon={state.coupon}
                                onClick={() => {
                                    actions.setSelectedPlan(plan.sKU)
                                    recalculatePrice(
                                        state.initialData,
                                        state.organizers,
                                        additionalPhoneNumbers,
                                        plan.sKU
                                    )
                                }}
                                logo={icon}
                                locale={state.locale}
                                hideCheckCircle
                                title={plan.name}
                            />
                        ))}
                    </CardSelector>
                )}
            </BoxWrapper>
            <BoxWrapper data-qat="plan-license-selection">
                <h3>{state.content.quantityLabelLong}</h3>
                <div>{state.content.quantityPopupInfo}</div>
                {isAddonFlow && (
                    <p>
                        <strong>{state.content.additionalUserLicences}:</strong> {state.content.howManyMore}
                    </p>
                )}
                <div className={styles.license}>
                    <LicenseSelector
                        theme="dark"
                        organizers={state.organizers}
                        setOrganizers={actions.setOrganizers}
                        min={LICENSECAP.MIN}
                        max={LICENSECAP.MAX}
                        error={state.selectedPlan.quantityErrorMessage}
                        qaTag="license-quantity"
                    />
                    <span className={styles.label}>
                        {state.isUserLoggedIn && state.existingAccountInformation
                            ? `${state.existingAccountInformation.quantity} ${
                                  state.existingAccountInformation.quantity > 1
                                      ? state.content.existingUserLicencePlural
                                      : state.content.existingUserLicense
                              } + ${state.organizers} ${state.content.additional}`
                            : `${
                                  state.organizers > 1 ? state.content.quantityLabelPlural : state.content.quantityLabel
                              }`}
                    </span>
                </div>
            </BoxWrapper>
            {isNumberPickerFeatureEnabled && (
                <BoxWrapper>
                    <h3>{state.content.mainNumber}</h3>
                    <div>{state.content.mainNumberSubText}</div>
                    <div className={styles.numberPickerOptions}>
                        <NumberPickerOptions
                            isChoosingPhoneNumber={state.isChoosingPhoneNumber}
                            content={contentNumberPicker}
                            isMobileView={!!props.isMobileView}
                            handleOptionChange={handleOptionChange}
                            handleEdit={handleChoosePhoneNumber}
                            number={phoneNumber}
                            showMissingPhoneNumberError={showMissingPhoneNumberError}
                            vanityPhrase={phoneNumberVanityPhrase}
                        />
                    </div>
                </BoxWrapper>
            )}
            {state.selectedPlan.addons && state.userType !== USER_TYPES.ADDON && (
                <>
                    {state.selectedPlan.addons.map((planAddon: Addon) => {
                        return (
                            <BoxWrapper key={planAddon.name} data-qat={`addon-${planAddon.sKU}-selection`}>
                                <div className={styles['flex-header']}>
                                    <h3>{isNumberPickerFeatureEnabled ? planAddon.name : planAddon.title}</h3>
                                    {isAddonFlow && (
                                        <button onClick={() => setEditPhoneModalOpen(true)}>
                                            <img src={Pencil} alt="pencil" />
                                            {state.content.edit}
                                        </button>
                                    )}
                                </div>
                                {!isNumberPickerFeatureEnabled && (
                                    <div>
                                        <span>
                                            <ConvertStringToReact
                                                htmlString={
                                                    state.userType === USER_TYPES.TRIALER
                                                        ? planAddon.existingUserDescription
                                                        : planAddon.description
                                                }
                                            />
                                        </span>
                                    </div>
                                )}
                                {isNumberPickerFeatureEnabled ? (
                                    <div>
                                        <span className={styles.flexNoMargin}>
                                            {planAddon.additionalNumbersSubText}
                                            <Tooltip
                                                content={planAddon.additionalNumbersTooltip}
                                                direction={DIRECTIONS.RIGHT}
                                            />
                                        </span>
                                    </div>
                                ) : (
                                    <div>
                                        <span className={styles.flex}>
                                            {planAddon.additionalDescription}
                                            <Tooltip
                                                content={planAddon.quantityPopupInfo}
                                                direction={DIRECTIONS.RIGHT}
                                            />
                                        </span>
                                    </div>
                                )}
                                <div className={styles.license}>
                                    <LicenseSelector
                                        organizers={additionalPhoneNumbers}
                                        setOrganizers={(organizerNumber: number) => {
                                            setAdditionalPhoneNumbers(organizerNumber)
                                        }}
                                        theme="dark"
                                        min={NUMBERSCAP.MIN}
                                        max={NUMBERSCAP.MAX}
                                        error={planAddon.quantityErrorMessage}
                                        qaTag="additional-phone-number"
                                    />
                                    <span className={styles.label}>
                                        {additionalPhoneNumbers !== NUMBERSCAP.MIN + 1
                                            ? planAddon.quantityLabelShortPlural
                                            : planAddon.quantityLabelShort}
                                    </span>
                                </div>
                            </BoxWrapper>
                        )
                    })}
                </>
            )}
            <ConnectedModal isOpen={isPlanModalOpen} closeable onClose={() => setIsPlanModalOpen(false)}>
                <div data-qat="change-plan-restriction-modal">
                    <h3>{state.content.changePlanMethodModalHeader}</h3>
                    <ConvertStringToReact htmlString={state.content.changePlanModalSubheader} />
                    <CommonButton purpose="primary" onClick={() => setIsPlanModalOpen(false)}>
                        {state.content.modalCloseButtonText}
                    </CommonButton>
                </div>
            </ConnectedModal>
            <ConnectedModal isOpen={isEditPhoneModalOpen} closeable onClose={() => setEditPhoneModalOpen(false)}>
                <div>
                    <h3>{state.content.changeNumberModalHeader}</h3>
                    <p>
                        <ConvertStringToReact htmlString={state.content.changeNumberModalContent} />
                    </p>
                    <CommonButton purpose="primary" onClick={() => setEditPhoneModalOpen(false)}>
                        {state.content.modalCloseButtonText}
                    </CommonButton>
                </div>
            </ConnectedModal>
            {props.isTabletOrMobileView && (
                <ConnectedOrderSummary contentNumberPicker={contentNumberPicker} isTabletOrMobileView isMobileView />
            )}
            <CommonButton
                purpose="primary"
                type="button"
                modifier={styles.proceed}
                onClick={() => props.nextSteps()}
                disabled={state.fatalErrorBlocker}
                data-qat="continue-to-checkout"
            >
                {state.content.continue}
            </CommonButton>
            {props.isTabletOrMobileView && <TrustArcLogo />}
            {state.isCouponValidAndApplied && state.couponServerData?.validTo && (
                <div className={styles.disclaimer} data-qat="disclaimer-message">
                    <ConvertStringToReact
                        htmlString={state.content.couponDisclaimer.replace(
                            '{couponExpDate}',
                            formatDate(new Date(state.couponServerData?.validTo), 'UTC', state.locale)
                        )}
                    />
                </div>
            )}
            {isNumberPickerModalOpen && (
                <NumberPickerModal
                    content={contentNumberPicker}
                    errorMessage={phoneNumberListError || reservingPhoneNumberError}
                    handleDismiss={() => setIsNumberPickerModalOpen(false)}
                    handleSearchNumbers={handlePhoneNumberSearch}
                    handleChangeNumber={reservePhoneNumber}
                    handleResetError={resetErrorsInNumberPickerModal}
                    isFirstTimeChoosing={!phoneNumber}
                    defaultSelectedNumber={undefined} // Don't pass in a default here, this fixes WAE1-1233
                    numberList={phoneNumberList}
                    loading={phoneNumberListLoading}
                    reservingPhoneNumber={reservingPhoneNumber}
                />
            )}
        </div>
    )
}

export const ConnectedCustomize = connectRedux(Customize, mapCustomizeState, mapCustomizeActions)
