import { TRACKING_EVENTS, GTC_VIEW_PATHS } from '@common/constants'
import { usePageLoad } from '@common/hooks'
import { TRACKING_COMPONENTS } from '@gtc/constants'
import { BuyFormData } from '@gtc/state/buy-form/state'
import { FormikProps } from 'formik'
import { useState, useEffect } from 'react'
import { useGTCTracking } from './useGTCTracking'

export interface CheckoutFormPageTrackingParams {
    planName: string
    formikRef: React.RefObject<FormikProps<BuyFormData>>
}

export const useCheckoutFormPageTracking = (params: CheckoutFormPageTrackingParams) => {
    const [hasTrackedStartForm, setHasTrackedStartForm] = useState(false)
    const componentName = TRACKING_COMPONENTS.CHECKOUT_PAGE
    const { track } = useGTCTracking()
    const trackFormError = (formIds: string[], formErrors: string[]) => {
        track({ event: TRACKING_EVENTS.ERROR_FORM, eventData: { formIds, formErrors } }, componentName)
    }
    const trackBillingInfoStep = () => {
        track({ event: TRACKING_EVENTS.BEGIN_CHECKOUT, eventData: { step: 3 } }, componentName)
    }
    const trackPaymentStep = () => {
        track({ event: TRACKING_EVENTS.BEGIN_CHECKOUT, eventData: { step: 4 } }, componentName)
    }
    const trackSubmitForm = () => {
        track({ event: TRACKING_EVENTS.SUBMIT_FORM }, componentName)
    }
    usePageLoad(() => {
        track({ event: TRACKING_EVENTS.VIEW_CONTENT }, componentName)
        track({ event: TRACKING_EVENTS.LOAD_FORM }, componentName)
        track({ event: TRACKING_EVENTS.BEGIN_CHECKOUT, eventData: { step: 2 } }, componentName)
    }, !!params.planName)

    useEffect(() => {
        if (params.formikRef.current) {
            const fieldTouched = Object.keys(params.formikRef.current.touched).length > 0
            if (fieldTouched && !hasTrackedStartForm) {
                setHasTrackedStartForm(true)
                track({ event: TRACKING_EVENTS.START_FORM }, componentName)
            }
            return () => {
                if (
                    fieldTouched &&
                    hasTrackedStartForm &&
                    window.location.pathname !== GTC_VIEW_PATHS.CONNECT_CHECKOUT
                ) {
                    track({ event: TRACKING_EVENTS.ABANDON_FORM }, componentName)
                }
            }
        }
        return undefined
    }, [componentName, hasTrackedStartForm, params.formikRef, track])
    return { trackFormError, trackBillingInfoStep, trackPaymentStep, trackSubmitForm }
}
