import { select, put, takeLeading, call } from 'redux-saga/effects'
import queryString from 'query-string'
import { loadSessionState, loadState } from '@gtc/utils'
import { CheckoutActionTypes, checkoutActions } from '@gtc/state/checkout/actions'
import { getCheckoutState, getPlanData } from '@gtc/state/selectors'
import { getSelectedPlan } from '@gtc/saga/content/content-helpers'
import { Plan } from '@gtc/state/plan'
import { TimePeriod } from '@common/constants'
import { CheckoutState, SavedSessionState, SavedStoreState } from '@gtc/state/checkout/state'
import { getCurrentAddons } from '@gtc/utils/get-current-addons'
import { SelectedAddon } from '@gtc/state/add-on'
import { couponActions } from '@gtc/state/coupon/actions'

function* loadSavedStateSaga(action: ReturnType<typeof checkoutActions.getSavedState>) {
    try {
        const savedState: SavedStoreState | undefined = yield call(loadState)
        const savedSessionState: SavedSessionState | undefined = yield call(loadSessionState)
        const checkout: CheckoutState = yield select(getCheckoutState)
        const planData: Plan[] = yield select(getPlanData)
        const selectedPlan: Plan = yield call(getSelectedPlan)
        const { urlPlan } = action.payload
        const {
            billingFrequency: queryBillingFrequency,
            minUsers,
            couponCode: queryCouponCode,
        } = queryString.parse(window.location.search)
        // grab url param for billing frequecy or saved one
        const preselectedBillingFrequency = queryBillingFrequency || savedState?.checkout?.billingFrequency
        // if min users was passed via url or saved in the store
        const preselectedMinUsers = Number(minUsers) || savedState?.checkout?.organizers

        // grab url param for couponCode first and if not available go for saved one
        let preSavedCouponCode = (queryCouponCode as string) || savedSessionState?.coupon?.urlCouponCode
        // try to apply coupon code and also save the coupon code in state
        preSavedCouponCode = preSavedCouponCode || ''
        yield put(couponActions.setUrlCouponCode(preSavedCouponCode))

        // if the value of the preselected frequency is an acceptable value and is not the same
        // as what is already saved, update it
        if (
            (preselectedBillingFrequency === TimePeriod.Month || preselectedBillingFrequency === TimePeriod.Year) &&
            preselectedBillingFrequency !== checkout.billingFrequency
        ) {
            yield put(checkoutActions.setBillingFrequency(preselectedBillingFrequency))
        }

        if (preselectedMinUsers) {
            yield put(checkoutActions.setOrganizers(preselectedMinUsers))
        }
        // grab url param for plan or saved in store
        if (urlPlan) {
            const preselectedPlan = urlPlan
            // check if plan name is different
            if (urlPlan && urlPlan !== selectedPlan.name) {
                // make sure plan exists in the plans data api call
                const foundPlan = planData.find(
                    (pln: Plan) => pln.name.toLocaleLowerCase() === preselectedPlan.toLowerCase()
                )
                if (foundPlan) {
                    yield put(checkoutActions.setSelectedPlan(foundPlan.sKU))
                }
            }
        } else if (savedState?.checkout?.selectedPlanSku) {
            // make sure plan exists in the plans data api call
            const foundPlan = planData.find((pln: Plan) => pln.sKU === savedState?.checkout?.selectedPlanSku)
            if (foundPlan) {
                yield put(checkoutActions.setSelectedPlan(foundPlan.sKU))
            }
        } else if (!savedState?.checkout?.selectedPlanSku) {
            yield put(checkoutActions.setSelectedPlan(planData[0].sKU))
        }

        if (savedState) {
            const { selectedAddons } = savedState.checkout

            const plan: Plan = yield call(getSelectedPlan)
            // if a plan was selected
            if (plan.name) {
                // find matching addon in the plan from preselected addon
                const foundAddons: SelectedAddon[] = yield call(getCurrentAddons, selectedAddons, plan)

                yield put(checkoutActions.setSelectedAddons(foundAddons))
            }
        }
        yield put(checkoutActions.setSavedStateLoaded(true, 'saved state saga'))
    } catch (e) {
        // fail silently
    }
}

function* initializeLoadSavedStateSaga() {
    yield takeLeading(CheckoutActionTypes.GET_SAVED_STATE, loadSavedStateSaga)
}

export default initializeLoadSavedStateSaga
