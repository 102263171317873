import { isConnectApp } from '@common/utils'
import { saveSessionState, saveState } from '@gtc/utils'
import { AppState } from 'app-store'
import { MiddlewareAPI } from 'redux'

export const gtcThrottle = (store: MiddlewareAPI) => {
    if (isConnectApp()) {
        const appState: AppState = store.getState()
        const gtcStore = appState.connect
        const {
            organizers,
            billingFrequency,
            selectedPlanSku,
            selectedAddons,
            savedStateLoaded,
            purchaseComplete,
            addonComplete,
        } = gtcStore.checkout
        const { urlCouponCode } = gtcStore.coupon
        if (savedStateLoaded && !purchaseComplete && !addonComplete) {
            saveState({
                checkout: {
                    organizers,
                    billingFrequency,
                    selectedPlanSku,
                    selectedAddons,
                },
            })
            if (urlCouponCode) {
                saveSessionState({
                    coupon: {
                        urlCouponCode,
                    },
                })
            }
        }
    }
}
