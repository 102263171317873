import { call, takeEvery, put, select } from 'redux-saga/effects'
import { userActions, UserActionTypes } from '@gtc/state/user/actions'
import commonServices, {
    verifyUser,
    getBillingSubscriptions,
    getBillingInfo,
    getExistingPaymentDetails,
} from '@common/services'
import { getLabelContent, getUserState } from '@gtc/state/selectors'
import { authClass, processExistingBillingInfo } from '@common/utils'
import { trackAbandonForm } from '@gtc/utils'
import { UserState } from '@gtc/state/user/state'
import {
    ExistingUserInfoType,
    ExistingAccountInfoType,
    ExistingPaymentInfoType,
    ExistingBillingInfoType,
} from '@common/state/user/state'
import { checkoutActions } from '@gtc/state/checkout/actions'
import { buyFormActions } from '@gtc/state/buy-form/actions'
import { BuyFormData } from '@gtc/state/buy-form/state'
import { USER_TYPES, USER_TYPES_RESPONSE } from '@gtc/constants'
import { fatalErrorActions } from '@common/state/fatal-error/actions'
import {
    PREFIX,
    TimePeriod,
    BILLING_TYPES,
    PRODUCT_TYPES,
    PRODUCT_SKU,
    COUNTRIES,
    PRODUCT_FAMILY_NAME,
} from '@common/constants'
import { LabelContent } from '@gtc/state/label-content'
import isEmpty from 'lodash/isEmpty'
import partition from 'lodash/partition'
import { BillingDetailedSubscriptionPlan } from '@common/state/billing/state'
import { accountActions } from '@common/state/account/actions'
import { GenericResponse } from '@common/state/generic-response/generic-response'

function* userLoggedInSaga() {
    try {
        yield put(userActions.setLoadingExistingInformation(true))
        const {
            loggedIn: { accessToken },
        }: UserState = yield select(getUserState)
        const { paymentMethodErrorMessage, disabledUserError }: LabelContent = yield select(getLabelContent)

        // clear previous session on the server
        yield call(commonServices.clearUserSession)
        // get user info
        // eslint-disable-next-line camelcase
        const response: GenericResponse = yield call(
            verifyUser,
            accessToken?.access_token || '',
            PRODUCT_FAMILY_NAME.G2C
        )
        if (response && !isEmpty(response.data)) {
            const {
                User: { UserName, Name },
                isBanned,
                hasGroupSubscription,
            } = response.data

            // review this url once this starts functioning again
            if (hasGroupSubscription) {
                trackAbandonForm()
                window.location.href = process.env.ADMIN_CONNECT_URL as string
            }

            const userInfo: ExistingUserInfoType = {
                UserName,
                Name,
            }

            const buyFormData: BuyFormData = {
                FirstName: Name?.GivenName,
                LastName: Name?.FamilyName,
                Email: UserName,
                Street1: '',
                City: '',
                ZipCode: '',
                StateCode: '',
                CountryCode: COUNTRIES.US,
                ContactPhone: '',
                Password: '',
                BillingFirstName: '',
                BillingLastName: '',
                ExpirationDate: '',
            }

            // return error message if user is banned
            if (isBanned === true) {
                yield put(userActions.setUserType(USER_TYPES.BANNED))
                yield put(userActions.setLoadingExistingInformation(false))
                yield put(
                    fatalErrorActions.setFatalError(
                        true,
                        { fatalErrorMessage: disabledUserError, trackErrorMessage: disabledUserError },
                        true
                    )
                )
                return
            }

            // USER_IDENTITY_NOT_FOUND implies that user has neither boss nor identity account
            if (response.data.Code === USER_TYPES_RESPONSE.USER_IDENTITY_NOT_FOUND) {
                yield put(userActions.setUserType(USER_TYPES.NEW))
                yield put(userActions.userLogout())
                return
            }

            // set up and display existing user account information
            yield put(userActions.setExistingUserInformation(userInfo))

            // USER_BOSS_NOT_FOUND implies that user has an identity account
            if (response.data.Code === USER_TYPES_RESPONSE.USER_BOSS_NOT_FOUND) {
                yield put(userActions.setUserType(USER_TYPES.IDENTITY_ONLY))
                yield put(buyFormActions.updateInitialFormState(buyFormData))
                yield put(userActions.setLoadingExistingInformation(false))
                return
            }

            // #region attempt to set up existing billing info
            const billingInfoRequest: GenericResponse = yield call(getBillingInfo)
            if (billingInfoRequest && !isEmpty(billingInfoRequest.data)) {
                // We get company name same as the email without the `@` in case we don't provide in the first place.
                // This is a known behavior for GTC. So, we check for GTR, we check if that's what we got and don't show the value
                const { email, companyName } = billingInfoRequest.data
                if (email.replace('@', '').replace('_', '') === companyName) {
                    billingInfoRequest.data.companyName = ''
                }

                const processedBillingData = processExistingBillingInfo(billingInfoRequest.data)
                const billingInfo: ExistingBillingInfoType = processedBillingData

                if (billingInfo.state === 'XX') {
                    billingInfo.state = ''
                }

                billingInfo.firstName = Name.GivenName
                billingInfo.lastName = Name.FamilyName
                buyFormData.Street1 = billingInfo.addressLine1
                buyFormData.City = billingInfo.city
                buyFormData.CountryCode = billingInfo.country
                buyFormData.ZipCode = billingInfo.postalCode
                buyFormData.StateCode = billingInfo.state
                buyFormData.ContactPhone = billingInfo.phoneNumber

                // all five fields must contain values in order to set existing billing information
                if (buyFormData.Street1 && buyFormData.City && buyFormData.CountryCode && buyFormData.ZipCode) {
                    yield put(userActions.setExistingBillingInformation(billingInfo))
                }
            }
            yield put(buyFormActions.updateInitialFormState(buyFormData))
            // #endregion

            // #region attempt to set up existing payment info
            const paymentInfoRequest: GenericResponse = yield call(getExistingPaymentDetails)
            if (paymentInfoRequest && !isEmpty(paymentInfoRequest.data)) {
                const currentDate = new Date()
                const currentYear = currentDate.getFullYear()
                const currentMonth = currentDate.getMonth()
                const ccInfo = paymentInfoRequest.data[0].creditCard
                if (
                    ccInfo.expirationYear < currentYear ||
                    (ccInfo.expirationYear === currentYear && ccInfo.expirationMonth < currentMonth)
                ) {
                    yield put(
                        fatalErrorActions.setFatalError(
                            true,
                            {
                                fatalErrorMessage: paymentMethodErrorMessage,
                                trackErrorMessage: paymentMethodErrorMessage,
                            },
                            true
                        )
                    )
                }
                const paymentInfo: ExistingPaymentInfoType = {
                    last4Digits: ccInfo.last4Digits,
                }
                yield put(userActions.setExistingPaymentDetails(paymentInfo))
            }
            // #endregion

            // if subscription is empty, mark as expired-user
            const subscriptionRequest: GenericResponse = yield call(getBillingSubscriptions)
            if (isEmpty(subscriptionRequest?.data)) {
                yield put(userActions.setUserType(USER_TYPES.EXPIRED))
                yield put(userActions.setLoadingExistingInformation(false))
                return
            }

            // set account subscription state
            yield put(accountActions.setAccountSubscriptions(subscriptionRequest.data))

            // partition subscriptions into two arrays - one for GTC and another for GTR
            const [connectSubscriptions] = partition(
                subscriptionRequest.data,
                (plan: BillingDetailedSubscriptionPlan) => {
                    return plan.productSku.startsWith(PREFIX.G2C)
                }
            )

            const connectTrialSubscription = connectSubscriptions.find(
                (plan: BillingDetailedSubscriptionPlan) => plan.planType === PRODUCT_TYPES.TRIAL
            )

            // #region determine buy flow user state
            const selectedPlan = connectSubscriptions?.find(
                (plan: BillingDetailedSubscriptionPlan) =>
                    plan.productSku === PRODUCT_SKU.G2C || plan.productSku === PRODUCT_SKU.G2C_JUST_TALK
            )
            if (selectedPlan) {
                const {
                    productName,
                    paymentPlan,
                    startDate,
                    endDate,
                    currency,
                    renewalPrice,
                    autorenew,
                    productSku,
                    subscriptionId,
                    status,
                    planType,
                } = selectedPlan

                const overallQuantity = connectSubscriptions.reduce(
                    (accumulator: number, subItem: ExistingAccountInfoType) => {
                        if (subItem.productSku === productSku) {
                            return accumulator + subItem.quantity
                        }
                        return accumulator
                    },
                    0
                )

                const accountInfo: ExistingAccountInfoType = {
                    productName,
                    paymentPlan,
                    startDate,
                    endDate,
                    currency,
                    renewalPrice,
                    autorenew,
                    productSku,
                    subscriptionId,
                    status,
                    quantity: overallQuantity,
                    planType,
                }

                yield put(userActions.setExistingAccountInformation(accountInfo))
                yield put(checkoutActions.setSelectedPlan(productSku))
                yield put(
                    checkoutActions.setBillingFrequency(
                        paymentPlan === BILLING_TYPES.ANNUAL ? TimePeriod.Year : TimePeriod.Month
                    )
                )
                // if user already has the selected plan, mark as add-on flow
                yield put(userActions.setUserType(USER_TYPES.ADDON))
                yield put(checkoutActions.setSelectedAddons([]))
            } else if (connectTrialSubscription) {
                // otherwise, if user has any G2Connect trial subscription, mark as trialer
                yield put(userActions.setUserType(USER_TYPES.TRIALER))
            } else {
                // otherwise, since user has at least one subscription, mark as cross product
                yield put(userActions.setUserType(USER_TYPES.CROSSPRODUCT))
            }
            // #endregion

            yield put(userActions.setLoadingExistingInformation(false))
        }
    } catch (e) {
        yield put(userActions.setUserType(USER_TYPES.NEW))
        yield put(userActions.setLoadingExistingInformation(false))
        yield call(authClass().logout)
    }
}

function* initializeLoggedInSaga() {
    yield takeEvery(UserActionTypes.GET_LOGGED_IN_USER_STATE, userLoggedInSaga)
}

export default initializeLoggedInSaga
