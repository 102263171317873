// The city names retrieved from the numbers api come back upper case
// See format-city.test.ts for examples of what this changes the formatting to
export const formatCity = (city: string | undefined | null) => {
    if (!city) {
        return ''
    }

    const words = city.split(' ')

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < words.length; i++) {
        if (words[i].trim() !== '') {
            words[i] = words[i][0].toUpperCase() + (words[i].length > 1 ? words[i].substr(1).toLowerCase() : '')
        }
    }

    const formattedCity = words.join(' ')

    return formattedCity
}
