export const trapFocus = (parentElem: string, setDefaultFocus?: boolean) => {
    const modal = document.querySelector<HTMLElement>(parentElem) // select the parent element by it's id

    if (!modal) return

    if (setDefaultFocus && modal) {
        modal.focus()
    }

    // add all the elements inside modal which you want to make focusable
    const focusableElements = 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'

    const firstFocusableElement = modal.querySelectorAll<HTMLElement>(focusableElements)[0] // get first element to be focused inside modal
    const focusableContent = modal.querySelectorAll<HTMLElement>(focusableElements)
    const lastFocusableElement = focusableContent[focusableContent.length - 1] // get last element to be focused inside modal

    document.addEventListener('keydown', (e) => {
        const isTabPressed = e.key === 'Tab' || e.keyCode === 9

        if (!isTabPressed) {
            return
        }

        if (e.shiftKey) {
            // if shift key pressed for shift + tab combination
            if (document.activeElement === firstFocusableElement) {
                lastFocusableElement.focus() // add focus for the last focusable element
                e.preventDefault()
            }
        } else if (document.activeElement === lastFocusableElement) {
            // if tab key is pressed
            // if focused has reached to last focusable element then focus first focusable element after pressing tab
            firstFocusableElement.focus() // add focus for the first focusable element
            e.preventDefault()
        }
    })
}
