import { ActionUnion, createAction } from '@common/utils'
import { CHECKOUTFORM_STEPS } from '@lmipro/constants'
import { LmiProBuyFormData } from './state'

export enum LmiProBuyFormActionTypes {
    SUBMIT_BUY_FORM = '[lmipro-buy-form-actions] submit-exp',
    RESET_BUY_FORM_AFFECTED_STATES = '[lmipro-buy-form-actions] reset-affected-states',
    RESET_BUY_FORM = '[lmipro-buy-form-actions] reset-checkout-form',
    EXISTING_USER_SUBMIT_BUY_FORM = '[lmipro-buy-form-actions] existing-user-submit-exp',
    UPDATE_INITIAL_FORM_STATE = '[lmipro-buy-form-actions] update-initial-form-state',
    SET_CLEAR_EMAIL = '[lmipro-buy-form-actions] set-clear-email',
    UPDATE_STEPS = '[lmipro-buy-form-actions] update-steps',
    SET_STATES_OPTIONAL = '[lmipro-buy-form-actions] set-states-optional',
}

export const lmiproBuyFormActions = {
    submitForm: (values: LmiProBuyFormData) => createAction(LmiProBuyFormActionTypes.SUBMIT_BUY_FORM, { values }),
    existingUserSubmitForm: (values: LmiProBuyFormData) =>
        createAction(LmiProBuyFormActionTypes.EXISTING_USER_SUBMIT_BUY_FORM, { values }),
    resetCheckoutFormAffectedStates: () => createAction(LmiProBuyFormActionTypes.RESET_BUY_FORM_AFFECTED_STATES),
    updateInitialFormState: (data: LmiProBuyFormData) =>
        createAction(LmiProBuyFormActionTypes.UPDATE_INITIAL_FORM_STATE, {
            data,
        }),
    resetForm: () => createAction(LmiProBuyFormActionTypes.RESET_BUY_FORM),
    setClearEmail: (clear: boolean) => createAction(LmiProBuyFormActionTypes.SET_CLEAR_EMAIL, { clear }),
    updateSteps: (stepName: CHECKOUTFORM_STEPS, stepResult: boolean) =>
        createAction(LmiProBuyFormActionTypes.UPDATE_STEPS, {
            stepName,
            stepResult,
        }),
    setStatesOptional: (optional: boolean) => createAction(LmiProBuyFormActionTypes.SET_STATES_OPTIONAL, { optional }),
}

export type BuyFormActions = ActionUnion<typeof lmiproBuyFormActions>
