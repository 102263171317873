import { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { APP_PATHS, STORAGE_CONST } from '@common/constants'

export const useRedirectOnLoad = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const [didRedirect, setDidRedirect] = useState<boolean | undefined>(undefined)

    useEffect(() => {
        try {
            const { pathname, search, hash } = location
            // check if there is a saved route
            const searchParams = new URLSearchParams(search)
            const savedRoute = localStorage.getItem(STORAGE_CONST.SAVED_ROUTE) || pathname
            const savedQuery = localStorage.getItem(STORAGE_CONST.SAVED_QUERY)

            if (savedQuery) {
                new URLSearchParams(savedQuery).forEach((val, key) => {
                    searchParams.set(key, val)
                })
            }

            const combinedQuery = searchParams.toString() ? `?${searchParams.toString()}` : ''
            // check to make sure the route is valid
            const isRouteDefined = Object.values(APP_PATHS).find((path) => savedRoute.includes(path))
            // Once we get the saved Route and Query values, delete the stored Route and Query so that after user don't have to get redirected to the stored page even if it's valid path
            localStorage.removeItem(STORAGE_CONST.SAVED_ROUTE)
            localStorage.removeItem(STORAGE_CONST.SAVED_QUERY)
            // if the route is valid and is different than the current one or the current search value
            if (isRouteDefined && (savedRoute !== pathname || combinedQuery !== search)) {
                // redirect to that route
                setDidRedirect(true)
                navigate({ pathname: savedRoute, search: combinedQuery, hash })
            } else {
                setDidRedirect(false)
            }
        } catch (e: any) {
            console.error(e.message)
        }
    }, [navigate, location])

    return {
        didRedirect,
    }
}
