import React from 'react'
import { CommonButton } from '@common/components/CommonButton'
import { NumberPickerContent } from '@gtc/components/NumberPicker/NumberPicker.types'
import styles from './FooterButtons.module.css'

interface IProps {
    clickedSearch: boolean
    content: NumberPickerContent
    isResetVisible: boolean
    isSearchDisabled: boolean
    isSaveDisabled: boolean
    handleSave: () => void
    handleReset: () => void
    handleDismiss: () => void
    handleSearch: () => void
}

export const FooterButtons = ({
    clickedSearch,
    content,
    isResetVisible,
    isSearchDisabled,
    isSaveDisabled,
    handleSave,
    handleReset,
    handleDismiss,
    handleSearch,
}: IProps) => (
    <>
        {isResetVisible && (
            <CommonButton
                qaTag="numberpicker_modal_footer_reset"
                type="button"
                onClick={handleReset}
                modifier={styles.resetButton}
            >
                {content.reset}
            </CommonButton>
        )}
        <div className={styles.flexSpacer} />
        <CommonButton
            qaTag="numberpicker_modal_footer_cancel"
            type="button"
            onClick={handleDismiss}
            modifier={styles.cancelButton}
        >
            {content.cancel}
        </CommonButton>

        {clickedSearch ? (
            <CommonButton
                qaTag="numberpicker_modal_footer_save"
                type="button"
                onClick={handleSave}
                disabled={isSaveDisabled}
            >
                {content.save}
            </CommonButton>
        ) : (
            <CommonButton
                qaTag="numberpicker_modal_footer_search"
                type="button"
                onClick={handleSearch}
                disabled={isSearchDisabled}
            >
                {content.search}
            </CommonButton>
        )}
    </>
)
