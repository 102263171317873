import React, { FunctionComponent } from 'react'

// Import Local Styles
import styles from './cardselector.module.css'

export interface CardSelectorProps {
    small?: boolean
    stacked?: boolean
    wrap?: boolean
}

export const CardSelector: FunctionComponent<CardSelectorProps> = ({ small, wrap, stacked, children }) => {
    return (
        <div
            className={`${styles.selector}
            ${wrap ? styles['selector--wrap'] : ''}
            ${small ? styles['selector--small'] : ''}
            ${stacked ? styles['selector--stacked'] : ''}`}
        >
            {children}
        </div>
    )
}
