import axios from 'axios'
import { AddressDataType } from '@common/state/location/state'
import { ENVIRONMENT_URL, SC_SITE } from '@common/constants'
import { MarketoPayload } from '@common/state/marketing/state'
import { ReCAPTCHAValidationResponseData } from '@common/state/reCAPTCHA/state'
import memoize from 'lodash/memoize'

export interface EmailType {
    emailAddress: string
}

export interface EmailCheckResponse {
    status: number
    data: {
        canUserAuthenticate: boolean
    }
}

/* eslint-disable camelcase */
export interface SiteParamType {
    sc_site: SC_SITE
}

// API Reference: https://confluence.ops.expertcity.com/x/qFunCw
export interface FlexTokenDataType {
    targetOrigin: string
    currency: string
    country: string
    skipFlexToken?: boolean
    skipSca?: boolean
}

/* eslint-disable camelcase */
export interface SiteType {
    sc_site: string
}

export function getFlexTokenCall(data: FlexTokenDataType): Promise<any> {
    return axios.post('/ecommapi/purchase/FlexKeyV3', data)
}

export function getExternalLocationInfo() {
    return axios.get(`${ENVIRONMENT_URL}/ecommapi/utility/GetLocationInfo`)
}

export function getLocationInfo(): Promise<any> {
    return axios.get('/ecommapi/utility/GetLocationInfo')
}

// get countries
export function getCountries(params?: SiteParamType): Promise<any> {
    return axios.get(
        `/ecommapi/content/countries${
            params?.sc_site
                ? ''
                : `?sc_site=${/\/connect/.test(window.location.href) ? SC_SITE.CONNECT : SC_SITE.RESOLVE}`
        }`,
        { params }
    )
}

// validate address
export function getValidAddress(address: AddressDataType): Promise<any> {
    const addressHeaders = {
        headers: {
            Tenant: 'Connect',
            'Content-Type': 'application/json; charset=UTF-8',
        },
    }
    return axios.post('/ecommapi/avs/validateaddress', address, addressHeaders)
}

export interface VerificationTokenDataType {
    email: string
    theme?: string
}

// marketo
export function postMarketo(payload: MarketoPayload, params?: SiteType) {
    return axios.post('/ecommapi/form/SendAsync', payload, {
        withCredentials: false,
        headers: {
            'Content-Type': 'application/json',
        },
        params,
    })
}

// coupon
export function getCouponDetail(couponCode: string) {
    return axios.get(`${ENVIRONMENT_URL}/ecommapi/purchase/coupondetail/${couponCode}`)
}

export function getCouponInfo(couponCode: string) {
    return axios.get(`${ENVIRONMENT_URL}/ecommapi/content/couponinfo/${couponCode}`)
}

// verify user is logged in correctly
export function verifyUser(token: string, productGroup: string) {
    const data = {
        token,
        productGroup,
    }
    return axios.post('/ecommapi/identity/ValidateUser', data)
}

export function getBillingSubscriptions() {
    return axios.get('/ecommapi/purchase/BillingDetailedSubscriptions', {
        withCredentials: true,
    })
}

export function getBillingInfo() {
    return axios.get('/ecommapi/purchase/BillingInfo', {
        withCredentials: true,
    })
}

// get existing payment info
export function getExistingPaymentDetails() {
    return axios.post('/ecommapi/purchase/GetStoredPaymentMethods', {
        withCredentials: true,
    })
}

// clear user session
export function clearUserSession(): Promise<any> {
    return axios.post('/ecommapi/identity/ClearSession/')
}

export interface EmailVerificationType {
    accessToken: string | undefined
    emailCode?: string
    emailToken?: string
}

// update user email verification status
export function updateUser(payload: EmailVerificationType) {
    return axios.post('/ecommapi/identity/updateuser', payload)
}

// check if user already has an account
export function canUserAuthenticate(data: EmailType): Promise<any> {
    return axios.post('/ecommapi/identity/canUserAuthenticate', data)
}

interface FeatureFlagsResponseData {
    flags: string[]
}
const getFeatureFlags = memoize((siteType: SC_SITE): Promise<any> => {
    return axios.get<FeatureFlagsResponseData>('/ecommapi/content/featureflags', {
        params: { sc_site: siteType },
    })
})

export function validateReCAPTCHA(token: string) {
    return axios.post<ReCAPTCHAValidationResponseData>('/ecommapi/recaptcha/index', { token })
}

export default {
    getLocationInfo,
    clearUserSession,
    getCountries,
    getFlexTokenCall,
    canUserAuthenticate,
    getValidAddress,
    getFeatureFlags,
}
