/* eslint-disable react/require-default-props */
import React from 'react'
import { PhoneNumber } from '@gtc/services/numbersApi'
import { Badge } from '@common/components/Badge'
import { formatCity } from '@common/utils/format-city'
import IconError from '@common/images/icon-error.svg'
import { NumberPickerContent } from '../NumberPicker.types'
import { FormattedPhoneNumber } from '../NumberPickerModal/components/FormattedPhoneNumber/FormattedPhoneNumber'
import styles from './NumberPickerSummary.module.css'

interface IProps {
    number?: PhoneNumber
    content: NumberPickerContent
    qaTag?: string
    vanityPhrase: string
    showMissingPhoneNumberError?: boolean
    showBadge?: boolean
}

export const NumberPickerSummary = React.memo((props: IProps) => {
    const {
        number,
        content,
        qaTag = 'numberpickersummary',
        vanityPhrase = '',
        showBadge = true,
        showMissingPhoneNumberError = false,
    } = props
    const isLocalNumber = number && !!number.city

    return (
        <div className={styles.wrapper} data-qat={qaTag}>
            {!number && !showMissingPhoneNumberError && (
                <div className={styles.noNumber} data-qat={`${qaTag}_no_number`}>
                    {content.noNumberSelected}
                </div>
            )}
            {!number && showMissingPhoneNumberError && (
                <div className={styles.noNumberError} data-qat={`${qaTag}_no_number_error`}>
                    <img src={IconError} alt="error" />
                    <span>{content.noNumberSelected}</span>
                </div>
            )}
            {number && (
                <>
                    <div className={styles.numberArea}>
                        <FormattedPhoneNumber
                            modifier={styles.selectedNumber}
                            phoneNumber={number.e164Number}
                            vanityPhrase={vanityPhrase}
                            showVanityLetters={true}
                            qaTag={`${qaTag}_selected_number`}
                        />
                        {showBadge && (
                            <Badge qaTag={`${qaTag}_number_type`}>
                                {isLocalNumber ? content.local : content.tollFree}
                            </Badge>
                        )}
                    </div>
                    {(isLocalNumber || vanityPhrase) && (
                        <div className={styles.locationArea}>
                            {vanityPhrase && (
                                <FormattedPhoneNumber
                                    phoneNumber={number.e164Number}
                                    vanityPhrase={vanityPhrase}
                                    showVanityLetters={false}
                                    qaTag={`${qaTag}_selected_number_sub`}
                                />
                            )}
                            {vanityPhrase && (number.city || number.region) && <>{' - '}</>}
                            {number.city && (
                                <>
                                    <span data-qat={`${qaTag}_number_city`}>{formatCity(number.city)}</span>,{' '}
                                </>
                            )}
                            {number.region && <span data-qat={`${qaTag}_number_region`}>{number.region}</span>}
                        </div>
                    )}
                </>
            )}
        </div>
    )
})
