import React, { Suspense, lazy, FunctionComponent } from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { PRODUCT_PATH } from '@common/constants'
import { LoadingSpinner } from '@common/components'

// products
const Connect = lazy(() => import('@gtc/App'))
const Resolve = lazy(() => import('@gtresolve/App'))
const LmiPro = lazy(() => import('@lmipro/App'))
const RootView = lazy(() => import('@common/views/RootView'))
const Buy = lazy(() => import('@buy/App'))

/**
 * This component is responsible for deciding which product the user is trying to access
 * each route represents a specific product. Products may have multiple flows within them (buy, trial, upgrade etc)
 */
export const AppRoutes: FunctionComponent = () => {
    return (
        <Suspense fallback={<LoadingSpinner />}>
            <BrowserRouter>
                <Routes>
                    {/* main product routes  */}
                    <Route path={`${PRODUCT_PATH.CONNECT}/*`} element={<Connect />} />
                    <Route path={`${PRODUCT_PATH.RESOLVE}/*`} element={<Resolve />} />
                    <Route path={`${PRODUCT_PATH.LOGMEINPRO}/*`} element={<LmiPro />} />
                    <Route path={`${PRODUCT_PATH.BUY}/*`} element={<Buy />} />

                    {/* if path not recognized, redirect to root path */}
                    <Route path="/*" element={<Navigate to="/" />} />

                    {/* root path */}
                    <Route path="/" element={<RootView />} />
                </Routes>
            </BrowserRouter>
        </Suspense>
    )
}
