import { ActionUnion, createAction } from '@common/utils'
import { CURRENCY, TimePeriod } from '@common/constants'
import { BuySelectedAddon, BuyAddon } from '@buy/state/add-on'
import { BuyFormData } from '@buy/state/buy-form/state'
import { BuyProductPriceItem } from '@buy/state/calculate-price'
import {
    BuyBillingInfoDataType,
    BuyPaymentDataType,
    BuySubmitDataType,
    BuyBillingFrequencyVolumeDiscountRecords,
    BuySubmitWithCardAddDataType,
} from './state'

export enum BuyCheckoutActionTypes {
    SET_LOADING = '[buy-checkout-actions] set-loading',
    SET_PLAN_ID = '[buy-checkout-actions] set-plan-id',
    SET_BILLING_FREQUENCY = '[buy-checkout-actions] set-billing-frequency',
    CLEAR_ADDONS = '[buy-checkout-actions] clear-addons',
    SET_SELECTED_ADDONS = '[buy-checkout-actions] set-selected-addons',
    SET_ORGANIZERS = '[buy-checkout-actions] set-organizers',
    SET_SELECTED_PLAN = '[buy-checkout-actions] set-selected-plan',
    SET_EXISTING_USER_MODAL_OPEN = '[buy-checkout-actions] set-existing-user-modal',
    CREATE_BILLING_INFO = '[buy-checkout-actions] create-billing-info',
    CREATE_PAYMENT = '[buy-checkout-actions] create-payment',
    SUBMIT_PAYMENT = '[buy-checkout-actions] submit-payment',
    CREATE_AND_SUBMIT_PAYMENT = '[buy-checkout-actions] create-and-submit-payment',
    GET_AVAILABLE_PAYMENT_METHODS = '[buy-checkout-actions] get-available-payment-methods',
    CALCULATE_PRICE = '[buy-checkout-actions] calculate-price',
    SET_CALCULATED_PRICE = '[buy-checkout-actions] set-calculated-price',
    GET_SAVED_STATE = '[buy-checkout-actions] get-saved-state',
    SET_SAVED_STATE_LOADED = '[buy-checkout-actions] set-saved-state-loaded',
    RESET_CHECKOUT_STATE = '[buy-checkout-actions] reset-state',
    SET_EXISTING_ORGANIZERS = '[buy-checkout-actions] set-existing-organizers',
    SET_PURCHASE_COMPLETE = '[buy-checkout-actions] set-purchase-complete',
    SET_LOADING_TOTAL = '[buy-checkout-actions] set-loading-total',
    SET_ADDON_COMPLETE = '[buy-checkout-actions] set-addon-complete',
    SET_SELECTED_TIER_INDEX = '[buy-checkout-actions] set-selected-tier-index',
    CALCULATE_VOLUME_DISCOUNT = '[buy-checkout-actions] calculate-volume-discount',
    SET_VOLUME_DISCOUNT = '[buy-checkout-actions] set-volume-discount',
    SET_VOLUME_DISCOUNT_LOADING = '[buy-checkout-actions] set-volume-discount-loading',
    GET_VOLUME_DISCOUNT = '[buy-checkout-actions] get-volume-discount',
    SET_CURRENT_VOLUME_DISCOUNT_PRICE_AND_PERCENTAGE = '[buy-checkout-actions] set-current-volume-discount-price-and-percentage',
    ADJUST_CURRENT_VOLUME_DISCOUNT_FOR_REPRICE_COUPON = '[buy-checkout-actions] adjust-current-volume-discount-for-reprice-coupon',
    SET_PRORATED_AMOUNT = '[buy-checkout-actions] set-prorated-amount',
    SET_EXTRA_ADDON = '[buy-checkout-actions] set-extra-addon',
    SET_ENDPOINTS = '[buy-checkout-actions] set-endpoints',
    SET_AGENTS = '[buy-checkout-actions] set-agents',
    SET_TAX_TYPE = '[buy-checkout-actions] set-tax-type',
    SET_AUTO_LOGIN = '[resolve-checkout-form-actions] set-auto-login',
}

export const buyCheckoutActions = {
    setLoading: (loading: boolean) => createAction(BuyCheckoutActionTypes.SET_LOADING, { loading }),
    setPurchaseComplete: (complete: boolean) =>
        createAction(BuyCheckoutActionTypes.SET_PURCHASE_COMPLETE, { complete }),
    setBillingFrequency: (frequency: TimePeriod) =>
        createAction(BuyCheckoutActionTypes.SET_BILLING_FREQUENCY, { frequency }),
    clearAddons: () => createAction(BuyCheckoutActionTypes.CLEAR_ADDONS),
    setSelectedPlan: (planSku: string) => createAction(BuyCheckoutActionTypes.SET_SELECTED_PLAN, { planSku }),
    setSelectedAddons: (addons: BuySelectedAddon[]) =>
        createAction(BuyCheckoutActionTypes.SET_SELECTED_ADDONS, { addons }),
    setOrganizers: (organizers: number) => createAction(BuyCheckoutActionTypes.SET_ORGANIZERS, { organizers }),
    createBillingInfo: (billingValues: BuyBillingInfoDataType) =>
        createAction(BuyCheckoutActionTypes.CREATE_BILLING_INFO, {
            billingValues,
        }),
    createPayment: (paymentValues: BuyPaymentDataType) =>
        createAction(BuyCheckoutActionTypes.CREATE_PAYMENT, {
            paymentValues,
        }),
    submitPayment: (submitValues: BuySubmitDataType | BuySubmitWithCardAddDataType) =>
        createAction(BuyCheckoutActionTypes.SUBMIT_PAYMENT, {
            submitValues,
        }),
    createAndSubmitPayment: (submitValues: BuySubmitWithCardAddDataType) =>
        createAction(BuyCheckoutActionTypes.CREATE_AND_SUBMIT_PAYMENT, {
            submitValues,
        }),
    getPaymentMethods: () => createAction(BuyCheckoutActionTypes.GET_AVAILABLE_PAYMENT_METHODS),
    calculatePrice: (values: BuyFormData, component?: string, planSKU?: string) =>
        createAction(
            BuyCheckoutActionTypes.CALCULATE_PRICE,
            {
                values,
                planSKU,
            },
            { component }
        ),
    setCalculatedPrice: (
        productPrices: BuyProductPriceItem[],
        net: number,
        total: number,
        tax: number,
        creditback: number,
        anniversaryDate: string
    ) =>
        createAction(BuyCheckoutActionTypes.SET_CALCULATED_PRICE, {
            productPrices,
            net,
            total,
            tax,
            creditback,
            anniversaryDate,
        }),
    getSavedState: (urlPlan: string) =>
        createAction(BuyCheckoutActionTypes.GET_SAVED_STATE, {
            urlPlan,
        }),
    setSavedStateLoaded: (savedStateLoaded: boolean, component: string) =>
        createAction(
            BuyCheckoutActionTypes.SET_SAVED_STATE_LOADED,
            {
                savedStateLoaded,
            },
            { component }
        ),
    setTotalLoading: (loading: boolean) =>
        createAction(BuyCheckoutActionTypes.SET_LOADING_TOTAL, {
            loading,
        }),
    setAddonComplete: (addonComplete: boolean) =>
        createAction(BuyCheckoutActionTypes.SET_ADDON_COMPLETE, {
            addonComplete,
        }),
    resetCheckoutState: () => createAction(BuyCheckoutActionTypes.RESET_CHECKOUT_STATE),
    setSelectedTierIndex: (selectedTierIndex: number, organizers: number) =>
        createAction(BuyCheckoutActionTypes.SET_SELECTED_TIER_INDEX, { selectedTierIndex, organizers }),
    calculateVolumeDiscount: () => createAction(BuyCheckoutActionTypes.CALCULATE_VOLUME_DISCOUNT),
    setVolumeDiscount: (
        selectedPlanSku: string,
        currency: CURRENCY,
        volumeDiscountForSelectedPlanAndCurrency: BuyBillingFrequencyVolumeDiscountRecords
    ) =>
        createAction(BuyCheckoutActionTypes.SET_VOLUME_DISCOUNT, {
            selectedPlanSku,
            currency,
            volumeDiscountForSelectedPlanAndCurrency,
        }),
    setVolumeDiscountLoading: (volumeDiscountLoading: boolean) =>
        createAction(BuyCheckoutActionTypes.SET_VOLUME_DISCOUNT_LOADING, { volumeDiscountLoading }),
    getVolumeDiscount: (organizers: number, selectedTierIndex: number, billingFrequency: TimePeriod) =>
        createAction(BuyCheckoutActionTypes.GET_VOLUME_DISCOUNT, {
            organizers,
            selectedTierIndex,
            billingFrequency,
        }),
    setCurrentVolumeDiscountPriceAndPercentage: (currentVolumeDiscountPrice: number) =>
        createAction(BuyCheckoutActionTypes.SET_CURRENT_VOLUME_DISCOUNT_PRICE_AND_PERCENTAGE, {
            currentVolumeDiscountPrice,
        }),
    setProratedAmount: (proratedAmount: number) =>
        createAction(BuyCheckoutActionTypes.SET_PRORATED_AMOUNT, { proratedAmount }),
    adjustCurrentVolumeDiscountForRepriceCoupon: () =>
        createAction(BuyCheckoutActionTypes.ADJUST_CURRENT_VOLUME_DISCOUNT_FOR_REPRICE_COUPON),
    setExtraAddons: (addons: BuyAddon[]) => createAction(BuyCheckoutActionTypes.SET_EXTRA_ADDON, { addons }),
    setEndpoints: (endpoints: number) => createAction(BuyCheckoutActionTypes.SET_ENDPOINTS, { endpoints }),
    setAgents: (agents: number) => createAction(BuyCheckoutActionTypes.SET_AGENTS, { agents }),
    setTaxType: (type: string) => createAction(BuyCheckoutActionTypes.SET_TAX_TYPE, { type }),
    setAutoLogin: (autoLogin?: string) =>
        createAction(BuyCheckoutActionTypes.SET_AUTO_LOGIN, {
            autoLogin,
        }),
}

export type BuyCheckoutActions = ActionUnion<typeof buyCheckoutActions>
