import React, { FunctionComponent } from 'react'
import { useMediaQuery } from 'react-responsive'

import { HeaderLabels } from '@gtc/state/header-labels'
import { screenSize } from '@common/constants/screenSizes'
import { ConvertStringToReact } from '../ConvertStringToReact'

// Import Local Styles
import styles from './header.module.css'

export interface HeaderProps {
    logo: string
    mobileLogo: string
    content: HeaderLabels
    isUserLoggedIn?: boolean
    logoUrl: string
    onLogin: () => void
    onLogout: () => void
}

export const Header: FunctionComponent<HeaderProps> = ({
    logo,
    mobileLogo,
    content,
    isUserLoggedIn,
    logoUrl,
    onLogin,
    onLogout,
}) => {
    const isInMobileView = useMediaQuery({
        query: screenSize.mobile,
    })

    // setting the default logo based on whether the screen size is desktop or mobile
    const headerLogo = isInMobileView ? mobileLogo : logo

    return (
        <header className={styles.header}>
            <div className={styles.wrapper}>
                <a href={logoUrl} className={styles['logo-link']} aria-label="GoTo Home Page">
                    <img id="header-checkoutlogo" src={headerLogo} alt="goto logo" className={styles.logo} />
                </a>
                {content.questions && (
                    <div className={styles['support-copy']}>
                        <span className={styles['extra-copy']}>
                            <ConvertStringToReact htmlString={content.questions} />{' '}
                        </span>
                        <ConvertStringToReact htmlString={content.headerPhone} />
                    </div>
                )}
                <div className={styles.account}>
                    {isUserLoggedIn ? (
                        <div>
                            <button className={styles.loginOut} data-qat="sign-in-sign-out-link" onClick={onLogout}>
                                {content.signOut}
                            </button>
                        </div>
                    ) : (
                        <div>
                            <button className={styles.loginOut} data-qat="sign-in-sign-out-link" onClick={onLogin}>
                                {content.signIn}
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </header>
    )
}
