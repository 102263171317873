import { BillingPeriod, CURRENCY, TimePeriod } from '@common/constants'
import { BuyPlan } from '@buy/state/plan'
import { BuyPrice, BuyPricing, BuyTiers } from '@buy/state/price'

const tierMap = new Map<string, number>()

export const getSelectedTierIndex = (
    currentOrganizers: number,
    billingFrequency: string,
    selectedPlan: BuyPlan,
    currency: CURRENCY
) => {
    const { sKU = '', pricingInfo } = selectedPlan ?? {}
    const identifier = `${sKU}-${currency}-${billingFrequency}-${currentOrganizers}`
    if (!tierMap.get(identifier)) {
        const currencyPricing = (pricingInfo?.[currency as keyof BuyPrice] as BuyPricing) ?? []
        const billingFrequencyKey = billingFrequency === TimePeriod.Year ? BillingPeriod.ANNUAL : BillingPeriod.MONTHLY
        const billingFrequencyTier = currencyPricing[billingFrequencyKey as keyof BuyPricing] ?? []

        const billingFrequencyTierKeys = Object.keys(billingFrequencyTier)

        billingFrequencyTierKeys.some((tierName, index) => {
            const tierData = billingFrequencyTier[tierName as keyof BuyTiers]
            if (currentOrganizers >= tierData.min && currentOrganizers <= tierData.max) {
                tierMap.set(identifier, index)
                return true
            }
            return false
        })
    }

    return tierMap.get(identifier) as number
}
