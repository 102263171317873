import { call, takeEvery, put, select, delay } from 'redux-saga/effects'
import { buyFormActions, BuyFormActionTypes } from '@gtc/state/buy-form/actions'
import { fatalErrorActions } from '@common/state/fatal-error/actions'
import { flexActions } from '@common/state/flex/actions'
import { userActions } from '@gtc/state/user/actions'
import { checkoutActions } from '@gtc/state/checkout/actions'
import { UserDataType, UserState, UserAccountType, AbandonedCartDataType } from '@gtc/state/user/state'
import {
    BillingInfoDataType,
    CreditCardDataType,
    PaymentDataType,
    SubmitDataType,
    CheckoutState,
    PaymentDataReturnType,
} from '@gtc/state/checkout/state'
import { createTokenSaga } from '@common/sagas/flex/create-flex-token'
import { createUserSaga } from '@gtc/saga/user/create-user'
import { getBillingValues } from '@gtc/utils/get-billing-values'
import { getSelectedAddons, getSelectedPlan } from '@gtc/saga/content/content-helpers'
import { getPaymentItems } from '@gtc/utils/get-item-values'
import { getBuyFormState, getCheckoutState, getContentData, getCouponState, getUserState } from '@gtc/state/selectors'
import { getFlexState, getLocationState } from '@common/state/selectors'
import { CHECKOUTFORM_STEPS, TRACKING_COMPONENTS, USER_TYPES } from '@gtc/constants'
import { marketingActions } from '@common/state/marketing/actions'
import { MarketoUserInfo } from '@common/state/marketing/state'
import { makeMarketoObject } from '@common/utils'
import { LocationState } from '@common/state/location/state'
import { FlexState } from '@common/state/flex/state'
import { ContentData } from '@gtc/state/content-data'
import { gtcTrackingActions } from '@gtc/state/tracking/actions'
import { PRODUCTS, SC_SITE, TRACKING_EVENTS } from '@common/constants'
import { clearState, getActiveCouponServerData } from '@gtc/utils'
import { Plan } from '@gtc/state/plan'
import { Addon } from '@gtc/state/add-on'
import { BuyFormState } from '@gtc/state/buy-form/state'
import { CouponState } from '@gtc/state/coupon/state'
import { CouponServerData } from '@common/state/coupon/state'
import isEmpty from 'lodash/isEmpty'
import { SubmitPaymentResponseData } from '@gtc/state/submit-payment/state'
import { createBillingInfoSaga } from './create-billing-info'
import { createPaymentSaga } from './create-payment'
import { submitPaymentSaga } from './submit-payment'
import { getAvailablePaymentMethodsSaga } from './get-payment-methods'
import { orderPhoneNumber } from '../number-picker/reserve-phone-number-helpers'

function* submitFormSaga(action: ReturnType<typeof buyFormActions.submitForm>) {
    const componentName = TRACKING_COMPONENTS.FORM_SUBMISSION_SAGA
    const steps: Record<CHECKOUTFORM_STEPS, boolean> = {
        [CHECKOUTFORM_STEPS.CREATE_FLEX_TOKEN]: false,
        [CHECKOUTFORM_STEPS.CREATE_USER_ACCOUNT]: false,
        [CHECKOUTFORM_STEPS.SAVE_BILLING_INFO]: false,
        [CHECKOUTFORM_STEPS.SAVE_PAYMENT_INFO]: false,
        [CHECKOUTFORM_STEPS.GET_AVAILABLE_PAYMENT_METHODS]: false,
        [CHECKOUTFORM_STEPS.SUBMIT_PAYMENT]: false,
    }
    const {
        FirstName: firstName,
        LastName: lastName,
        Email: email,
        Street1,
        ZipCode,
        City,
        StateCode,
        Password: password,
        ContactPhone,
        ExpirationDate,
    } = action.payload.values
    const checkout: CheckoutState = yield select(getCheckoutState)
    const { userType }: UserState = yield select(getUserState)
    const { locationInfo }: LocationState = yield select(getLocationState)
    const { fingerprint, keyInfo }: FlexState = yield select(getFlexState)

    const { submittoMarketo, marketoProductName, marketoSalesBrief, marketoSalesforceCampaignId }: ContentData =
        yield select(getContentData)
    const buyFormState: BuyFormState = yield select(getBuyFormState)
    const userAccountAlreadyCreated =
        buyFormState.steps.createUserAccount.completed ||
        userType === USER_TYPES.TRIALER ||
        userType === USER_TYPES.EXPIRED ||
        userType === USER_TYPES.CROSSPRODUCT
    steps[CHECKOUTFORM_STEPS.CREATE_USER_ACCOUNT] = userAccountAlreadyCreated

    const selectedPlan: Plan = yield call(getSelectedPlan)
    const selectedAddons: Addon[] = yield call(getSelectedAddons)
    const coupon: CouponState = yield select(getCouponState)
    const { isCouponValidAndApplied } = coupon
    const couponServerData: CouponServerData | null = yield call(getActiveCouponServerData, coupon, selectedPlan)
    const isCouponValid = isCouponValidAndApplied && couponServerData && !isEmpty(couponServerData)

    try {
        const billingValues: BillingInfoDataType = getBillingValues(action.payload.values)
        const items = getPaymentItems(selectedPlan, checkout.organizers, checkout.billingFrequency, selectedAddons)

        const userValues: UserDataType = {
            email,
            password,
            firstName,
            lastName,
            phoneNumber: ContactPhone,
            country: billingValues.country,
            fingerPrintSessionId: fingerprint,
            productName: PRODUCTS.CONNECT,
        }
        // #region Get flex token
        const flexTokenAction = flexActions.createToken(ExpirationDate)
        const tokenData: string = yield call(createTokenSaga, flexTokenAction)
        if (tokenData) {
            steps[CHECKOUTFORM_STEPS.CREATE_FLEX_TOKEN] = true
        } else {
            yield put(
                gtcTrackingActions.track(
                    {
                        event: TRACKING_EVENTS.ERROR_PURCHASE,
                        eventData: { errorMessage: 'failed to create flex token' },
                    },
                    componentName
                )
            )
        }
        yield put(
            buyFormActions.updateSteps(
                CHECKOUTFORM_STEPS.CREATE_FLEX_TOKEN,
                steps[CHECKOUTFORM_STEPS.CREATE_FLEX_TOKEN]
            )
        )
        // #endregion

        // #region Create user account
        if (!userAccountAlreadyCreated) {
            const createUserAction = userActions.createNewUser(userValues)
            const userData: UserAccountType = yield call(createUserSaga, createUserAction)
            if (userData) {
                steps[CHECKOUTFORM_STEPS.CREATE_USER_ACCOUNT] = true
                yield put(checkoutActions.setAutoLogin(userData.xAutoLoginTicket))
            }
            yield put(
                buyFormActions.updateSteps(
                    CHECKOUTFORM_STEPS.CREATE_USER_ACCOUNT,
                    steps[CHECKOUTFORM_STEPS.CREATE_USER_ACCOUNT]
                )
            )
        }
        // #endregion

        // #region Save billing info
        if (steps[CHECKOUTFORM_STEPS.CREATE_USER_ACCOUNT] && steps[CHECKOUTFORM_STEPS.CREATE_FLEX_TOKEN]) {
            const extraBillingValues = { ...billingValues }
            extraBillingValues.taxNumber = '' // should this be changed or made a constant?
            extraBillingValues.federalTaxType = null
            extraBillingValues.phoneNumber = ContactPhone

            const createBillingInfoAction = checkoutActions.createBillingInfo(extraBillingValues)
            const savedBillingData: boolean = yield call(createBillingInfoSaga, createBillingInfoAction)
            if (savedBillingData) {
                steps[CHECKOUTFORM_STEPS.SAVE_BILLING_INFO] = true
            }
            yield put(
                buyFormActions.updateSteps(
                    CHECKOUTFORM_STEPS.SAVE_BILLING_INFO,
                    steps[CHECKOUTFORM_STEPS.SAVE_BILLING_INFO]
                )
            )
        }
        // #endregion

        // #region Save payment method
        if (steps[CHECKOUTFORM_STEPS.SAVE_BILLING_INFO] && steps[CHECKOUTFORM_STEPS.CREATE_FLEX_TOKEN]) {
            const flexFormData: CreditCardDataType = {
                expirationMonth: ExpirationDate.substr(0, 2),
                expirationYear: `20${ExpirationDate.substr(3, 5)}`,
                flexResponse: tokenData,
                flexKeyId: keyInfo.flexKey.keyId,
                firstName,
                lastName,
                email,
            }

            // API Reference: https://confluence.ops.expertcity.com/x/t1unCw
            const paymentValues: PaymentDataType = {
                paymentMethodType: 'CC',
                flexFormData,
                fingerprintSessionId: fingerprint,
                currency: locationInfo.currency,
                cardinalJwt: null,
                isScaCompleted: false,
            }
            const createPaymentAction = checkoutActions.createPayment(paymentValues)
            const savedPaymentData: boolean = yield call(createPaymentSaga, createPaymentAction)
            if (savedPaymentData) {
                steps[CHECKOUTFORM_STEPS.SAVE_PAYMENT_INFO] = true
            }
            yield put(
                buyFormActions.updateSteps(
                    CHECKOUTFORM_STEPS.SAVE_PAYMENT_INFO,
                    steps[CHECKOUTFORM_STEPS.SAVE_PAYMENT_INFO]
                )
            )
        }
        // #endregion

        // #region Get payment methods
        let paymentMethodKey = ''
        if (steps[CHECKOUTFORM_STEPS.SAVE_PAYMENT_INFO]) {
            const getAvailablePaymentData: PaymentDataReturnType[] = yield call(getAvailablePaymentMethodsSaga)
            if (getAvailablePaymentData && getAvailablePaymentData[0]) {
                steps[CHECKOUTFORM_STEPS.GET_AVAILABLE_PAYMENT_METHODS] = true
                paymentMethodKey = getAvailablePaymentData[0].paymentMethodKey
            }
            yield put(
                buyFormActions.updateSteps(
                    CHECKOUTFORM_STEPS.GET_AVAILABLE_PAYMENT_METHODS,
                    steps[CHECKOUTFORM_STEPS.GET_AVAILABLE_PAYMENT_METHODS]
                )
            )
        }
        // #endregion

        // #region Process purchase
        if (steps[CHECKOUTFORM_STEPS.GET_AVAILABLE_PAYMENT_METHODS]) {
            const submitValues: SubmitDataType = {
                currency: locationInfo.currency,
                paymentMethodKey,
                products: items,
                grossAmount: checkout.calculatedTotal,
                couponCode: isCouponValid ? couponServerData?.couponCode : '',
                anniversaryDate: checkout.anniversaryDate,
                fingerprintSessionId: fingerprint,
            }

            const submitPaymentAction = checkoutActions.submitPayment(submitValues)
            const submitData: SubmitPaymentResponseData = yield call(submitPaymentSaga, submitPaymentAction)
            if (submitData) {
                steps[CHECKOUTFORM_STEPS.SUBMIT_PAYMENT] = true
                yield put(
                    gtcTrackingActions.track(
                        { event: TRACKING_EVENTS.PURCHASE, eventData: { invoiceNumber: submitData.invoiceNumber } },
                        componentName
                    )
                )
            }
            yield put(
                buyFormActions.updateSteps(CHECKOUTFORM_STEPS.SUBMIT_PAYMENT, steps[CHECKOUTFORM_STEPS.SUBMIT_PAYMENT])
            )
        }
        // #endregion

        // #region Set purchase complete
        if (steps[CHECKOUTFORM_STEPS.SUBMIT_PAYMENT]) {
            const abandonedCartData: AbandonedCartDataType = {
                email,
                success: true,
            }
            yield put(userActions.submitAbandonedCart(abandonedCartData))
            yield put(userActions.setDisplayName(firstName))
            yield put(
                gtcTrackingActions.track(
                    { event: TRACKING_EVENTS.COMPLETE_BUY_FLOW, eventData: { userEmail: email } },
                    componentName
                )
            )
            if (submittoMarketo) {
                const { planKey } = selectedPlan ?? {}
                const marketoData: MarketoUserInfo = makeMarketoObject({
                    firstName,
                    lastName,
                    email,
                    planKey,
                    Street1,
                    City,
                    StateCode,
                    ZipCode,
                    ContactPhone,
                    marketoSalesBrief,
                    marketoSalesforceCampaignId,
                    marketoProductName,
                })
                marketoData.sc_site = SC_SITE.CONNECT
                yield put(marketingActions.postToMarketo(marketoData))
            }
            yield put(
                gtcTrackingActions.track(
                    {
                        event: TRACKING_EVENTS.EMAIL_VALIDATION,
                        eventData: {
                            userEmail: email,
                            formName: TRACKING_COMPONENTS.CHECKOUT_PAGE,
                        },
                    },
                    componentName
                )
            )

            // Order phone number
            if (checkout.phoneNumber) {
                // Note: The following request fails a lot due to the asyncronous creation of new accounts on the backend.
                // It is a known issue. Hopefully the backend team will implement a retry mechanism like was done for trial signups.
                // But until then, the best I can do for this story is use a delay or a retry on the frontend.
                // So, I'm using a simple delay for now until further discussion can take place.
                yield delay(3000)
                yield call(orderPhoneNumber)
            }

            // delete saved state
            yield call(clearState)
            yield put(checkoutActions.setPurchaseComplete(true))
        }
        // #endregion
    } catch (e) {
        yield put(fatalErrorActions.setFatalError(true, { trackErrorMessage: 'regular form submission failed' }))
    }
}

function* initializeSubmitFormSaga() {
    yield takeEvery(BuyFormActionTypes.SUBMIT_BUY_FORM, submitFormSaga)
}

export default initializeSubmitFormSaga
