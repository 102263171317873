import React, { FunctionComponent, useState, useRef, useEffect } from 'react'
import classNames from 'classnames'

import styles from './customdropdown.module.css'

export interface CustomProps {
    error?: string
    hint?: string
    valid?: boolean
    label?: string | Element | React.ReactNode
    qaTag?: string
    modifier?: string
    labelClass?: string
    additionalClass?: string
    selectorClass?: string
    options?: Array<string | number>
    optionsContainerClass?: string
    optionsClass?: string
    name?: string
    disabled?: boolean
    onChange?: (event: React.MouseEvent<HTMLButtonElement>) => void
    value?: string | number
    customizeTheme?: string
    showAtTop?: boolean
    iconClass?: string
}

export const CustomDropdown: FunctionComponent<CustomProps> = ({
    label,
    error,
    options,
    hint,
    disabled = false,
    value,
    qaTag = '',
    valid,
    onChange,
    showAtTop = false,
    modifier = '',
    labelClass = '',
    selectorClass = '',
    additionalClass = '',
    customizeTheme = 'primary',
    optionsContainerClass = '',
    optionsClass = '',
    iconClass = '',
    name,
}) => {
    const [showCustomizeOptions, setShowCustomizeOptions] = useState(false)
    const dropdownRef = useRef<HTMLUListElement>(null)
    const [dropdownStyleHeight, setDropdownStyleHeight] = useState(0)

    useEffect(() => {
        if (showCustomizeOptions && dropdownRef.current) {
            setDropdownStyleHeight(dropdownRef.current.offsetHeight)
        }
    }, [showCustomizeOptions])

    // onBlur function to close the customized select dropdown
    const handleCustomizeBlur = (event: React.FocusEvent<HTMLElement>) => {
        event.stopPropagation()
        const customizedElem = document.getElementById('customDropdown')
        // Need timeout here to get the correct activeElement
        setTimeout(() => {
            if (customizedElem && !customizedElem.contains(document.activeElement)) {
                setShowCustomizeOptions(false)
            }
        }, 300)
    }

    const handleCustomize = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault()
        if (onChange) onChange(event)
    }

    const outerWrapperClassList = classNames({
        [modifier]: true,
    })

    const labelClassList = classNames({
        [labelClass]: true,
    })

    const innerWrapperClassList = classNames({
        [styles.error]: error,
        [styles.valid]: valid,
        [styles.disabled]: disabled,
        [additionalClass]: true,
        [`${additionalClass}--active`]: additionalClass && showCustomizeOptions,
    })

    const customizeSelectorStyles = classNames({
        [styles.selector]: true,
        [styles[`selector--${customizeTheme}`]]: true,
        [styles[`selector--${customizeTheme}-top`]]: showAtTop,
        [styles[`selector--${customizeTheme}-top-active`]]: showAtTop && showCustomizeOptions,
        [selectorClass]: true,
    })

    return (
        <div className={outerWrapperClassList}>
            {label && (
                <span className={labelClassList} id={name}>
                    {label}
                </span>
            )}
            <div className={innerWrapperClassList} onBlur={handleCustomizeBlur} id="customDropdown">
                {showAtTop && showCustomizeOptions && options && (
                    <ul
                        className={optionsContainerClass}
                        role="listbox"
                        tabIndex={-1}
                        aria-label={name}
                        ref={dropdownRef}
                        style={{ top: `-${dropdownStyleHeight - 25}px` }}
                    >
                        {options.map((option) => (
                            <li
                                key={`state-${option}`}
                                className={classNames({
                                    [optionsClass]: true,
                                    [`${optionsClass}--active`]: option === value,
                                })}
                                data-qat-value={option}
                            >
                                <button
                                    onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                                        handleCustomize(event)
                                        setShowCustomizeOptions(false)
                                    }}
                                    role="option"
                                    aria-selected={option === value}
                                >
                                    {option}
                                </button>
                            </li>
                        ))}
                    </ul>
                )}
                <button
                    onClick={() => setShowCustomizeOptions(!showCustomizeOptions)}
                    className={customizeSelectorStyles}
                    aria-label={`${name} ${value}`}
                    aria-haspopup="listbox"
                >
                    {iconClass && <span className={iconClass} />}
                    {value}
                </button>
                {!showAtTop && showCustomizeOptions && options && (
                    <ul className={optionsContainerClass} role="listbox" tabIndex={-1} aria-labelledby={name}>
                        {options.map((option) => (
                            <li
                                key={`state-${option}`}
                                className={classNames({
                                    [optionsClass]: true,
                                    [`${optionsClass}--active`]: option === value,
                                })}
                                data-qat-value={option}
                            >
                                <button
                                    onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                                        handleCustomize(event)
                                        setShowCustomizeOptions(false)
                                    }}
                                    role="option"
                                    aria-selected={option === value}
                                >
                                    {option}
                                </button>
                            </li>
                        ))}
                    </ul>
                )}
            </div>
            {error && (
                <div className={styles['error-message']} data-qat={qaTag ? `${qaTag}-error-message` : ''}>
                    {error}
                </div>
            )}
            {hint && <div className={styles.hint}>{hint}</div>}
        </div>
    )
}
