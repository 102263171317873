import isEmpty from 'lodash/isEmpty'

export interface UrlParams {
    pathname: string
    search: string
}
export const updatePlanUrlParam = (planName: string, planNameSet: Set<string>): UrlParams => {
    const { pathname, search } = window.location
    if (isEmpty(planName)) {
        return { pathname, search }
    }
    const currentLocationList = pathname.split('/')
    const possiblePlanNameIndex = currentLocationList.findIndex((cpath) => planNameSet.has(cpath.toLowerCase()))
    if (possiblePlanNameIndex > -1) {
        currentLocationList[possiblePlanNameIndex] = planName.toLowerCase()
    }
    const updatedPathname = currentLocationList.join('/')

    return { pathname: updatedPathname, search }
}

export const getResolveSuccessUrl = (successUrl: string, autoLogin: string | undefined) => {
    // if successUrl doesn't include gotoresolve.com, no further processing is required
    const target = 'gotoresolve.com'
    if (!successUrl.includes(target)) {
        return successUrl
    }
    let resolveSuccessUrl = successUrl
    if (autoLogin) {
        resolveSuccessUrl = `${resolveSuccessUrl}${resolveSuccessUrl.includes('?') ? '&' : '?'}ticket=${autoLogin}`
    }

    return resolveSuccessUrl
}

export const getLmiProSuccessUrl = (successUrl: string, autoLogin: string | undefined) => {
    let lmiproSuccessUrl = successUrl

    // TODO: WAE1-3569: Update redirect url as needed for LogMeIn Pro
    // See getResolveSuccessUrl() for example if needed

    if (autoLogin) {
        lmiproSuccessUrl = `${lmiproSuccessUrl}${lmiproSuccessUrl.includes('?') ? '&' : '?'}ticket=${autoLogin}`
    }

    return lmiproSuccessUrl
}
