import { call, put, select, takeEvery } from 'redux-saga/effects'
import { Country, LocationState } from '@common/state/location/state'
import { getLocationState } from '@common/state/selectors'
import { locationActions, LocationActionTypes } from '@common/state/location/actions'

export function* createGetInternationalBlocker() {
    const { countries, locationInfo }: LocationState = yield select(getLocationState)

    const isUserCountryAllowed: boolean = yield call(() =>
        countries.some((country: Country) => country.abbreviation === locationInfo.country)
    )

    yield put(locationActions.setIsUserCountryAllowed(isUserCountryAllowed))
}

function* initializeCreateGetInternationalBlocker() {
    yield takeEvery(LocationActionTypes.GET_IS_USER_COUNTRY_ALLOWED, createGetInternationalBlocker)
}

export default initializeCreateGetInternationalBlocker
