import React, { FunctionComponent, useEffect } from 'react'
import { connectRedux, GoToComponentProps } from '@common/utils'
import {
    mapInternationalBlockerActions,
    mapInternationalBlockerState,
    InternationalBlockerType,
} from '@gtc/connectedComponents/InternationalBlocker/connector'
import { ConnectedModal } from '@common/connectedComponents'
import { CommonButton, ConvertStringToReact } from '@common/components'
import { InternationalBlockerLabels } from '@gtc/state/international-blocker-labels'

import { InternationalBlockerViewState } from '@gtc/connectedComponents/InternationalBlocker/state'

import styles from './international-blocker.css'

type InternationalBlockerProps = GoToComponentProps<
    InternationalBlockerViewState,
    InternationalBlockerType,
    {
        labels: InternationalBlockerLabels
        isClosable: boolean
    }
>

const InternationalBlocker: FunctionComponent<InternationalBlockerProps> = ({ state, actions, props }) => {
    const { isLocationInfoLoaded, isCountriesLoaded, isUserCountryAllowed } = state

    const {
        notAvailableModalHeader,
        notAvailableModalContent,
        notAvailableModalButtonText,
        notAvailableModalButtonUrl,
    } = props.labels

    useEffect(() => {
        if (!isLocationInfoLoaded) {
            actions.getLocationInfo()
        }
        if (!isCountriesLoaded) {
            actions.getCountries()
        }

        if (isLocationInfoLoaded && isCountriesLoaded) {
            actions.getIsUserCountryAllowed()
        }
    }, [isLocationInfoLoaded, isCountriesLoaded, actions])

    return (
        <ConnectedModal
            isOpen={!isUserCountryAllowed}
            closeable={props.isClosable}
            onClose={() => actions.setIsUserCountryAllowed(true)}
        >
            <h3 className={styles.header}>{notAvailableModalHeader}</h3>
            <div className={styles.body}>
                <p>
                    <ConvertStringToReact htmlString={notAvailableModalContent} />
                </p>
            </div>
            <a href={notAvailableModalButtonUrl}>
                <CommonButton theme="legacy">{notAvailableModalButtonText}</CommonButton>
            </a>
        </ConnectedModal>
    )
}

const ConnectedInternationalBlocker = connectRedux(
    InternationalBlocker,
    mapInternationalBlockerState,
    mapInternationalBlockerActions
)

export { ConnectedInternationalBlocker }
