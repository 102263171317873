import { resolveCouponActions, ResolveCouponActionTypes } from '@gtresolve/state/coupon/actions'
import { ResolvePlan } from '@gtresolve/state/plan'
import isEmpty from 'lodash/isEmpty'
import { call, put, takeLatest } from 'redux-saga/effects'
import { getSelectedPlan } from '../content/content-helpers'

function* checkCMSCouponSaga(action: ReturnType<typeof resolveCouponActions.checkCMSCoupon>) {
    // clear out previous coupon processed data
    yield put(resolveCouponActions.resetCouponData())
    const { selectedPlanSku } = action.payload
    const selectedPlan: ResolvePlan = yield call(getSelectedPlan, selectedPlanSku)
    const { activeCoupon } = selectedPlan ?? {} ?? {}
    if (activeCoupon && !isEmpty(activeCoupon)) {
        // apply the cms coupon only if cms coupon is available
        yield put(resolveCouponActions.observeValidateCMSCoupon(activeCoupon))
    }
}

export default function* initialCheckCMSCouponSaga() {
    yield takeLatest(ResolveCouponActionTypes.CHECK_CMS_COUPON, checkCMSCouponSaga)
}
