export enum CHECKOUTFORM_STEPS {
    CREATE_FLEX_TOKEN = 'createFlexToken',
    CREATE_USER_ACCOUNT = 'createUserAccount',
    SAVE_BILLING_INFO = 'saveBillingInfo',
    SAVE_PAYMENT_INFO = 'savePaymentInfo',
    CARDINAL_SEND_CHALLENGE = 'sendCardinalChallenge',
    GET_AVAILABLE_PAYMENT_METHODS = 'getAvailablepaymentMethods',
    SUBMIT_PAYMENT = 'submitPayment',
    CARDINAL_TRIGGER_BIN_PROCESS = 'triggerBinProcess',
}
