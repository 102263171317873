import { ResolveBillingInfoDataType } from '@gtresolve/state/checkout/state'
import { ResolveBuyFormData } from '@gtresolve/state/buy-form/state'

export const getBillingValues = (values: ResolveBuyFormData) => {
    const billingValues: ResolveBillingInfoDataType = {
        addressLine1: values.Street1,
        city: values.City,
        state: values.StateCode,
        country: values.CountryCode,
        postalCode: values.ZipCode,
        firstName: values.BillingFirstName,
        lastName: values.BillingLastName,
        email: values.Email,
        companyName: values.CompanyName,
        phoneNumber: '',
        taxNumber: values.TaxNumber,
        federalTaxType: values.TaxType,
    }
    return billingValues
}
