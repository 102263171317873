import React, { FunctionComponent, useState, useEffect } from 'react'
import InputMask, { ReactInputMask } from 'react-input-mask'
import classNames from 'classnames'

import { GenericInputProps } from './GenericInput'

// Import Local Styles
import styles from './input.module.css'

export interface MaskedInputProps extends GenericInputProps {
    mask?: string | Array<string | RegExp>
    maskChar?: string
    alwaysShowMask?: boolean
    beforeMaskedStateChange?: (nextState: Record<string, unknown>) => void
    maskedInputRef?: React.RefObject<ReactInputMask> | null
}

export const MaskedInput: FunctionComponent<MaskedInputProps> = (props) => {
    const { mask, warning, error, onChange, valid, value, maskedInputRef, ...rest } = props

    const [inputValue, setInputValue] = useState(value || '')

    const classList = classNames({
        [styles.input]: true,
        [styles.error]: error,
        [styles.valid]: valid,
        [styles.warning]: warning,
    })

    useEffect(() => {
        setInputValue(value || '')
    }, [value])

    return (
        <InputMask
            {...{
                ...rest,
            }}
            mask={mask || ''}
            className={classList}
            onChange={(e) => {
                setInputValue(e.target.value)
                if (onChange) {
                    onChange(e)
                }
            }}
            ref={maskedInputRef}
            value={inputValue}
        />
    )
}
