import { call, takeEvery, put } from 'redux-saga/effects'
import lmiproServices from '@lmipro/services'
import { contentActions, LmiProContentActionTypes } from '@lmipro/state/content/actions'

import { fatalErrorActions } from '@common/state/fatal-error/actions'
import { locationActions } from '@common/state/location/actions'
import { GenericResponse } from '@common/state/generic-response/generic-response'
import { PRODUCT_SKU } from '@common/constants'

function* loadContentSaga() {
    try {
        const labelServerResponse: GenericResponse = yield call(lmiproServices.getLmiProLabelData)
        if (labelServerResponse && labelServerResponse.data) {
            const { labels, plans, ...content } = labelServerResponse.data
            const { sEOPageTitle } = labels
            const plansWithPricing = plans.map((plan: any) => {
                if (plan.sKU === PRODUCT_SKU.LMIPRO_REMOTE_ACCESS) {
                    return {
                        ...plan,
                        pricingInfo: {
                            USD: {
                                monthly: {
                                    tier1: {
                                        min: 1,
                                        max: 4,
                                        price: 29.0,
                                    },
                                    tier2: {
                                        min: 5,
                                        max: 10,
                                        price: 27.55,
                                    },
                                    tier3: {
                                        min: 11,
                                        max: 25,
                                        price: 26.1,
                                    },
                                    tier4: {
                                        min: 26,
                                        max: 50,
                                        price: 24.65,
                                    },
                                    tier5: {
                                        min: 51,
                                        max: 2147483647,
                                        price: 23.2,
                                    },
                                },
                                annual: {
                                    tier1: {
                                        min: 1,
                                        max: 4,
                                        price: 23.0,
                                    },
                                    tier2: {
                                        min: 5,
                                        max: 10,
                                        price: 21.85,
                                    },
                                    tier3: {
                                        min: 11,
                                        max: 25,
                                        price: 20.7,
                                    },
                                    tier4: {
                                        min: 26,
                                        max: 50,
                                        price: 19.55,
                                    },
                                    tier5: {
                                        min: 51,
                                        max: 2147483647,
                                        price: 18.4,
                                    },
                                },
                            },
                        },
                    }
                }
                return plan
            })
            yield call(() => {
                if (sEOPageTitle) document.title = sEOPageTitle
            })
            locationActions.getLocationInfo()
            yield put(contentActions.setContent(content, { ...labels }, plansWithPricing, plansWithPricing))
            yield put(contentActions.setContentLoaded(true))
        }
    } catch (e) {
        yield put(fatalErrorActions.setFatalError(true, { trackErrorMessage: 'label data failed to load' }))
    }
}

function* initializeLmiProLoadContentSaga() {
    yield takeEvery(LmiProContentActionTypes.GET_LABEL_CONTENT, loadContentSaga)
}

export default initializeLmiProLoadContentSaga
