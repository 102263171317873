import { BillingDetailedSubscriptionPlan } from '@common/state/billing/state'
import { BuyPlan } from '@buy/state/plan'
import { PRODUCT_TYPES } from '@common/constants'

export const getUpgradingStatus = (accountSubscriptions: BillingDetailedSubscriptionPlan[]) => {
    return accountSubscriptions && accountSubscriptions.length > 0
}

export const getSubscriptionId = (accountSubscriptions: BillingDetailedSubscriptionPlan[]) => {
    if (accountSubscriptions) {
        return accountSubscriptions.find((sub) => sub.planType === PRODUCT_TYPES.PAID && sub.status === 'Active')
            ?.subscriptionId
    }
    return undefined
}

export const getSubscriptionMatch = (
    subscriptionId: number | undefined,
    selectedPlan: BuyPlan,
    accountSubscriptions: BillingDetailedSubscriptionPlan[]
) => {
    if (accountSubscriptions && subscriptionId) {
        return !!accountSubscriptions.find(
            (sub) => sub.subscriptionId === subscriptionId && sub.productSku === selectedPlan.sKU
        )
    }
    return false
}
