import { locationInitialData } from '@common/data'
import { LocationState } from './state'
import { LocationActionTypes } from './actions'
import type { LocationActions } from './actions'

export const initialLocationState: LocationState = {
    ...locationInitialData,
}

export const locationReducer = (previousState: LocationState | undefined, action: LocationActions): LocationState => {
    const state = previousState || initialLocationState
    switch (action.type) {
        case LocationActionTypes.SET_COUNTRIES: {
            return { ...state, countries: action.payload.countries }
        }
        case LocationActionTypes.SET_STATES: {
            return { ...state, states: action.payload.states }
        }
        case LocationActionTypes.SET_VALID_ADDRESS: {
            return {
                ...state,
                validAddress: action.payload.isValid,
            }
        }
        case LocationActionTypes.SET_CORRECTED_ADDRESS: {
            return {
                ...state,
                correctedAddress: action.payload.address,
            }
        }
        case LocationActionTypes.SET_IS_VALIDADING_ADDRESS: {
            return {
                ...state,
                isValidatingAddress: action.payload.isValidatingAddress,
            }
        }
        case LocationActionTypes.SET_ADDRESS_ERRORS: {
            return {
                ...state,
                addressErrors: action.payload.addressErrors,
            }
        }
        case LocationActionTypes.SET_LOCATION_INFO: {
            return {
                ...state,
                locationInfo: action.payload.locationInfo,
            }
        }
        case LocationActionTypes.SET_IS_LOCATION_INFO_LOADED: {
            return {
                ...state,
                isLocationInfoLoaded: action.payload.isLocationInfoLoaded,
            }
        }
        case LocationActionTypes.SET_FETCHED_COUNTRIES: {
            return {
                ...state,
                isCountriesLoaded: action.payload.isCountriesLoaded,
            }
        }
        case LocationActionTypes.SET_IS_USER_COUNTRY_ALLOWED: {
            return {
                ...state,
                isUserCountryAllowed: action.payload.isUserCountryAllowed,
            }
        }
        default:
            return state
    }
}
