type languages = {
    [key: string]: string
}
export const LANGUAGE_CODES: languages = {
    English: 'en',
    Español: 'es',
    Deutsch: 'de',
    Français: 'fr',
    Italiano: 'it',
    Nederlands: 'nl',
    Português: 'pt',
}
