import { BuyFormData } from '@gtc/state/buy-form/state'
import isEmpty from 'lodash/isEmpty'

export const isValidationAddressEligible = (formValues: BuyFormData): boolean => {
    if (
        isEmpty(formValues.FirstName.trim()) ||
        isEmpty(formValues.LastName.trim()) ||
        isEmpty(formValues.Email.trim()) ||
        isEmpty(formValues.BillingFirstName.trim()) ||
        isEmpty(formValues.BillingLastName.trim())
    ) {
        return false
    }
    return true
}

export const isAddressComplete = (formValues: BuyFormData): boolean => {
    if (
        isEmpty(formValues.CountryCode.trim()) ||
        isEmpty(formValues.City.trim()) ||
        isEmpty(formValues.Street1.trim()) ||
        isEmpty(formValues.StateCode.trim()) ||
        isEmpty(formValues.ZipCode.trim())
    ) {
        return false
    }
    return true
}
