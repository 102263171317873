import { CheckCouponProductsValidityParameters, CouponSkuValidity } from '@gtc/state/coupon/state'
import { CouponServerData } from '@common/state/coupon/state'
import { CalculateCouponPriceActionPayload } from '@gtc/state/coupon-util'
import { couponActions, CouponActionTypes } from '@gtc/state/coupon/actions'
import {
    checkCouponGenericValidity,
    checkCouponProductsValidity,
    checkIfCouponAppliedToMinimumSelectedProducts,
} from '@gtc/utils'
import { call, put, takeLatest } from 'redux-saga/effects'

function* validateCouponSaga(action: ReturnType<typeof couponActions.validateCoupon>) {
    const { couponValidationData, isAttemptingUrlCoupon } = action.payload
    const { couponServerData, selectedPlan, selectedAddons, billingFrequency } = couponValidationData
    const selectedPlanSku = selectedPlan.sKU
    const isCouponGenericValid: boolean = yield call(checkCouponGenericValidity, couponServerData as CouponServerData)
    if (isCouponGenericValid) {
        const checkProductValidityArguments: CheckCouponProductsValidityParameters = {
            couponServerData,
            selectedPlanSku,
            selectedAddons,
            billingFrequency,
        }
        const couponSkuValidity: CouponSkuValidity = yield call(
            checkCouponProductsValidity,
            checkProductValidityArguments
        )
        const isCouponAppliedToMinimumProductsInCart: boolean = yield call(
            checkIfCouponAppliedToMinimumSelectedProducts,
            couponSkuValidity
        )

        yield put(couponActions.setCouponValidAndApplied(isCouponAppliedToMinimumProductsInCart))
        if (isCouponAppliedToMinimumProductsInCart) {
            const calculateCouponPriceActionPayload: CalculateCouponPriceActionPayload = {
                ...couponValidationData,
                couponSkuValidity,
            }
            yield put(couponActions.calculateCouponPrice(calculateCouponPriceActionPayload))
            yield put(couponActions.setUrlCouponActive(isAttemptingUrlCoupon))
        } else if (isAttemptingUrlCoupon) {
            // look out for cms coupon code if current try is url coupon
            yield put(couponActions.checkCMSCoupon(selectedPlanSku))
        } else {
            // no coupon can be applied in this case
            yield put(couponActions.resetCouponData())
        }
    } else if (isAttemptingUrlCoupon) {
        // look out for cms coupon code if current try is url coupon
        yield put(couponActions.checkCMSCoupon(selectedPlanSku))
    } else {
        // no coupon can be applied in this case
        yield put(couponActions.resetCouponData())
    }
}

export default function* initializeValidateCouponSaga() {
    yield takeLatest(CouponActionTypes.VALIDATE_COUPON, validateCouponSaga)
}
