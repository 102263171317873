import React, { FunctionComponent } from 'react'
import { GoToComponentProps, connectRedux } from '@common/utils'
import { FormatCurrencyState } from '@gtc/connectedComponents/FormatCurrency/state'
import {
    FormatCurrencyActionsType,
    mapFormatCurrencyState,
    mapFormatCurrencyActions,
} from '@gtc/connectedComponents/FormatCurrency/connector'
import { FormatDecimalCurrency } from '@common/components'

type FormatDecimalCurrencyProps = GoToComponentProps<
    FormatCurrencyState,
    FormatCurrencyActionsType,
    {
        price: number
    }
>

const FormatDecimalCurrencyGTC: FunctionComponent<FormatDecimalCurrencyProps> = ({ state, props }) => {
    return <FormatDecimalCurrency {...props} {...state} />
}

export const ConnectedFormatDecimalCurrency = connectRedux(
    FormatDecimalCurrencyGTC,
    mapFormatCurrencyState,
    mapFormatCurrencyActions
)
