import React, { FunctionComponent } from 'react'

import Trust from '@common/images/img-trust-arc.svg'
import ClockIcon from '@common/images/icon-clock.svg'

import classNames from 'classnames'
import styles from './trust-arc-logo.css'

export interface TrustArcLogoProps {
    modifier?: string
    label?: string
}

export const TrustArcLogo: FunctionComponent<TrustArcLogoProps> = ({ modifier = '', label }) => {
    const wrapperClassList = classNames({
        [styles.wrapper]: true,
        [modifier]: !!modifier,
    })
    return (
        <div className={wrapperClassList}>
            <a
                href="https://privacy.truste.com/privacy-seal/validation?rid=2853fc28-1c77-46f4-8ff8-e4289f5e569b"
                target="_blank"
                rel="noreferrer"
            >
                <img className={styles.logo} src={Trust} alt="TRUSTe" />
            </a>
            {label && (
                <div className={styles.support}>
                    <img className={styles['clock-icon']} src={ClockIcon} alt="clock" />
                    {label}
                </div>
            )}
        </div>
    )
}
