export enum CHECKOUTFORM_FIELDS {
    FIRST_NAME = 'FirstName',
    LAST_NAME = 'LastName',
    EMAIL = 'Email',
    PASSWORD = 'Password',
    BILLING_FIRST_NAME = 'BillingFirstName',
    BILLING_LAST_NAME = 'BillingLastName',
    STREET1 = 'Street1',
    COUNTRY_CODE = 'CountryCode',
    CITY = 'City',
    STATE_CODE = 'StateCode',
    ZIP_CODE = 'ZipCode',
    CONTACT_PHONE = 'ContactPhone',
    EXPIRATION_DATE = 'ExpirationDate',
    TAX_ID = 'TaxNumber',
    COMPANY_NAME = 'CompanyName',
}
