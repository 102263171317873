export const getExternalUrl = () => {
    const hostName = window.location.hostname.toLowerCase()
    if (hostName.includes('buydevcm')) {
        return 'https://buydevcm.goto.com'
    }
    if (hostName.includes('buydevcd')) {
        return 'https://buydevcd.goto.com'
    }
    return ''
}
