import { BoxWrapper, InputField, Tooltip, InlineErrorView } from '@common/components'
import { FieldProps, Field } from 'formik'
import React, { FunctionComponent, useEffect } from 'react'
import PencilIcon from '@common/images/icon-modify-on.svg'
import CreditIcon from '@common/images/img-icon-credit@2x.png'
import SecureIcon from '@common/images/icon-secure.svg'
import { ConnectedFlexForm } from '@common/connectedComponents'
import { connectRedux, GoToComponentProps } from '@common/utils'
import { PaymentFormInfoState } from '@gtc/connectedComponents/PaymentFormInfo/state'
import {
    mapPaymentFormInfoActions,
    mapPaymentFormInfoState,
    PaymentFormInfoActionsType,
} from '@gtc/connectedComponents/PaymentFormInfo/connector'
import { CHECKOUTFORM_FIELDS } from '@gtc/constants'
import { FlexField } from '@common/components/FlexField/flex-field'
import { DIRECTIONS } from '@common/constants'
import paymentStyles from './payment-info.module.css'

export interface PaymentFormInfoComponentProps {
    resetFatalError: () => void
    hasExistingPaymentInformation: boolean
    setIsPaymentModalOpen: (value: React.SetStateAction<boolean>) => void
    trackPaymentStep: () => void
    setHasCardValidationErrors: (value: React.SetStateAction<boolean>) => void
    setCheckoutFormInlineError: (value: string) => void
    removeCheckoutFormInlineError: (value: string) => void
    inlineErrors: { [key: string]: string } | Record<string, unknown>
}
type PaymentFormInfoProps = GoToComponentProps<
    PaymentFormInfoState,
    PaymentFormInfoActionsType,
    PaymentFormInfoComponentProps
>

export const PaymentFormInfo: FunctionComponent<PaymentFormInfoProps> = ({ state, actions, props }) => {
    const {
        content,
        errorContent,
        isFatalError,
        existingPaymentInformation,
        isCardValid,
        isCvvValid,
        userInteractedWithFlexField,
        userInteractedWithFlexFieldCvv,
        microformCreated,
    } = state
    const {
        resetFatalError,
        setIsPaymentModalOpen,
        hasExistingPaymentInformation,
        trackPaymentStep,
        setHasCardValidationErrors,
        setCheckoutFormInlineError,
        removeCheckoutFormInlineError,
        inlineErrors,
    } = props

    // effects
    // if credit card is invalid display error message
    useEffect(() => {
        if (!isCardValid && userInteractedWithFlexField) {
            setHasCardValidationErrors(true)
            if ((inlineErrors && !inlineErrors.cardCC) || !inlineErrors) {
                setCheckoutFormInlineError('cardCC')
            }
        }
        if (isCardValid && userInteractedWithFlexField) {
            setHasCardValidationErrors(false)
            if (inlineErrors && inlineErrors.cardCC) {
                removeCheckoutFormInlineError('cardCC')
            }
        }

        if (!isCvvValid && userInteractedWithFlexFieldCvv) {
            setHasCardValidationErrors(true)
            if ((inlineErrors && !inlineErrors.cardCvv) || !inlineErrors) {
                setCheckoutFormInlineError('cardCvv')
            }
        }

        if (isCvvValid && userInteractedWithFlexFieldCvv) {
            setHasCardValidationErrors(false)
            if (inlineErrors && inlineErrors.cardCvv) {
                removeCheckoutFormInlineError('cardCvv')
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        isCardValid,
        setHasCardValidationErrors,
        userInteractedWithFlexField,
        userInteractedWithFlexFieldCvv,
        isCvvValid,
        inlineErrors,
    ])

    return (
        <BoxWrapper>
            <div className="payment-info">
                <div className={paymentStyles.wrapper}>
                    <div className={paymentStyles.heading}>
                        <div className={paymentStyles['payment-details']}>
                            <h3>{content.paymentDetails}</h3>
                            <div className={paymentStyles['secure-badge']}>
                                <img className={paymentStyles['secure-icon']} src={SecureIcon} alt="secure" />
                                {content.secure}
                            </div>
                        </div>
                        {hasExistingPaymentInformation && (
                            <button onClick={() => setIsPaymentModalOpen(true)} type="button">
                                <img src={PencilIcon} alt="pencil" />
                                {content.edit}
                            </button>
                        )}
                    </div>
                    <div className={paymentStyles.selector}>
                        <img
                            alt="Credit Cards Badge"
                            className={paymentStyles['cc-img']}
                            src={CreditIcon}
                            title="ccbadge"
                        />
                    </div>
                    <div className={`${paymentStyles['cc-grid']}`}>
                        {!hasExistingPaymentInformation && <ConnectedFlexForm shouldRefresh={isFatalError} />}

                        {/* Credit Card Number */}
                        {!hasExistingPaymentInformation ? (
                            <div className={paymentStyles.cc}>
                                <FlexField
                                    id="cc__number"
                                    aria-label="credit card number"
                                    flexFieldType="number"
                                    wrapperClass={paymentStyles['cc-wrapper']}
                                    valid={isCardValid}
                                    labelCopy={content.cardNumber}
                                    hasError={!isCardValid && userInteractedWithFlexField}
                                    microformCreated={microformCreated === true}
                                    errorCopyNotEmpty={errorContent.errorInvalidCreditCardNumber}
                                    errorCopyEmpty={errorContent.errorRequiredCreditCardNumber}
                                    onChange={(valid: boolean) => {
                                        actions.setCardValid(valid)
                                        actions.setUserInteractedWithFlexField(false)
                                    }}
                                    onBlur={() => {
                                        actions.setUserInteractedWithFlexField(true)
                                        trackPaymentStep()
                                        resetFatalError()
                                    }}
                                    shouldRefresh={isFatalError}
                                />
                            </div>
                        ) : (
                            // Show a disabled field if isAddonFlow
                            <InputField
                                value={`**** **** **** ${existingPaymentInformation?.last4Digits ?? '****'}`}
                                id="cc"
                                aria-label="credit card number"
                                inputMode="numeric"
                                label={content.cardNumber}
                                type="tel"
                                wrapperClass={paymentStyles.cc}
                                disabled
                            />
                        )}

                        {/* Expiration Date */}
                        <Field name={CHECKOUTFORM_FIELDS.EXPIRATION_DATE}>
                            {({ form, field, meta }: FieldProps) => {
                                return (
                                    <InlineErrorView
                                        fieldData={{ form, field, meta }}
                                        inlineErrors={inlineErrors}
                                        setErrorCall={setCheckoutFormInlineError}
                                        removeErrorCall={removeCheckoutFormInlineError}
                                    >
                                        <InputField
                                            name={field.name}
                                            aria-label="expiration date"
                                            error={meta.error}
                                            value={hasExistingPaymentInformation ? '**/**' : field.value}
                                            hint="MM/YY"
                                            id="exp-date"
                                            ismasked={true}
                                            inputMode="numeric"
                                            label={content.expirationDate}
                                            mask={!hasExistingPaymentInformation ? '99/99' : ''}
                                            disabled={hasExistingPaymentInformation}
                                            type="tel"
                                            wrapperClass={paymentStyles['exp-date']}
                                            onBlur={async (e) => {
                                                await form.setFieldValue(field.name, e.target.value.trim())
                                                field.onBlur(e)
                                                form.validateField(field.name)
                                                resetFatalError()
                                            }}
                                            onChange={(e) => {
                                                field.onChange(e)
                                            }}
                                            valid={!meta.error && meta.touched}
                                        />
                                    </InlineErrorView>
                                )
                            }}
                        </Field>

                        {/* CVV Code */}
                        <div className={paymentStyles.cvv} aria-labelledby="cc__cvv">
                            {!hasExistingPaymentInformation ? (
                                <FlexField
                                    id="cc__cvv"
                                    aria-label="cvv"
                                    flexFieldType="securityCode"
                                    wrapperClass={paymentStyles['cvv-wrapper']}
                                    valid={isCvvValid}
                                    labelCopy={content.cVV}
                                    hasError={!isCvvValid && userInteractedWithFlexFieldCvv}
                                    microformCreated={microformCreated === true}
                                    errorCopyNotEmpty={errorContent.errorInvalidCVV}
                                    errorCopyEmpty={errorContent.errorRequiredCVV}
                                    onChange={(valid: boolean) => {
                                        actions.setCvvValid(valid)
                                        actions.setUserInteractedWithFlexFieldCvv(false)
                                    }}
                                    onBlur={() => {
                                        actions.setUserInteractedWithFlexFieldCvv(true)
                                        resetFatalError()
                                    }}
                                    shouldRefresh={isFatalError}
                                />
                            ) : (
                                // Show a disabled field if isAddonFlow
                                <InputField
                                    name="cvv"
                                    aria-label="cvv"
                                    value="***"
                                    hint={content.cVVHint}
                                    id="SecurityCode"
                                    inputMode="numeric"
                                    label={content.cVV}
                                    type="tel"
                                    wrapperClass={paymentStyles.cvv}
                                    disabled
                                />
                            )}
                            <div className={paymentStyles['tooltip-holder']}>
                                <Tooltip direction={DIRECTIONS.RIGHT} content={content.cVVPopupInfo} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </BoxWrapper>
    )
}

export const ConnectedPaymentFormInfo = connectRedux(
    PaymentFormInfo,
    mapPaymentFormInfoState,
    mapPaymentFormInfoActions
)
