import { takeEvery, put, call } from 'redux-saga/effects'
import { fatalErrorActions } from '@common/state/fatal-error/actions'
import { flexActions, FlexActionTypes } from '@common/state/flex/actions'
import { createFlexMicroform, setFlexMicroform } from '@common/utils'
import { Microform } from '@common/state/flex/state'

export function* createFlexMicroformSaga(action: ReturnType<typeof flexActions.createFlexMicroform>) {
    const { keyInfo } = action.payload

    try {
        const microformInstance: Microform = yield call(createFlexMicroform, keyInfo)

        if (microformInstance) {
            yield call(setFlexMicroform, microformInstance)
            yield put(flexActions.setMicroformCreated(true))
        }
    } catch (e) {
        yield put(fatalErrorActions.setFatalError(true, { trackErrorMessage: 'could not create flex microform' }))
    }
}

function* initializeCreateFlexMicroformSaga() {
    yield takeEvery(FlexActionTypes.CREATE_MICROFORM, createFlexMicroformSaga)
}

export default initializeCreateFlexMicroformSaga
