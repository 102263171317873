import { ActionUnion, createAction } from '@common/utils'
import { TrackingState } from './state'

export enum TrackingActionTypes {
    TRACK = '[tracking] generic-event',
}

export const trackingActions = {
    track: (trackingInfo: TrackingState, component: string) =>
        createAction(TrackingActionTypes.TRACK, { trackingInfo }, { component }),
}

export type TrackingActions = ActionUnion<typeof trackingActions>
