import { STORAGE_CONST } from '@common/constants'
import { SavedSessionState } from '@gtc/state/checkout/state'

export const loadSessionState = () => {
    try {
        const serializedState = sessionStorage.getItem(STORAGE_CONST.GTC_COUPON_STORE)

        if (serializedState === null) {
            return undefined
        }

        return JSON.parse(serializedState) as SavedSessionState
    } catch (err) {
        return undefined
    }
}

export const saveSessionState = (state: SavedSessionState) => {
    try {
        const serializedState = JSON.stringify(state)
        sessionStorage.setItem(STORAGE_CONST.GTC_COUPON_STORE, serializedState)
    } catch (err) {
        // fail silently
    }
}

export const clearSessionState = () => {
    try {
        const serializedState = sessionStorage.getItem(STORAGE_CONST.GTC_COUPON_STORE)

        if (serializedState) sessionStorage.removeItem(STORAGE_CONST.GTC_COUPON_STORE)
    } catch (err) {
        // fail silently
    }
}
