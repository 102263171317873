import { LmiProCouponActions, LmiProCouponActionTypes } from './actions'
import { LmiProCouponState } from './state'

export const initialCouponState: LmiProCouponState = {
    couponServerData: null,
    couponProcessedDataPerSku: null,
    isUrlCouponActive: false,
    isCMSCouponActive: false,
    isCouponValidAndApplied: false,
    couponDoubleDipAmountForNewUser: 0,
    isUrlCouponCodeAvailable: false,
    urlCouponCode: '',
}

export const couponReducer = (previousState: LmiProCouponState | undefined, action: LmiProCouponActions) => {
    const state = previousState || initialCouponState
    switch (action.type) {
        case LmiProCouponActionTypes.SET_COUPON_SERVER_DATA: {
            const { couponServerData } = action.payload
            return {
                ...state,
                couponServerData,
            }
        }
        case LmiProCouponActionTypes.SET_COUPON_PROCESSED_DATA: {
            const { couponProcessedDataPerSku } = action.payload
            return { ...state, couponProcessedDataPerSku }
        }
        case LmiProCouponActionTypes.RESET_COUPON_DATA: {
            return {
                ...state,
                couponProcessedDataPerSku: null,
                isCMSCouponActive: false,
                isUrlCouponActive: false,
                isCouponValidAndApplied: false,
                couponDoubleDipAmountForNewUser: 0,
            }
        }
        case LmiProCouponActionTypes.SET_COUPON_VALID_AND_APPLIED: {
            const { isCouponValidAndApplied } = action.payload
            return { ...state, isCouponValidAndApplied }
        }
        case LmiProCouponActionTypes.SET_COUPON_DOUBLE_DIP_AMOUNT_FOR_NEW_USER: {
            const { couponDoubleDipAmountForNewUser } = action.payload
            return { ...state, couponDoubleDipAmountForNewUser }
        }
        case LmiProCouponActionTypes.SET_URL_COUPON_ACTIVE: {
            const { isUrlCouponActive } = action.payload
            return {
                ...state,
                isUrlCouponActive,
                isCMSCouponActive: !isUrlCouponActive,
            }
        }
        case LmiProCouponActionTypes.SET_URL_COUPON_CODE: {
            const { couponCode } = action.payload
            return { ...state, urlCouponCode: couponCode, isUrlCouponCodeAvailable: couponCode.length > 0 }
        }
        default:
            return state
    }
}
