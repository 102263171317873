// AppInsights.js
import { ApplicationInsights, ICustomProperties, IEventTelemetry } from '@microsoft/applicationinsights-web'
import { ReactPlugin } from '@microsoft/applicationinsights-react-js'
import { createBrowserHistory } from 'history'

let appInsights: ApplicationInsights

const appInsightsTrackEvent = (e: IEventTelemetry, customProperties: ICustomProperties) => {
    appInsights.trackEvent(e, customProperties)
}

const initializeApplicationInsights = () => {
    const browserHistory = createBrowserHistory()
    const reactPlugin = new ReactPlugin()

    appInsights = new ApplicationInsights({
        config: {
            instrumentationKey: process.env.GTC_AZURE_INSIGHT_INSTRUMENTATION_KEY,
            extensions: [reactPlugin],
            extensionConfig: {
                [reactPlugin.identifier]: { history: browserHistory },
            },
        },
    })
    appInsights.loadAppInsights()

    // This script to mask the auth_token in the url before it gets sent to azure ai through the js sdk.
    appInsights.addTelemetryInitializer((envelope) => {
        const telemetryItem = Object.values(envelope).find((obj) => obj.uri) || {}

        if (telemetryItem.uri && telemetryItem.uri.includes('access_token')) {
            const newURL = telemetryItem.uri.replace(/access_token=.*/, 'access_token=[masked]')

            Object.keys(telemetryItem).forEach((key) => {
                if (typeof telemetryItem[key] === 'string' && telemetryItem[key].includes('access_token')) {
                    telemetryItem[key] = telemetryItem[key].replace(telemetryItem.uri, newURL)
                }
            })
        }
    })
    appInsights.trackPageView()

    return reactPlugin
}
export { initializeApplicationInsights, appInsightsTrackEvent }
