import { FieldProps } from 'formik'

export const manuallyValidateFieldData = (fieldData: FieldProps) => {
    return (
        (!fieldData.meta.error && fieldData.meta.touched) ||
        (fieldData.meta.initialValue !== '' && !fieldData.meta.error)
    )
}

export const scrollToError = () => {
    const top =
        document.getElementsByClassName('input__error')[0] ||
        document.getElementsByClassName('input-module__error')[0] ||
        document.getElementsByClassName('flex-field__flex-field--error')[0]
    if (top) {
        top.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
    }
}
