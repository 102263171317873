import { Microform, KeyInfo } from '@common/state/flex/state'
import { FlexConfig } from '../../../typings/window-globals'

export const createFlexMicroform = (keyInfo: KeyInfo): Promise<Microform> => {
    return new Promise((resolve, reject) => {
        const flex = new window.Flex(keyInfo.flexKey.keyId)
        try {
            const microformInstance = flex.microform({
                styles: {
                    input: {
                        'font-size': '16px',
                        'font-family': 'ProximaNova, sans-serif',
                        'font-weight': '400',
                        color: '#000',
                    },
                    ':focus': { color: '#000' },
                    valid: { color: '#000' },
                    invalid: { color: '#E60502' },
                },
            } as FlexConfig)
            resolve(microformInstance)
        } catch (_ex) {
            reject(new Error('error in creating microform instance'))
        }
    })
}
