import React, { FunctionComponent } from 'react'
import icon from '@common/images/icon-info.svg'
import { BoxWrapper } from '../BoxWrapper'
import styles from './info-view.module.css'

export const InfoView: FunctionComponent = ({ children }) => {
    return (
        <section className={styles.wrapper}>
            <BoxWrapper modifier={styles.msgBlock}>
                <img className={styles.img} src={icon} alt="info icon" />
                <div className={styles.msg}>{children}</div>
            </BoxWrapper>
        </section>
    )
}

export default InfoView
